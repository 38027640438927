import { API, Auth } from 'aws-amplify'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import uuid from 'react-native-uuid'

const getHeaders = async (isAuth, tgt, screenId) => {
  const headers = {
    'Content-Type': 'application/json',
    tgt,
    correlationid: uuid.v4(),
  }
  if (isAuth) {
    const user = await Auth.currentSession()
    const idToken = user.idToken.jwtToken
    const decoded = jwt_decode(idToken)
    const userRoleDetails = JSON.parse(decoded.userRoleDetails)
    headers.Authorization = `Bearer ${idToken}`
    headers.idToken = idToken
    headers.roleId = userRoleDetails?.[0]?.userRoleId
    headers.screenId = screenId || ''
    headers.tenantId = userRoleDetails[0].tenantId
    headers.userPerson = userRoleDetails?.[0]?.userType
  }
  return headers
}

const apiCall = async (
  tgt,
  methodName,
  body = {},
  isAuth = true,
  endPointName = 'Public',
) => {
  const headers = await getHeaders(isAuth, tgt, body?.extraHeader?.screen)
  try {
    const response = await API.post(endPointName, '/', {
      headers,
      body: {
        query: methodName,
        variables: body,
      },
    })
    return response?.data || response
  } catch (error) {
    return error
  }
}

export default apiCall
