import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'

export const useGlobalSearchData = ({ queryKey, searchText, type }) => {
  const queryClient = useQueryClient()
  const {
    data,
    refetch: refetchQuery,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
  } = useInfiniteQuery(
    queryKey,
    async () => {
      let response
      if (type === 'student') {
        response = await API.searchStudentAPI({ searchText })
      } else if (type === 'staff') {
        response = await API.searchStaffAPI({ searchText })
      }
      return response
    },
    {
      getNextPageParam: (_lastPage, pages) => {
        const total = pages?.reduce(
          (acc, page) => acc + (page?.items?.length ?? 0),
          0,
        )
        return total < _lastPage?.total ? _lastPage?.nextToken : undefined
      },
      enabled: false,
    },
  )

  const refetch = (text) => {
    if (text) {
      queryClient.removeQueries(queryKey, { exact: true })
      refetchQuery()
    }
  }

  const allData = data?.pages[0]?.flatMap((page) => page) || []
  return {
    allData,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
  }
}
