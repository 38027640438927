import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { SecureStore } from '@camped/utils'

const pendingUserAtom = atom({})
const userRollAtom = atomWithStorage('userRoll', '')
const toDoItemsAtom = atom({})
const persistedDomainAtom = atom(SecureStore.getCookie('domainDetail'))
const persistedUserAtom = atom(SecureStore.getCookie('user-details'))
const themeSwitchAtom = atomWithStorage('themeSwitchAtom', 'LIGHT')
// const userAtom = atomWithStorage('tenantDetails')
const yearOfEstablishmentAtom = atomWithStorage('yearOfEstablishmentAtom', '')
const aceprepToken = atomWithStorage('aceprepToken', '')
const socialToken = atomWithStorage('socialToken', '')

const menuItems = atomWithStorage('menuItems', [])

const domainAtom = atom(
  (get) => {
    const domainValue = get(persistedDomainAtom)
    const returnValue = domainValue?._j ?? domainValue
    return returnValue
  },

  async (get, set, value) => {
    let valueJson
    if (typeof value === 'function') {
      valueJson = (await SecureStore.getCookie('domainDetail')) || 'null'
    } else {
      valueJson = value
    }
    await SecureStore.setCookie('domainDetail', valueJson)
    set(persistedDomainAtom, valueJson)
  },
)
const userAtom = atom(
  (get) => {
    const domainValue = get(persistedUserAtom)
    const returnValue = domainValue?._j ?? domainValue
    return returnValue
  },

  async (get, set, value) => {
    let valueJson
    if (typeof value === 'function') {
      valueJson = (await SecureStore.getCookie('user-details')) || 'null'
    } else {
      valueJson = value
    }
    await SecureStore.setCookie('user-details', valueJson)
    set(persistedUserAtom, valueJson)
  },
)

export {
  aceprepToken,
  socialToken,
  domainAtom,
  menuItems,
  pendingUserAtom,
  themeSwitchAtom,
  toDoItemsAtom,
  userAtom,
  userRollAtom,
  yearOfEstablishmentAtom,
}
