import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import Keyboard from '@camped/components/Keyboard'
import { MobileContainer } from '@camped/shared-container'

const MobileView = (props) => {
  const {
    guessList,
    wordToGuess,
    gameOver,
    wordleEmoji,
    inputWord,
    MAX_WORD_LEN,
    disabledLetters,
    onKeyPress,
    getInitialBoard,
    colorState,
  } = props
  const { t } = useTranslation()
  const { colors } = useTheme()
  const board = getInitialBoard()

  return (
    <MobileContainer>
      <View style={{ flexGrow: 1, justifyContent: 'center' }}>
        {board.map((row, rowIndex) => (
          <View
            key={`row-${rowIndex}`}
            style={{
              marginBottom: 4,
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {row.map((_, colIndex) => {
              const guessLetter = guessList[rowIndex]?.[colIndex]
              let state = 'guess'

              if (guessLetter === undefined) {
                state = 'guess'
              } else if (guessLetter === wordToGuess.current[colIndex]) {
                state = 'correct'
              } else if (wordToGuess.current.includes(guessLetter)) {
                state = 'possible'
              } else {
                state = 'incorrect'
              }

              const letterToShow =
                rowIndex === guessList.length
                  ? inputWord[colIndex]
                  : guessLetter

              return (
                <View
                  key={`col-${colIndex}`}
                  style={{
                    height: 30,
                    width: 40,
                    backgroundColor: colorState.find((m) => m.state === state)
                      ?.color,
                    borderWidth: 1,
                    borderRadius: 4,
                    borderColor: colors.onPrimaryContainer,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text variant='titleSmall' state={state}>
                    {letterToShow || ''}
                  </Text>
                </View>
              )
            })}
          </View>
        ))}

        <View style={{ justifyContent: 'center' }}>
          {gameOver ? (
            <>
              <Text style={{ textAlign: 'center' }}>
                {t('Wordle.GAME_OVER')}
              </Text>
              <Text style={{ textAlign: 'center' }}>
                {t('Wordle.WORD_WAS')} {wordToGuess.current}
              </Text>

              <Text style={{ textAlign: 'center' }} selectable>
                {wordleEmoji}
              </Text>
            </>
          ) : (
            <Keyboard
              disabledKeyList={[
                ...disabledLetters,
                inputWord.length !== MAX_WORD_LEN ? 'ENTER' : '',
              ]}
              onKeyPress={onKeyPress}
            />
          )}
        </View>
      </View>
    </MobileContainer>
  )
}

export default MobileView
