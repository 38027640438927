/* eslint-disable react/no-unstable-nested-components */
import {
  useIsFocused,
  useNavigation,
  useNavigationState,
} from '@react-navigation/native'
import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { Platform, View, useWindowDimensions } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Divider } from '@camped/components'
import { Icon } from '@camped/icons'
import { domainAtom, userRollAtom } from '@camped/jotai-storage'
import { AuthScreen, Logout } from '@camped/shared-screen-authn'
import { ViewDetails } from '@camped/shared-screen-details'
import { Listing } from '@camped/shared-screen-listing'
import { ScreenLayout, SecureStore, appUtil } from '@camped/utils'

import { HeaderLeft, HeaderRight } from '../components/header'
import { staffValidRoute } from '../routes/staffRoute'
import { studentValidRoute } from '../routes/studentRoute'
import AcePrepScreen from '../screens/acePrepView'
import Permission from '../screens/configureRole'
import DepartmentDetail from '../screens/departmentDetail'
import DepartmentListing from '../screens/departmentListing'
import FieldPermissions from '../screens/fieldPermissions'
import GlobalSearch from '../screens/globalSearch'
import Home from '../screens/home'
import MarketPlaceScreen from '../screens/marketPlaceView'
import Roles from '../screens/roles'
import SocialScreen from '../screens/socialView'
import WebView from '../screens/webView'
import Wordle from '../screens/wordle'

export const Stacks = (Stack, props) => {
  const windowWidth = useWindowDimensions().width
  const { colors, spacing } = useTheme()
  const isDesktop = ScreenLayout.isWeb(windowWidth)
  const [domainDetails] = useAtom(domainAtom)
  const [userRoll, setUserRoll] = useAtom(userRollAtom)
  const navigation = useNavigation()
  const userDetails = SecureStore.getCookie('user-details')
  const currentRoute = useNavigationState(
    (state) => state?.routes?.[state.routes.length - 1],
  )?.name
  const isValidRoute =
    (userRoll === 'student' && !studentValidRoute.includes(currentRoute)) ||
    (['staff', 'admin'].includes(userRoll) &&
      !staffValidRoute.includes(currentRoute))

  if (isDesktop) {
    if (isValidRoute && userDetails) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      })
    }
  }

  const screenOptions = {
    headerShown: false,
    headerTitleAlign: 'center',
    headerTitle: '',
    headerStyle: {
      backgroundColor: isDesktop ? colors.surface1 : colors.background,
      borderColor: colors.surfaceVariant,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderWidth: 1,
      borderBottomWidth: 1,
    },
    headerTitleStyle: {
      color: colors.onPrimary,
    },
    headerLeft: () => (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Icon
          name='Menu'
          width={32}
          height={32}
          style={{ marginLeft: spacing.spacing6 }}
        />
        <Icon
          name='ColorLogo'
          width={88}
          height={36}
          style={{ marginLeft: spacing.spacing4 }}
        />
        <Divider />
      </View>
    ),
    headerRight: () => <HeaderRight />,
  }

  const loginSuccess = async (response) => {
    const userRoleDetails = response?.userRoleDetails
    let userRoles
    if (userRoleDetails) {
      userRoles = JSON.parse(response?.userRoleDetails)?.[0]
    }
    setUserRoll(userRoles?.userType.toLowerCase() || 'staff')
    if (Platform.OS === 'web') {
      if (window.location.host.includes(domainDetails?.domain)) {
        window.location.href = `${window.location.protocol}//${window.location.host}/home`
      } else {
        window.location.href = `${window.location.protocol}//${domainDetails?.domain}.${window.location.host}/home`
      }
    } else if (domainDetails) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      })
    } else {
      navigation.navigate('campus')
    }
  }

  const LaunchScreen = () => {
    const isFocused = useIsFocused()

    useEffect(() => {
      if (!isFocused) return
      ;(async () => {
        const domainName = domainDetails?.domain
          ? domainDetails?.domain
          : window?.location?.hostname?.split('.')?.reverse()[3]

        const user = await SecureStore.getCookie('user-details')
        try {
          if (user) {
            loginSuccess(user, navigation)
          } else if (domainName) {
            const domainLocal = window.location.host.split('.')?.[0]
            if (domainLocal === domainName) {
              window.location.href = `${window.location.protocol}//${window.location.host}/login`
            } else
              window.location.href = `${window.location.protocol}//${domainName}.${window.location.host}/login`
          } else {
            navigation.navigate('campus')
          }
        } catch (error) {
          if (domainName) {
            if (Platform.OS === 'web') {
              const currentDomain = window?.location?.hostname
                ?.split('.')
                ?.reverse()
                .pop()
              const targetDomain = domainName

              if (currentDomain === targetDomain) {
                navigation.navigate('login')
              } else {
                const { protocol } = window.location
                const { host } = window.location
                const domain = domainDetails?.domain
                const loginUrl = `${protocol}//${domain}.${host}/campus`
                window.location.href = loginUrl
              }
            } else {
              navigation.navigate('login')
            }
          } else {
            navigation.navigate('campus')
          }
        }
      })()
    }, [isFocused])

    // return <Home {...props} />
  }

  return (
    <Stack.Group screenOptions={screenOptions}>
      <Stack.Screen name='dashboard' options={{ title: 'Dashboard' }}>
        {(screenProps) => <LaunchScreen {...screenProps} {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name='home'
        options={() => ({
          title: 'Dashboard',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? 'Dashboard' : ''}
              hasImage={!isDesktop}
            />
          ),
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
        })}
      >
        {(screenProps) => <Home {...screenProps} {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name='logout'
        options={{
          title: 'Logout',
          headerShown: false,
        }}
      >
        {(screenProps) => <Logout {...screenProps} {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name='web-view'
        options={({ route }) => ({
          headerShown: true,
          headerTitleAlign: 'center',
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? route?.params?.headerTitle : ''}
              hasBack
            />
          ),
        })}
      >
        {(screenProps) => <WebView {...screenProps} {...props} />}
      </Stack.Screen>
      <Stack.Screen name='wordle' options={{ title: 'Wordle' }}>
        {(screenProps) => <Wordle {...screenProps} {...props} />}
      </Stack.Screen>

      <Stack.Screen name='global-search' options={{ title: '' }}>
        {(screenProps) => <GlobalSearch {...screenProps} {...props} />}
      </Stack.Screen>

      <Stack.Screen
        name='forgot-password'
        options={{ title: 'Forgot Password' }}
      >
        {(screenProps) => <AuthScreen {...screenProps} {...props} />}
      </Stack.Screen>
      <Stack.Screen name='reset-password' options={{ title: 'Reset Password' }}>
        {(screenProps) => <AuthScreen {...screenProps} {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name='change-password'
        options={{ title: 'Change Password' }}
      >
        {(screenProps) => <AuthScreen {...screenProps} {...props} />}
      </Stack.Screen>
      <Stack.Screen name='login' options={{ title: 'Login' }}>
        {(screenProps) => <AuthScreen {...screenProps} {...props} />}
      </Stack.Screen>
      <Stack.Screen name='campus' options={{ title: 'Campus' }}>
        {(screenProps) => <AuthScreen {...screenProps} {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name='course-listing'
        options={() => ({
          title: 'Learn',
          headerShown: true,
          headerLeft: () => <HeaderLeft title='Course' hasBack />,
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <ViewDetails {...screenProps} {...props} module='course-listing' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='my-profile'
        options={() => ({
          title: 'Update Student Details',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? 'Profile' : ''}
              hasImage={!isDesktop}
              hasBack
            />
          ),
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <ViewDetails
            {...screenProps}
            {...props}
            module={`${userRoll.toLowerCase()}Profile`}
          />
        )}
      </Stack.Screen>

      <Stack.Screen
        name='mock-interview'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft title='Mock Interview' hasBack hasImage={!isDesktop} />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => (
          <AcePrepScreen {...screenProps} {...props} path='mock/category' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='tailored-practice'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft
              title='Tailored Practice'
              hasBack
              hasImage={!isDesktop}
            />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => (
          <AcePrepScreen {...screenProps} {...props} path='tailored-practice' />
        )}
      </Stack.Screen>

      <Stack.Screen
        name='coding-problems'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft
              title='Coding Challenges'
              hasBack
              hasImage={!isDesktop}
            />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => (
          <AcePrepScreen {...screenProps} {...props} path='coding-problems' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='frontend-practice'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft
              title='Frontend Practice'
              hasBack
              hasImage={!isDesktop}
            />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => (
          <AcePrepScreen {...screenProps} {...props} path='frontend-practice' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='resume-builder'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft title='Resume Builder' hasBack hasImage={!isDesktop} />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => (
          <AcePrepScreen {...screenProps} {...props} path='resume' />
        )}
      </Stack.Screen>

      <Stack.Screen
        name='student-directory'
        options={() => ({
          title: 'Student Directory',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? 'Student' : ''}
              hasImage={!isDesktop}
            />
          ),
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
        })}
      >
        {(screenProps) => (
          <Listing {...screenProps} {...props} module='student' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='course-directory'
        options={() => ({
          title: 'Course Directory',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? 'Course' : ''}
              hasImage={!isDesktop}
            />
          ),
          headerRight: () => {},
        })}
      >
        {(screenProps) => (
          <Listing {...screenProps} {...props} module='course' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='academics-directory'
        options={() => ({
          title: 'Academics Directory',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? 'Academics' : ''}
              hasImage={!isDesktop}
            />
          ),
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
        })}
      >
        {(screenProps) => (
          <Listing {...screenProps} {...props} module='course' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='staff-directory'
        options={() => ({
          title: 'Staff Directory',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? 'Staff' : ''}
              hasImage={!isDesktop}
            />
          ),
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
        })}
      >
        {(screenProps) => (
          <Listing {...screenProps} {...props} module='staff' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='view-student-details'
        options={({ route }) => ({
          title: 'Student Details',
          headerShown: true,
          headerLeft: () => {
            const module = 'student'
            const firstName = route?.params?.firstName
            const userId = route?.params?.id

            const getTitle = () => {
              if (userId) {
                return `${appUtil.capitalize(firstName)}`
              }
              return `Add new ${module}`
            }

            return (
              <HeaderLeft
                title={isDesktop ? getTitle() : ''}
                hasBack={userId || !isDesktop}
                hasImage={!isDesktop}
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <ViewDetails {...screenProps} {...props} module='student' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='view-staff-details'
        options={({ route }) => ({
          title: 'Staff Details',
          headerShown: true,
          headerLeft: () => {
            const module = 'staff'
            const firstName = route?.params?.firstName
            const userId = route?.params?.id

            const getTitle = () => {
              if (userId) {
                return `${appUtil.capitalize(firstName)}`
              }
              return `Add new ${module}`
            }

            return (
              <HeaderLeft
                title={isDesktop ? getTitle() : ''}
                hasBack={userId || !isDesktop}
                hasImage={!isDesktop}
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <ViewDetails {...screenProps} {...props} module='staff' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='view-course-details'
        options={({ route }) => ({
          title: 'Course Details',
          headerShown: true,
          headerLeft: () => {
            const firstName = route?.params?.firstName

            return (
              <HeaderLeft
                title={isDesktop ? appUtil.capitalize(firstName) : ''}
                hasBack
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <ViewDetails {...screenProps} {...props} module='viewCourse' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='view-syllabus-details'
        options={({ route }) => ({
          title: 'Syllabus Details',
          headerShown: true,
          headerLeft: () => {
            const firstName = route?.params?.firstName

            return <HeaderLeft title={appUtil.capitalize(firstName)} hasBack />
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <ViewDetails {...screenProps} {...props} module='viewSyllabus' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='add-staff'
        options={({ route }) => ({
          title: 'Add Staff Details',
          headerShown: true,
          headerLeft: () => {
            const module = 'staff'
            const firstName = route?.params?.firstName
            const userId = route?.params?.userId

            const getTitle = () => {
              if (userId) {
                return `${appUtil.capitalize(firstName)}`
              }
              return `Add new ${module}`
            }

            return (
              <HeaderLeft
                title={isDesktop ? getTitle() : ''}
                hasBack
                hasImage={!isDesktop}
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <ViewDetails {...screenProps} {...props} module='staff' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='add-course'
        options={() => ({
          title: 'Add Course',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? 'Add New Course' : ''}
              hasBack
              hasImage={!isDesktop}
            />
          ),
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <ViewDetails {...screenProps} {...props} module='course' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='add-student'
        options={({ route }) => ({
          title: 'Add Student Details',
          headerShown: true,
          headerLeft: () => {
            const module = 'Student'
            const firstName = route?.params?.firstName
            const userId = route?.params?.userId

            const getTitle = () => {
              if (userId) {
                return `${appUtil.capitalize(firstName)}`
              }
              return `Add new ${module}`
            }

            return (
              <HeaderLeft
                title={isDesktop ? getTitle() : ''}
                hasBack
                hasImage={!isDesktop}
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <ViewDetails {...screenProps} {...props} module='student' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='configure-role-screen'
        options={{
          title: 'Role Permissions',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft title='Role Permissions' hasImage={!isDesktop} />
          ),
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
        }}
      >
        {(screenProps) => (
          <Permission {...screenProps} {...props} module='Role' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='configure-role-menu'
        options={{
          title: 'Menu Permissions',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft title='Menu Permissions' hasImage={!isDesktop} />
          ),
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
        }}
      >
        {(screenProps) => (
          <Permission {...screenProps} {...props} module='Menu' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='field-permissions'
        options={({ route }) => ({
          title: 'Field Permissions',
          headerShown: true,
          headerLeft: () => {
            if (!isDesktop) {
              return (
                <HeaderLeft
                  title={`${route?.params?.module} Permission - ${route?.params?.role}`}
                  hashead
                />
              )
            }
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight />
            }
          },
        })}
      >
        {(screenProps) => <FieldPermissions {...screenProps} {...props} />}
      </Stack.Screen>
      <Stack.Screen
        name='campus-configuration'
        options={({ route }) => ({
          title: 'Campus configuration',
          headerShown: true,
          headerLeft: () => {
            const userId = route?.params?.id

            return (
              <HeaderLeft
                title={isDesktop ? 'Campus Configuration' : ''}
                hasBack={userId || !isDesktop}
                hasImage={!isDesktop}
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <ViewDetails
            {...screenProps}
            {...props}
            module='campus-configuration'
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='department-directory'
        options={({ route }) => ({
          title: 'Department',
          headerShown: true,
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? 'Department' : ''}
              hasBack={route?.params?.isAdd}
              hasImage={!isDesktop && !route?.params?.isAdd}
            />
          ),
        })}
      >
        {(screenProps) => (
          <DepartmentListing {...screenProps} {...props} module='department' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='graduation-directory'
        options={({ route }) => ({
          title: 'Graduation',
          headerShown: true,
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? 'Graduation Type' : ''}
              hasBack={route?.params?.isAdd}
              hasImage={!isDesktop && !route?.params?.isAdd}
            />
          ),
        })}
      >
        {(screenProps) => (
          <DepartmentListing {...screenProps} {...props} module='graduation' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='degree-directory'
        options={({ route }) => ({
          title: 'Degree',
          headerShown: true,
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
          headerLeft: () => (
            <HeaderLeft
              title={isDesktop ? 'Degree' : ''}
              hasBack={route?.params?.isAdd}
              hasImage={!isDesktop && !route?.params?.isAdd}
            />
          ),
        })}
      >
        {(screenProps) => (
          <DepartmentListing {...screenProps} {...props} module='degree' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='add-department'
        options={({ route }) => ({
          title: '',
          headerShown: true,
          headerLeft: () => {
            const deparment = route?.params?.department
            const heading = deparment || 'Add New Department'
            return (
              <HeaderLeft
                title={isDesktop ? heading : ''}
                hasBack
                hasImage={!isDesktop}
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <DepartmentDetail
            {...screenProps}
            module='addDepartment'
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='add-graduation'
        options={() => ({
          title: '',
          headerShown: true,
          headerLeft: () => {
            const heading = 'Add New graduation Type'
            return (
              <HeaderLeft
                title={isDesktop ? heading : ''}
                hasBack
                hasImage={!isDesktop}
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <DepartmentDetail
            {...screenProps}
            module='addGraduation'
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='add-degree'
        options={() => ({
          title: '',
          headerShown: true,
          headerLeft: () => {
            const heading = 'Add New Degree'
            return (
              <HeaderLeft
                title={isDesktop ? heading : ''}
                hasBack
                hasImage={!isDesktop}
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <DepartmentDetail {...screenProps} module='addDegree' {...props} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='update-deparment'
        options={({ route }) => ({
          title: '',
          headerShown: true,
          headerLeft: () => {
            const deparment = route?.params?.department
            return (
              <HeaderLeft
                title={isDesktop ? deparment : ''}
                hasBack
                hasImage={!isDesktop}
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
            return null
          },
        })}
      >
        {(screenProps) => (
          <DepartmentDetail
            {...screenProps}
            module='updateDepartment'
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='roles'
        options={{
          title: 'Roles',
          headerShown: true,
          headerLeft: () => <HeaderLeft title='Roles' hasImage={!isDesktop} />,
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
        }}
      >
        {(screenProps) => <Roles {...screenProps} {...props} module='Role' />}
      </Stack.Screen>
      <Stack.Screen
        name='add-role'
        options={{
          title: 'Add Role',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft title='Create Role' hasImage={!isDesktop} hasBack />
          ),
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
        }}
      >
        {(screenProps) => (
          <Roles {...screenProps} {...props} module='Role' isAdd />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='assign-role'
        options={{
          title: 'Assign User',
          headerShown: true,
          headerLeft: () => (
            <HeaderLeft title='Assign User' hasImage={!isDesktop} hasBack />
          ),
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
        }}
      >
        {(screenProps) => (
          <Roles {...screenProps} {...props} module='assignRole' isAdd />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='role-details'
        options={({ route }) => ({
          title: 'Role Details',
          headerShown: true,
          headerLeft: () => {
            const name = route?.params?.name
            return (
              <HeaderLeft
                title={`${name} Role Users`}
                hasImage={!isDesktop}
                hasBack
              />
            )
          },
          headerRight: () => {
            if (!isDesktop) {
              return <HeaderRight hasSearch hasNotification hasProfile />
            }
          },
        })}
      >
        {(screenProps) => (
          <Listing {...screenProps} {...props} module='rolelisting' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='interview-events'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft title='Institution' hasBack hasImage={!isDesktop} />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => (
          <AcePrepScreen {...screenProps} {...props} path='institution' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='social-dashboard'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft title='Institution' hasBack hasImage={!isDesktop} />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => (
          <SocialScreen {...screenProps} {...props} path='dashboard' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='social-settings'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft title='Institution' hasBack hasImage={!isDesktop} />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => (
          <SocialScreen {...screenProps} {...props} path='settings' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='social-posts'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft title='Social Posts' hasBack hasImage={!isDesktop} />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => (
          <SocialScreen {...screenProps} {...props} path='posts' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='social-reports'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft title='Social Reports' hasBack hasImage={!isDesktop} />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => (
          <SocialScreen {...screenProps} {...props} path='reports' />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='market-place'
        options={() => ({
          title: '',
          headerShown: !isDesktop,
          headerLeft: () => (
            <HeaderLeft title='Institution' hasBack hasImage={!isDesktop} />
          ),
          headerRight: () => (
            <HeaderRight hasSearch hasNotification hasProfile />
          ),
        })}
      >
        {(screenProps) => <MarketPlaceScreen {...screenProps} {...props} />}
      </Stack.Screen>
    </Stack.Group>
  )
}
