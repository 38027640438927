/* eslint-disable no-undef */
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'

import { Text } from '@camped/components'
import { ScreenLayout } from '@camped/utils'

import DesktopView from './DesktopView'
import MobileView from './MobileView'
import words from './words.json'

const Wordle = () => {
  const [guessList, setGuessList] = useState([])
  const [inputWord, setInputWord] = useState('')
  const [gameOver, setGameOver] = useState(false)
  const [disabledLetters, setDisabledLetters] = useState([])
  const { t } = useTranslation()
  const wordToGuess = useRef('xxxxx')
  const MAX_GUESSES = 6
  const MAX_WORD_LEN = 5
  const colorState = [
    { state: 'guess', color: 'transparent' },
    { state: 'correct', color: '#76b041' },
    { state: 'incorrect', color: '#8b939c' },
    { state: 'possible', color: '#FFC914' },
  ]
  useEffect(() => {
    if (gameOver === false) {
      wordToGuess.current = getRandomWord()
      setInputWord('')
      setGuessList([])
    }
  }, [gameOver])

  useEffect(() => {
    const guessLen = guessList.length
    if (guessList[guessLen - 1] === wordToGuess.current) {
      setGameOver(true)
    } else if (guessLen === MAX_GUESSES) {
      setGameOver(true)
    }
  }, [guessList])

  useEffect(() => {
    const list = []

    guessList.forEach((word) => {
      word.split('').forEach((letter) => {
        if (!wordToGuess.current.includes(letter)) {
          list.push(letter)
        }
      })
    })

    setDisabledLetters(list)
  }, [guessList])

  const getInitialBoard = () => {
    const board = []
    for (let i = 0; i < 6; i += 1) {
      board.push(new Array(5).fill(''))
    }

    return board
  }

  const getRandomWord = () => {
    const len = words.length
    const randomIndex = Math.floor(Math.random() * 100000) % len
    return words[randomIndex].toUpperCase()
  }

  const getWordleEmoji = (word, guessed) => {
    const hasWon = guessed[guessed.length - 1] === word

    let output = `Wordle ${hasWon ? guessed.length : 'x'}/${MAX_GUESSES}\n\n`

    guessed.forEach((row) => {
      let line = ''

      row.split('').forEach((char, colIndex) => {
        if (char === word[colIndex]) {
          line += '🟩'
        } else if (word.includes(char)) {
          line += '🟨'
        } else {
          line += '⬜️'
        }
      })

      output += `${line}\n`
    })

    return output
  }

  const onKeyPress = useCallback(
    (key) => {
      if (key === 'DEL') {
        setInputWord((prev) => prev.slice(0, -1))
      } else if (key === 'ENTER') {
        setGuessList((prev) => [...prev, inputWord.toUpperCase()])
        setInputWord('')
      } else if (key.length === 1) {
        setInputWord((prev) => {
          if (prev.length < MAX_WORD_LEN && !disabledLetters.includes(key)) {
            return prev + key
          }

          return prev
        })
      }
    },
    [disabledLetters, inputWord],
  )

  useEffect(() => {
    if (Platform.OS === 'web') {
      const callback = (event) => {
        const { key } = event

        if (/^[A-Za-z]$/.test(key)) {
          onKeyPress(key.toUpperCase())
        } else if (key === 'Enter' && inputWord.length === MAX_WORD_LEN) {
          onKeyPress(SpecialKeyboardKeys.GUESS)
        } else if (key === 'Backspace') {
          onKeyPress(SpecialKeyboardKeys.DELETE)
        }
      }

      window.addEventListener('keyup', callback)
      return () => window.removeEventListener('keyup', callback)
    }
  }, [inputWord.length, onKeyPress])

  const wordleEmoji = useMemo(() => {
    if (!gameOver) {
      return ''
    }

    return getWordleEmoji(wordToGuess.current, guessList)
  }, [gameOver, guessList])

  const viewProps = {
    getInitialBoard,
    colorState,
    guessList,
    wordToGuess,
    gameOver,
    wordleEmoji,
    inputWord,
    setGameOver,
    MAX_WORD_LEN,
    disabledLetters,
    onKeyPress,
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  return (
    <Suspense fallback={<Text>{t('Wordle.LOADING')}</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default Wordle
