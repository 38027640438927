import { Platform } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import uuid from 'react-native-uuid'

import { SecureStore, fingerPrint } from '@camped/utils'

import { initializeAnalytics, trackEvent } from './utils/analytics'

class Audit {
  static logEvent = async (body) => {
    const tenantStored = await SecureStore.getItemAsync('tenantDetails')
    if (tenantStored) {
      const tenant = await JSON.parse(tenantStored)
      if (tenant?.['custom:userId']) {
        body.userId = tenant?.['custom:userId']
      }
      if (tenant?.userRoleDetails) {
        const userRoles = JSON.parse(tenant.userRoleDetails)?.[0]
        if (userRoles?.tenant) {
          body.tenantId = userRoles?.tenant
        }
      }
    }
    body.platform = Platform.OS
    if (Platform.OS !== 'web') {
      body.deviceId = DeviceInfo?.getUniqueId()
    } else {
      const visitorId = await fingerPrint?.getVisitorId()
      body.visitorId = visitorId
    }
    body.applicationType = '1'
    body.id = uuid.v4()
    body.timestamp = new Date().toISOString()

    initializeAnalytics()
    trackEvent(body.entityType, body.eventType, body.id)
  }
}

export default Audit
