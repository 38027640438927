import * as React from 'react'
import {
  I18nManager,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native'

import { Text } from '@camped/components'

const DataTableTitle = ({
  numeric,
  children,
  onPress,
  theme,
  textStyle,
  style,
  numberOfLines = 1,
  iconLeft,
  flexN,
  textVariant,
  ...rest
}) => {
  const getTextAlign = () => {
    if (numberOfLines > 1) {
      if (numeric) {
        if (I18nManager.getConstants().isRTL) {
          return { textAlign: 'left' }
        }
        return { textAlign: 'right' }
      }
      return {
        textAlign: 'center',
      }
    }
    return {}
  }

  return (
    <TouchableWithoutFeedback disabled={!onPress} onPress={onPress} {...rest}>
      <View
        style={[
          styles.container,

          numeric && styles.right,
          style,
          { flex: flexN },
        ]}
      >
        {iconLeft && (
          <View
            style={{
              marginRight: 12,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {iconLeft}
          </View>
        )}

        <Text
          style={[
            styles.cell,
            { maxHeight: 24 * numberOfLines },
            getTextAlign(),
            textStyle,
          ]}
          variant={textVariant || 'titleSmall'}
          numberOfLines={numberOfLines}
        >
          {children}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignContent: 'center',
  },
  right: {
    justifyContent: 'flex-end',
  },
  cell: {
    alignItems: 'center',
  },
})

export default DataTableTitle
