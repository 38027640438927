import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { useQueryClient } from '@tanstack/react-query'

import { Button } from '@camped/components'
import { DynamicField, FormContainer } from '@camped/form-container'
import Overlay from '@camped/shared-component-overlay'

import { capitalize } from '../utils/capitailize'

const Filter = (props) => {
  const {
    isFilterVisible,
    setIsFilterVisible,
    data,
    hasDelete = false,
    handleDropdownChange,
    getLookupData,
    filterValue,
    disableField,
    setErrors,
    refetch,
    module,
    totalCountRefecth,
  } = props
  const { colors, spacing, fonts } = useTheme()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const deleteStyle = { borderWidth: 0, color: colors.onSurface1 }
  const renderItem = (metaData) => {
    const lookupData = getLookupData(metaData?.fieldName)
    const multiSelect = ['course', 'degree']?.includes(
      (item) => item === metaData?.fieldName,
    )
    const value =
      metaData?.fieldName === 'isInvitationSent'
        ? filterValue?.[metaData?.fieldName] === true
          ? 'true'
          : filterValue?.[metaData?.fieldName] === false
          ? 'false'
          : ''
        : filterValue?.[metaData?.fieldName]
    return (
      <DynamicField
        data={metaData}
        onChangeText={handleDropdownChange}
        isEdit
        value={value}
        list={lookupData?.length ? lookupData : metaData?.options}
        onFocus={setErrors}
        disabled={disableField(metaData)}
        multiSelect={multiSelect}
      />
    )
  }
  return (
    <Overlay
      isVisible={isFilterVisible}
      setVisible={setIsFilterVisible}
      title={t('Directory.FILTER_BY')}
    >
      <View
        style={{ paddingTop: spacing.spacing3, paddingLeft: spacing.spacing4 }}
      >
        <FormContainer
          data={data}
          renderItem={renderItem}
          width='100%'
          filter={true}
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: hasDelete ? 'space-between' : 'flex-end',
          flex: 1,
        }}
      >
        {hasDelete && (
          <Button
            mode='outlined'
            style={{
              alignSelf: 'flex-end',
              marginLeft: spacing.spacing3,
              borderColor: colors.error,
              ...deleteStyle,
            }}
            textColor={deleteStyle?.color ? deleteStyle?.color : colors.error}
            labelStyle={{
              ...fonts.titleSmall,
            }}
            onPress={() => {
              queryClient.setQueriesData(
                [`filter${capitalize(module)}Value`],
                () => ({}),
              )
              setTimeout(() => {
                refetch()
              }, 300)
              setIsFilterVisible(false)
            }}
          >
            {t('Directory.CLEAR')}
          </Button>
        )}

        <Button
          mode='contained'
          style={{
            backgroundColor: colors.primary,
            alignSelf: 'flex-end',
            marginRight: spacing.spacing5,
            justifyContent: 'center',
          }}
          labelStyle={{
            color: colors.onPrimary,
            ...fonts.titleSmall,
            marginHorizontal: spacing.spacing3,
          }}
          onPress={() => {
            refetch()
            setIsFilterVisible(false)
            totalCountRefecth()
          }}
        >
          {t('Directory.APPLY_FILTER')}
        </Button>
      </View>
    </Overlay>
  )
}

export default Filter
