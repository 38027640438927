import { useIsFocused, useNavigation } from '@react-navigation/native'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowDimensions } from 'react-native'

import { Text } from '@camped/components'
import { ScreenLayout, appUtil } from '@camped/utils'

import { HeaderIcon } from '../../components/header'
import { useGetDepartmentListing } from '../../hooks/useGetDepartment'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const DepartmentListing = (props) => {
  const { t } = useTranslation()
  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout?.isWeb(windowWidth)
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const [checked, setChecked] = useState(false)
  const [errors, setErrors] = useState()
  const [showModal, setShowModal] = useState(false)
  const [user, setUser] = useState({})

  const module = props?.module ?? ''
  const department = props?.route?.params?.department ?? false

  const {
    data: departmentDetails,
    isFetching: listDepartmentsIsFetching,
    refetch: departmentRefetch,
  } = useGetDepartmentListing({
    module,
  })
  useEffect(() => {
    if (isFocused) {
      departmentRefetch()
    }
  }, [isFocused])
  useEffect(() => {
    if (
      !departmentDetails?.metaData?.screens?.[0]?.permissions?.add ||
      !isDesktop
    )
      return
    navigation.setOptions({
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => (
        <HeaderIcon
          iconName='PlusIcon'
          module={appUtil.capitalize(module)}
          onPress={() => {
            navigation.navigate(`add-${module}`)
          }}
        />
      ),
    })
  }, [departmentDetails.metaData])

  const getMobileHeading = () => {
    switch (module) {
      case 'degree':
        return t('Settings.DEGREE')
      case 'department':
        return t('Settings.DEPARTMENT')
      default:
        return t('Settings.GRADUATION_TYPE')
    }
  }

  const viewProps = {
    ...props,
    departmentDetails: departmentDetails.departmentData,
    metadata: departmentDetails.metaData,
    profileImage: departmentDetails.profileImage,
    errors,
    listDepartmentsIsFetching,
    setErrors,
    setShowModal,
    setUser,
    showModal,
    user,
    department,
    checked,
    setChecked,
    module,
    getMobileHeading,
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )
  return (
    <Suspense fallback={<Text>{t('Settings.LOADING')}</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default DepartmentListing
