import ReactGA from 'react-ga4'

export const initializeAnalytics = () => {
  ReactGA.initialize('G-5XJHGN5GJY')
}

export const trackEvent = (category, eventType, label) => {
  ReactGA.event({
    category,
    action: eventType,
    label,
  })
}
