import React from 'react'
import { Text, View } from 'react-native'

const MobileView = () => (
  <View>
    <Text>MobileView</Text>
  </View>
)

export default MobileView
