import React from 'react'
import {
  HelperText,
  TextInput as RNPTextInput,
  useTheme,
} from 'react-native-paper'

const TextInput = (props) => {
  const { colors } = useTheme()
  return (
    <>
      <RNPTextInput
        {...props}
        theme={{
          colors: {
            onSurface: colors.onSurface,
          },
        }}
        mode={props?.mode || 'outlined'}
        outlineColor={props.outlineColor || colors.onBackground}
        activeOutlineColor={colors.onSurface}
        placeholderTextColor={props.placeholderTextColor || colors.onSurface}
        style={{
          ...props?.style,
          backgroundColor: colors.surface2,
        }}
        onFocus={() => {
          props?.onFocus?.()
        }}
        onBlur={() => {
          props?.onBlur?.()
        }}
        onKeyPress={({ nativeEvent }) => {
          if (nativeEvent.key === 'Enter') {
            props?.method()
          }
        }}
      />
      {props?.error && (
        <HelperText
          type='error'
          visible
          padding='none'
          numberOfLines={2}
          style={{ width: '95%' }}
        >
          {props?.error?.message || props?.error?.type}
        </HelperText>
      )}
    </>
  )
}

TextInput.Affix = RNPTextInput.Affix
TextInput.Icon = RNPTextInput.Icon

export default TextInput
