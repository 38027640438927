import { useAtom } from 'jotai'
import React from 'react'
import { TouchableOpacity, View, useWindowDimensions } from 'react-native'
import { useTheme } from 'react-native-paper'

import { API } from '@camped/api'
import { Button, Text } from '@camped/components'
import { lmsStudioUrl, lmsUrl } from '@camped/core/src/config'
import { Icon } from '@camped/icons'
import { userAtom, userRollAtom } from '@camped/jotai-storage'
import { FileHandler, ScreenLayout } from '@camped/utils'

import {
  toDetermineStyle,
  urlOpenMethod,
  validateUrl,
} from '../../utils/helpers'
import DynamicField from '../dynamic-field'
import { SemesterButton } from './semester-button'

export const RenderDisplayField = (props) => {
  const { colors, spacing, fonts } = useTheme()
  const { keys, style, value, setSelectedSem, selectedSem, user } = props
  const { width } = useWindowDimensions()
  const [userRoll] = useAtom(userRollAtom)
  const [currentUser] = useAtom(userAtom)
  const isDesktop = ScreenLayout.isWeb(width)
  if (keys?.component === 'selectSem') {
    return (
      <SemesterButton
        selectedSem={selectedSem}
        item={user?.totalSemesters?.value}
        setSelectedSem={setSelectedSem}
      />
    )
  }
  if (keys?.component === 'button') {
    return (
      <View
        style={{
          width: '100%',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Button
          style={{
            backgroundColor: colors.primary,
            height: 32,
            justifyContent: 'center',
            marginRight: spacing.spacing6,
            marginVertical: spacing.spacing6,
            padding: 0,
            alignItems: 'center',
          }}
          labelStyle={{
            color: colors.onPrimary,
            ...fonts.labelMedium,
            marginBottom: 9,
            fontSize: 14,
          }}
          onPress={async () => {
            await API.studentEnrollmentAPI({
              enrollment: {
                lmsSubjectId: user?.[keys?.fieldName]?.value,
                lmsUserName: currentUser.email,
              },
            })
            if (['staff', 'admin'].includes(userRoll)) {
              urlOpenMethod(`${lmsStudioUrl}${user?.[keys?.fieldName]?.value}`)
            } else {
              urlOpenMethod(`${lmsUrl}${user?.[keys?.fieldName]?.value}`)
            }
          }}
        >
          Subject Details
        </Button>
      </View>
    )
  }
  return (
    <View
      style={{
        width: '100%',
        marginBottom: 24,
        justifyContent: 'center',
        ...toDetermineStyle(value, keys?.label, style, isDesktop),
      }}
    >
      <Text
        variant='labelMedium'
        style={{
          color: colors.onSurface1,
          opacity: keys.component === 'TextArea' ? 1 : 0.4,
          fontWeight: keys.component === 'TextArea' ? '700' : '100',
          textTransform: 'capitalize',
        }}
      >
        {keys?.label}
        {keys?.isMandatory ? '*' : ''}
      </Text>

      <Text
        variant='bodyMedium'
        style={{
          width: '100%',
          fontWeight: keys.component === 'TextArea' ? '300' : '200',
          color: validateUrl(value) ? colors.primary : colors.onSurface1,
        }}
        onPress={() => urlOpenMethod(value)}
        numberOfLines={20}
      >
        {typeof value === 'object' && value !== null
          ? Object.entries(value).map(([values]) => (
              <DynamicField value={values} />
            ))
          : value || '-'}
      </Text>
      {!isDesktop && keys?.label.includes('mailId') && (
        <TouchableOpacity
          onPress={() =>
            FileHandler.copy(value).then((res) => {
              if (res) {
                toast.show('copied to clipboard', { type: 'success' })
              }
            })
          }
          style={{
            position: 'absolute',
            right: 0,
            height: spacing.spacing6,
            width: spacing.spacing6,
          }}
        >
          <Icon
            name='CopyIcon'
            height={20}
            width={20}
            color={colors.onBackground}
          />
        </TouchableOpacity>
      )}
    </View>
  )
}
