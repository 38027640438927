import { useQuery, useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { SecureStore } from '@camped/utils'

export const useGetDepartmentListing = ({ module, setUser, id }) => {
  const queryClient = useQueryClient()
  const getScreenName = () => {
    if (module === 'addDepartment') return 'add-department'
    if (module === 'addDegree') return 'add-degree'
    if (module === 'addGraduation') return 'add-graduation'
  }
  const screenIds = queryClient.getQueryData(['getScreenIds'])
  const { data, isFetching, refetch } = useQuery(
    [`${module}-filter-Detail`],
    async () => {
      try {
        const tenant = await SecureStore.getCookie('user-details')
        const userDetail = JSON.parse(tenant?.userRoleDetails)
        if (module === 'department') {
          const screenName = 'department-directory'

          const screen = screenIds?.find(
            (item) => item?.fieldName === screenName,
          )
          const metaData = await API.getMetaDataAPI({
            metaData: {
              role: userDetail?.[0]?.userRoleId,
              tenant: userDetail[0].tenantId ?? '',
              screen: screen?._id,
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
          if (metaData?.screens?.[0]?.permissions?.view) {
            const departmentData = await API.getDepartmentsByFilterAPI({
              filter: {
                tenant: userDetail?.[0]?.tenantId ?? '',
              },
              extraHeader: {
                screen: screen?._id,
              },
            })
            const profilePath = departmentData
              ?.map((item) => {
                if (item?.image === null) return
                return {
                  id: item?._id,
                  path: item?.image,
                }
              })
              .filter(Boolean)
            const profileImage = await API.getViewProfileImageUrlAPI({
              s3Data: profilePath,
            })
            return { departmentData, metaData, profileImage }
          }
          return { metaData }
        }
        if (
          module === 'addDepartment' ||
          module === 'addDegree' ||
          module === 'addGraduation'
        ) {
          const screenName = getScreenName()
          const screen = screenIds?.find(
            (item) => item?.fieldName === screenName,
          )
          const metaData = await API.getMetaDataAPI({
            metaData: {
              role: userDetail?.[0]?.userRoleId,
              tenant: userDetail[0].tenantId ?? '',
              screen: screen?._id,
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
          return { metaData }
        }
        if (module === 'updateDepartment') {
          const screenName = 'add-department'
          const screen = screenIds?.find(
            (item) => item?.fieldName === screenName,
          )
          const metaData = await API.getMetaDataAPI({
            metaData: {
              role: userDetail?.[0]?.userRoleId,
              tenant: userDetail[0].tenantId ?? '',
              screen: screen?._id,
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
          const departmentData = await API.getDepartmentByIdAPI({
            id,
            extraHeader: {
              screen: screen?._id,
            },
          })
          setUser(departmentData)
          return { metaData }
        }
        if (module === 'graduation') {
          const screenName = 'graduation-directory'

          const screen = screenIds?.find(
            (item) => item?.fieldName === screenName,
          )
          const departmentData = await API.getGraduationsByFilterAPI({
            filter: {
              tenant: userDetail?.[0]?.tenantId ?? '',
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
          const metaData = await API.getMetaDataAPI({
            metaData: {
              role: userDetail?.[0]?.userRoleId,
              tenant: userDetail[0].tenantId ?? '',
              screen: screen?._id,
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
          return { departmentData, metaData }
        }
        if (module === 'degree') {
          const screenName = 'degree-directory'

          const screen = screenIds?.find(
            (item) => item?.fieldName === screenName,
          )
          const departmentData = await API.getDegreesByFilterAPI({
            filter: {
              tenant: userDetail?.[0]?.tenantId ?? '',
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
          const metaData = await API.getMetaDataAPI({
            metaData: {
              role: userDetail?.[0]?.userRoleId,
              tenant: userDetail[0].tenantId ?? '',
              screen: screen?._id,
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
          return { departmentData, metaData }
        }
      } catch (error) {
        return error
      }
    },

    {
      initialData: [],
      retry: false,
      enabled: screenIds?.length !== 0,
      onError: () => {},
    },
  )

  return { data, isFetching, refetch }
}
