const appUtil = {
  capitalize: (name) =>
    name ? name.charAt(0).toUpperCase() + name.slice(1) : '',

  yearList: (start, end) => {
    const currentYear = Number(end) || new Date().getFullYear()
    const startYear = Number(start)
    return Array.from({ length: currentYear - startYear + 1 }, (_, i) => ({
      displayName: startYear + i,
      value: startYear + i,
    }))
  },
  isValidMobileNumber: (mobileNumber) => {
    let number
    if (typeof mobileNumber === 'number') {
      number = mobileNumber
    } else if (mobileNumber?.includes('-')) {
      number = mobileNumber?.split('-')[1]
    }
    // Check if the mobileNumber contains exactly 10 digits
    return /^\d{10}$/.test(number || mobileNumber)
  },
  isValidEmail: (email) => {
    // Regular expression pattern for email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    // Test the email against the pattern and return the result
    return emailPattern.test(email)
  },
  isValidName: (name) => {
    // Regular expression pattern for name validation
    const namePattern = /^[a-zA-z]+([\s][a-zA-Z]+)*$/

    // Test the name against the pattern and return the result
    return namePattern.test(name)
  },

  isValidUrl: (urlString) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ) // validate fragment locator
    return !!urlPattern.test(urlString)
  },
}
export default appUtil
