import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'
import { getScreenType } from '@camped/utils/src/screenLayout'

import cseDepartment from 'assets/cseDepartment.png'
import graduation from 'assets/graduation.png'

export const DepartmentListing = ({
  departmentDetails,
  imageSize,
  module,
  profileImage,
}) => {
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const device = getScreenType(width)

  const navigation = useNavigation()

  return (
    <>
      <Text style={styles({ ...theme, device }).headLine}>
        {`View all the ${module} and ${module} details of your campus`}
      </Text>
      <View style={styles(theme).ListView}>
        {departmentDetails?.map((ele, i) => (
          <DepartmentCard
            ele={ele}
            i={i}
            imageSize={imageSize}
            navigation={navigation}
            module={module}
            profileImage={profileImage}
          />
        ))}
      </View>
    </>
  )
}

const DepartmentCard = ({
  ele,
  i,
  imageSize,
  navigation,
  module,
  profileImage,
}) => {
  const theme = useTheme()
  const { spacing, colors } = theme
  const { width } = useWindowDimensions()
  const device = getScreenType(width)
  const [image, setImage] = useState()
  const getWidth = () => {
    if (device === 'mobile') return width > 390 ? '47.5%' : '98%'
    if (device === 'tablet') return '47.5%'
    return width > 1240 ? '22%' : '30%'
  }
  const getBorders = () =>
    device === 'desktop'
      ? { marginBottom: spacing.spacing6, marginLeft: spacing.spacing6 }
      : { marginBottom: spacing.spacing5, marginHorizontal: spacing.spacing2 }
  const getImage = async () => {
    const res = profileImage?.find((item) => item?.id === ele?._id)
    setImage(res?.imageUrl)
  }
  useEffect(() => {
    getImage()
  }, [])
  const defaultImage = module === 'department' ? cseDepartment : graduation
  return (
    <Surface
      key={i}
      elevation={1}
      style={[
        styles(theme).surfaceStyle,
        {
          width: getWidth(),
          ...getBorders(),
        },
      ]}
    >
      <View style={styles(theme).imageView}>
        <Image
          source={ele?.image ? image : defaultImage}
          resizeMode='contain'
          style={styles({ ...theme, imageSize }).imageStyle}
        />
        {module === 'department' && (
          <TouchableOpacity
            style={styles(theme).touchableIcon}
            onPress={() =>
              navigation.navigate('update-deparment', {
                id: ele?._id,
                department: ele?.name,
              })
            }
          >
            <Icon
              name='EditIcon'
              width={15}
              height={15}
              color={colors.primary}
              style={styles(theme).iconWidth}
            />
          </TouchableOpacity>
        )}
      </View>

      <Text numberOfLines={2} style={styles(theme).departmentText}>
        {ele.name}
      </Text>
    </Surface>
  )
}
const styles = ({ colors, spacing, fonts, ...rest }) =>
  StyleSheet.create({
    imageView: {
      height: 84,
      width: 84,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 42,
      borderWidth: 1,
      backgroundColor: colors.surface1,
      borderColor: colors.surface3,
    },
    touchableIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 8,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      shadowColor: colors.onSurface,
      backgroundColor: colors.onPrimary,
      borderRadius: spacing.spacing5,
    },
    iconWidth: {
      height: spacing.spacing4,
      width: spacing.spacing4,
    },
    departmentText: {
      ...fonts.bodyLarge,
      margin: spacing.spacing4,
      paddingHorizontal: spacing.spacing4,
      textAlign: 'center',
      fontWeight: '500',
      color: colors.onBackground,
    },
    surfaceStyle: {
      alignItems: 'center',
      borderRadius: 16,
      height: 182,
      justifyContent: 'center',
      backgroundColor: colors.background,
    },
    ListView: { flexWrap: 'wrap', flexDirection: 'row' },
    headLine: {
      paddingTop: 6,
      paddingBottom: spacing.spacing6,
      ...fonts.titleMedium,
      color: colors.onBackground,
      paddingLeft: rest.device === 'desktop' ? spacing.spacing6 : 0,
    },
    imageStyle: {
      width: rest.imageSize,
      height: rest.imageSize,
      borderRadius: 28,
      overflow: 'hidden',
    },
  })
