import React from 'react'
import { useTranslation } from 'react-i18next'
import { Linking } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { mailTo } from '@camped/core/src/config'
import { testProps } from '@camped/utils'

import { Behavior, ScreenName, TypeofControl } from '../constants/testing'

export const ContactUs = () => {
  const { colors, fonts } = useTheme()
  const { t } = useTranslation()
  return (
    <Text
      variant='bodyLarge'
      style={{
        color: colors.primary,
        alignItems: 'center',
        textAlign: 'center',
        ...fonts.bodyMedium,
      }}
      onPress={() => {
        Linking.openURL(mailTo)
      }}
      {...testProps(
        `${ScreenName.CAMPUS}_${TypeofControl.LINK}_${Behavior.NAVIGATE}`,
      )}
    >
      {t('Global.CONTACT_US')}
    </Text>
  )
}
