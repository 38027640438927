import { Platform } from 'react-native'

import * as MD3Tokens from './material-theme/styledictionary/js/tokens'

const ref = {
  palette: {
    primary100: MD3Tokens.MdRefPalettePrimary100,
    primary99: MD3Tokens.MdRefPalettePrimary99,
    primary95: MD3Tokens.MdRefPalettePrimary95,
    primary90: MD3Tokens.MdRefPalettePrimary90,
    primary80: MD3Tokens.MdRefPalettePrimary80,
    primary70: MD3Tokens.MdRefPalettePrimary70,
    primary60: MD3Tokens.MdRefPalettePrimary60,
    primary50: MD3Tokens.MdRefPalettePrimary50,
    primary40: MD3Tokens.MdRefPalettePrimary40,
    primary30: MD3Tokens.MdRefPalettePrimary30,
    primary20: MD3Tokens.MdRefPalettePrimary20,
    primary10: MD3Tokens.MdRefPalettePrimary10,
    primary0: MD3Tokens.MdRefPalettePrimary0,
    secondary100: MD3Tokens.MdRefPaletteSecondary100,
    secondary99: MD3Tokens.MdRefPaletteSecondary99,
    secondary95: MD3Tokens.MdRefPaletteSecondary95,
    secondary90: MD3Tokens.MdRefPaletteSecondary90,
    secondary80: MD3Tokens.MdRefPaletteSecondary80,
    secondary70: MD3Tokens.MdRefPaletteSecondary70,
    secondary60: MD3Tokens.MdRefPaletteSecondary60,
    secondary50: MD3Tokens.MdRefPaletteSecondary50,
    secondary40: MD3Tokens.MdRefPaletteSecondary40,
    secondary30: MD3Tokens.MdRefPaletteSecondary30,
    secondary20: MD3Tokens.MdRefPaletteSecondary20,
    secondary10: MD3Tokens.MdRefPaletteSecondary10,
    secondary0: MD3Tokens.MdRefPaletteSecondary0,
    tertiary100: MD3Tokens.MdRefPaletteTertiary100,
    tertiary99: MD3Tokens.MdRefPaletteTertiary99,
    tertiary95: MD3Tokens.MdRefPaletteTertiary95,
    tertiary90: MD3Tokens.MdRefPaletteTertiary90,
    tertiary80: MD3Tokens.MdRefPaletteTertiary80,
    tertiary70: MD3Tokens.MdRefPaletteTertiary70,
    tertiary60: MD3Tokens.MdRefPaletteTertiary60,
    tertiary50: MD3Tokens.MdRefPaletteTertiary50,
    tertiary40: MD3Tokens.MdRefPaletteTertiary40,
    tertiary30: MD3Tokens.MdRefPaletteTertiary30,
    tertiary20: MD3Tokens.MdRefPaletteTertiary20,
    tertiary10: MD3Tokens.MdRefPaletteTertiary10,
    tertiary0: MD3Tokens.MdRefPaletteTertiary0,
    neutral100: MD3Tokens.MdRefPaletteNeutral100,
    neutral99: MD3Tokens.MdRefPaletteNeutral99,
    neutral95: MD3Tokens.MdRefPaletteNeutral95,
    neutral90: MD3Tokens.MdRefPaletteNeutral90,
    neutral80: MD3Tokens.MdRefPaletteNeutral80,
    neutral70: MD3Tokens.MdRefPaletteNeutral70,
    neutral60: MD3Tokens.MdRefPaletteNeutral60,
    neutral50: MD3Tokens.MdRefPaletteNeutral50,
    neutral40: MD3Tokens.MdRefPaletteNeutral40,
    neutral30: MD3Tokens.MdRefPaletteNeutral30,
    neutral20: MD3Tokens.MdRefPaletteNeutral20,
    neutral10: MD3Tokens.MdRefPaletteNeutral10,
    neutral0: MD3Tokens.MdRefPaletteNeutral0,
    neutralVariant100: MD3Tokens.MdRefPaletteNeutralVariant100,
    neutralVariant99: MD3Tokens.MdRefPaletteNeutralVariant99,
    neutralVariant95: MD3Tokens.MdRefPaletteNeutralVariant95,
    neutralVariant90: MD3Tokens.MdSysColorSurfaceVariantLight,
    neutralVariant80: MD3Tokens.MdRefPaletteNeutralVariant80,
    neutralVariant70: MD3Tokens.MdRefPaletteNeutralVariant70,
    neutralVariant60: MD3Tokens.MdRefPaletteNeutralVariant60,
    neutralVariant50: MD3Tokens.MdRefPaletteNeutralVariant50,
    neutralVariant40: MD3Tokens.MdRefPaletteNeutralVariant40,
    neutralVariant30: MD3Tokens.MdSysColorSurfaceVariantDark,
    neutralVariant20: MD3Tokens.MdRefPaletteNeutralVariant20,
    neutralVariant10: MD3Tokens.MdRefPaletteNeutralVariant10,
    neutralVariant0: MD3Tokens.MdRefPaletteNeutralVariant0,
    error100: MD3Tokens.MdRefPaletteError100,
    error99: MD3Tokens.MdRefPaletteError99,
    error95: MD3Tokens.MdRefPaletteError95,
    error90: MD3Tokens.MdRefPaletteError90,
    error80: MD3Tokens.MdRefPaletteError80,
    error70: MD3Tokens.MdRefPaletteError70,
    error60: MD3Tokens.MdRefPaletteError60,
    error50: MD3Tokens.MdRefPaletteError50,
    error40: MD3Tokens.MdRefPaletteError40,
    error30: MD3Tokens.MdRefPaletteError30,
    error20: MD3Tokens.MdRefPaletteError20,
    error10: MD3Tokens.MdRefPaletteError10,
    error0: MD3Tokens.MdRefPaletteError0,
    success100: MD3Tokens.MdRefPaletteSuccess100,
    success99: MD3Tokens.MdRefPaletteSuccess99,
    success95: MD3Tokens.MdRefPaletteSuccess95,
    success90: MD3Tokens.MdRefPaletteSuccess90,
    success80: MD3Tokens.MdRefPaletteSuccess80,
    success70: MD3Tokens.MdRefPaletteSuccess70,
    success60: MD3Tokens.MdRefPaletteSuccess60,
    success50: MD3Tokens.MdRefPaletteSuccess50,
    success40: MD3Tokens.MdRefPaletteSuccess40,
    success30: MD3Tokens.MdRefPaletteSuccess30,
    success20: MD3Tokens.MdRefPaletteSuccess20,
    success10: MD3Tokens.MdRefPaletteSuccess10,
    success0: MD3Tokens.MdRefPaletteSuccess0,
    specialcolorOne100: MD3Tokens.MdRefPaletteSpecialColorOne100,
    specialcolorOne99: MD3Tokens.MdRefPaletteSpecialColorOne99,
    specialcolorOne95: MD3Tokens.MdRefPaletteSpecialColorOne95,
    specialcolorOne90: MD3Tokens.MdRefPaletteSpecialColorOne90,
    specialcolorOne80: MD3Tokens.MdRefPaletteSpecialColorOne80,
    specialcolorOne70: MD3Tokens.MdRefPaletteSpecialColorOne70,
    specialcolorOne60: MD3Tokens.MdRefPaletteSpecialColorOne60,
    specialcolorOne50: MD3Tokens.MdRefPaletteSpecialColorOne50,
    specialcolorOne40: MD3Tokens.MdRefPaletteSpecialColorOne40,
    specialcolorOne30: MD3Tokens.MdRefPaletteSpecialColorOne30,
    specialcolorOne20: MD3Tokens.MdRefPaletteSpecialColorOne20,
    specialcolorOne10: MD3Tokens.MdRefPaletteSpecialColorOne10,
    specialcolorOne0: MD3Tokens.MdRefPaletteSpecialColorOne0,
    specialcolorTwo100: MD3Tokens.MdRefPaletteSpecialColorTwo100,
    specialcolorTwo99: MD3Tokens.MdRefPaletteSpecialColorTwo99,
    specialcolorTwo95: MD3Tokens.MdRefPaletteSpecialColorTwo95,
    specialcolorTwo90: MD3Tokens.MdRefPaletteSpecialColorTwo90,
    specialcolorTwo80: MD3Tokens.MdRefPaletteSpecialColorTwo80,
    specialcolorTwo70: MD3Tokens.MdRefPaletteSpecialColorTwo70,
    specialcolorTwo60: MD3Tokens.MdRefPaletteSpecialColorTwo60,
    specialcolorTwo50: MD3Tokens.MdRefPaletteSpecialColorTwo50,
    specialcolorTwo40: MD3Tokens.MdRefPaletteSpecialColorTwo40,
    specialcolorTwo30: MD3Tokens.MdRefPaletteSpecialColorTwo30,
    specialcolorTwo20: MD3Tokens.MdRefPaletteSpecialColorTwo20,
    specialcolorTwo10: MD3Tokens.MdRefPaletteSpecialColorTwo10,
    specialcolorTwo0: MD3Tokens.MdRefPaletteSpecialColorTwo0,
    surfaceLight1: MD3Tokens.MdSysColorSurfaceLight1,
    surfaceLight2: MD3Tokens.MdSysColorSurfaceLight2,
    surfaceLight3: MD3Tokens.MdSysColorSurfaceLight3,
    surfaceLight4: MD3Tokens.MdSysColorSurfaceLight4,
    surfaceLight5: MD3Tokens.MdSysColorSurfaceLight5,
    surfaceDark: MD3Tokens.MdSysColorSurfaceDark,
    surfaceDark1: MD3Tokens.MdSysColorSurfaceDark1,
    surfaceDark2: MD3Tokens.MdSysColorSurfaceDark2,
    surfaceDark3: MD3Tokens.MdSysColorSurfaceDark3,
    surfaceDark4: MD3Tokens.MdSysColorSurfaceDark4,
    surfaceDark5: MD3Tokens.MdSysColorSurfaceDark5,
    surfaceVariantLight: MD3Tokens.MdSysColorSurfaceVariantLight,
    onSurfaceVariantLight: MD3Tokens.MdSysColorOnSurfaceVariantLight,
    surfaceVariantDark: MD3Tokens.MdSysColorSurfaceVariantDark,
    onSurfaceVariantDark: MD3Tokens.MdSysColorOnSurfaceVariantDark,
    inverseSurfaceLight: MD3Tokens.MdSysColorInverseSurfaceLight,
    inverseOnSurfaceLight: MD3Tokens.MdSysColorInverseOnSurfaceLight,
    inverseSurfaceDark: MD3Tokens.MdSysColorInverseSurfaceDark,
    inverseOnSurfaceDark: MD3Tokens.MdSysColorInverseOnSurfaceDark,
    neutralPrimaryLight: MD3Tokens.MdSysColorNeutralPrimaryLight,
    neutralPrimaryDark: MD3Tokens.MdSysColorNeutralPrimaryDark,
    successLight: MD3Tokens.MdSysColorSuccessLight,
    onSuccessLight: MD3Tokens.MdSysColorOnSuccessLight,
    successContainerLight: MD3Tokens.MdSysColorSuccessContainerLight,
    onSuccessContainerLight: MD3Tokens.MdSysColorOnSuccessContainerLight,
    successDark: MD3Tokens.MdSysColorSuccessDark,
    onSuccessDark: MD3Tokens.MdSysColorOnSuccessDark,
    successContainerDark: MD3Tokens.MdSysColorSuccessContainerDark,
    onSuccessContainerDark: MD3Tokens.MdSysColorOnSuccessContainerDark,
    errorLight: MD3Tokens.MdSysColorErrorLight,
    onErrorLight: MD3Tokens.MdSysColorOnErrorLight,
    errorContainerLight: MD3Tokens.MdSysColorErrorContainerLight,
    onErrorContainerLight: MD3Tokens.MdSysColorOnErrorContainerLight,
    errorDark: MD3Tokens.MdSysColorErrorDark,
    onErrorDark: MD3Tokens.MdSysColorOnErrorDark,
    errorContainerDark: MD3Tokens.MdSysColorErrorContainerDark,
    onErrorContainerDark: MD3Tokens.MdSysColorOnErrorContainerDark,
    backgroundLight: MD3Tokens.MdSysColorBackgroundLight,
    onBackgroundLight: MD3Tokens.MdSysColorOnBackgroundLight,
    backgroundDark: MD3Tokens.MdSysColorBackgroundDark,
    onBackgroundDark: MD3Tokens.MdSysColorOnBackgroundDark,
    surfaceLight: MD3Tokens.MdSysColorSurfaceLight,
    onSurfaceLight: MD3Tokens.MdSysColorOnSurfaceLight,
    onSurfaceDark: MD3Tokens.MdSysColorOnSurfaceDark,
    outlineLight: MD3Tokens.MdSysColorOutlineLight,
    outlineDark: MD3Tokens.MdSysColorOutlineDark,
    neutralLight: MD3Tokens.MdSysColorNeutralLight,
    onNeutralLight: MD3Tokens.MdSysColorOnNeutralLight,
    neutralDark: MD3Tokens.MdSysColorNeutralDark,
    onNeutralDark: MD3Tokens.MdSysColorOnNeutralDark,
    primaryLight: MD3Tokens.MdSysColorPrimaryLight,
    onPrimaryLight: MD3Tokens.MdSysColorOnPrimaryLight,
    primaryContainerLight: MD3Tokens.MdSysColorPrimaryContainerLight,
    onPrimaryContainerLight: MD3Tokens.MdSysColorOnPrimaryContainerLight,
    primaryDark: MD3Tokens.MdSysColorPrimaryDark,
    onPrimaryDark: MD3Tokens.MdSysColorOnPrimaryDark,
    primaryContainerDark: MD3Tokens.MdSysColorPrimaryContainerDark,
    onPrimaryContainerDark: MD3Tokens.MdSysColorOnPrimaryContainerDark,
    inversePrimaryLight: MD3Tokens.MdSysColorInversePrimaryLight,
    inversePrimaryDark: MD3Tokens.MdSysColorInversePrimaryDark,
    secondaryLight: MD3Tokens.MdSysColorSecondaryLight,
    onSecondaryLight: MD3Tokens.MdSysColorOnSecondaryLight,
    secondaryContainerLight: MD3Tokens.MdSysColorSecondaryContainerLight,
    onSecondaryContainerLight: MD3Tokens.MdSysColorOnSecondaryContainerLight,
    secondaryDark: MD3Tokens.MdSysColorSecondaryDark,
    onSecondaryDark: MD3Tokens.MdSysColorOnSecondaryDark,
    secondaryContainerDark: MD3Tokens.MdSysColorSecondaryContainerDark,
    onSecondaryContainerDark: MD3Tokens.MdSysColorOnSecondaryContainerDark,
    tertiaryLight: MD3Tokens.MdSysColorTertiaryLight,
    onTertiaryLight: MD3Tokens.MdSysColorOnTertiaryLight,
    tertiaryContainerLight: MD3Tokens.MdSysColorTertiaryContainerLight,
    onTertiaryContainerLight: MD3Tokens.MdSysColorOnTertiaryContainerLight,
    tertiaryDark: MD3Tokens.MdSysColorTertiaryDark,
    onTertiaryDark: MD3Tokens.MdSysColorOnTertiaryDark,
    tertiaryContainerDark: MD3Tokens.MdSysColorTertiaryContainerDark,
    onTertiaryContainerDark: MD3Tokens.MdSysColorOnTertiaryContainerDark,
    specialColorOneLight: MD3Tokens.MdSysColorSpecialColorOneLight,
    onSpecialColorOneLight: MD3Tokens.MdSysColorOnSpecialColorOneLight,
    specialColorOneContainerLight:
      MD3Tokens.MdSysColorSpecialColorOneContainerLight,
    onSpecialColorOneContainerLight:
      MD3Tokens.MdSysColorOnSpecialColorOneContainerLight,
    specialColorTwoLight: MD3Tokens.MdSysColorSpecialColorTwoLight,
    onSpecialColorTwoLight: MD3Tokens.MdSysColorOnSpecialColorTwoLight,
    specialColorTwoContainerLight:
      MD3Tokens.MdSysColorSpecialColorTwoContainerLight,
    onSpecialColorTwoContainerLight:
      MD3Tokens.MdSysColorOnSpecialColorTwoContainerLight,
    specialColorOneDark: MD3Tokens.MdSysColorSpecialColorOneDark,
    onSpecialColorOneDark: MD3Tokens.MdSysColorOnSpecialColorOneDark,
    specialColorOneContainerDark:
      MD3Tokens.MdSysColorSpecialColorOneContainerDark,
    onSpecialColorOneContainerDark:
      MD3Tokens.MdSysColorOnSpecialColorOneContainerDark,
    specialColorTwoDark: MD3Tokens.MdSysColorSpecialColorTwoDark,
    onSpecialColorTwoDark: MD3Tokens.MdSysColorOnSpecialColorTwoDark,
    specialColorTwoContainerDark:
      MD3Tokens.MdSysColorSpecialColorTwoContainerDark,
    onSpecialColorTwoContainerDark:
      MD3Tokens.MdSysColorOnSpecialColorTwoContainerDark,
  },

  typeface: {
    kanitSemiBoldFont: Platform.select({
      web: 'Kanit',
      ios: 'Kanit',
      android: 'Kanit-SemiBold',
    }),
    albertSansRegularFont: Platform.select({
      web: 'Albert Sans',
      ios: 'AlbertSans-Regular',
      android: 'AlbertSans-Regular',
    }),
    albertSansSemiBoldFont: Platform.select({
      web: 'Albert Sans',
      ios: 'AlbertSans-SemiBold',
      android: 'AlbertSans-SemiBold',
    }),
    albertSansBoldFont: Platform.select({
      web: 'Albert Sans',
      ios: 'AlbertSans-Bold',
      android: 'AlbertSans-Bold',
    }),
    albertSansExtraBoldFont: Platform.select({
      web: 'Albert Sans',
      ios: 'AlbertSans-ExtraBold',
      android: 'AlbertSans-ExtraBold',
    }),
    weight600: '600',
    weight500: '500',
    weight400: '400',
    weight700: '700',
    weight300: '300',
  },

  opacity: {
    level1: 0.08,
    level2: 0.12,
    level3: 0.16,
    level4: 0.38,
  },
}

const headerType = {
  fontFamily: ref.typeface.kanitSemiBoldFont,
  letterSpacing: 0,
  fontWeight: ref.typeface.weight600,
}

export const typeScale = {
  displayLarge: {
    ...headerType,
    lineHeight: Platform.select({
      web: '64px',
      ios: 64,
      android: 64,
    }),
    fontSize: MD3Tokens.MdSysTypescaleDisplayLargeSize,
  },
  displayMedium: {
    lineHeight: Platform.select({
      web: '58px',
      ios: 58,
      android: 58,
    }),
    fontSize: MD3Tokens.MdSysTypescaleDisplayMediumSize,
    letterSpacing: 0.15,
    fontFamily: ref.typeface.albertSansRegularFont,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
  },

  displaySmall: {
    lineHeight: Platform.select({
      web: '44px',
      ios: 44,
      android: 44,
    }),
    fontSize: MD3Tokens.MdSysTypescaleDisplaySmallSize,
    letterSpacing: 0.15,
    fontFamily: ref.typeface.albertSansExtraBoldFont,
    fontWeight: Platform.OS === 'android' ? 'normal' : '700',
  },
  // Frozen
  headlineLarge: {
    lineHeight: Platform.select({
      web: '40px',
      ios: 40,
      android: 40,
    }),
    fontSize: MD3Tokens.MdSysTypescaleHeadlineLargeSize,
    letterSpacing: 0.15,
    fontFamily: ref.typeface.albertSansBoldFont,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
  },
  headlineMedium: {
    lineHeight: Platform.select({
      web: '36px',
      ios: 36,
      android: 36,
    }),
    fontSize: MD3Tokens.MdSysTypescaleHeadlineMediumSize,
    fontFamily: ref.typeface.albertSansExtraBoldFont,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
  },
  headlineSmall: {
    lineHeight: Platform.select({
      web: '32px',
      ios: 32,
      android: 32,
    }),
    fontSize: MD3Tokens.MdSysTypescaleHeadlineSmallSize,
    fontFamily: ref.typeface.albertSansExtraBoldFont,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
  },

  titleLarge: {
    lineHeight: Platform.select({
      web: '28px',
      ios: 28,
      android: 28,
    }),
    fontSize: MD3Tokens.MdSysTypescaleTitleLargeSize,
    fontFamily: ref.typeface.albertSansExtraBoldFont,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
  },
  // Frozen
  titleMedium: {
    lineHeight: Platform.select({
      web: '24px',
      ios: 24,
      android: 24,
    }),
    fontSize: MD3Tokens.MdSysTypescaleBodyLargeSize,
    fontFamily: ref.typeface.albertSansRegularFont,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
  },
  titleSmall: {
    letterSpacing: 0.1,
    lineHeight: Platform.select({
      web: '20px',
      ios: 20,
      android: 20,
    }),
    fontSize: 12,
    fontFamily: ref.typeface.albertSansRegularFont,
    fontWeight: Platform.OS === 'android' ? 'normal' : '700',
  },

  // Frozen
  labelLarge: {
    letterSpacing: 0.1,
    lineHeight: Platform.select({
      web: '20px',
      ios: 20,
      android: 20,
    }),
    fontSize: MD3Tokens.MdSysTypescaleLabelLargeSize,
    fontFamily: ref.typeface.albertSansRegularFont,
  },
  labelMedium: {
    letterSpacing: 0.5,
    lineHeight: Platform.select({
      web: '16px',
      ios: 16,
      android: 16,
    }),
    fontSize: 14,
    fontFamily: ref.typeface.albertSansRegularFont,
    fontWeight: Platform.OS === 'android' ? 'normal' : '500',
  },
  labelSmall: {
    fontFamily: ref.typeface.albertSansRegularFont,
    letterSpacing: 0.5,
    lineHeight: Platform.select({
      web: '16px',
      ios: 16,
      android: 16,
    }),
    fontSize: MD3Tokens.MdSysTypescaleLabelSmallSize,
  },

  bodyLarge: {
    fontFamily: ref.typeface.albertSansRegularFont,
    lineHeight: Platform.OS === 'web' ? '24px' : 24,
    fontSize: MD3Tokens.MdSysTypescaleBodyLargeSize,
  },
  bodyMedium: {
    fontFamily: ref.typeface.albertSansRegularFont,
    letterSpacing: 0.25,
    lineHeight: Platform.OS === 'web' ? '20px' : 20,
    fontSize: MD3Tokens.MdSysTypescaleBodyMediumSize,
  },
  bodySmall: {
    fontFamily: ref.typeface.albertSansRegularFont,
    letterSpacing: 0.4,
    lineHeight: Platform.OS === 'web' ? '16px' : 16,
    fontSize: MD3Tokens.MdSysTypescaleBodySmallSize,
  },
}

export const tokens = {
  md: {
    ref,
    sys: {
      typeScale,
    },
  },
}

export const spacing = {
  spacing1: 2,
  spacing2: 4,
  spacing3: 8,
  spacing4: 12,
  spacing5: 16,
  spacing6: 24,
  spacing7: 32,
  spacing8: 40,
  spacing9: 48,
  spacing10: 64,
  spacing11: 80,
  spacing12: 96,
  spacing13: 160,
}

export const MD3Colors = ref.palette
