import React from 'react'
import { View } from 'react-native'

import { spacing } from '@camped/theme-provider'

import QuestioPaperListing from './components/question-paper-listing'
import { SubWrapper } from './components/sub-wrapper'

const ViewContentContainer = (props) => {
  const {
    handleFileChange,
    handleOnSave,
    mainKeys,
    mainValue,
    pdfInputRef,
    user,
    selectedSem,
    setSelectedSem,
    subject,
    handleEditQuestionPaper,
  } = props
  let val = ''
  mainValue?.fields?.forEach((items) => {
    val = items?.find((item) => item?.metadata?.arrayName)
  })

  const isAdd = !!val

  if (mainKeys !== 'question Paper Preview')
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          marginTop: spacing.spacing4,
          marginLeft: spacing.spacing4,
          marginRight: spacing.spacing4,
        }}
      >
        {typeof mainValue?.fields !== 'string' && (
          <SubWrapper
            title={mainKeys}
            mainValue={mainValue?.fields}
            user={user}
            isAdd={isAdd}
            handleFileChange={handleFileChange}
            pdfInputRef={pdfInputRef}
            onSave={handleOnSave}
            setSelectedSem={setSelectedSem}
            selectedSem={selectedSem}
          />
        )}
      </View>
    )
  return (
    <QuestioPaperListing
      mainValue={mainValue}
      mainKeys={mainKeys}
      subject={subject}
      handleEditQuestionPaper={handleEditQuestionPaper}
    />
  )
}

export default ViewContentContainer
