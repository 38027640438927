/* eslint-disable no-unused-vars */
import { useAtom } from 'jotai'

import { useInfiniteQuery } from '@tanstack/react-query'

import { trpcApiCall } from '@camped/api/src/trpcApiCall'
import { domainAtom, userAtom } from '@camped/jotai-storage'

import { feedsUrl } from '../config'

export const useGetNewFeeds = ({
  limit,
  sortValue,
  filterValue,
  totalCount,
}) => {
  const [currentUser] = useAtom(userAtom)
  console.log({ currentUser })
  const { data, fetchNextPage, refetch, isFetching, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['getNewsFeed'],
      queryFn: async ({ pageParam }) => {
        const page = pageParam || 1
        const response = await trpcApiCall({
          baseUrl: `${feedsUrl}/api`,
          pathname: 'socialFeeds.get-social-feeds-by-filter',
          method: 'QUERY',
          input: {
            userId: currentUser?.['custom:userId'],
            page,
            pageSize: limit,
          },
        })
        console.log({ response })
        return response
      },
      initialData: [],
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1
        return nextPage
      },
      onError: (error) => {
        console.log(error)
      },
    })
  const feedsList = (data?.pages || [])
    ?.flatMap((page) => page?.result || [])
    ?.filter((item) => item !== undefined)

  const hasNextPage = feedsList?.length < data?.pages?.[0]?.count
  // console.log({ feedsList })
  return {
    data: feedsList,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  }
}
