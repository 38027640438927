import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider/src/tokens'

const UserRoleComponent = ({ item }) => {
  const { colors } = useTheme()
  const styles = createStyles(colors)
  return (
    <View style={styles.container}>
      <Icon name='DefaultImage' fill={colors.onSurface} style={styles.icon} />
      <Text style={styles.title}>{item.name}</Text>
    </View>
  )
}

const createStyles = (colors) =>
  StyleSheet.create({
    container: {
      marginVertical: spacing.spacing4,
      marginRight: spacing.spacing4,
      backgroundColor: colors.surface2,
      borderRadius: spacing.spacing5,
      borderColor: colors.onSurface,
      padding: spacing.spacing4,
      borderWidth: 0.35,
      width: 240,
    },
    title: {
      marginTop: spacing.spacing6,
      color: colors.onPrimaryContainer,
      fontSize: spacing.spacing5,
      fontWeight: '600',
    },
    icon: {
      height: 30,
      width: 30,
    },
  })

export default UserRoleComponent
