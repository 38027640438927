import React from 'react'
import { StyleSheet, View, useWindowDimensions } from 'react-native'

import { ShimmerPlaceholder } from '@camped/components'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout } from '@camped/utils'

export const CampEdBrandingSkeleton = () => {
  const { width } = useWindowDimensions()

  const isDesktop = ScreenLayout.isWeb(width)

  return (
    <>
      <View style={styles.logoContainer}>
        <ShimmerPlaceholder style={styles.logoShimmer} />
      </View>
      <View style={styles.imageContainer}>
        <ShimmerPlaceholder style={styles.imageShimmer} />
      </View>
      {isDesktop && (
        <View style={styles.textContainer}>
          <ShimmerPlaceholder style={styles.textShimmer} />
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  logoContainer: {
    minHeight: 61,
    marginBottom: spacing.spacing6,
    marginTop: spacing.spacing5,
  },
  logoShimmer: {
    width: 100,
    height: 30,
    borderRadius: 4,
  },
  imageContainer: {
    aspectRatio: 1.5,
    borderRadius: 4,
    marginTop: spacing.spacing3,
    marginBottom: 12,
  },
  imageShimmer: {
    flex: 1,
    borderRadius: 4,
    width: '100%',
  },
  textContainer: {
    marginTop: spacing.spacing8,
  },
  textShimmer: {
    width: '60%',
    height: 30,
    borderRadius: 4,
    marginTop: spacing.spacing4,
  },
})
