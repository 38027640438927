import color from 'color'
import React from 'react'
import { Provider as PaperProvider } from 'react-native-paper'

import { Icon } from '@camped/icons'

import { spacing, tokens, typeScale } from './tokens'

const { palette, opacity } = tokens.md.ref

export const MD3LightTheme = {
  dark: false,
  roundness: 4,
  version: 3,
  isV3: true,
  colors: {
    primary: '#1C4ED8',
    onPrimary: '#ffffff',
    primaryContainer: '#DBE9FE',
    onPrimaryContainer: palette.primary10,
    secondary: palette.secondary40,
    onSecondary: palette.secondary100,
    secondaryContainer: palette.secondary90,
    onSecondaryContainer: palette.secondary10,
    tertiary: palette.tertiary40,
    onTertiary: palette.tertiary100,
    tertiaryContainer: palette.tertiary90,
    onTertiaryContainer: palette.tertiary10,
    background: '#F9FAFB',
    onBackground: '#979FAB',
    surface: palette.neutral99,
    onSurface: palette.neutral10,
    surfaceVariant: palette.surfaceVariantLight,
    onSurfaceVariant: palette.onSurfaceVariantLight,
    outline: palette.neutralVariant50,
    success: '#30C48D',
    onSuccess: palette.onSuccessLight,
    successContainer: palette.successContainerLight,
    onSuccessContainer: palette.onSuccessContainerLight,
    error: '#C81E1D',
    onError: palette.onErrorLight,
    errorContainer: palette.errorContainerLight,
    onErrorContainer: palette.onErrorContainerLight,
    surface1: '#ffffff',
    onSurface1: '#000000',
    surface2: '#f3f4f6',
    surface3: palette.primary100,
    surface4: 'rgba(28, 27, 31, 0.15)',
    skeleton: '#d1d5db',
    onSurfaceDisabled: color(palette.neutral10)
      .alpha(opacity.level4)
      .rgb()
      .string(),
    inverseSurface: palette.neutral20,
    inverseOnSurface: palette.neutral95,
    inversePrimary: palette.primary80,
    primaryVariant: palette.primary70,
    elevation: {
      level0: 'transparent',
      // Note: Color values with transparency cause RN to transfer shadows to children nodes
      // instead of View component in Surface. Providing solid background fixes the issue.
      // Opaque color values generated with `palette.primary99` used as background
      level1: 'rgb(247, 243, 249)', // palette.primary40, alpha 0.05
      level2: 'rgb(243, 237, 246)', // palette.primary40, alpha 0.08
      level3: 'rgb(238, 232, 244)', // palette.primary40, alpha 0.11
      level4: 'rgb(236, 230, 243)', // palette.primary40, alpha 0.12
      level5: 'rgb(233, 227, 241)', // palette.primary40, alpha 0.14
    },
    white: palette.primary100,
    black: palette.primary0,
  },
  fonts: typeScale,
  animation: {
    scale: 1.0,
  },
  spacing,
}

export const MD3DarkTheme = {
  ...MD3LightTheme,
  dark: true,
  mode: 'adaptive',
  version: 3,
  isV3: true,
  colors: {
    primary: '#1C4ED8',
    onPrimary: '#ffffff',
    primaryContainer: '#BEDBFE',
    onPrimaryContainer: palette.primary90,
    secondary: palette.secondary80,
    onSecondary: palette.secondary20,
    secondaryContainer: palette.secondary30,
    onSecondaryContainer: palette.secondary90,
    tertiary: palette.tertiary80,
    onTertiary: palette.tertiary20,
    tertiaryContainer: palette.tertiary30,
    onTertiaryContainer: palette.tertiary90,
    background: '#1f2937',
    onBackground: '#e9eaed',
    surface: palette.neutral10,
    onSurface: palette.neutral80,
    surfaceVariant: palette.surfaceVariantDark,
    onSurfaceVariant: palette.onSurfaceVariantDark,
    outline: palette.neutralVariant60,
    success: '#30C48D',
    onSuccess: palette.onSuccessDark,
    successContainer: palette.successContainerDark,
    onSuccessContainer: palette.onSuccessContainerDark,
    error: '#C81E1D',
    onError: palette.onErrorDark,
    errorContainer: palette.errorContainerDark,
    onErrorContainer: palette.onErrorContainerDark,
    surface1: '#111827',
    onSurface1: '#ffffff',
    surface2: '#4b5563',
    surface3: '#1f2937',
    surface4: 'rgba(229, 225, 230, 0.04)',
    skeleton: '#6b7280',
    surfaceDark: palette.surfaceDark,
    onSurfaceDisabled: color(palette.neutral90)
      .alpha(opacity.level4)
      .rgb()
      .string(),
    inverseSurface: palette.neutral90,
    inverseOnSurface: palette.neutral20,
    inversePrimary: palette.primary80,
    primaryVariant: palette.primary70,
    elevation: {
      level0: 'transparent',
      // Note: Color values with transparency cause RN to transfer shadows to children nodes
      // instead of View component in Surface. Providing solid background fixes the issue.
      // Opaque color values generated with `palette.primary80` used as background
      level1: 'rgb(37, 35, 42)', // palette.primary80, alpha 0.05
      level2: 'rgb(44, 40, 49)', // palette.primary80, alpha 0.08
      level3: 'rgb(49, 44, 56)', // palette.primary80, alpha 0.11
      level4: 'rgb(51, 46, 58)', // palette.primary80, alpha 0.12
      level5: 'rgb(52, 49, 63)', // palette.primary80, alpha 0.14
    },
    white: palette.primary100,
    black: palette.primary0,
  },
  fonts: typeScale,
  animation: {
    scale: 1.0,
  },
  spacing,
}

export { spacing }

const icons = (props) => <Icon {...props} />
export const ThemeProvider = ({ children, mode }) => {
  let theme = {
    ...MD3LightTheme,
  }
  if (mode === 'DARK') {
    theme = { ...MD3DarkTheme }
  }
  return (
    <PaperProvider
      settings={{
        icon: (props) => icons(props),
      }}
      theme={theme}
    >
      {children}
    </PaperProvider>
  )
}
