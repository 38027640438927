import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Surface } from 'react-native-paper'

import { Row, Text } from '@camped/components'
import { Icon } from '@camped/icons'

import { Card } from './list-card'

export const ListItems = (props) => {
  const {
    userLists,
    goTo,
    handleCheckBox,
    selectedIDs,
    expanded,
    handleInviteClick,
    colors,
    module,
    tableHeader,
    setExpanded,
    profileImageList,
    handleBulkInvite,
  } = props
  const { t } = useTranslation()
  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      showsVerticalScrollIndicator={false}
    >
      {userLists?.map((item, index) => (
        <View key={`${item?._id}-${index}`}>
          <Card
            item={item}
            goTo={goTo}
            profileImageList={profileImageList}
            handleCheckBox={handleCheckBox}
            selectedIDs={selectedIDs}
            expanded={expanded}
            setExpanded={setExpanded}
            tableHeader={tableHeader}
            module={module}
            handleInviteClick={handleInviteClick}
          />
        </View>
      ))}
      {selectedIDs.length > 0 && (
        <Surface
          style={{
            borderRadius: 30,
            width: '100%',
            height: 60,
            backgroundColor: colors.primaryVariant,
          }}
        >
          <Row
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              paddingHorizontal: 40,
            }}
          >
            <View>
              <TouchableOpacity onPress={() => handleBulkInvite()}>
                <Icon
                  name='InviteIcon'
                  width={21}
                  height={20}
                  color={colors.onPrimary}
                  style={styles.iconView}
                />
                <Text style={{ color: colors.onPrimary }}>
                  {t('Directory.SEND_INVITE')}
                </Text>
              </TouchableOpacity>
            </View>
          </Row>
        </Surface>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  iconView: { alignSelf: 'center', justifyContent: 'center', flex: 1 },
})
