import React, { useEffect, useRef, useState } from 'react'
import {
  FlatList,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { Surface, TextInput, useTheme } from 'react-native-paper'

import { Text } from '@camped/components'

const SearchInput = (props) => {
  const {
    inputValue,
    setInputValue,
    getSearchData,
    fieldName,
    searchData,
    data,
    style,
    disabled,
    error,
  } = props
  const [visible, setVisible] = useState(false)
  const [dropDownWidth, setDropDownWidth] = useState(0)
  const DropdownButton = useRef()

  useEffect(() => {
    if (inputValue?.length === 0 || !inputValue || inputValue === undefined) {
      setVisible(false)
    }
  }, [inputValue])

  const toggleDropdown = () => {
    if (!visible) {
      DropdownButton?.current?.measure((_fx, _fy, _w) => {
        setDropDownWidth(_w)
      })
      setVisible(true)
    }
  }
  const { spacing, colors } = useTheme()
  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={[styles.item(spacing), {}]}
      onPress={() => {
        setInputValue(data, item)
        setVisible(false)
      }}
    >
      <View style={{ marginLeft: spacing.spacing3 }}>
        <Text
          variant='titleMedium'
          style={{
            color: colors.onBackground,
          }}
        >
          {item?.firstName}
        </Text>
        <Text
          variant='titleSmall'
          style={{
            color: colors.outline,
          }}
        >
          {item?.registrationNumber}
        </Text>
      </View>
    </TouchableOpacity>
  )

  const renderDropdown = () =>
    visible && (
      <ScrollView
        style={{
          height: 240,
          position: 'absolute',
          width: '100%',
          marginTop: 60,
          zIndex: 200,
        }}
      >
        <View style={[styles.dropdown, {}]}>
          <Surface style={{ width: dropDownWidth }}>
            <View style={{ flex: 1 }}>
              <FlatList
                data={searchData}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
              />
            </View>
          </Surface>
        </View>
      </ScrollView>
    )
  return (
    <View
      style={{
        position: 'relative',
        flex: 1,
        ...style,
      }}
    >
      <TouchableOpacity ref={DropdownButton}>
        <View
          style={{
            flex: 1,
            height: '100%',
            width: '100%',
          }}
        >
          <TextInput
            autoCorrect={false}
            mode='outlined'
            label={props.label || 'Search'}
            outlineColor={colors.onBackground}
            placeholderTextColor={colors.onBackground}
            placeholder={props.placeholder || 'Search'}
            style={{
              width: '100%',
              height: '100%',
              borderColor: 'transparent',
              flex: 1,
              borderWidth: 0,
              color: colors.onBackground,
            }}
            onChangeText={(text) => {
              toggleDropdown()
              setInputValue(data, text, fieldName)
              getSearchData(text)
            }}
            value={inputValue}
            disabled={disabled}
            error={error}
          />
          {renderDropdown()}
        </View>
      </TouchableOpacity>
    </View>
  )
}
const styles = StyleSheet.create({
  dropdown: {
    position: 'absolute',
    width: 144,
    borderBottomLeftRadius: 18,
    borderBottomRightRadius: 18,
  },
  overlay: {
    flex: 1,
    height: '100%',
  },
  item: (spacing) => ({
    paddingHorizontal: spacing.spacing4,
    paddingVertical: spacing.spacing4,
    flexDirection: 'row',
    alignItems: 'center',
  }),
})

export default SearchInput
