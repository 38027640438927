import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Platform, ScrollView, TouchableOpacity, View } from 'react-native'
import { Calendar } from 'react-native-calendars'
import { Menu, TouchableRipple, useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'

import Divider from '../Divider'
import Text from '../Text'
import TextInput from '../TextInput'

const CalendarInput = (props) => {
  const { label, placeholder, dob, value, data, onChangeText, error, style } =
    props
  const [isVisible, setIsVisible] = useState(false)
  const [yearVisible, setYearVisible] = useState(false)
  const { colors, spacing } = useTheme()
  const minYear = 1970
  const currentYear = dob
    ? new Date().getFullYear() - 15
    : new Date().getFullYear()
  const defaultValue =
    value || `${currentYear}-${new Date().toISOString().slice(5, 10)}`
  const [selectedYear, setSelectedYear] = useState(defaultValue.slice(0, 4))
  const [selectedMonth, setSelectedMonth] = useState(defaultValue.slice(5, 7))
  const [selectedDay, setSelectedDay] = useState(defaultValue.slice(8, 10))

  const yearScrollViewRef = useRef(null)
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const handleMonthChange = (localMonth) => {
    setSelectedMonth(localMonth.month)
  }
  const yearItems = []
  for (let year = minYear; year <= currentYear; year += 1) {
    yearItems.push(year)
  }
  useEffect(() => {
    if (!yearVisible) return
    const selectedYearIndex = yearItems.findIndex(
      (year) => year === selectedYear,
    )
    setTimeout(() => {
      if (selectedYearIndex !== -1) {
        const yearItemHeight = 48
        const scrollToOffset = selectedYearIndex * yearItemHeight
        yearScrollViewRef.current.scrollTo({
          y: scrollToOffset,
          animated: true,
        })
      }
    }, 400)
  }, [selectedYear, yearItems, yearVisible])

  const renderHeader = () => (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Text>{month?.find((item, index) => selectedMonth - 1 === index)}</Text>

      <Menu
        visible={yearVisible}
        anchorPosition='bottom'
        onDismiss={() => setYearVisible(false)}
        anchor={
          <TouchableOpacity onPress={() => setYearVisible(!yearVisible)}>
            <View
              style={{
                paddingLeft: 16,
                alignItems: 'center',
              }}
            >
              <TextInput
                value={selectedYear.toString()}
                style={{
                  width: 100,
                  height: 24,
                  paddingTop: 0,
                }}
                onChangeText={(itemValue) => {
                  setSelectedYear(itemValue)
                }}
                editable={false}
              />
              <Icon
                name='DownArrowFilled'
                color={colors.onSurfaceVariant}
                style={{
                  height: spacing.spacing4,
                  width: spacing.spacing4,
                  position: 'absolute',
                  right: spacing.spacing3,
                  top: Platform.OS === 'web' ? '40%' : '20%',
                }}
              />
            </View>
          </TouchableOpacity>
        }
      >
        <ScrollView
          ref={yearScrollViewRef}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
          style={{
            height: 200,
          }}
        >
          {yearItems?.map((_item, index) => (
            <Fragment key={index}>
              <TouchableRipple
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                onPress={() => {
                  setSelectedYear(_item)
                  setYearVisible(false)
                }}
              >
                <Menu.Item title={_item} />
              </TouchableRipple>
              {yearItems &&
                yearItems.length > 0 &&
                yearItems.length - 1 !== index && <Divider />}
            </Fragment>
          ))}
        </ScrollView>
      </Menu>
    </View>
  )

  return (
    <View>
      <Menu
        visible={isVisible}
        anchorPosition='bottom'
        onDismiss={() => setIsVisible(false)}
        anchor={
          <TouchableOpacity onPress={() => setIsVisible(!isVisible)}>
            <TextInput
              label={label}
              placeholder={placeholder}
              right={<TextInput.Affix />}
              value={value || null}
              maxLength={data?.maxLength}
              error={error}
              onFocus={() => {
                props?.onFocus?.()
              }}
              style={style}
              editable={false}
            />
          </TouchableOpacity>
        }
      >
        <View
          style={{ width: 296, height: 350, backgroundColor: colors.surface2 }}
        >
          <Calendar
            key={selectedYear}
            hideExtraDays
            enableSwipeMonths
            current={`${selectedYear}-${selectedMonth}-${selectedDay}`}
            minDate={`${minYear}-01-01`}
            maxDate={`${currentYear}-12-31`}
            disableAllTouchEventsForDisabledDays
            onDayPress={(day) => {
              onChangeText(
                `${day.year}-${
                  day.month.toString().length > 1 ? day.month : `0${day.month}`
                }-${day.day.toString().length > 1 ? day.day : `0${day.day}`}`,
              )
              setSelectedYear(day.year)
              setSelectedMonth(day.month)
              setSelectedDay(day.day)
              setIsVisible(false)
            }}
            renderHeader={renderHeader}
            onMonthChange={(localMonth) => handleMonthChange(localMonth)}
            theme={{
              calendarBackground: colors.surface2,
              dayTextColor: colors.onSurface1,
            }}
            renderArrow={(direction) => (
              <Icon
                name='BackArrow'
                color={colors.primary}
                width={20}
                height={20}
                style={{
                  transform:
                    direction === 'left'
                      ? [{ rotate: '360deg' }]
                      : [{ rotate: '180deg' }],
                }}
              />
            )}
          />
        </View>
      </Menu>
    </View>
  )
}

export default CalendarInput
