// this is an auto generated file. This will be overwritten

export const tenant = /* GraphQL */ `
  query Tenant($_id: String!) {
    tenant(_id: $_id) {
      _id
      tenantName
      tenantDomain
      primaryContact
      primaryContactEmail
      signInMode
      otherContacts {
        contactName
        contactEmail
      }
    }
  }
`

export const metadatum = /* GraphQL */ `
  query Metadatum($_id: String!) {
    metadatum(_id: $_id) {
      _id
      userPerson
      collectionName
      screenName
      fieldName
      placeholder
      label
      component
      displayOrder
      errorMessage
      isMandatory
      maxLength
      mode
      multiline
      valueType
      displayName
      options {
        displayName
        value
        displayOrder
      }
      arrayName
      link
    }
  }
`
export const tenantRolePermission = /* GraphQL */ `
  query TenantRolePermission($_id: String!) {
    tenantRolePermission(_id: $_id) {
      _id
      tenantId
      tenantRoleId
      name
      type
      parent
      userType
      fieldPermissions {
        view
        edit
        delete
        add
      }
      fieldMetadata
    }
  }
`
export const domain = /* GraphQL */ `
  query Domain($_id: String!) {
    domain(_id: $_id) {
      _id
      domainName
      fields {
        fieldName
        displayName
        dataType
        placeholder
        view
        isSortable
        isSearchable
      }
    }
  }
`
export const role = /* GraphQL */ `
  query Role($_id: String!) {
    role(_id: $_id) {
      _id
      roleName
      tenantId
      domainId
      domainPermissions {
        domainId
        create
        edit
        view
        delete
        fieldPermissions {
          fieldId
          create
          edit
          view
          delete
        }
      }
    }
  }
`
export const user = /* GraphQL */ `
  query User($_id: String!) {
    user(_id: $_id) {
      _id
      tenantId
      emailId
      userType
      signInMode
      isCognitoUserCreated
      staffId
      studentId
    }
  }
`
export const tenantRole = /* GraphQL */ `
  query TenantRole($_id: String!) {
    tenantRole(_id: $_id) {
      _id
      tenantId
      userType
      userRole
    }
  }
`
export const userRoleMapping = /* GraphQL */ `
  query UserRoleMapping($_id: String!) {
    userRoleMapping(_id: $_id) {
      tenantId
      userId
      roleId
      userType
    }
  }
`

export const getS3ImageUploadUrl = /* GraphQL */ `
  query GetS3ImageUploadUrl($s3Data: S3UploadImageInput!) {
    getS3ImageUploadUrl(s3Data: $s3Data) {
      uploadUrl
    }
  }
`
export const getViewProfileImageUrl = /* GraphQL */ `
  query getViewProfileImageUrl($s3Data: [S3ViewImageInput!]!) {
    getViewProfileImageUrl(s3Data: $s3Data) {
      id
      imageUrl
    }
  }
`

export const getCoursesByFilter = `
query getCoursesByFilter($filter: FilterCourseInput!) {
  getCoursesByFilter (
      filter: $filter
  ) {
      _id
      courseName
      department{
        _id
        name
      }
      graduation{
        _id
        name
      }
      category
      degree{
        _id
        name
        graduation{
          _id
          name
        }
      }
      modeOfStudy
      totalSemesters
      courseDuration
      yearOfStart
      tenant{
        _id
      }
  }
}
`

export const getSubjectsByFilter = `
query GetSubjectsByFilter($filter: FilterSubjectInput!) {
  getSubjectsByFilter (
    filter: $filter,
) {
    _id
    subjectCode
    semester
    natureOfSubject
    subjectType
    totalHoursOrPeriods
    credits
    internalMarks
    externalMarks
    lmsSubjectId
    subjectName
}
}
`

export const getCourseById = `
query GetCourseById($id: String!) {
  getCourseById(
    id: $id
  ) {
    _id
    courseName
    department{
        _id
        name
      }
    graduation{
      _id
      name
    }
    category
    modeOfStudy
    degree{
      _id
      name
      graduation{
        _id
        name
      }
    }
    totalSemesters
    courseDuration
    yearOfStart
  }
}
`
export const getSubjectById = `
query getSubjectById($id: String!) {
    getSubjectById (
        id: $id
    ) {
        _id
        subjectCode
        semester
        natureOfSubject
        subjectType
        totalHoursOrPeriods
        credits
        internalMarks
        externalMarks
        lmsSubjectId
        subjectName
        staffId
        objectives
        outcomes
        noOfUnits
        units { 
            unitName
            totalHoursOrPeriods
            unitDetails
            locatorId
        }
        textBooks { 
            bookName
            author
            edition
            publisher
        }
        references { 
            referenceName
            referenceAuthor
            referenceEdition
            referencePublisher
        }
        course { 
            _id
            courseName
            department{
              _id
              name
            }
            graduation{
              _id
              name
            }
            modeOfStudy
            degree{
              _id
              name
              graduation{
                _id
                name
              }
            }
            totalSemesters
            courseDuration
            yearOfStart
        }
        tenantId
    }
}`

export const getQuestionPaperById = `
query getQuestionPaperById($id: String!) {
    getQuestionPaperById (
        id: $id
    ) {
        _id
        examName
        totalMarks
        difficultyLevel
        timeLimit
        date
        noOfSections
        generatedQuestion
        subject { 
            _id
            subjectCode
            semester
            natureOfSubject
            totalHoursOrPeriods
            credits
            internalMarks
            externalMarks
            lmsSubjectId
            staffId
            course { 
                _id
                courseName
                department{
                  _id
                  name
                }
                graduation{
                  _id
                  name
                }
                degree{
                  _id
                  name
                  graduation{
                    _id
                    name
                  }
                }
                modeOfStudy
                yearOfStart
            }
        }
        questionPaperSection { 
            sectionNumber
            sectionMarks
            totalQuestions
            eachQuestionMark
            questionsAnswered
            sectionType
        }
    }
}`
export const getQuestionPaperByFilter = `
query getQuestionPaperByFilter($filter: FilterQuestionPaperInput!) {
    getQuestionPaperByFilter (
        filter: $filter
    ) {
        _id
        examName
        totalMarks
        difficultyLevel
        timeLimit
        date
        noOfSections
        generatedQuestion
        subject { 
            _id
            subjectCode
            semester
            natureOfSubject
            totalHoursOrPeriods
            credits
            internalMarks
            externalMarks
            lmsSubjectId
            staffId
            subjectType
            objectives
            outcomes
            noOfUnits
            units { 
                unitName
                totalHoursOrPeriods
                unitDetails
            }
            textBooks { 
                bookName
                author
                edition
                publisher
            }
            references { 
                referenceName
                referenceAuthor
                referenceEdition
                referencePublisher
            }
            course { 
                _id
                courseName
                department{
                  _id
                  name
                }
                graduation{
                  _id
                  name
                }
                category
                degree{
                  _id
                  name
                  graduation{
                    _id
                    name
                  }
                }
                modeOfStudy
                totalSemesters
                courseDuration
                yearOfStart
            }
            tenantId
        }
        questionPaperSection { 
            sectionNumber
            sectionMarks
            totalQuestions
            eachQuestionMark
            questionsAnswered
            sectionType
        }
    }
}`

export const getQuestionPapersByFilter = `
query getQuestionPapersByFilter($filter: FilterQuestionPaperInput!, $pagination: PaginationInput, $sorts: [SortInput!]) {
    getQuestionPapersByFilter (
        filter: $filter,
        pagination: $pagination,
        sorts: $sorts
    ) {
        _id
        examName
        totalMarks
        difficultyLevel
        timeLimit
        date
        noOfSections
        generatedQuestion
        subject { 
            _id
            subjectCode
            semester
            natureOfSubject
            totalHoursOrPeriods
            credits
            internalMarks
            externalMarks
            lmsSubjectId
            staffId
            subjectType
            objectives
            outcomes
            noOfUnits
            units { 
                unitName
                totalHoursOrPeriods
                unitDetails
            }
            textBooks { 
                bookName
                author
                edition
                publisher
            }
            references { 
                referenceName
                referenceAuthor
                referenceEdition
                referencePublisher
            }
            course { 
                _id
                courseName
                department{
                  _id
                  name
                }
                graduation{
                  _id
                  name
                }
                category
                degree{
                  _id 
                  name
                  graduation{
                    _id
                    name
                  }
                }
                modeOfStudy
                totalSemesters
                courseDuration
                yearOfStart
            }
            tenantId
        }
        questionPaperSection { 
            sectionNumber
            sectionMarks
            totalQuestions
            eachQuestionMark
            questionsAnswered
            sectionType
        }
    }
}`

export const getMetaData = `
query getMetaData($metaData: MetadataInput!) {
    getMetaData (
        metaData: $metaData
    ) {
        screens { 
            metadata { 
                _id
                displayName
                
            }
            permissions { 
                view
                edit
                delete
                add
            }
        }
        tabs { 
            metadata { 
                _id
                userPerson
                screenName
                fieldName
                displayName
                displayOrder
                screen { 
                    _id
                    displayName
                    
                }
            }
            permissions { 
                view
                edit
                delete
                add
            }
        }
        sections { 
            metadata { 
                _id
                userPerson
                screenName
                fieldName
                displayName
                displayOrder
                tab { 
                    _id
                    userPerson
                    screenName
                    fieldName
                    displayName
                    displayOrder
                    screen { 
                        _id
                        displayName
                    }
                }
            }
            permissions { 
                view
                edit
                delete
                add
            }
            fields { 
                metadata { 
                    _id
                    userPerson
                    screenName
                    fieldName
                    placeholder
                    additionalFieldNames
                    label
                    component
                    displayOrder
                    errorMessage
                    isMandatory
                    maxLength
                    multiline
                    valueType
                    isHidden
                    options { 
                        displayName
                        value
                        displayOrder
                    }
                    arrayName
                    referenceName
                    navigateTo
                    link
                    mode
                    section { 
                        _id
                        userPerson
                        screenName
                        fieldName
                        displayName
                        displayOrder
                    }
                }
                permissions { 
                    view
                    edit
                    delete
                    add
                }
            }
        }
    }
}`

export const getTenantById = `
query getTenantById($id: String!) {
    getTenantById (
        id: $id
    ) {
         _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
      }
}`

export const getTenantUserRoleMappingByFilter = `
query getTenantUserRoleMappingByFilter($filter: FilterTenantUserRoleMappingInput!) {
    getTenantUserRoleMappingByFilter (
        filter: $filter
    ) { 
        _id
        userPerson
        tenant { 
            _id
            name
            domain
        }
        user { 
            _id
            email
        }
        role { 
            _id
            name
        }
    }
}`
export const getTenantUserRoleMappingsByFilter = `
query getTenantUserRoleMappingsByFilter($filter: FilterTenantUserRoleMappingInput!, $pagination: PaginationInput, $sorts: [SortInput!]) {
    getTenantUserRoleMappingsByFilter (
        filter: $filter,
        pagination: $pagination,
        sorts: $sorts
    ) {
        _id
        userPerson
        enabledOn
        disabledOn
        isActive
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
        user { 
            _id
            email
            userPerson
            signInMode
            createCognitoUser
            isCognitoUserCreated
        }
        role { 
            _id
            name
            userPerson
            tenant { 
                _id
                name
                domain
                welcomeMessage
                institutionName
                logoURL
                backgroundImageURL
                primaryContact
                primaryContactEmail
                signInMode
                collegeCode
                collegeName
                yearOfEstablishment
                campusType
                universityName
                universityPhoneNumber
                universityFaxNo
                universityEmailId
                universityWebsite
                address
                phoneNumber
                faxNo
                emailId
                website
            }
        }
    }
}`

export const getTenantByFilter = `
query getTenantByFilter($filter: FilterTenantInput!) {
    getTenantByFilter (
        filter: $filter
    ) {
        _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
    }
}`
export const getStaffById = `
query getStaffById($id: String!) {
    getStaffById (
        id: $id
    ) {
        _id
        profileImage
        employeeId
        title
        firstName
        middleName
        lastName
        gender
        emailId
        dateOfBirth
        department{
          _id
          name
        }
        designation
        employeeType
        dateOfJoining
        aadhaarNumber
        accommodationType
        contactRelation
        nationality
        bloodGroup
        maritalStatus
        collegeEmailId
        emergencyContactName
        emergencyContactNumber
        relation
        personalEmailId
        landlineNumber
        mobileNumber
        communicationAddress
        permanentAddress
        pincode
        currentRoomNo
        hasDisabilities
        hasAllergies
        medicalCondition
        caste
        community
        religion
        educationDetails { 
            educationType
            educationSchool
            educationPercentage
            educationYOP
            educationSpecialization
            educationTitle
        }
        employmentDetails { 
            departmentName
            employmentClass
            employmentDesignation
            employmentSalary
            employmentAppraisal
        }
        achievements { 
            achievementType
            achievementDescription
            achievementDate
        }
        user { 
            _id
        }
        tenant { 
            _id
        }
    }
}`

export const getStaffsByFilter = `
query getStaffsByFilter($filter: FilterStaffInput!, $pagination: PaginationInput, $sorts: [SortInput!]) {
    getStaffsByFilter (
        filter: $filter,
        pagination: $pagination,
        sorts: $sorts
    ) {
        _id
        profileImage
        employeeId
        title
        firstName
        middleName
        lastName
        gender
        emailId
        dateOfBirth
        isInvitationSent
        department{
          _id
          name
        }
        aadhaarNumber
        accommodationType
        contactRelation
        designation
        employeeType
        dateOfJoining
        nationality
        bloodGroup
        maritalStatus
        collegeEmailId
        emergencyContactName
        emergencyContactNumber
        relation
        personalEmailId
        landlineNumber
        mobileNumber
        communicationAddress
        permanentAddress
        pincode
        currentRoomNo
        hasDisabilities
        hasAllergies
        medicalCondition
        caste
        community
        religion
        educationDetails { 
            educationType
            educationSchool
            educationPercentage
            educationYOP
            educationSpecialization
            educationTitle
        }
        employmentDetails { 
            departmentName
            employmentClass
            employmentDesignation
            employmentSalary
            employmentAppraisal
        }
        achievements { 
            achievementType
            achievementDescription
            achievementDate
        }
        user { 
            _id
        }
        tenant { 
            _id
        }
    }
}`

export const getMenuMetaData = `
query getMenuMetaData($filter: FilterRoleFeaturePermissionInput!) {
    getMenuMetaData (
        filter: $filter
    ) {
        _id
        id
        userPerson
        displayName
        displayOrder
        iconName
        placement
        activePath
        navigationPath
        subMenuItems { 
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            placement
            activePath
            navigationPath
            subMenuItems { 
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                placement
                activePath
                navigationPath
                subMenuItems { 
                    _id
                    id
                    userPerson
                    displayName
                    displayOrder
                    iconName
                    placement
                    activePath
                    navigationPath
                    subMenuItems { 
                        _id
                        id
                        userPerson
                        displayName
                        displayOrder
                        iconName
                        placement
                        activePath
                        navigationPath
                    }
                }
            }
        }
    }
}
`
export const getStudentById = `
query getStudentById($id: String!) {
    getStudentById (
        id: $id
    ) {
        _id
        profileImage
        firstName
        middleName
        lastName
        dateOfBirth
        gender
        nationality
        bloodGroup
        aadhaarNumber
        maritalStatus
        emailId
        graduation{
          _id
          name
        }
        course{
          _id
          courseName
        }
        degree{
          _id
          name
          graduation{
            _id
            name
          }
        }
        collegeEmailId
        emergencyContactName
        emergencyContact
        contactRelation
        personalEmailId
        registrationNumber
        mobileNumber
        landLineNumber
        permanentAddress
        communicationAddress
        roomNo
        blockNo
        department{
          _id
          name
        }      
        dayScholarOrHostel
        otherConditions
        hasAllergies
        hasDisabilities
        caste
        community
        religion
        academicStartYear
        academicEndYear
        tuitionFee
        examFee
        transportFee
        hostelFee
        extraCurricularActivities
        hobbies
        achievements { 
            achievementType
            achievementDescription
            achievementDate
        }
        placements { 
            placementType
            companyPlaced
            approval
            role
            package
        }
        familyInfo { 
            name
            dateOfBirth
            gender
            occupation
            relation
            mobileNumber
            working
            annualIncome
        }
        previousAcademicDetails { 
            educationType
            educationSchool
            educationPercentage
            educationYOP
            educationSpecialization
            educationTitle
        }
        isInvitationSent
    }
}`

export const getStudentsByFilter = `
query getStudentsByFilter($filter: FilterStudentInput!, $pagination: PaginationInput, $sorts: [SortInput!]) {
    getStudentsByFilter (
        filter: $filter,
        pagination: $pagination,
        sorts: $sorts
        ) {
        _id
        profileImage
        firstName
        middleName
        lastName
        dateOfBirth
        gender
        registrationNumber
        nationality
        bloodGroup
        aadhaarNumber
        maritalStatus
        emailId
        department{
          _id
          name
        }      
        graduation{
          _id
          name
        }
        course{
          _id
          courseName
        }
        degree{
          _id
          name
          graduation{
            _id
            name
          }
        }
        collegeEmailId
        emergencyContactName
        emergencyContact
        contactRelation
        personalEmailId
        mobileNumber
        landLineNumber
        permanentAddress
        communicationAddress
        roomNo
        blockNo
        dayScholarOrHostel
        otherConditions
        hasAllergies
        hasDisabilities
        caste
        community
        religion
        academicStartYear
        academicEndYear
        tuitionFee
        examFee
        transportFee
        hostelFee
        extraCurricularActivities
        hobbies
        achievements { 
            achievementType
            achievementDescription
            achievementDate
        }
        placements { 
            placementType
            companyPlaced
            approval
            role
            package
        }
        familyInfo { 
            name
            dateOfBirth
            gender
            occupation
            relation
            mobileNumber
            working
            annualIncome
        }
        previousAcademicDetails { 
            educationType
            educationSchool
            educationPercentage
            educationYOP
            educationSpecialization
            educationTitle
          }
        isInvitationSent
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
        user { 
            _id
            email
            userPerson
            signInMode
            createCognitoUser
            isCognitoUserCreated
        }
    }
}`
export const getDepartmentById = `
query getDepartmentById($id: String!) {
    getDepartmentById (
        id: $id
    ) {
        
        _id
        image
        name
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
    }
}`
export const getDepartmentsByFilter = `
query getDepartmentsByFilter($filter: FilterDepartmentInput!, $pagination: PaginationInput, $sorts: [SortInput!]) {
    getDepartmentsByFilter (
        filter: $filter,
        pagination: $pagination,
        sorts: $sorts
    ) {
        
        _id
        image
        name
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
    }
}`
export const getRoleScreenPermissionsByFilter = /* GraphQL */ `
  query GetRoleScreenPermissionsByFilter(
    $filter: FilterRoleScreenPermissionInput!
    $pagination: PaginationInput
    $sorts: [SortInput!]
  ) {
    getRoleScreenPermissionsByFilter(
      filter: $filter
      pagination: $pagination
      sorts: $sorts
    ) {
      _id
      isEnabled
      userPerson
      tenant {
        _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
      }
      role {
        _id
        name
        tenant {
          _id
          name
          domain
          welcomeMessage
          institutionName
          logoURL
          backgroundImageURL
          primaryContact
          primaryContactEmail
          signInMode
          collegeCode
          collegeName
          yearOfEstablishment
          campusType
          universityName
          universityPhoneNumber
          universityFaxNo
          universityEmailId
          universityWebsite
          address
          phoneNumber
          faxNo
          emailId
          website
        }
      }
      screen {
        _id
        displayName
        feature {
          _id
          id
          userPerson
          displayName
          displayOrder
          iconName
          moduleName
          placement
          activePath
          navigationPath
          modules
          accordionList
          parentFeature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      permissions {
        view
        edit
        delete
        add
      }
    }
  }
`
export const getRoleFieldPermissionsByFilter = /* GraphQL */ `
  query GetRoleFieldPermissionsByFilter(
    $filter: FilterRoleFieldPermissionInput!
    $pagination: PaginationInput
    $sorts: [SortInput!]
  ) {
    getRoleFieldPermissionsByFilter(
      filter: $filter
      pagination: $pagination
      sorts: $sorts
    ) {
      _id
      userPerson
      tenant {
        _id
        name
      }
      role {
        _id
        name
      }

      screen {
        _id
        displayName
      }
      tab {
        _id
        userPerson
        screenName
        fieldName
        displayName
        displayOrder
      }
      section {
        _id
        userPerson
        screenName
        fieldName
        displayName
        displayOrder
        tab {
          _id
          userPerson
          screenName
          fieldName
          displayName
          displayOrder
          screen {
            _id
            displayName
            feature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
            }
          }
        }
        screen {
          _id
          displayName
          feature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
          }
        }
      }
      field {
        _id
        userPerson
        screenName
        fieldName
        placeholder
        label
        component
        displayOrder
        errorMessage
        isMandatory
        maxLength
        multiline
        valueType
        arrayName
        navigateTo
        link
        mode
        section {
          _id
          userPerson
          screenName
          fieldName
          displayName
          displayOrder
          tab {
            _id
            userPerson
            screenName
            fieldName
            displayName
            displayOrder
            screen {
              _id
              displayName
              feature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
          screen {
            _id
            displayName
            feature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      permissions {
        view
        edit
        delete
        add
      }
    }
  }
`

export const getRoleTabPermissionsByFilter = /* GraphQL */ `
  query GetRoleTabPermissionsByFilter(
    $filter: FilterRoleTabPermissionInput!
    $pagination: PaginationInput
    $sorts: [SortInput!]
  ) {
    getRoleTabPermissionsByFilter(
      filter: $filter
      pagination: $pagination
      sorts: $sorts
    ) {
      _id
      isEnabled
      userPerson
      tenant {
        _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
      }
      role {
        _id
        name
        tenant {
          _id
          name
          domain
          welcomeMessage
          institutionName
          logoURL
          backgroundImageURL
          primaryContact
          primaryContactEmail
          signInMode
          collegeCode
          collegeName
          yearOfEstablishment
          campusType
          universityName
          universityPhoneNumber
          universityFaxNo
          universityEmailId
          universityWebsite
          address
          phoneNumber
          faxNo
          emailId
          website
        }
      }
      feature {
        _id
        id
        userPerson
        displayName
        displayOrder
        iconName
        moduleName
        placement
        activePath
        navigationPath
        modules
        accordionList
        parentFeature {
          _id
          id
          userPerson
          displayName
          displayOrder
          iconName
          moduleName
          placement
          activePath
          navigationPath
          modules
          accordionList
          parentFeature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      screen {
        _id
        displayName
        feature {
          _id
          id
          userPerson
          displayName
          displayOrder
          iconName
          moduleName
          placement
          activePath
          navigationPath
          modules
          accordionList
          parentFeature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      tab {
        _id
        userPerson
        screenName
        fieldName
        displayName
        displayOrder
        screen {
          _id
          displayName
          feature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
    }
  }
`

export const getRoleSectionPermissionsByFilter = /* GraphQL */ `
  query GetRoleSectionPermissionsByFilter(
    $filter: FilterRoleSectionPermissionInput!
  ) {
    getRoleSectionPermissionsByFilter(filter: $filter) {
      _id
      isEnabled
      userPerson
      tenant {
        _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
      }
      feature {
        _id
        id
        userPerson
        displayName
        displayOrder
        iconName
        moduleName
        placement
        activePath
        navigationPath
        modules
        accordionList
        parentFeature {
          _id
          id
          userPerson
          displayName
          displayOrder
          iconName
          moduleName
          placement
          activePath
          navigationPath
          modules
          accordionList
          parentFeature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      role {
        _id
        name
        tenant {
          _id
          name
          domain
          welcomeMessage
          institutionName
          logoURL
          backgroundImageURL
          primaryContact
          primaryContactEmail
          signInMode
          collegeCode
          collegeName
          yearOfEstablishment
          campusType
          universityName
          universityPhoneNumber
          universityFaxNo
          universityEmailId
          universityWebsite
          address
          phoneNumber
          faxNo
          emailId
          website
        }
      }
      screen {
        _id
        displayName
        feature {
          _id
          id
          userPerson
          displayName
          displayOrder
          iconName
          moduleName
          placement
          activePath
          navigationPath
          modules
          accordionList
          parentFeature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      tab {
        _id
        userPerson
        screenName
        fieldName
        displayName
        displayOrder
        screen {
          _id
          displayName
          feature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      section {
        _id
        userPerson
        screenName
        fieldName
        displayName
        displayOrder
        tab {
          _id
          userPerson
          screenName
          fieldName
          displayName
          displayOrder
          screen {
            _id
            displayName
            feature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
        screen {
          _id
          displayName
          feature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      permissions {
        view
        edit
        delete
        add
      }
    }
  }
`
export const getRoleFeaturePermissionsByFilter = /* GraphQL */ `
  query GetRoleFeaturePermissionsByFilter(
    $filter: FilterRoleFeaturePermissionInput!
    $pagination: PaginationInput
    $sorts: [SortInput!]
  ) {
    getRoleFeaturePermissionsByFilter(
      filter: $filter
      pagination: $pagination
      sorts: $sorts
    ) {
      _id
      isEnabled
      userPerson
      role {
        _id
        name
      }
      feature {
        _id
        id
        userPerson
        displayName
        displayOrder
        iconName
        moduleName
        placement
        activePath
        navigationPath
        modules
        accordionList
        parentFeature {
          _id
          id
          userPerson
          displayName
          displayOrder
          iconName
          moduleName
          placement
          activePath
          navigationPath
          modules
          accordionList
          parentFeature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
    }
  }
`

export const getRolesByFilter = /* GraphQL */ `
  query GetRolesByFilter(
    $filter: FilterRoleInput!
    $pagination: PaginationInput
    $sorts: [SortInput!]
  ) {
    getRolesByFilter(filter: $filter, pagination: $pagination, sorts: $sorts) {
      _id
      name
      userPerson
      tenant {
        _id
        name
      }
    }
  }
`

export const getSortAndFiltersByFilter = `
query getSortAndFiltersByFilter($filter: FilterSortAndFilterInput!, $pagination: PaginationInput, $sorts: [SortInput!]) {
  getSortAndFiltersByFilter (
        filter: $filter,
        pagination: $pagination,
        sorts: $sorts
    ) {

      _id
      displayName
      fieldName
      displayOrder
      order
      fieldType
      component
      displayOrder
      label
      placeholder
      options {
        displayName
        value
        displayOrder
      }
     
    }
}`

export const getScreensByFilter = `
query getScreensByFilter($filter: FilterScreenInput!, $pagination: PaginationInput, $sorts: [SortInput!]) {
  getScreensByFilter (
        filter: $filter,
        pagination: $pagination,
        sorts: $sorts
    ) {
    _id
    displayName
    fieldName
    }
}`

export const searchStudent = `
query searchStudent($searchText: String!) {
  searchStudent (
        searchText: $searchText
    ) {
    _id
    firstName
    lastName
    registrationNumber
      user {
      _id
      email
      userPerson
      signInMode
      createCognitoUser
       isCognitoUserCreated
        }
    }
}`

export const searchStaff = `
query searchStaff($searchText: String!) {
  searchStaff (
        searchText: $searchText
    ) {
    _id
    firstName
    lastName
    employeeId
    user {
      _id
      email
      userPerson
      signInMode
      createCognitoUser
       isCognitoUserCreated
        }
    }
}`

export const getStudentAnalytics = `
query {
  getStudentAnalytics {
    total
    studentAnalyticCount {
      department
      gender
      count
    }
  }
}`

export const getCampedPlatformTokens = `
query getCampedPlatformTokens($email: String!) {
  getCampedPlatformTokens (email: $email) {
      aceprep {
        email
        emailProvider
        userId
        token
      }
      social {
          email
        emailProvider
        userId
        token
      }
    
  }
}`
export const getRoleById = `
query getRoleById($id: String!) {
    getRoleById (
        id: $id
    ) {
        _id
        name
        userPerson
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
    }
}`

export const getDegreesByFilter = `
query getDegreesByFilter($filter: FilterDegreeInput!, $pagination: PaginationInput, $sorts: [SortInput!]) {
  getDegreesByFilter (
        filter: $filter,
        pagination: $pagination,
        sorts: $sorts
    ) {
        _id
        name
        graduation { 
            _id
            name
            tenant { 
                _id
                name
                domain
                welcomeMessage
                institutionName
                logoURL
                backgroundImageURL
                primaryContact
                primaryContactEmail
                signInMode
                collegeCode
                collegeName
                yearOfEstablishment
                campusType
                universityName
                universityPhoneNumber
                universityFaxNo
                universityEmailId
                universityWebsite
                address
                phoneNumber
                faxNo
                emailId
                website
            }
        }
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
    }
}`
export const getGraduationsByFilter = `
query getGraduationsByFilter($filter: FilterGraduationInput!, $pagination: PaginationInput, $sorts: [SortInput!]) {
    getGraduationsByFilter (
        filter: $filter,
        pagination: $pagination,
        sorts: $sorts
    ) {
        _id
        name
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
    }
}`

export const getStaffsCountWithFilter = `
query ($filter: FilterStaffInput) {
    getStaffsCountWithFilter (
        filter: $filter
    )
}`

export const getStudentsCountWithFilter = `
query ($filter: FilterStudentInput) {
    getStudentsCountWithFilter (
        filter: $filter
    )
}`

export const getGraduationsCountWithFilter = `
query ($filter: FilterGraduationInput) {
    getGraduationsCountWithFilter (
        filter: $filter
    )
}`

export const getDepartmentsCountWithFilter = `
query ($filter: FilterDepartmentInput) {
    getDepartmentsCountWithFilter (
        filter: $filter
    )
}`

export const getCoursesCountWithFilter = `
query ($filter: FilterCourseInput) {
    getCoursesCountWithFilter (
        filter: $filter
    )
}`

export const getDegreesCountWithFilter = `
query ($filter: FilterDegreeInput) {
    getDegreesCountWithFilter (
        filter: $filter
    )
}`

export const studentEnrollment = `
query studentEnrollment($enrollment: StudentEnrollmentInput!) {
  studentEnrollment (
    enrollment: $enrollment,
    ) 
}`

export const getNewsByFilter = `
query getNewsByFilter($filter: FilterNewsInput!,$pagination: PaginationInput, $sorts: [SortInput!]) {
  getNewsByFilter (
    filter: $filter,
    pagination: $pagination,
    sorts: $sorts
  ) {
      _id
      source
      title
      description
      url
      imageUrl
      publishedAt
  }
}`
