import copyToClipBoard from 'copy-to-clipboard'

export const uploadFileToS3 = async ({ url, file }) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      header: {
        'Content-Type': 'image/png',
      },
    }).then((res) => res)
    return response
  } catch (err) {
    return err
  }
}

export const copy = async (data) => {
  copyToClipBoard(data, {
    debug: true,
    message: 'Copy',
  })
  return true
}
