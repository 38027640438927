export const Behavior = {
  BACK: 'Back',
  CLICK: 'Click',
  CONFIRM_PASSWORD: 'ConfirmPassword',
  DOMAIN: 'Domain',
  EMAIL: 'Email',
  NAVIGATE: 'Navigate',
  NEW_PASSWORD: 'Password',
  PASSWORD: 'Password',
  SUBMIT: 'Submit',
}
