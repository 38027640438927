import { NavigationContainer } from '@react-navigation/native'
import * as Sentry from '@sentry/react-native'
import { Amplify } from 'aws-amplify'
import 'intl-pluralrules'
import { Provider as JotaiProvider, useAtom } from 'jotai'
import React, { useRef } from 'react'
import { Platform } from 'react-native'
import {
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context'
import Toast from 'react-native-toast-notifications'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { themeSwitchAtom } from '@camped/jotai-storage'
import { ThemeProvider } from '@camped/theme-provider/src/index'
import { RootNavigator } from '@camped/utils'

import awsConfig from '../aws-exports'
import { sentryUrl } from './config'
import { AppNavigator } from './navigation'
import './translations/i18n'

Sentry.init({
  dsn: sentryUrl,
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 1.0,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  const routeNameRef = useRef()

  Amplify.configure(awsConfig)

  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      {Platform.OS === 'web' && (
        <style type='text/css' suppressHydrationWarning>
          {`
            @font-face {
              font-family: 'MaterialCommunityIcons';
              src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
            }
          `}
        </style>
      )}

      <NavigationContainer
        documentTitle={{
          formatter: (options) => `CampEd | ${options?.title || ''}`,
        }}
        ref={RootNavigator.navigationRef}
        onReady={() => {
          routeNameRef.current =
            RootNavigator.navigationRef.current.getCurrentRoute()?.name
        }}
        onStateChange={async () => {
          const currentRouteName =
            RootNavigator.navigationRef.current.getCurrentRoute()?.name
          routeNameRef.current = currentRouteName
        }}
        linking={{ enabled: true }}
      >
        <JotaiProvider>
          <QueryClientProvider client={queryClient}>
            <AppWrapper />
          </QueryClientProvider>
        </JotaiProvider>
      </NavigationContainer>
    </SafeAreaProvider>
  )
}

const AppWrapper = () => {
  const [themeState] = useAtom(themeSwitchAtom)

  return (
    <ThemeProvider mode={themeState}>
      <AppNavigator />
      <Toast
        ref={(ref) => {
          global.toast = ref
        }}
        duration={5000}
        animationType='zoom-in'
        animationDuration={0}
        textStyle={{ fontSize: 14 }}
      />
    </ThemeProvider>
  )
}

Sentry.setTag('Platform:', Platform.OS)
export default App
