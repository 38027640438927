export const getInitialLayout = [
  {
    _id: '650063ca4bb6fefd932ef2f6',
    feature: {
      _id: '65005d9478b3951d1f76ded9',
      name: 'Student',
      parentFeature: null,
    },
    form: {
      _id: '650152f96cd0249857ef69f0',
      name: '',
    },
    permissions: [
      {
        scope: 5,
        permission: 1,
      },
      {
        scope: 5,
        permission: 2,
      },
      {
        scope: 5,
        permission: 3,
      },
      {
        scope: 5,
        permission: 4,
      },
    ],
  },
  {
    _id: '6500645bff3a7e6e0ab36dff',
    feature: {
      _id: '65005db678b3951d1f76dee1',
      name: 'Staff',
    },
    form: {
      _id: '65014d5d6cd0249857ef69d2',
      name: '',
    },
    permissions: [
      {
        scope: 5,
        permission: 1,
      },
      {
        scope: 5,
        permission: 2,
      },
      {
        scope: 5,
        permission: 3,
      },
      {
        scope: 5,
        permission: 4,
      },
    ],
  },
  {
    _id: '65006473ff3a7e6e0ab36e0f',
    feature: {
      _id: '65005dc678b3951d1f76dee5',
      name: 'Alumini',
    },
    form: {
      _id: '65014d5d6cd0249857ef69d2',
      name: '',
    },
    permissions: [
      {
        scope: 5,
        permission: 1,
      },
      {
        scope: 5,
        permission: 2,
      },
      {
        scope: 5,
        permission: 3,
      },
      {
        scope: 5,
        permission: 4,
      },
    ],
  },
]

export const getRoleFormPermissionByFilter = [
  {
    _id: '650063ca4bb6fefd932ef2f6',
    feature: {
      _id: '65005d9478b3951d1f76ded9',
      name: 'Add student',
      parentFeature: null,
    },
    form: {
      _id: '650152f96cd0249857ef69f0',
      name: 'empty',
    },
    permissions: [
      {
        scope: 1,
        permission: 1,
      },
      {
        scope: 1,
        permission: 2,
      },
      {
        scope: 1,
        permission: 4,
      },
      {
        scope: 1,
        permission: 8,
      },
    ],
  },
]
export const getRoleFieldPermissionByFilter = [
  {
    _id: '6503ed75d32e730489d782e5',
    feature: {
      _id: '65005e0178b3951d1f76defd',
      name: 'view-student',
      parentFeature: {
        _id: '65005df978b3951d1f76def9',
        name: 'Student',
      },
    },
    form: {
      _id: '65014d776cd0249857ef69db',
      name: 'Personal Info',
    },
    subForm: {
      _id: '65014d776cd0249857ef69db',
      name: 'Basic Details',
    },
    fields: [
      {
        _id: '65014d776cd0249857ef69db',
        name: 'First Name',
        permissions: [
          {
            scope: 2,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Middle Name',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Last Name',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Date Of Birth',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
    ],
  },
  {
    _id: '6503ed75d32e730489d782e5',
    feature: {
      _id: '65005e0178b3951d1f76defd',
      name: 'view-student',
      parentFeature: {
        _id: '65005df978b3951d1f76def9',
        name: 'Student',
      },
    },
    form: {
      _id: '65014d776cd0249857ef69db',
      name: 'Personal Info',
    },
    subForm: {
      _id: '65014d776cd0249857ef69db',
      name: 'Academic Info',
    },
    fields: [
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Graduation Type',
        permissions: [
          {
            scope: 2,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Course',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Specialization',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
    ],
  },
  {
    _id: '6503ed75d32e730489d782e5',
    feature: {
      _id: '65005e0178b3951d1f76defd',
      name: 'view-student',
      parentFeature: {
        _id: '65005df978b3951d1f76def9',
        name: 'Student',
      },
    },
    form: {
      _id: '65014d776cd0249857ef69db',
      name: 'Personal Info',
    },
    subForm: {
      _id: '65014d776cd0249857ef69db',
      name: 'Contact Info',
    },
    fields: [
      {
        _id: '65014d776cd0249857ef69db',
        name: 'College Email Id',
        permissions: [
          {
            scope: 2,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Emergency Contact Name',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Emergency Contact Number',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'MobileNumber',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Relation',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
    ],
  },
  {
    _id: '6503ed75d32e730489d782e5',
    feature: {
      _id: '65005e0178b3951d1f76defd',
      name: 'view-student',
      parentFeature: {
        _id: '65005df978b3951d1f76def9',
        name: 'Student',
      },
    },
    form: {
      _id: '65014d776cd0249857ef69db',
      name: 'Personal Info',
    },
    subForm: {
      _id: '65014d776cd0249857ef69db',
      name: 'Accomodation',
    },
    fields: [
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Day Scholar/ Hosteller',
        permissions: [
          {
            scope: 2,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Hostel Block No',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
      {
        _id: '65014d776cd0249857ef69db',
        name: 'Room No',
        permissions: [
          {
            scope: 5,
            permission: 1,
          },
          {
            scope: 2,
            permission: 4,
          },
        ],
      },
    ],
  },
]

export const data = [
  {
    metadata: {
      arrayName: null,
      collectionName: 'student',
      component: 'Dropdown',
      displayName: 'Screen name',
      displayOrder: 1,
      errorMessage: '',
      fieldName: 'permission',
      filter: true,
      isMandatory: true,
      label: 'Screen name',
      link: null,
      maxLength: 100,
      mode: 'outlined',
      multiline: false,
      options: [
        {
          displayName: 'Student Details',
          value: '652f702db6e169ce6cfea6b9',
          displayOrder: 0,
        },
        {
          displayName: 'Staff Details',
          value: '652f702db6e169ce6cfea6b7',
          displayOrder: 1,
        },
        {
          displayName: 'Campus Details',
          value: '652cc625a53699dd241ef82a',
          displayOrder: 2,
        },
      ],
      placeholder: 'Screen name',
      screenName: 'permission',
      userPerson: 'ADMIN',
      valueType: 'string',
      _id: '64e4e420678e27c05f0a1724',
    },
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
    },
  },
]

export const ModuleData = [
  {
    metadata: {
      arrayName: null,
      collectionName: 'student',
      component: 'DropDown2',
      displayName: 'Modules',
      displayOrder: 1,
      errorMessage: '',
      fieldName: 'permission',
      filter: true,
      isMandatory: true,
      label: 'Modules',
      link: null,
      maxLength: 100,
      mode: 'outlined',
      multiline: false,
      options: [
        {
          displayName: 'Personal Info',
          value: 'personalInfo',
          displayOrder: 0,
        },
        {
          displayName: 'Acheivements And ECA',
          value: 'achievementsAndECA',
          displayOrder: 1,
        },
        {
          displayName: 'Placements',
          value: 'placements',
          displayOrder: 2,
        },
      ],
      placeholder: 'Permission for',
      screenName: 'permission',
      userPerson: 'ADMIN',
      valueType: 'string',
      _id: '64e4e420678e27c05f0a1724',
    },
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
    },
  },
]
export const mobileData = [
  {
    metadata: {
      arrayName: null,
      collectionName: 'student',
      component: 'Dropdown',
      displayName: 'Modules',
      displayOrder: 1,
      errorMessage: '',
      fieldName: 'permission',
      filter: true,
      isMandatory: true,
      label: 'Modules',
      link: null,
      maxLength: 100,
      mode: 'outlined',
      multiline: false,
      options: [
        {
          displayName: 'Personal Info',
          value: 'personalInfo',
          displayOrder: 0,
        },
        {
          displayName: 'Acheivements And ECA',
          value: 'achievementsAndECA',
          displayOrder: 1,
        },
        {
          displayName: 'Placements',
          value: 'placements',
          displayOrder: 2,
        },
      ],
      placeholder: 'Permission for',
      screenName: 'permission',
      userPerson: 'ADMIN',
      valueType: 'string',
      _id: '64e4e420678e27c05f0a1724',
    },
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
    },
  },
]
export const tableData = [
  {
    role: 'Super Admin',
    recordPermissions: {
      viewAndEdit: true,
      viewOnly: true,
      add: true,
      delete: true,
    },
    fieldPermissions: [true, true],
    actionPermissions: {
      upload: false,
      download: true,
    },
  },
  {
    role: 'HOD',
    recordPermissions: {
      viewAndEdit: true,
      viewOnly: true,
      add: true,
      delete: true,
    },
    fieldPermissions: [true],
    actionPermissions: {
      upload: false,
      download: true,
    },
  },
  {
    role: 'Accountant',
    recordPermissions: {
      viewAndEdit: true,
      viewOnly: true,
      add: true,
      delete: true,
    },
    fieldPermissions: [true],
    actionPermissions: {
      upload: true,
      download: false,
    },
  },
  {
    role: 'Placement Coordinator',
    recordPermissions: {
      viewAndEdit: true,
      viewOnly: true,
      add: true,
      delete: true,
    },
    fieldPermissions: [true],
    actionPermissions: {
      upload: false,
      download: true,
    },
  },
]
