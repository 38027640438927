import React, { useRef } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { GestureHandlerRootView, Swipeable } from 'react-native-gesture-handler'
import { useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'

const CustomSwipe = (props) => {
  const { colors } = useTheme()
  const {
    children,
    disabled,
    handleInviteClick = () => {},
    rightTeritaryIcon = 'InviteIcon',
  } = props

  const swipeRef = useRef(null)

  // const closeSwipeable = async () => {
  //   swipeRef.current.close()
  // }

  const rightSwipeActions = () => (
    <View
      style={{
        backgroundColor: colors.primaryVariant,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TouchableOpacity
        style={{ paddingHorizontal: 12 }}
        onPress={handleInviteClick}
      >
        <Icon
          name={rightTeritaryIcon}
          width={20}
          height={20}
          color={colors.onPrimary}
        />
      </TouchableOpacity>
    </View>
  )

  if (disabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <GestureHandlerRootView>
      <Swipeable ref={swipeRef} renderRightActions={rightSwipeActions}>
        {children}
      </Swipeable>
    </GestureHandlerRootView>
  )
}

export default CustomSwipe
