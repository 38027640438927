import React from 'react'
import { Image, Platform, TouchableOpacity, View } from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'
import ProfileIcon from '@camped/shared-component-avatar'
import { appUtil } from '@camped/utils'

const ImageUpload = (props) => {
  const { attachment, fileInputRef, handleAddImage, onUpload, photo } = props
  const { colors } = useTheme()
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {appUtil.isValidUrl(photo) ? (
        <Image
          source={attachment?.encode ?? { uri: photo }}
          resizeMode='contain'
          style={{
            width: 94,
            height: 94,
            borderRadius: 100,
            overflow: 'hidden',
          }}
        />
      ) : (
        <ProfileIcon size={94} />
      )}

      <View
        style={{
          position: 'absolute',
          right: 0,
          bottom: 10,
        }}
      >
        <TouchableOpacity onPress={() => handleAddImage()}>
          <Surface
            style={{
              width: 24,
              height: 24,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: colors.background,
              borderRadius: 40,
            }}
          >
            <Icon
              name='EditIcon'
              color={colors.onBackground}
              height={12}
              width={12}
            />
            {Platform.OS === 'web' && (
              <input
                ref={fileInputRef}
                style={{ display: 'none' }}
                type='file'
                id='attachment'
                name='attachment'
                accept='image/*'
                onChange={({ target: { files } }) => onUpload(files[0])}
                onClick={() => {}}
              />
            )}
          </Surface>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default ImageUpload
