import { useQuery } from '@tanstack/react-query'

import { API } from '@camped/api'
import { SecureStore } from '@camped/utils'

export const useGetFilterOptions = () => {
  const fetchData = async (apiCall, emptyFilter = false) => {
    const tenant = await SecureStore.getCookie('user-details')
    const userDetail = JSON.parse(tenant?.userRoleDetails)

    const filter = !emptyFilter
      ? {
          tenant: userDetail?.[0]?.tenantId ?? '',
        }
      : {}

    return apiCall({
      filter,
    })
  }

  const { data: courseData } = useQuery(['course-lookup-Detail'], () =>
    fetchData(API.getCoursesByFilterAPI, true),
  )

  const { data: graduationData } = useQuery(['graduation-lookup-Detail'], () =>
    fetchData(API.getGraduationsByFilterAPI),
  )

  const { data: departmentData } = useQuery(['department-lookup-Detail'], () =>
    fetchData(API.getDepartmentsByFilterAPI),
  )

  const { data: degreeData } = useQuery(['degree-lookup-Detail'], () =>
    fetchData(API.getDegreesByFilterAPI),
  )

  return {
    courseData,
    degreeData,
    departmentData,
    graduationData,
  }
}
