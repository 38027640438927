import React from 'react'
import { StyleSheet } from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Audit } from '@camped/audit'
import { Button } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

import {
  EventAction,
  EventEntityType,
  EventScreen,
  EventType,
} from '../../constants/audit'

const HeaderIcon = (props) => {
  const { colors } = useTheme()
  const { iconName, onPress, module } = props

  const handleNewStudent = () => {
    Audit.logEvent({
      action: EventAction.CLICK,
      eventtype: EventType.ADD_NEW,
      entityType: EventEntityType.ADD_NEW,
      details: {
        Screen: EventScreen.ALL,
        IconName: iconName,
      },
    })
  }
  const getButtonTitle = () => {
    if (module === 'Rolelisting') {
      return 'Assign User'
    }
    if (module === 'Role') {
      return `Create ${module}`
    }
    return `Add ${module}`
  }
  return (
    <Surface elevation={0} style={styles.surface}>
      <Button
        mode='contained'
        labelStyle={{
          color: colors.onPrimary,
        }}
        style={[styles.buttonStyle]}
        onPress={() => {
          handleNewStudent()
          onPress()
        }}
      >
        <Icon
          name={iconName}
          fill={colors.primary}
          color={colors.onPrimary}
          style={styles.iconSize}
        />
        {getButtonTitle()}
      </Button>
    </Surface>
  )
}

const styles = StyleSheet.create({
  surface: {
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconSize: {
    height: 15,
    width: 15,
    marginRight: spacing.spacing2,
  },
  buttonStyle: {
    height: 32,
    borderRadius: 4,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
export default HeaderIcon
