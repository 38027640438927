export const trpcApiCall = async ({ baseUrl, pathname, method, input }) => {
  const structuredInput = {
    0: {
      json: input,
    },
  }

  const queryParams =
    method === 'QUERY'
      ? `?batch=1&input=${encodeURIComponent(JSON.stringify(structuredInput))}`
      : '?batch=1'

  const url = `${baseUrl}/${pathname}${queryParams}`

  try {
    let response

    if (method === 'MUTATION') {
      response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(structuredInput),
      })
    } else {
      response = await fetch(url)
    }

    // if (!response.ok) {
    //   throw new Error(`Error: ${response.status} - ${response.statusText}`);
    // }

    const data = await response.json()

    return data[0]?.result?.data?.json
  } catch (error) {
    return error
  }
}
