import React, { Fragment } from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { CheckBox, DataTableHeader, DataTableTitle } from '@camped/components'
import { spacing } from '@camped/theme-provider'

export const Header = (props) => {
  const { colors } = useTheme()
  const {
    hasMultipleSelect,
    tableHeader,
    selectedIDs,
    totalCount,
    handleMultipleCheckBox,
    module,
  } = props
  const checkbox = () => (
    <View
      style={{
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        marginRight: spacing.spacing4,
      }}
    >
      <CheckBox
        status={hasMultipleSelect ? 'checked' : 'unchecked'}
        iconName={
          selectedIDs.length === totalCount ? 'Selection' : 'DeSelection'
        }
        onPress={() => handleMultipleCheckBox()}
      />
    </View>
  )
  return (
    <DataTableHeader
      paddingHorizontal={spacing.spacing4}
      paddingVertical={spacing.spacing4}
    >
      {module === 'student' || module === 'staff' ? checkbox() : null}

      {tableHeader?.map((m, index) => {
        if (m?.metadata?.valueType === 'image') {
          return
        }
        return (
          <Fragment key={index}>
            <DataTableTitle
              textStyle={{
                color: colors.onSurface,
                fontWeight: '600',
                fontSize: 12,
              }}
              flexN={index === 0 ? 3 : 1}
            >
              {m?.metadata?.label?.toUpperCase() ||
                m?.displayName?.toUpperCase()}
            </DataTableTitle>
          </Fragment>
        )
      })}
    </DataTableHeader>
  )
}
