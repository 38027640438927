import React from 'react'
import { StyleSheet, View } from 'react-native'

import { ShimmerPlaceholder } from '@camped/components'
import { spacing } from '@camped/theme-provider'

export const LoginSkeleton = () => (
  <View>
    <ShimmerPlaceholder style={{ width: 100, height: 30, borderRadius: 4 }} />

    <View style={styles.container}>
      <ShimmerPlaceholder style={styles.inputShimmer} />

      <View style={styles.buttonContainer}>
        <ShimmerPlaceholder
          style={{
            width: '30%',
            height: 20,
            borderRadius: 4,
          }}
        />
        <ShimmerPlaceholder style={styles.buttonShimmer} />
      </View>
    </View>

    <View style={{ alignItems: 'center', marginTop: spacing.spacing9 }}>
      <ShimmerPlaceholder
        style={{
          maxWidth: 280,
          width: '100%',
          height: 30,
          marginTop: spacing.spacing7,
          borderRadius: 4,
        }}
      />

      <ShimmerPlaceholder
        style={{
          maxWidth: 160,
          width: '100%',
          height: 30,
          marginTop: spacing.spacing5,
          borderRadius: 4,
        }}
      />
      <ShimmerPlaceholder
        style={{
          maxWidth: 200,
          width: '100%',
          height: 30,
          marginTop: spacing.spacing5,
          borderRadius: 4,
        }}
      />
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    marginTop: spacing.spacing7,
    width: '100%',
  },
  inputShimmer: {
    width: '100%',
    height: 48,
    borderRadius: 4,
    marginBottom: spacing.spacing6,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: spacing.spacing4,
  },
  buttonShimmer: {
    width: '30%',
    height: 40,
    borderRadius: 4,
  },
})
