import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTheme } from 'react-native-paper'

import { Button } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

import departmentsEmptyStatus from 'assets/departmentsEmptyStatus.png'

import { DepartmentListing } from '../../components/department/departmentListing'

const MobileView = (props) => {
  const { colors, fonts } = useTheme()
  const {
    departmentDetails,
    navigation,
    moduleDataIsFetching,
    listDepartmentsIsFetching,
    profileImage,
    module,
    getMobileHeading,
  } = props
  const { t } = useTranslation()

  if (moduleDataIsFetching || listDepartmentsIsFetching)
    return (
      <ActivityIndicator
        size='large'
        color={colors.primary}
        style={{
          flex: 1,
          backgroundColor: colors.background,
        }}
      />
    )

  if (departmentDetails?.items?.length === 0 || departmentDetails?.errors)
    return (
      <ScrollView
        contentContainerStyle={{
          backgroundColor: colors.background,
          flexGrow: 1,
        }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            alignItems: 'center',
            flex: 1,
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Image style={styles.image} source={departmentsEmptyStatus} />
          <Text
            style={{
              flex: 0.1,
              justifyContent: 'center',
              paddingTop: spacing.spacing7,
              ...fonts.headlineSmall,
            }}
          >
            {t('Settings.NO_DEPARTMENTS')}
          </Text>
          <Text
            style={{
              flex: 0.1,
              paddingTop: spacing.spacing4,
              textAlign: 'center',
              width: '75%',
              ...fonts.titleSmall,
            }}
          >
            {t('Settings.GET_STARTED_ADD_DEPARTMENTS')}
          </Text>
          <Button
            mode='outlined'
            style={{
              alignSelf: 'center',
              backgroundColor: colors.onPrimary,
              borderColor: colors.primary,
              borderRadius: spacing.spacing2,
              height: spacing.spacing8,
              justifyContent: 'center',
              marginTop: spacing.spacing5,
            }}
            labelStyle={{
              ...fonts.labelMedium,
              marginRight: 8,
            }}
            onPress={() => navigation.navigate('add-department')}
          >
            <Icon
              name='AddIcon'
              style={{
                height: 18,
                marginLeft: -8,
                width: 18,
              }}
              color={colors.primary}
            />
            {t('Settings.ADD_DEPARTMENT')}
          </Button>
        </View>
      </ScrollView>
    )
  return (
    <ScrollView
      contentContainerStyle={{
        paddingHorizontal: spacing.spacing5,
        backgroundColor: colors.background,
        flexGrow: 1,
        paddingTop: spacing.spacing6,
        paddingBottom: spacing.spacing11,
      }}
      showsVerticalScrollIndicator={false}
      onScroll={({
        nativeEvent: {
          contentOffset: { y: scrollY },
        },
      }) => {
        navigation.setOptions({
          headerTitle: scrollY > 80 ? 'Department' : '',
          headerTitleStyle: {
            color: colors.onSurface,
            ...fonts.headlineSmall,
          },
        })
      }}
      scrollEventThrottle={460}
    >
      <>
        <Header
          fonts={fonts}
          navigation={navigation}
          colors={colors}
          getMobileHeading={getMobileHeading}
          module={module}
        />
        <DepartmentListing
          departmentDetails={departmentDetails}
          imageSize={54}
          profileImage={profileImage}
          navigation={navigation}
          module={module}
        />
      </>
    </ScrollView>
  )
}

export default MobileView

const styles = StyleSheet.create({
  image: {
    height: 266,
    width: 360,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  button: (colors) => ({
    borderColor: colors.primary,
    marginRight: spacing.spacing5,
    width: 160,
  }),
})
const Header = ({ fonts, navigation, colors, getMobileHeading, module }) => {
  const mobileHeader = getMobileHeading()
  return (
    <View
      style={{
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: spacing.spacing4,
      }}
    >
      <View>
        <Text
          style={{
            ...fonts.headlineLarge,
            color: colors.onBackground,
          }}
        >
          {mobileHeader}
        </Text>
      </View>
      <TouchableOpacity onPress={() => navigation.navigate(`add-${module}`)}>
        <Icon
          name='AddIcon'
          color={colors.surface}
          fill={colors.primary}
          style={{ height: 32, width: 32 }}
        />
      </TouchableOpacity>
    </View>
  )
}
