import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View, useWindowDimensions } from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Auth } from '@camped/api'
import { Audit } from '@camped/audit'
import { Button, Text, TextInput } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout, testProps } from '@camped/utils'

import {
  EventAction,
  EventEntityType,
  EventScreen,
  EventType,
} from '../constants/audit'
import { Behavior, ScreenName, TypeofControl } from '../constants/testing'
import { ContactUs } from './ContactUs'

export const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const { colors, fonts } = useTheme()
  const navigation = useNavigation()
  const { width } = useWindowDimensions()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    emailId: '',
  })
  const [success, setSuccess] = useState(false)
  const isDesktop = ScreenLayout.isWeb(width)

  const forgotPassword = async (data) => {
    Audit.logEvent({
      action: EventAction.CLICK,
      entityType: EventEntityType.FORGET_PASSWORD,
      eventType: EventType.FORGOT_PASSWORD,
      details: {
        Screen: EventScreen.FORGOT_PASSWORD,
        Email: data?.emailId,
      },
    })
    try {
      setIsLoading(true)
      const res = await Auth.forgotPasswordAsync(data?.emailId)
      if (res?.message) {
        toast.show(res?.message, { type: 'danger' })
      } else {
        setSuccess(true)
      }
    } catch (error) {
      if (error.message) {
        toast.show(error.message, { type: 'danger' })
      }
    }
  }
  if (success) {
    return (
      <View>
        <Surface
          elevation={0}
          style={{
            flexDirection: 'column',
          }}
        >
          <Text
            variant={isDesktop ? 'headlineLarge' : 'headlineSmall'}
            style={{
              color: colors.onSurfaceVariant,
            }}
          >
            {t('Auth.RESET_LINK_SENT')}
          </Text>
          <Text
            variant='bodyLarge'
            style={{
              color: colors.onSurfaceVariant,
              marginTop: spacing.spacing6,
            }}
          >
            {t('Auth.RESET_LINK_RECEIVED')}
          </Text>
          <Text
            variant='bodyLarge'
            style={{
              color: colors.onSurfaceVariant,
              marginTop: spacing.spacing6,
            }}
          >
            {t('Auth.CHECK_SPAM_FOLDER')}
          </Text>
          <Button
            style={{
              marginTop: spacing.spacing6,
              marginBottom: isDesktop ? 0 : spacing.spacing13,
              alignSelf: 'flex-start',
            }}
            labelStyle={{
              ...(isDesktop ? fonts.titleMedium : fonts.bodyMedium),
            }}
            onPress={() => navigation.navigate('login')}
            {...testProps(
              `${ScreenName.FORGOT_PASSWORD}_${TypeofControl.BUTTON}_${Behavior.BACK}`,
            )}
          >
            {t('Auth.SIGN_IN_RETURN')}
          </Button>
        </Surface>
      </View>
    )
  }
  return (
    <View>
      <Surface
        elevation={0}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: isDesktop ? 0 : spacing.spacing6,
        }}
      >
        <TouchableOpacity
          onPress={() => navigation.navigate('login')}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          {...testProps(
            `${ScreenName.FORGOT_PASSWORD}_${TypeofControl.BUTTON}_${Behavior.BACK}`,
          )}
        >
          <Icon
            name='BackArrow'
            color={colors.onBackground}
            width={24}
            height={24}
            style={{ marginRight: spacing.spacing4 }}
          />
          <Text
            variant='bodyLarge'
            style={{
              color: colors.onSurfaceVariant,
            }}
          >
            {t('Auth.SIGN_IN_RETURN')}
          </Text>
        </TouchableOpacity>
      </Surface>
      <Text
        variant={isDesktop ? 'headlineLarge' : 'headlineSmall'}
        style={{
          color: colors.onSurfaceVariant,
          marginTop: spacing.spacing6,
        }}
      >
        {t('Auth.PASSWORD_RECOVERY')}
      </Text>
      <Text
        variant='bodyLarge'
        style={{
          color: colors.onSurfaceVariant,
          marginVertical: spacing.spacing6,
        }}
      >
        {t('Auth.NO_WORRIES')}
      </Text>
      <Text
        variant='bodyLarge'
        style={{
          color: colors.onSurfaceVariant,
          marginBottom: spacing.spacing6,
        }}
      >
        {t('Auth.PASSWORD_RESET_REQUEST')}
      </Text>
      <Controller
        control={control}
        name='emailId'
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            label={t('Auth.EMAIL')}
            placeholder={t('Auth.EMAIL')}
            value={value}
            onBlur={onBlur}
            onChangeText={onChange}
            method={handleSubmit}
            error={errors?.emailId}
            {...testProps(
              `${ScreenName.FORGOT_PASSWORD}_${TypeofControl.INPUT}_${Behavior.EMAIL}`,
            )}
          />
        )}
        rules={{
          required: 'Email is Required',
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: 'Please Enter Valid Email',
          },
        }}
      />

      <Button
        loading={isLoading}
        mode='contained'
        style={{
          marginTop: spacing.spacing8,
          marginBottom: isDesktop ? 0 : spacing.spacing13,

          alignSelf: 'flex-end',
        }}
        onPress={handleSubmit(forgotPassword)}
        {...testProps(
          `${ScreenName.FORGOT_PASSWORD}_${TypeofControl.BUTTON}_${Behavior.SUBMIT}`,
        )}
      >
        {t('Auth.CONTINUE')}
      </Button>
      {isDesktop && (
        <View
          style={{
            marginTop: 40,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            variant='bodyLarge'
            style={{
              textAlign: 'center',
              color: colors.onSurface,
            }}
          >
            {t('Auth.SUPPORT_ASSISTANCE')}{' '}
          </Text>
          <ContactUs />
        </View>
      )}
    </View>
  )
}
