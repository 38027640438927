import React from 'react'
import { ActivityIndicator, ScrollView } from 'react-native'
import { useTheme } from 'react-native-paper'

import { DesktopContainer } from '@camped/shared-container'
import EdgeCase from '@camped/shared-screen-listing/components/edge-case'

import { DepartmentListing } from '../../components/department/departmentListing'

const DesktopView = (props) => {
  const { colors } = useTheme()
  const {
    departmentDetails,
    moduleDataIsFetching,
    listDepartmentsIsFetching,
    module,
    profileImage,
  } = props

  if (moduleDataIsFetching || listDepartmentsIsFetching) {
    return (
      <DesktopContainer>
        <ActivityIndicator
          size='large'
          color={colors.primary}
          style={{ position: 'absolute', top: '40%', right: '50%' }}
        />
      </DesktopContainer>
    )
  }

  if (departmentDetails?.length === 0 || departmentDetails?.errors) {
    return (
      <DesktopContainer>
        <EdgeCase module={module} />
      </DesktopContainer>
    )
  }

  return (
    <DesktopContainer>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        showsVerticalScrollIndicator={false}
      >
        <DepartmentListing
          departmentDetails={departmentDetails}
          imageSize={56}
          module={module}
          profileImage={profileImage}
        />
      </ScrollView>
    </DesktopContainer>
  )
}

export default DesktopView
