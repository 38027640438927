/* eslint-disable no-unused-vars */
import { useState } from 'react'

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { roleListing } from '@camped/shared-mock-data'
import { SecureStore } from '@camped/utils'

import { capitalize } from '../utils/capitailize'

const getSortData = async (selectedSort) =>
  selectedSort && {
    field: selectedSort.fieldName,
    order: selectedSort.order,
  }

export const usePagination = ({
  limit,
  module,
  sortValue,
  filterValue,
  id,
  metaData,
  totalCount,
}) => {
  const [profileImageList, setProfileImageList] = useState([])
  const queryClient = useQueryClient()
  const screenIds = queryClient.getQueryData(['getScreenIds'])
  const getProfileImage = async (data, page, limit) => {
    const profilePath = data
      ?.map((item) => {
        if (item?.profileImage === null) return
        return {
          id: item?._id,
          path: item?.profileImage,
        }
      })
      .filter(Boolean)
    if (profilePath?.length) {
      const profileImage = await API.getViewProfileImageUrlAPI({
        s3Data: profilePath,
        pagination: {
          limit: page * limit,
        },
      })
      if (profileImageList)
        setProfileImageList([...profileImageList, ...profileImage])
      else setProfileImageList(profileImage)
    }
    return
  }

  const {
    data,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [`getAll${capitalize(module)}sSummaryAPI`],
    async ({ pageParam = 1 }) => {
      if (screenIds?.length === 0) return
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)
      const sort = await getSortData(sortValue)
      const filter = {
        tenant: userDetail?.[0]?.tenantId ?? '',
        ...filterValue,
      }
      const page = pageParam || 1
      const pagination = {
        limit,
        skip: (page - 1) * limit,
      }

      const sortField = sort
        ? {
            filter,
            sorts: { ...sort },
          }
        : { filter }

      // const filter = await getFilterData(filterValue)
      if (module === 'student') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'student-directory',
        )
        if (
          metaData?.screens?.[0]?.permissions?.view &&
          metaData?.sections.length !== 0
        ) {
          const studentsData = await API.getStudentsByFilterAPI({
            ...sortField,
            extraHeader: {
              screen: screen?._id,
            },
            pagination,
          })
          await getProfileImage(studentsData, page, limit)
          if (studentsData?.length !== 0) {
            return {
              metadata: metaData?.sections?.[0]?.fields,
              students: studentsData,
            }
          }
        }
      }
      if (module === 'staff') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'staff-directory',
        )

        if (
          metaData?.screens?.[0]?.permissions?.view &&
          metaData?.sections.length !== 0
        ) {
          const staffsData = await API.getStaffsByFilterAPI({
            ...sortField,
            extraHeader: {
              screen: screen?._id,
            },
            pagination,
          })
          await getProfileImage(staffsData, page, limit)
          if (staffsData?.length !== 0) {
            return {
              metadata: metaData?.sections?.[0]?.fields,
              staffs: staffsData,
            }
          }
        }
      }
      if (module === 'course') {
        if (
          metaData?.screens?.[0]?.permissions?.view &&
          metaData?.sections.length !== 0
        ) {
          const courseData = await API.getCoursesByFilterAPI({
            filter: filterValue,
          })
          if (courseData?.length !== 0) {
            return {
              metadata: metaData?.sections?.[0]?.fields,
              course: courseData,
            }
          }
        }
      }
      if (module === 'rolelisting') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'role-details',
        )
        const user = await API.getTenantUserRoleMappingsByFilterAPI({
          filter: {
            role: id ?? '',
            tenant: userDetail?.[0]?.tenantId ?? '',
          },
          extraHeader: {
            screen: screen?._id,
          },
          pagination,
        })
        return {
          metadata: roleListing?.metadata,
          role: user ?? [],
        }
      }
    },
    {
      initialData: [],
      getNextPageParam: (lastPage, pages) => {
        const paginatedData = pages.flatMap(
          (page) =>
            page?.staffs ||
            page?.students ||
            page?.course ||
            page?.academics ||
            page?.role,
        )

        return paginatedData?.length < totalCount
          ? paginatedData?.length / limit + 1
          : undefined
      },
      enabled: !!(screenIds?.length && Object.keys(metaData).length !== 0),
    },
  )

  let userLists

  if (data?.pages?.[0]) {
    userLists = data?.pages?.flatMap(
      (page) =>
        page?.staffs ||
        page?.students ||
        page?.course ||
        page?.academics ||
        page?.role,
    )
  }

  const tableHeader = data?.pages?.[0]?.metadata?.sort(
    (a, b) =>
      (a?.metadata?.displayOrder ?? a.displayOrder) -
      (b?.metadata?.displayOrder ?? b.displayOrder),
  )

  return {
    fetchNextPage,
    hasNextPage,
    tableHeader,
    refetch,
    userLists,
    isFetching,
    isFetchingNextPage,
    profileImageList,
  }
}
