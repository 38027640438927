import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Button, Dropdown, Row, Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'
import { appUtil } from '@camped/utils'

export const TopSection = (props) => {
  const {
    module,
    onSortSelected,
    selectedIDs,
    selectedSortValue,
    setIsFilterVisible,
    sortList,
    fieldData,
    filterApplied,
    userLists,
  } = props
  const { colors, fonts } = useTheme()
  const { t } = useTranslation()
  const screenName = () => {
    if (module === 'rolelisting') return 'User'
    return module
  }
  return (
    <Row
      style={{
        paddingVertical: spacing.spacing4,
        paddingHorizontal: spacing.spacing4,
        alignItems: 'center',
      }}
    >
      <Text
        variant='labelLarge'
        style={{
          color: colors.onSurface1,
        }}
      >
        {selectedIDs.length > 0
          ? `${selectedIDs?.length || 0} of ${
              userLists?.length
            } ${screenName()} ${t('Directory.SELECT')}`
          : `${userLists?.length || 0} ${appUtil.capitalize(screenName())}s ${t(
              'Directory.AVAILABLE',
            )}`}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          flex: 2,
          justifyContent: 'flex-end',
          gap: 20,
        }}
      >
        {module === 'course' && sortList && (
          <View style={{ flex: 0.3, flexDirection: 'row-reverse' }}>
            {sortList.map((item) => (
              <Dropdown
                key={item.value}
                value={selectedSortValue?.displayName}
                list={item.metadata.options}
                placeholder={item.metadata.placeholder}
                inputProps={{
                  outlineStyle: {
                    borderWidth: 0,
                  },
                  style: {
                    height: 32,
                    borderRadius: 4,
                    marginTop: -6,
                    marginRight: 5,
                    backgroundColor: colors.surface2,
                    ...fonts.labelLarge,
                  },
                }}
                setValue={(text) => {
                  onSortSelected(text.displayName)
                }}
                iconStyle={{ top: '30%' }}
                field='displayName'
              />
            ))}
          </View>
        )}
        {module === 'student' && (
          <View style={{ flex: 0.2 }}>
            <Dropdown
              value={selectedSortValue?.displayName}
              list={sortList}
              placeholder={t('Directory.SORT_BY')}
              inputProps={{
                outlineStyle: {
                  borderWidth: 0,
                },
                style: {
                  height: 32,
                  borderRadius: 4,
                  marginTop: -6,
                  backgroundColor: colors.surface2,
                  ...fonts.labelLarge,
                },
              }}
              setValue={(text) => {
                onSortSelected(text.displayName)
              }}
              iconStyle={{ top: '30%' }}
              field='displayName'
            />
          </View>
        )}
        {module === 'staff' && (
          <View style={{ flex: 0.2 }}>
            <Dropdown
              value={selectedSortValue?.displayName}
              list={sortList}
              placeholder={t('Directory.SORT_BY')}
              inputProps={{
                outlineStyle: {
                  borderWidth: 0,
                },
                style: {
                  height: 32,
                  borderRadius: 4,
                  marginTop: -6,
                  backgroundColor: colors.surface2,
                  ...fonts.labelLarge,
                },
              }}
              setValue={(text) => {
                onSortSelected(text.displayName)
              }}
              iconStyle={{ top: '30%' }}
              field='displayName'
            />
          </View>
        )}

        {fieldData && module !== 'rolelisting' && (
          <Button
            textColor={colors.onPrimary}
            style={{
              maxHeight: 32,
              justifyContent: 'center',
              borderRadius: 4,
              backgroundColor: colors.surface2,
            }}
            contentStyle={{
              flexDirection: 'row-reverse',
            }}
            labelStyle={{
              color: colors.onSurfaceVariant,
            }}
            onPress={() => setIsFilterVisible(true)}
          >
            <Text style={{ fontSize: 16 }}>{t('Directory.FILTER')} </Text>
            <ButtonIcon filterApplied={filterApplied} colors={colors} />
          </Button>
        )}
      </View>
    </Row>
  )
}

const ButtonIcon = (props) => {
  const { filterApplied, colors } = props
  if (filterApplied)
    return (
      <>
        <Icon
          name='FilterIcon'
          color={colors.onSurface}
          style={{
            height: 15,
            width: 15,
            marginLeft: spacing.spacing2,
            marginTop: spacing.spacing2,
          }}
        />
        <View
          style={{
            backgroundColor: colors.tertiary,
            width: 8,
            height: 8,
            position: 'absolute',
            right: 18,
            top: 12,
            borderRadius: 6,
          }}
        />
      </>
    )
  return (
    <Icon
      name='FilterIcon'
      color={colors.onSurface}
      style={{
        height: 15,
        width: 15,
        marginLeft: spacing.spacing2,
        marginTop: spacing.spacing2,
      }}
    />
  )
}
