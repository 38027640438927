import { useNavigation } from '@react-navigation/native'
import React from 'react'
import {
  FlatList,
  Modal,
  Platform,
  TextInput as RNTextInput,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import {
  Button,
  Divider,
  Dropdown,
  Row,
  Text,
  TextInput,
} from '@camped/components'
import { BoxContainer } from '@camped/form-container'
import { DesktopContainer } from '@camped/shared-container'
import { spacing } from '@camped/theme-provider'

import UserRoleComponent from '../../components/role/UserRoleComponent'

const DesktopView = ({
  rolesData,
  isAdd,
  onChangeText,
  role,
  getLookupData,
  handleCreate,
  isLoading,
  module,
  singleRole,
  setVisible,
  inputValue,
  setInputValue,
  getSearchData,
  allData,
  handleClick,
  visible,
  DropdownButton,
  inputRef,
  dropdownLeft,
  dropDownWidth,
  dropdownTop,
  toggleDropdown,
}) => {
  const navigation = useNavigation()
  const { colors } = useTheme()
  const renderItem = ({ item }) => (
    <TouchableOpacity style={styles.item} onPress={() => handleClick(item)}>
      <View style={{ marginLeft: spacing.spacing3 }}>
        <Text
          variant='titleMedium'
          style={{
            color: colors.onBackground,
          }}
        >
          {`${item?.firstName} ${item?.lastName}`}
        </Text>
        <Text
          variant='titleSmall'
          style={{
            color: colors.outline,
          }}
        >
          {item?.registrationNumber || item?.employeeId}
        </Text>
      </View>
    </TouchableOpacity>
  )
  const renderDropdown = () => (
    <Modal visible={visible} transparent>
      <TouchableOpacity
        style={styles.overlay}
        onPress={() => {
          setVisible(false)
        }}
      >
        <View
          style={[styles.dropdown, { top: dropdownTop, left: dropdownLeft }]}
        >
          <Row
            style={{
              width: '100%',
            }}
          >
            <View style={{ height: '100%', width: '100%' }}>
              <RNTextInput
                ref={inputRef}
                value={inputValue}
                label={singleRole?.userPerson}
                mode='outlined'
                style={{
                  borderWidth: 1,
                  borderRadius: spacing.spacing2,
                  height: 40,
                  backgroundColor: colors.surface2,
                  paddingLeft: spacing.spacing3,
                  marginTop: spacing.spacing4,
                }}
                onLayout={() => inputRef?.current?.focus()}
                onFocus={() => {
                  setVisible(true)
                }}
                onChangeText={(text) => {
                  setInputValue(text)
                  getSearchData(text)
                }}
                placeholder={`Search a ${singleRole?.userPerson.toLowerCase()} to assign role`}
                placeholderTextColor={colors.onSurfaceVariant}
              />
            </View>
          </Row>
          <Surface
            style={{
              width: dropDownWidth,
              height: 400,
              borderRadius: 8,
              marginTop: spacing.spacing2,
              backgroundColor: colors.surface1,
            }}
          >
            <View style={{ flex: 1 }}>
              <Divider style={{ backgroundColor: colors.surface2 }} />
              <FlatList
                data={allData}
                renderItem={renderItem}
                keyExtractor={(item, index) => index.toString()}
              />
            </View>
          </Surface>
        </View>
      </TouchableOpacity>
    </Modal>
  )

  if (isAdd && module === 'Role') {
    return (
      <DesktopContainer>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Divider />
          <BoxContainer title='Role Details'>
            <View
              style={{
                marginBottom: spacing.spacing6,
                marginRight: spacing.spacing3,
                marginLeft: spacing.spacing4,
                width: '35%',
                paddingTop: 0,
              }}
            >
              <TextInput
                value={role?.name}
                label='Role Name'
                style={{
                  marginBottom: spacing.spacing3,
                  marginTop: spacing.spacing3,
                }}
                onChangeText={(text) => onChangeText('name', text)}
                disabled={isLoading}
              />
              <Dropdown
                label='Select a role you want to clone'
                mode='outlined'
                value={role?.clone}
                list={getLookupData()}
                style={{
                  marginBottom: spacing.spacing3,
                  marginTop: spacing.spacing3,
                }}
                setValue={(text) => onChangeText('clone', text)}
                disabled={isLoading}
              />
            </View>
          </BoxContainer>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              margin: spacing.spacing5,
            }}
          >
            <Button
              mode='outlined'
              labelStyle={{
                margin: 6,
                marginHorizontal: 1,
                color: colors.primary,
                paddingLeft: spacing.spacing6,
                paddingRight: spacing.spacing6,
              }}
              style={{
                paddingHorizontal: spacing.spacing2,
                alignSelf: 'flex-end',
                marginBottom: spacing.spacing4,
                marginRight: spacing.spacing3,
              }}
              onPress={() => {
                if (Platform.OS === 'web') {
                  window.history.go(-1)
                } else {
                  navigation.goBack()
                }
              }}
            >
              Cancel
            </Button>
            <Button
              labelStyle={{
                margin: 6,
                marginHorizontal: 1,
                paddingLeft: spacing.spacing6,
                paddingRight: spacing.spacing6,
              }}
              loading={isLoading}
              disabled={isLoading}
              style={{
                paddingHorizontal: spacing.spacing2,
                alignSelf: 'flex-end',
                marginBottom: spacing.spacing4,
              }}
              onPress={() => {
                handleCreate()
              }}
            >
              Create
            </Button>
          </View>
        </ScrollView>
      </DesktopContainer>
    )
  }
  if (isAdd && module === 'assignRole') {
    return (
      <DesktopContainer>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Divider />
          <BoxContainer title='Assign User'>
            <View
              style={{
                marginBottom: spacing.spacing6,
                marginRight: spacing.spacing3,
                marginLeft: spacing.spacing4,
                width: '35%',
                paddingTop: 0,
              }}
            >
              <TouchableOpacity ref={DropdownButton}>
                <RNTextInput
                  mode='outlined'
                  style={{
                    borderWidth: 1,
                    borderRadius: spacing.spacing2,
                    height: 40,
                    backgroundColor: colors.surface2,
                    paddingLeft: spacing.spacing3,
                    marginTop: spacing.spacing4,
                  }}
                  value={inputValue}
                  placeholder={`Search a ${singleRole?.userPerson.toLowerCase()} to assign role`}
                  onChangeText={(text) => {
                    toggleDropdown()
                    setInputValue(text)
                    getSearchData(text)
                  }}
                />
              </TouchableOpacity>
            </View>
          </BoxContainer>
        </ScrollView>{' '}
        {renderDropdown()}
      </DesktopContainer>
    )
  }
  return (
    <DesktopContainer>
      <View
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          padding: spacing.spacing4,
        }}
      >
        {rolesData?.map((localRole) => (
          <TouchableOpacity
            onPress={() =>
              navigation.navigate('role-details', {
                id: localRole?._id,
                name: localRole?.name,
              })
            }
          >
            <UserRoleComponent key={localRole?._id} item={localRole} />
          </TouchableOpacity>
        ))}
      </View>
    </DesktopContainer>
  )
}
const styles = StyleSheet.create({
  dropdown: {
    width: '30%',
    borderBottomLeftRadius: 18,
    borderBottomRightRadius: 18,
  },
  overlay: {
    flex: 1,
    borderRadius: 0,
    height: '100%',
  },

  item: {
    paddingHorizontal: spacing.spacing4,
    paddingVertical: spacing.spacing4,
    flexDirection: 'row',
    alignItems: 'center',
  },
})

export default DesktopView
