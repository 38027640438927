import * as React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'

const DataTableCell = ({
  children,
  textStyle,
  style,
  link,
  numeric,
  onClick,
  flexN = 1,
  buttonStyle,
  textVariant,
  textSize,
  ...rest
}) => {
  const { colors } = useTheme()
  return (
    <View
      {...rest}
      style={[
        styles.container,
        numeric && styles.right,
        style,
        { flex: flexN },
      ]}
    >
      {link ? (
        <TouchableOpacity onPress={onClick}>{children}</TouchableOpacity>
      ) : (
        <Text
          variant={textVariant || 'titleMedium'}
          style={{
            ...textStyle,
            fontSize: textSize || 14,
            fontWeight: '300',
          }}
          numberOfLines={2}
          theme={{
            colors: {
              onSurface: colors.onSurface,
            },
          }}
        >
          {children || '-'}
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  right: {
    justifyContent: 'flex-end',
  },
})

export default DataTableCell
