import { useNavigationState } from '@react-navigation/native'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { SecureStore } from '@camped/utils'

const convertArrayToObject = (arr) => {
  const result = {}

  arr.forEach((item) => {
    result[item.name] = item.value
  })

  return result
}

export const useUpdateUserDetails = ({
  onSuccess,
  module,
  userId,
  setIsLoading,
}) => {
  const queryClient = useQueryClient()
  const currentRoute = useNavigationState(
    (state) => state?.routes?.[state.routes.length - 1],
  )?.name

  return useMutation(
    async (variables) => {
      const screenIds = queryClient.getQueryData(['getScreenIds'])
      const screen = screenIds?.find((item) => item?.fieldName === currentRoute)

      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)

      if (module === 'student' || module === 'studentProfile') {
        if (module === 'studentProfile') {
          const studentData = await API.getStudentsByFilterAPI({
            filter: {
              tenant: userDetail?.[0]?.tenantId ?? '',
              user: tenant?.['custom:userId'] ?? '',
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
          const response = await API.updateStudentAPI({
            ...variables?.data,
            id: studentData?.[0]?._id,
            extraHeader: {
              screen: screen?._id,
            },
          })
          return response
        }

        const response = await API.updateStudentAPI({
          ...variables?.data,
          id: variables?.id,
          extraHeader: {
            screen: screen?._id,
          },
        })
        return response
      }
      if (
        module === 'staff' ||
        module === 'staffProfile' ||
        module === 'adminProfile'
      ) {
        if (module === 'staffProfile' || module === 'adminProfile') {
          const staffData = await API.getStaffsByFilterAPI({
            filter: {
              tenant: userDetail?.[0]?.tenantId ?? '',
              user: tenant?.['custom:userId'] ?? '',
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
          const response = await API.updateStaffAPI({
            ...variables.data,
            id: staffData?.[0]?._id,
            extraHeader: {
              screen: screen?._id,
            },
          })
          return response
        }
        const response = await API.updateStaffAPI({
          ...variables.data,
          id: variables.id,
          extraHeader: {
            screen: screen?._id,
          },
        })
        return response
      }
      if (module === 'viewCourse') {
        if (variables.arrayName === 'subjectDetails') {
          if (variables.isAdd) {
            const subjects = variables?.data?.subjectDetails
            const subjectPromises = subjects.map(async (item) => {
              const subjectData = convertArrayToObject(item)
              delete subjectData?._id
              subjectData.staffId = '23456789'
              subjectData.course = userId
              delete subjectData?.lmsSubjectId

              await API.createSubjectAPI({
                subject: {
                  ...subjectData,
                  tenantName: variables?.domainDetails?.domain,
                },
                extraHeader: {
                  screen: screen?._id,
                },
              })
              return subjectData
            })

            const finalResponse = await Promise.all(subjectPromises)
            return finalResponse
          }
          const subjects = variables?.data?.subject?.subjectDetails
          const subjectPromises = subjects.map(async (item) => {
            const id = item._id
            delete item._id
            const subjectResponse = await API.updateSubjectAPI({
              id,
              subject: {
                ...item,
              },
              extraHeader: {
                screen: screen?._id,
              },
            })
            return subjectResponse
          })

          const finalResponse = await Promise.all(subjectPromises)

          return finalResponse
        }
        const response = await API.updateCourseAPI({
          ...variables.data,
          extraHeader: {
            screen: screen?._id,
          },
        })
        return response
      }
      if (module === 'viewSyllabus') {
        if (variables.isAdd) {
          const date = new Date()

          const day = date.getDate()
          const month = date.getMonth() + 1
          const year = date.getFullYear()

          // This arrangement can be altered based on how we want the date's format to appear.
          const currentDate = `${day}-${month}-${year}`
          const questionPaperResponse = await API.createQuestionPaperAPI({
            questionPaper: {
              ...variables.data,
              generatedQuestion: '',
              subject: userId,
              noOfSections:
                variables?.data?.questionPaperSection?.length?.toString(),
              date: currentDate,
            },
          })
          return questionPaperResponse
        }
        if (variables.isUpdate) {
          const { id } = variables.data
          delete variables.data.id
          const questionPaperResponse = await API.updateQuestionPaperAPI({
            questionPaper: { ...variables.data },
            id,
          })
          return questionPaperResponse
        }
        const subjectResponse = await API.updateSubjectAPI({
          id: userId,
          subject: { ...variables.data },
          extraHeader: {
            screen: screen?._id,
          },
        })
        return subjectResponse
      }
      if (module === 'campus-configuration') {
        const response = await API.updateTenantAPI({
          id: userDetail?.[0]?.tenantId ?? '',
          tenant: { ...variables.data },
        })
        return response
      }
    },
    {
      onSuccess: async (newData) => {
        if (!newData?.errors) {
          onSuccess(newData)
          toast.show('Updated Successfully', {
            type: 'success',
          })
          queryClient.setQueryData([`${module}Detail-${userId}`], (data) => {
            data?.fields?.forEach((item) => {
              const matchingNewItem = newData?.fields?.find(
                (newItem) =>
                  item?.metadata?.fieldName === newItem?.metadata?.fieldName,
              )

              if (matchingNewItem) {
                item.value = matchingNewItem?.value
              }
            })
            return data
          })
        } else {
          toast.show('Failed to Generate Question Paper', {
            type: 'danger',
          })
        }
        setIsLoading(false)
      },
    },
  )
}
