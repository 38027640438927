import {
  createCourse,
  createDegree,
  createDepartment,
  createGraduation,
  createQuestionPaper,
  createRole,
  createStaff,
  createStudent,
  createSubject,
  createTenantUserRoleMapping,
  sendInvitation,
  sendUserInvitation,
  updateCourse,
  updateDepartment,
  updateQuestionPaper,
  updateRoleFeaturePermission,
  updateRoleFieldPermission,
  updateRoleScreenPermission,
  updateRoleSectionPermission,
  updateRoleTabPermission,
  updateStaff,
  updateStudent,
  updateSubject,
  updateTenant,
  updateTenantUserRoleMapping,
} from './graphql/mutations'
import {
  getCampedPlatformTokens,
  getCourseById,
  getCoursesByFilter,
  getCoursesCountWithFilter,
  getDegreesByFilter,
  getDegreesCountWithFilter,
  getDepartmentById,
  getDepartmentsByFilter,
  getDepartmentsCountWithFilter,
  getGraduationsByFilter,
  getGraduationsCountWithFilter,
  getMenuMetaData,
  getMetaData,
  getNewsByFilter,
  getQuestionPaperByFilter,
  getQuestionPaperById,
  getQuestionPapersByFilter,
  getRoleById,
  getRoleFeaturePermissionsByFilter,
  getRoleFieldPermissionsByFilter,
  getRoleScreenPermissionsByFilter,
  getRoleSectionPermissionsByFilter,
  getRoleTabPermissionsByFilter,
  getRolesByFilter,
  getS3ImageUploadUrl,
  getScreensByFilter,
  getSortAndFiltersByFilter,
  getStaffById,
  getStaffsByFilter,
  getStaffsCountWithFilter,
  getStudentAnalytics,
  getStudentById,
  getStudentsByFilter,
  getStudentsCountWithFilter,
  getSubjectById,
  getSubjectsByFilter,
  getTenantByFilter,
  getTenantById,
  getTenantUserRoleMappingByFilter,
  getTenantUserRoleMappingsByFilter,
  getViewProfileImageUrl,
  searchStaff,
  searchStudent,
  studentEnrollment,
} from './graphql/queries'

export const graphqlOperations = [
  {
    name: 'updateRoleScreenPermission',
    operation: updateRoleScreenPermission,
    tgt: 'tenant',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'updateRoleTabPermission',
    operation: updateRoleTabPermission,
    tgt: 'tenant',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'updateRoleSectionPermission',
    operation: updateRoleSectionPermission,
    tgt: 'tenant',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'updateRoleFieldPermission',
    operation: updateRoleFieldPermission,
    tgt: 'tenant',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'getRoleFieldPermissionsByFilter',
    operation: getRoleFieldPermissionsByFilter,
    tgt: 'tenant',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getRoleTabPermissionsByFilter',
    operation: getRoleTabPermissionsByFilter,
    tgt: 'tenant',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getRoleSectionPermissionsByFilter',
    operation: getRoleSectionPermissionsByFilter,
    tgt: 'tenant',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'updateStaff',
    operation: updateStaff,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'sendInvitation',
    operation: sendInvitation,
    tgt: 'profile',
    type: 'mutation',
  },
  {
    name: 'updateStudent',
    operation: updateStudent,
    tgt: 'profile',
    type: 'mutation',
  },
  {
    name: 'getS3ImageUploadUrl',
    operation: getS3ImageUploadUrl,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'getViewProfileImageUrl',
    operation: getViewProfileImageUrl,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'getRoleById',
    operation: getRoleById,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'getCoursesByFilter',
    operation: getCoursesByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'createCourse',
    operation: createCourse,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'createDegree',
    operation: createDegree,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'createSubject',
    operation: createSubject,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'createQuestionPaper',
    operation: createQuestionPaper,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'updateCourse',
    operation: updateCourse,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'updateSubject',
    operation: updateSubject,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'updateDepartment',
    operation: updateDepartment,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'updateQuestionPaper',
    operation: updateQuestionPaper,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'updateTenant',
    operation: updateTenant,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'createTenantUserRoleMapping',
    operation: createTenantUserRoleMapping,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'updateTenantUserRoleMapping',
    operation: updateTenantUserRoleMapping,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'createRole',
    operation: createRole,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'createStudent',
    operation: createStudent,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'updateStudent',
    operation: updateStudent,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'createStaff',
    operation: createStaff,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'getSubjectsByFilter',
    operation: getSubjectsByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getMenuMetaData',
    operation: getMenuMetaData,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getCourseById',
    operation: getCourseById,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getSubjectById',
    operation: getSubjectById,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getCampedPlatformTokens',
    operation: getCampedPlatformTokens,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getQuestionPaperById',
    operation: getQuestionPaperById,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getQuestionPaperByFilter',
    operation: getQuestionPaperByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getQuestionPapersByFilter',
    operation: getQuestionPapersByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getMetaData',
    operation: getMetaData,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getTenantById',
    operation: getTenantById,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getTenantUserRoleMappingByFilter',
    operation: getTenantUserRoleMappingByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getRoleFeaturePermissionsByFilter',
    operation: getRoleFeaturePermissionsByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getRolesByFilter',
    operation: getRolesByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'updateRoleFeaturePermission',
    operation: updateRoleFeaturePermission,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'getTenantByFilter',
    operation: getTenantByFilter,
    tgt: 'profile',
    type: 'query',
    isAuth: false,
    endPointName: 'Academics',
  },
  {
    name: 'getScreensByFilter',
    operation: getScreensByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getStaffById',
    operation: getStaffById,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getStaffsByFilter',
    operation: getStaffsByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getStudentById',
    operation: getStudentById,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getStudentsByFilter',
    operation: getStudentsByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'createDepartment',
    operation: createDepartment,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'createGraduation',
    operation: createGraduation,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'getDepartmentById',
    operation: getDepartmentById,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getDepartmentsByFilter',
    operation: getDepartmentsByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getRoleScreenPermissionsByFilter',
    operation: getRoleScreenPermissionsByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getTenantUserRoleMappingsByFilter',
    operation: getTenantUserRoleMappingsByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getSortAndFiltersByFilter',
    operation: getSortAndFiltersByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'searchStudent',
    operation: searchStudent,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getGraduationsByFilter',
    operation: getGraduationsByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getDegreesByFilter',
    operation: getDegreesByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'searchStaff',
    operation: searchStaff,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getStudentAnalytics',
    operation: getStudentAnalytics,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getGraduationsByFilter',
    operation: getGraduationsByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getDegreesByFilter',
    operation: getDegreesByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'studentEnrollment',
    operation: studentEnrollment,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getStaffsCountWithFilter',
    operation: getStaffsCountWithFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getStudentsCountWithFilter',
    operation: getStudentsCountWithFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getGraduationsCountWithFilter',
    operation: getGraduationsCountWithFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getDepartmentsCountWithFilter',
    operation: getDepartmentsCountWithFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getCoursesCountWithFilter',
    operation: getCoursesCountWithFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'getDegreesCountWithFilter',
    operation: getDegreesCountWithFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
  {
    name: 'sendUserInvitation',
    operation: sendUserInvitation,
    tgt: 'profile',
    type: 'mutation',
    endPointName: 'Academics',
  },
  {
    name: 'getNewsByFilter',
    operation: getNewsByFilter,
    tgt: 'profile',
    type: 'query',
    endPointName: 'Academics',
  },
]
