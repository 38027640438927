import { Platform } from 'react-native'

import { academicsApiUrl, apiUrl, awsDomain, baseUrl } from './src/config'

const awsRegion = 'ap-south-1'

const config = {
  Auth: {
    identityPoolId: `${awsRegion}:797b9a3a-dfa3-4e72-8a82-23c4fda4d3fe`,
    region: awsRegion,
    userPoolId: `${awsRegion}_RZm5ix4kK`,
    userPoolWebClientId: '4egoov20787vob3e3ej7r4gl1p',
    oauth: {
      domain: awsDomain,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: Platform.OS === 'web' ? baseUrl : 'camped://',
      redirectSignOut:
        Platform.OS === 'web' ? `${baseUrl}/logout` : 'camped://',
      responseType: 'token',
    },
    cookieStorage: {
      domain: '.qa.camped.academy',
      path: '/',
      expires: 7,
      secure: false,
      sameSite: 'lax',
    },
  },
  API: {
    endpoints: [
      {
        name: 'Public',
        endpoint: apiUrl,
        region: awsRegion,
      },
      {
        name: 'Academics',
        endpoint: academicsApiUrl,
        region: awsRegion,
      },
    ],
  },
}

export default config
