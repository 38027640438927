import React, { useState } from 'react'
import { View, useWindowDimensions } from 'react-native'
import { Divider, useTheme } from 'react-native-paper'

import { Button, Text } from '@camped/components'
import {
  BoxContainer,
  DynamicField,
  FormContainer,
} from '@camped/form-container'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout } from '@camped/utils'

export const AddUserFields = (props) => {
  const {
    countryCode,
    data,
    errors,
    onChangeText,
    setErrors,
    getLookupData,
    user,
    handleAdd,
    handleDelete,
  } = props
  const { fonts } = useTheme()
  const [selectedSem, setSelectedSem] = useState(1)
  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout?.isWeb(windowWidth)
  const renderItem = (localData, index) => {
    const fieldName = localData?.referenceName ?? localData?.fieldName
    const arrayValue = localData?.arrayName
      ? user?.[localData?.arrayName]?.[index]?.find(
          (field) => field?.name === fieldName,
        )?.value
      : undefined

    const fieldValue = user?.[fieldName]?.value ?? ''

    const finalError = errors?.find(
      (m) =>
        m?.fieldName === fieldName &&
        m?.value === (arrayValue !== undefined ? arrayValue : fieldValue),
    )
    const lookupData = getLookupData(fieldName)
    return (
      <DynamicField
        data={localData}
        onChangeText={onChangeText}
        isEdit
        error={finalError}
        value={arrayValue || fieldValue}
        list={lookupData?.length !== 0 ? lookupData : localData?.options}
        onFocus={setErrors}
        style={{ minWidth: isDesktop ? 296 : 360, width: '100%' }}
        disabled={
          fieldName === 'academicEndYear' ||
          (!user?.course?.value && fieldName === 'academicStartYear') ||
          (!user?.graduation?.value && fieldName === 'degree') ||
          (!user?.department?.value && fieldName === 'course')
        }
        countryCode={countryCode}
        user={user}
        onCountrySelect={onChangeText}
        index={index}
        selectedSem={selectedSem}
        setSelectedSem={setSelectedSem}
      />
    )
  }
  let sortSection = []
  sortSection = data?.sections?.sort(
    (a, b) => a.metadata.displayOrder - b.metadata.displayOrder,
  )
  return (
    <View>
      {Object?.entries(sortSection || {})?.map(([mainKey, mainValue]) => {
        const sortValue = []
        sortValue.push(
          mainValue?.fields?.sort((a, b) => {
            const aOrder = a.metadata?.displayOrder || 0
            const bOrder = b.metadata?.displayOrder || 0
            return aOrder - bOrder
          }),
        )
        const isAdd = mainValue?.fields?.[0]?.metadata?.arrayName
        const semester = user?.totalSemesters?.value || ''
        const val =
          mainValue?.metadata?.fieldName === 'selectSemester' ||
          mainValue?.metadata?.fieldName === 'subjectDetails'
        const iscourseDetails =
          mainValue?.metadata?.fieldName === 'subjectDetails'
        const permission = mainValue?.permissions
        if (!val || semester) {
          return (
            <BoxContainer
              title={mainValue?.metadata?.displayName}
              permission={permission}
              handleAdd={
                isAdd ? () => handleAdd(mainKey, mainValue, selectedSem) : null
              }
            >
              <View
                style={{
                  paddingTop: spacing.spacing2,
                  paddingLeft: spacing.spacing4,
                }}
              >
                {sortValue?.map((values, i) => {
                  const condition = values.find(
                    (item) => item?.metadata?.fieldName === 'semester',
                  )

                  if (
                    condition?.value === selectedSem.toString() ||
                    !iscourseDetails
                  )
                    return (
                      <>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            padding: spacing.spacing,
                          }}
                        >
                          {mainValue?.metadata?.fieldName !==
                            'selectSemester' &&
                            sortValue.length !== 1 && (
                              <Button
                                labelStyle={{
                                  margin: spacing.spacing2,
                                  marginHorizontal: spacing.spacing1,
                                  ...fonts.labelMedium,
                                }}
                                style={{
                                  marginRight: spacing.spacing5,
                                }}
                                onPress={() => {
                                  handleDelete(i, mainValue)
                                }}
                              >
                                Remove
                              </Button>
                            )}
                        </View>
                        <FormContainer
                          data={values}
                          renderItem={renderItem}
                          index={i}
                        />
                        {sortValue.length - 1 !== i && (
                          <Divider style={{ margin: spacing.spacing2 }} />
                        )}
                      </>
                    )
                  if (iscourseDetails && sortValue.length === 1) {
                    return (
                      <Text
                        style={{
                          fontWeight: '700',
                          paddingBottom: spacing.spacing4,
                          paddingLeft: spacing.spacing4,
                          fontSize: 18,
                          textTransform: 'capitalize',
                        }}
                      >
                        Kindly add the subjects to see the course details
                      </Text>
                    )
                  }
                  return null
                })}
              </View>
            </BoxContainer>
          )
        }
        return null
      })}
    </View>
  )
}
