// this is an auto generated file. This will be overwritten

export const createMetadatum = /* GraphQL */ `
  mutation CreateMetadatum($metadatumData: MetadatumInput!) {
    createMetadatum(metadatumData: $metadatumData) {
      _id
      userPerson
      collectionName
      screenName
      fieldName
      placeholder
      label
      component
      displayOrder
      errorMessage
      isMandatory
      maxLength
      mode
      multiline
      valueType
      displayName
      options {
        displayName
        value
        displayOrder
      }
      arrayName
      link
    }
  }
`
export const createTenantRolePermission = /* GraphQL */ `
  mutation CreateTenantRolePermission(
    $tenantRolePermissionData: TenantRolePermissionInput!
  ) {
    createTenantRolePermission(
      tenantRolePermissionData: $tenantRolePermissionData
    ) {
      _id
      tenantId
      tenantRoleId
      name
      type
      parent
      userType
      fieldPermissions {
        view
        edit
        delete
        add
      }
      fieldMetadata
    }
  }
`
export const createDomain = /* GraphQL */ `
  mutation CreateDomain($domainData: DomainInput!) {
    createDomain(domainData: $domainData) {
      _id
      domainName
      fields {
        fieldName
        displayName
        dataType
        placeholder
        view
        isSortable
        isSearchable
      }
    }
  }
`
export const createRole = /* GraphQL */ `
  mutation createRole($clonedRole: String!, $role: CreateRoleInput!) {
    createRole(clonedRole: $clonedRole, role: $role) {
      _id
      name
      userPerson
      tenant {
        _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
      }
    }
  }
`
export const createStaff = /* GraphQL */ `
  mutation createStaff($staff: CreateStaffInput!) {
    createStaff(staff: $staff) {
      _id
      employeeId
      title
      profileImage
      firstName
      middleName
      lastName
      gender
      emailId
      dateOfBirth
      aadhaarNumber
      department {
        _id
        name
      }
      designation
      employeeType
      dateOfJoining
      nationality
      bloodGroup
      maritalStatus
      collegeEmailId
      emergencyContactName
      emergencyContactNumber
      relation
      personalEmailId
      landlineNumber
      mobileNumber
      communicationAddress
      permanentAddress
      pincode
      currentRoomNo
      hasDisabilities
      hasAllergies
      medicalCondition
      caste
      community
      religion
      educationDetails {
        educationType
        educationSchool
        educationPercentage
        educationYOP
        educationSpecialization
        educationTitle
      }
      employmentDetails {
        departmentName
        employmentClass
        employmentDesignation
        employmentSalary
        employmentAppraisal
      }
      achievements {
        achievementType
        achievementDescription
        achievementDate
      }
      user {
        _id
        email
        userPerson
        signInMode
        createCognitoUser
        isCognitoUserCreated
      }
      tenant {
        _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
      }
    }
  }
`
export const sendInvitation = /* GraphQL */ `
  mutation SendInvitation($userData: SentInvitationInput!) {
    sendInvitation(userData: $userData) {
      initivationResults {
        id
        isCognitoUserCreated
        errorMessage
      }
    }
  }
`
export const createTenantRole = /* GraphQL */ `
  mutation CreateTenantRole($tenantRoleData: TenantRoleInput!) {
    createTenantRole(tenantRoleData: $tenantRoleData) {
      _id
      tenantId
      userType
      userRole
    }
  }
`
export const createUserRoleMapping = /* GraphQL */ `
  mutation CreateUserRoleMapping($userRoleMappingData: UserRoleMappingInput!) {
    createUserRoleMapping(userRoleMappingData: $userRoleMappingData) {
      tenantId
      userId
      roleId
      userType
    }
  }
`

export const createCourse = /* GraphQL */ `
  mutation createCourse($course: CreateCourseInput!) {
    createCourse(course: $course) {
      _id
      courseName
      department {
        _id
        name
      }
      graduation {
        _id
        name
      }
      category
      degree {
        _id
        name
        graduation {
          _id
          name
        }
      }
      totalSemesters
      courseDuration
      modeOfStudy
      yearOfStart
    }
  }
`
export const createSubject = /* GraphQL */ `
  mutation CreateSubject($subject: CreateSubjectInput!) {
    createSubject(subject: $subject) {
      _id
      subjectCode
      semester
      natureOfSubject
      subjectType
      totalHoursOrPeriods
      credits
      internalMarks
      externalMarks
      lmsSubjectId
      staffId
      course {
        _id
        courseName
        department {
          _id
          name
        }
        graduation {
          _id
          name
        }
        category
        degree {
          _id
          name
          graduation {
            _id
            name
          }
        }
        modeOfStudy
        yearOfStart
      }
    }
  }
`
export const createQuestionPaper = /* GraphQL */ `
  mutation ($questionPaper: CreateQuestionPaperInput!) {
    createQuestionPaper(questionPaper: $questionPaper) {
      _id
      examName
      totalMarks
      difficultyLevel
      timeLimit
      date
      noOfSections
      units {
        unitName
        topics
      }
      generatedQuestion
      subject {
        _id
        subjectCode
        semester
        natureOfSubject
        totalHoursOrPeriods
        credits
        internalMarks
        externalMarks
        lmsSubjectId
        staffId
        subjectType
        objectives
        outcomes
        noOfUnits
        units {
          unitName
          totalHoursOrPeriods
          unitDetails
          locatorId
        }
        textBooks {
          bookName
          author
          edition
          publisher
        }
        references {
          referenceName
          referenceAuthor
          referenceEdition
          referencePublisher
        }
        course {
          _id
          courseName
          department {
            _id
            name
          }
          graduation {
            _id
            name
          }
          category
          degree {
            _id
            name
            graduation {
              _id
              name
            }
          }
          modeOfStudy
          totalSemesters
          courseDuration
        }
      }
      questionPaperSection {
        sectionNumber
        sectionMarks
        totalQuestions
        eachQuestionMark
        questionsAnswered
        sectionType
      }
    }
  }
`
export const updateCourse = /* GraphQL */ `
  mutation updateCourse($id: String!, $course: UpdateCourseInput!) {
    updateCourse(id: $id, course: $course) {
      _id
      courseName
      department {
        _id
        name
      }
      graduation {
        _id
        name
      }
      category
      degree {
        _id
        name
        graduation {
          _id
          name
        }
      }
      modeOfStudy
      totalSemesters
      courseDuration
    }
  }
`
export const updateSubject = /* GraphQL */ `
  mutation updateSubject($id: String!, $subject: UpdateSubjectInput!) {
    updateSubject(id: $id, subject: $subject) {
      _id
      subjectCode
      semester
      natureOfSubject
      totalHoursOrPeriods
      credits
      internalMarks
      externalMarks
      lmsSubjectId
      staffId
      subjectType
      objectives
      outcomes
      noOfUnits
      units {
        unitName
        totalHoursOrPeriods
        unitDetails
      }
      textBooks {
        bookName
        author
        edition
        publisher
      }
      references {
        referenceName
        referenceAuthor
        referenceEdition
        referencePublisher
      }
      course {
        _id
        courseName
        department {
          _id
          name
        }
        graduation {
          _id
          name
        }
        category
        degree {
          _id
          name
          graduation {
            _id
            name
          }
        }
        modeOfStudy
        totalSemesters
        courseDuration
      }
    }
  }
`
export const updateQuestionPaper = /* GraphQL */ `
  mutation updateQuestionPaper(
    $id: String!
    $questionPaper: UpdateQuestionPaperInput!
  ) {
    updateQuestionPaper(id: $id, questionPaper: $questionPaper) {
      _id
      examName
      totalMarks
      difficultyLevel
      timeLimit
      date
      noOfSections
      units {
        unitName
        topics
      }
      generatedQuestion
      subject {
        _id
        subjectCode
        semester
        natureOfSubject
        totalHoursOrPeriods
        credits
        internalMarks
        externalMarks
        lmsSubjectId
        staffId
        subjectType
        objectives
        outcomes
        noOfUnits
        units {
          unitName
          totalHoursOrPeriods
          unitDetails
          locatorId
        }
        textBooks {
          bookName
          author
          edition
          publisher
        }
        references {
          referenceName
          referenceAuthor
          referenceEdition
          referencePublisher
        }
        course {
          _id
          courseName
          department {
            _id
            name
          }
          graduation {
            _id
            name
          }
          category
          degree {
            _id
            name
            graduation {
              _id
              name
            }
          }
          modeOfStudy
          totalSemesters
          courseDuration
        }
      }
      questionPaperSection {
        sectionNumber
        sectionMarks
        totalQuestions
        eachQuestionMark
        questionsAnswered
        sectionType
      }
    }
  }
`

export const updateTenant = /* GraphQL */ `
  mutation updateTenant($id: String!, $tenant: UpdateTenantInput!) {
    updateTenant(id: $id, tenant: $tenant) {
      _id
      logoURL
      backgroundImageURL
      primaryContact
      primaryContactEmail
      collegeCode
      collegeName
      yearOfEstablishment
      campusType
      universityName
      universityPhoneNumber
      universityFaxNo
      universityEmailId
      universityWebsite
      address
      phoneNumber
      faxNo
      emailId
      website
    }
  }
`
export const updateStaff = /* GraphQL */ `
  mutation updateStaff($id: String!, $staff: UpdateStaffInput!) {
    updateStaff(id: $id, staff: $staff) {
      _id
      employeeId
      title
      profileImage
      firstName
      middleName
      lastName
      gender
      emailId
      dateOfBirth
      department {
        _id
        name
      }
      aadhaarNumber
      accommodationType
      contactRelation
      designation
      employeeType
      dateOfJoining
      nationality
      bloodGroup
      maritalStatus
      collegeEmailId
      emergencyContactName
      emergencyContactNumber
      relation
      personalEmailId
      landlineNumber
      mobileNumber
      communicationAddress
      permanentAddress
      pincode
      currentRoomNo
      hasDisabilities
      hasAllergies
      medicalCondition
      caste
      community
      religion
      educationDetails {
        educationType
        educationSchool
        educationPercentage
        educationYOP
        educationSpecialization
        educationTitle
      }
      employmentDetails {
        departmentName
        employmentClass
        employmentDesignation
        employmentSalary
        employmentAppraisal
      }
      achievements {
        achievementType
        achievementDescription
        achievementDate
      }
      user {
        _id
      }
      tenant {
        _id
      }
    }
  }
`

export const createStudent = `
mutation createStudent($student: CreateStudentInput!) {
    createStudent (
        student: $student
    ) { 
        _id
        profileImage
        firstName
        middleName
        lastName
        dateOfBirth
        gender
        nationality
        bloodGroup
        aadhaarNumber
        maritalStatus
        graduation{
          _id
          name
        }
        course{
          _id
          courseName
        }
        degree{
          _id
          name
          graduation{
            _id
            name
          }
        }
        collegeEmailId
        registrationNumber
        emergencyContactName
        emergencyContact
        contactRelation
        personalEmailId
        mobileNumber
        landLineNumber
        permanentAddress
        communicationAddress
        roomNo
        blockNo
        department{
          _id
          name
        }
        dayScholarOrHostel
        otherConditions
        hasAllergies
        hasDisabilities
        caste
        community
        religion
        academicStartYear
        academicEndYear
        tuitionFee
        examFee
        transportFee
        hostelFee
        extraCurricularActivities
        hobbies
        achievements { 
            achievementType
            achievementDescription
            achievementDate
        }
        placements { 
            placementType
            companyPlaced
            approval
            role
            package
        }
        familyInfo { 
            name
            dateOfBirth
            gender
            occupation
            relation
            mobileNumber
            working
            annualIncome
        }
        previousAcademicDetails { 
            educationType
            educationSchool
            educationPercentage
            educationYOP
            educationSpecialization
            educationTitle
        }
        isInvitationSent
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
        user { 
            _id
            email
            userPerson
            signInMode
            createCognitoUser
            isCognitoUserCreated
        }
    }
}`

export const updateStudent = `
mutation updateStudent($id: String!, $student: UpdateStudentInput!) {
    updateStudent (
        id: $id,
        student: $student
    ) {
        _id
        profileImage
        firstName
        middleName
        lastName
        dateOfBirth
        gender
        nationality
        bloodGroup
        aadhaarNumber
        maritalStatus
        graduation{
          _id
          name
        }
        course{
          _id
          courseName
        }
        degree{
          _id
          name
          graduation{
            _id
            name
          }
        }
        collegeEmailId
        emergencyContactName
        emergencyContact
        contactRelation
        personalEmailId
        mobileNumber
        landLineNumber
        permanentAddress
        communicationAddress
        roomNo
        registrationNumber
        blockNo
        dayScholarOrHostel
        otherConditions
        hasAllergies
        hasDisabilities
        caste
        community
        religion
        academicStartYear
        academicEndYear
        tuitionFee
        examFee
        transportFee
        hostelFee
        extraCurricularActivities
        hobbies
        achievements { 
            achievementType
            achievementDescription
            achievementDate
        }
        placements { 
            placementType
            companyPlaced
            approval
            role
            package
        }
        familyInfo { 
            name
            dateOfBirth
            gender
            occupation
            relation
            mobileNumber
            working
            annualIncome
        }
        previousAcademicDetails { 
            educationType
            educationSchool
            educationPercentage
            educationYOP
            educationSpecialization
            educationTitle
        }
        isInvitationSent
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
        user { 
            _id
            email
            userPerson
            signInMode
            createCognitoUser
            isCognitoUserCreated
        }
    }
}`
export const createDepartment = `
mutation createDepartment($department: CreateDepartmentInput!) {
    createDepartment (
        department: $department
    ) {
        _id
        image
        name
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
    }
}`
export const updateDepartment = `
mutation updateDepartment($id: String!, $department: UpdateDepartmentInput!) {
    updateDepartment (
        id: $id,
        department: $department
    ) {
        _id
        image
        name
        tenant { 
            _id
            name
            domain
            welcomeMessage
            institutionName
            logoURL
            backgroundImageURL
            primaryContact
            primaryContactEmail
            signInMode
            collegeCode
            collegeName
            yearOfEstablishment
            campusType
            universityName
            universityPhoneNumber
            universityFaxNo
            universityEmailId
            universityWebsite
            address
            phoneNumber
            faxNo
            emailId
            website
        }
    }
}`

export const updateRoleScreenPermission = /* GraphQL */ `
  mutation updateRoleScreenPermission(
    $id: String!
    $roleScreenPermission: UpdateRoleScreenPermissionInput!
  ) {
    updateRoleScreenPermission(
      id: $id
      roleScreenPermission: $roleScreenPermission
    ) {
      _id
      isEnabled
      userPerson
      permissions {
        view
        edit
        delete
        add
      }
    }
  }
`

export const updateRoleSectionPermission = /* GraphQL */ `
  mutation UpdateRoleSectionPermission(
    $id: String!
    $roleSectionPermission: UpdateRoleSectionPermissionInput!
  ) {
    updateRoleSectionPermission(
      id: $id
      roleSectionPermission: $roleSectionPermission
    ) {
      _id
      isEnabled
      userPerson
      tenant {
        _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
      }
      feature {
        _id
        id
        userPerson
        displayName
        displayOrder
        iconName
        moduleName
        placement
        activePath
        navigationPath
        modules
        accordionList
        parentFeature {
          _id
          id
          userPerson
          displayName
          displayOrder
          iconName
          moduleName
          placement
          activePath
          navigationPath
          modules
          accordionList
          parentFeature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      role {
        _id
        name
        tenant {
          _id
          name
          domain
          welcomeMessage
          institutionName
          logoURL
          backgroundImageURL
          primaryContact
          primaryContactEmail
          signInMode
          collegeCode
          collegeName
          yearOfEstablishment
          campusType
          universityName
          universityPhoneNumber
          universityFaxNo
          universityEmailId
          universityWebsite
          address
          phoneNumber
          faxNo
          emailId
          website
        }
      }
      screen {
        _id
        displayName
        feature {
          _id
          id
          userPerson
          displayName
          displayOrder
          iconName
          moduleName
          placement
          activePath
          navigationPath
          modules
          accordionList
          parentFeature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      tab {
        _id
        userPerson
        screenName
        fieldName
        displayName
        displayOrder
        screen {
          _id
          displayName
          feature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      section {
        _id
        userPerson
        screenName
        fieldName
        displayName
        displayOrder
        tab {
          _id
          userPerson
          screenName
          fieldName
          displayName
          displayOrder
          screen {
            _id
            displayName
            feature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
        screen {
          _id
          displayName
          feature {
            _id
            id
            userPerson
            displayName
            displayOrder
            iconName
            moduleName
            placement
            activePath
            navigationPath
            modules
            accordionList
            parentFeature {
              _id
              id
              userPerson
              displayName
              displayOrder
              iconName
              moduleName
              placement
              activePath
              navigationPath
              modules
              accordionList
              parentFeature {
                _id
                id
                userPerson
                displayName
                displayOrder
                iconName
                moduleName
                placement
                activePath
                navigationPath
                modules
                accordionList
              }
            }
          }
        }
      }
      permissions {
        view
        edit
        delete
        add
      }
    }
  }
`

export const updateRoleFieldPermission = /* GraphQL */ `
  mutation UpdateRoleFieldPermission(
    $id: String!
    $roleFieldPermission: UpdateRoleFieldPermissionInput!
  ) {
    updateRoleFieldPermission(
      id: $id
      roleFieldPermission: $roleFieldPermission
    ) {
      _id
      userPerson
      permissions {
        view
        edit
        delete
        add
      }
    }
  }
`

export const updateRoleFeaturePermission = /* GraphQL */ `
  mutation UpdateRoleFeaturePermission(
    $id: String!
    $roleFeaturePermission: UpdateRoleFeaturePermissionInput!
  ) {
    updateRoleFeaturePermission(
      id: $id
      roleFeaturePermission: $roleFeaturePermission
    ) {
      _id
      isEnabled
      userPerson
      feature {
        _id
        id
        userPerson
        displayName
        displayOrder
        iconName
        moduleName
        placement
        activePath
        navigationPath
        modules
        accordionList
      }
    }
  }
`
export const updateRoleTabPermission = /* GraphQL */ `
  mutation UpdateRoleTabPermission(
    $id: String!
    $roleTabPermission: UpdateRoleTabPermissionInput!
  ) {
    updateRoleTabPermission(id: $id, roleTabPermission: $roleTabPermission) {
      _id
      isEnabled
      userPerson
      tab {
        _id
        userPerson
        screenName
        fieldName
        displayName
        displayOrder
      }
    }
  }
`
export const createTenantUserRoleMapping = /* GraphQL */ `
  mutation createTenantUserRoleMapping(
    $tenantUserRoleMapping: CreateTenantUserRoleMappingInput!
  ) {
    createTenantUserRoleMapping(tenantUserRoleMapping: $tenantUserRoleMapping) {
      _id
      userPerson
      enabledOn
      disabledOn
      isActive
      tenant {
        _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
      }
      user {
        _id
        email
        userPerson
        signInMode
        createCognitoUser
        isCognitoUserCreated
      }
      role {
        _id
        name
        userPerson
      }
    }
  }
`

export const createDegree = /* GraphQL */ `
  mutation createDegree($degree: CreateDegreeInput!) {
    createDegree(degree: $degree) {
      _id
      name
      graduation {
        _id
        name
        tenant {
          _id
          name
          domain
          welcomeMessage
          institutionName
          logoURL
          backgroundImageURL
          primaryContact
          primaryContactEmail
          signInMode
          collegeCode
          collegeName
          yearOfEstablishment
          campusType
          universityName
          universityPhoneNumber
          universityFaxNo
          universityEmailId
          universityWebsite
          address
          phoneNumber
          faxNo
          emailId
          website
        }
      }
    }
  }
`
export const updateTenantUserRoleMapping = /* GraphQL */ `
  mutation updateTenantUserRoleMapping(
    $id: String!
    $tenantUserRoleMapping: UpdateTenantUserRoleMappingInput!
  ) {
    updateTenantUserRoleMapping(
      id: $id
      tenantUserRoleMapping: $tenantUserRoleMapping
    ) {
      _id
      userPerson
      enabledOn
      disabledOn
      isActive
      tenant {
        _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
      }
      user {
        _id
        email
        userPerson
        signInMode
        createCognitoUser
        isCognitoUserCreated
      }
      role {
        _id
        name
        userPerson
        tenant {
          _id
          name
          domain
          welcomeMessage
          institutionName
          logoURL
          backgroundImageURL
          primaryContact
          primaryContactEmail
          signInMode
          collegeCode
          collegeName
          yearOfEstablishment
          campusType
          universityName
          universityPhoneNumber
          universityFaxNo
          universityEmailId
          universityWebsite
          address
          phoneNumber
          faxNo
          emailId
          website
        }
      }
    }
  }
`
export const createGraduation = /* GraphQL */ `
  mutation createGraduation($graduation: CreateGraduationInput!) {
    createGraduation(graduation: $graduation) {
      _id
      name
      tenant {
        _id
        name
        domain
        welcomeMessage
        institutionName
        logoURL
        backgroundImageURL
        primaryContact
        primaryContactEmail
        signInMode
        collegeCode
        collegeName
        yearOfEstablishment
        campusType
        universityName
        universityPhoneNumber
        universityFaxNo
        universityEmailId
        universityWebsite
        address
        phoneNumber
        faxNo
        emailId
        website
      }
    }
  }
`

export const sendUserInvitation = /* GraphQL */ `
  mutation ($emails: [String!]!) {
    sendUserInvitation(emails: $emails)
  }
`
