import React, { useState } from 'react'
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Button, Tab } from '@camped/components'
import {
  BoxContainer,
  EditContainer,
  ViewContentContainer,
} from '@camped/form-container'
import { MobileContainer } from '@camped/shared-container'

import { AddUserFields } from '../components/add-details-section'
import BottomButtons from '../components/bottom-button'
import ProfileSideSection from '../components/profile-side-top-section'
import QuestionPaperUi from '../components/question-paper-tab'
import { MobileView as Skeleton } from '../skeleton/Mobile-view'
import { groupMetaData } from '../utils/group-meta-data'

const MobileView = (props) => {
  const {
    updateValue,
    addSection,
    attachment,
    checked,
    clearEditSelection,
    countryCode,
    create,
    currentRoute,
    detailsRef,
    editSection,
    errors,
    fileInputRef,
    firstName,
    generateQuestion,
    getLookupData,
    handleAddImage,
    handleAddNew,
    handleEdit,
    handleOnSave,
    handleSubmit,
    handleDelete,
    handleTabChange,
    handleAddSection,
    handleEditQuestionPaper,
    isFetching,
    isLoading,
    module,
    onChangeText,
    onUpload,
    scrollToTop,
    selectedTabDisplayName,
    selectedTabFieldName,
    setChecked,
    setEditSection,
    setErrors,
    showSideBar,
    tabData,
    user,
    userDetail,
    id,
    pickPDF,
    handleFileChange,
    pdfInputRef,
    selectedTopics,
    setselectedTopics,
    selectedSem,
    setSelectedSem,
    selectedUnitsValidation,
    getUserData,
  } = props

  const { spacing, colors } = useTheme()
  const [tabHeight, setTabHeight] = useState(0)
  const [viewHeight, setviewHeight] = useState(0)
  const [heightValue, setHeightValue] = useState(0)

  if (
    id ||
    currentRoute === 'campus-configuration' ||
    currentRoute === 'course-listing' ||
    currentRoute === 'my-profile'
  ) {
    return (
      <MobileContainer hasHeader style={{ backgroundColor: colors.background }}>
        {/* {viewHeight + tabHeight < heightValue &&
        showSideBar?.includes(currentRoute) ? (
          <View
            style={{
              backgroundColor: colors.background,
              paddingTop: spacing.spacing3,
            }}
          >
            <Tab
              data={tabData}
              selectedTab={selectedTabDisplayName}
              onPress={(item) => {
                handleTabChange(item)
                scrollToTop()
              }}
              isMobile
              hasButton
            />
          </View>
        ) : null} */}
        {isFetching ? (
          <Skeleton />
        ) : (
          <ScrollView
            ref={detailsRef}
            showsVerticalScrollIndicator={false}
            style={{ backgroundColor: colors.surface1 }}
            scrollEventThrottle={16}
            onScroll={(event) => {
              const { contentOffset } = event.nativeEvent
              const { y } = contentOffset
              setHeightValue(y)
            }}
          >
            <View
              onLayout={(event) => {
                const { height } = event.nativeEvent.layout
                setviewHeight(height)
              }}
            >
              {showSideBar?.includes(currentRoute) && (
                <ProfileSideSection
                  summaryInfo={userDetail}
                  attachment={attachment}
                  fileInputRef={fileInputRef}
                  handleAddImage={handleAddImage}
                  onUpload={onUpload}
                  module={module}
                />
              )}
            </View>
            {tabData?.length !== 0 && (
              <View
                onLayout={(event) => {
                  const { height } = event.nativeEvent.layout
                  setTabHeight(height)
                }}
                style={{
                  backgroundColor: colors.background,
                  paddingVertical: spacing.spacing3,
                }}
              >
                <Tab
                  data={tabData}
                  selectedTab={selectedTabDisplayName}
                  onPress={(item) => handleTabChange(item)}
                  isMobile
                  hasButton
                />
              </View>
            )}
            <View style={{ paddingBottom: spacing.spacing4 }}>
              {Object.entries(
                groupMetaData(userDetail)[selectedTabFieldName] ||
                  groupMetaData(userDetail)[''] ||
                  {},
              ).map(([mainKeys, mainValue]) => {
                if (editSection !== 'question Paper Preview') {
                  if (mainKeys === 'Section' || mainKeys === 'General') {
                    return
                  }
                } else if (mainKeys === 'question Paper Preview') {
                  return
                }
                const permission = mainValue?.permissions
                return (
                  <BoxContainer
                    permission={permission}
                    title={mainKeys}
                    handleEdit={() =>
                      handleEdit(mainKeys, mainValue, false, selectedSem)
                    }
                    handleAdd={
                      mainKeys === 'Section'
                        ? () => handleAddSection(mainValue)
                        : () =>
                            handleEdit(mainKeys, mainValue, true, selectedSem)
                    }
                    onCancel={() => clearEditSelection()}
                    onSave={
                      mainKeys === addSection
                        ? () => create(mainValue?.fields, addSection)
                        : () => handleOnSave(mainValue?.fields)
                    }
                    isLoading={isLoading}
                    setErrors={setErrors}
                    getUserData={getUserData}
                    userDetail={userDetail}
                  >
                    <>
                      {mainValue?.metadata?.tab?._id ===
                        '65310bcf5578ed88bf066cfe' &&
                        mainKeys !== 'question Paper Preview' && (
                          <>
                            {mainKeys === 'General' && (
                              <QuestionPaperUi
                                data={userDetail}
                                selectedTopics={selectedTopics}
                                setselectedTopics={setselectedTopics}
                                selectedUnitsValidation={
                                  selectedUnitsValidation
                                }
                              />
                            )}
                            <EditContainer
                              add={updateValue}
                              addSection={addSection}
                              checked={checked}
                              countryCode={countryCode}
                              editSection={editSection}
                              getLookupData={getLookupData}
                              error={errors}
                              handleFileChange={handleFileChange}
                              mainKeys={mainKeys}
                              mainValue={mainValue?.fields}
                              onChangeText={onChangeText}
                              pdfInputRef={pdfInputRef}
                              pickPDF={pickPDF}
                              setChecked={setChecked}
                              setErrors={setErrors}
                              selectedSem={selectedSem}
                              user={user}
                            />
                          </>
                        )}
                      {mainKeys !== editSection &&
                        mainKeys !== addSection &&
                        mainKeys !== 'Section' &&
                        mainKeys !== 'General' && (
                          <ViewContentContainer
                            title={mainKeys}
                            mainKeys={mainKeys}
                            mainValue={mainValue}
                            handleEdit={handleEdit}
                            pickPDF={pickPDF}
                            user={user}
                            subject={firstName}
                            setSelectedSem={setSelectedSem}
                            selectedSem={selectedSem}
                            handleFileChange={handleFileChange}
                            pdfInputRef={pdfInputRef}
                            handleOnSave={handleOnSave}
                            handleEditQuestionPaper={handleEditQuestionPaper}
                          />
                        )}
                      {(mainKeys === editSection ||
                        mainKeys === addSection) && (
                        <EditContainer
                          add={updateValue}
                          addSection={addSection}
                          checked={checked}
                          countryCode={countryCode}
                          editSection={editSection}
                          error={errors}
                          handleFileChange={handleFileChange}
                          mainKeys={mainKeys}
                          mainValue={mainValue?.fields}
                          getLookupData={getLookupData}
                          onChangeText={onChangeText}
                          pdfInputRef={pdfInputRef}
                          pickPDF={pickPDF}
                          setChecked={setChecked}
                          setErrors={setErrors}
                          selectedSem={selectedSem}
                          user={user}
                        />
                      )}
                      {mainKeys === 'Section' && (
                        <View
                          style={{
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            mode='outlined'
                            labelStyle={{
                              margin: 6,
                              marginHorizontal: 1,
                              color: colors.primary,
                              paddingLeft: spacing.spacing6,
                              paddingRight: spacing.spacing6,
                            }}
                            style={{
                              paddingHorizontal: spacing.spacing2,
                              alignSelf: 'flex-end',
                              marginBottom: spacing.spacing4,
                              marginRight: spacing.spacing3,
                            }}
                            onPress={() => {
                              setEditSection('')
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            labelStyle={{
                              margin: 6,
                              marginHorizontal: 1,
                              paddingLeft: spacing.spacing6,
                              paddingRight: spacing.spacing6,
                            }}
                            loading={isLoading}
                            disabled={isLoading}
                            style={{
                              paddingHorizontal: spacing.spacing2,
                              alignSelf: 'flex-end',
                              marginBottom: spacing.spacing4,
                            }}
                            onPress={() => {
                              generateQuestion()
                            }}
                          >
                            Generate Question
                          </Button>
                        </View>
                      )}
                    </>
                  </BoxContainer>
                )
              })}
            </View>
          </ScrollView>
        )}
      </MobileContainer>
    )
  }
  return (
    <MobileContainer
      style={{ backgroundColor: colors.background }}
      hasHeader={60}
      hasKeyBoard
    >
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        {isFetching ? (
          <View style={[styles.loader(colors)]}>
            <ActivityIndicator size='large' color={colors.primary} />
          </View>
        ) : (
          <AddUserFields
            onChangeText={onChangeText}
            user={user}
            setErrors={setErrors}
            countryCode={countryCode}
            data={userDetail}
            errors={errors}
            module={module}
            getLookupData={getLookupData}
            handleAdd={handleAddNew}
            handleDelete={handleDelete}
            boxContainerPadding={spacing.spacing5}
            dividerStyle={{
              marginTop: spacing.spacing5,
              marginHorizontal: spacing.spacing5,
            }}
          />
        )}
        <BottomButtons
          module={module}
          handleSubmit={handleSubmit}
          userDetail={userDetail}
          isLoading={isLoading}
        />
      </ScrollView>
    </MobileContainer>
  )
}

export default MobileView

const styles = StyleSheet.create({
  button: (colors) => ({
    borderColor: colors.primary,
  }),
  loader: (colors) => ({
    flex: 1,
    justifyContent: 'center',
    backgroundColor: colors.background,
  }),
  buttonView: (spacing) => ({
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginVertical: spacing.spacing5,
    marginHorizontal: spacing.spacing5,
  }),
})
