import React, { useState } from 'react'
import { View } from 'react-native'

import { Text } from '@camped/components'
import { spacing } from '@camped/theme-provider'

export const Greeting = ({ user }) => {
  const [currentTime, setCurrentTime] = useState(new Date())

  const hour = new Intl.DateTimeFormat('en-US', {
    hour12: false,
    hour: 'numeric',
  }).format(currentTime)

  const date = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
  }).format(currentTime)

  const weekDay = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
  }).format(currentTime)

  const timeString = new Intl.DateTimeFormat('en-US', {
    timeZone: user?.user_timezone,
    hour12: false, // Use 24-hour format
    hour: '2-digit',
    minute: '2-digit',
  }).format(currentTime)

  const updateTime = () => {
    setCurrentTime(new Date())
  }
  setInterval(updateTime, 60000)

  let greeting
  const parsedHour = parseInt(hour, 10)

  if (parsedHour < 12) {
    greeting = 'morning'
  } else if (parsedHour < 18) {
    greeting = 'afternoon'
  } else {
    greeting = 'evening'
  }

  const greetingEmojis = {
    morning: '🌤️',
    afternoon: '🌥️',
    evening: '🌙️',
  }

  const emoji = greetingEmojis[greeting] || 'defaultEmoji'

  return (
    <View style={{ marginBottom: spacing.spacing4 }}>
      <Text style={{ fontSize: 24, fontWeight: '600' }}>
        Good {greeting}, {user?.first_name} {user?.last_name}
      </Text>
      <Text style={{ fontSize: 16, marginTop: spacing.spacing2 }}>
        <Text>{emoji}</Text>
        <Text style={{ marginLeft: spacing.spacing2 }}>
          {weekDay}, {date} {timeString}
        </Text>
      </Text>
    </View>
  )
}
