import React from 'react'
import { useTranslation } from 'react-i18next'
import { Linking, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Row, Text } from '@camped/components'
import {
  increscoUrl,
  privacyPolicyUrl,
  termsConditionUrl,
} from '@camped/core/src/config'

import { HoverHighlightLink } from './branding/HoverHighlightLink'

export const Footer = () => {
  const { t } = useTranslation()
  const { colors, fonts } = useTheme()

  return (
    <>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text variant='bodyMedium' style={{ color: colors.onSurfaceVariant }}>
          {t('Global.COPYRIGHT_CAMPED')}{' '}
        </Text>

        <HoverHighlightLink
          text='Incresco'
          textColor={colors.primary}
          style={{
            ...fonts.bodyMedium,
          }}
          onPress={() => Linking.openURL(increscoUrl)}
        />
      </View>
      <View>
        <Row style={{ justifyContent: 'center' }}>
          <HoverHighlightLink
            text={t('Global.PRIVACY_POLICY')}
            textColor={colors.primary}
            onHoverColor={colors.primary}
            style={{
              ...fonts.bodyMedium,
            }}
            onPress={() => Linking.openURL(privacyPolicyUrl)}
          />
          <Text
            style={{
              color: colors.onSurfaceVariant,
              ...fonts.labelLarge,
            }}
          >
            {' '}
            |{' '}
          </Text>

          <HoverHighlightLink
            text={t('Global.TERMS_AND_CONDITIONS')}
            textColor={colors.primary}
            onHoverColor={colors.primary}
            style={{
              ...fonts.bodyMedium,
            }}
            onPress={() => Linking.openURL(termsConditionUrl)}
          />
        </Row>
      </View>
    </>
  )
}
