import React from 'react'
import {
  Image,
  Platform,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout } from '@camped/utils'

import departmentUpload from 'assets/departmentUpload.png'

import { UploadIcon } from '../atoms/upload-icon'

export const PhotoField = (props) => {
  const {
    data,
    fieldName,
    fileInputRef,
    handleAddImage,
    hasUploadPromptText,
    onChangeText,
    showModalFun = () => {},
    attachment,
  } = props
  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout.isWeb(windowWidth)
  const { colors, fonts } = useTheme()

  const renderImage = () => (
    <View>
      <Image
        source={attachment || departmentUpload}
        resizeMode='contain'
        style={{
          width: 94,
          height: 94,
          borderRadius: 100,
          overflow: 'hidden',
        }}
      />
      {!isDesktop && (
        <UploadIcon
          onPress={() =>
            attachment ? handleAddImage() : showModalFun(data?.valueList)
          }
        />
      )}
    </View>
  )

  const renderUploadPrompt = () => {
    if (hasUploadPromptText) {
      return isDesktop ? (
        <View
          style={{
            color: colors.primary,
            borderColor: colors.primary,
            borderWidth: 1,
            height: 32,
            borderRadius: 32,
            paddingHorizontal: spacing.spacing5,
            paddingVertical: spacing.spacing2,
            marginHorizontal: spacing.spacing6,
            marginTop: spacing.spacing6,
          }}
        >
          <TouchableOpacity onPress={() => showModalFun(data?.valueList)}>
            <Text
              style={{
                ...fonts.titleSmall,
                color: colors.primary,
              }}
              numberOfLines={3}
            >
              {hasUploadPromptText}
            </Text>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={{ marginTop: 14 }}>
          <Text
            style={{
              ...fonts.titleMedium,
              fontWeight: '500',
            }}
          >
            {hasUploadPromptText}
          </Text>
        </View>
      )
    }
    return (
      <View
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
        }}
      >
        {isDesktop && (
          <TouchableOpacity onPress={handleAddImage}>
            <Surface
              style={{
                width: 24,
                height: 24,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: colors.background,
                borderRadius: 40,
              }}
            >
              <Icon
                name='AddIcon'
                width={32}
                height={32}
                color={colors.primary}
              />
              {Platform.OS === 'web' && (
                <input
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  type='file'
                  id='attachment'
                  name='attachment'
                  accept='image/*'
                  onChange={({ target: { files } }) =>
                    onChangeText(data, files[0], fieldName)
                  }
                  onClick={() => {}}
                />
              )}
            </Surface>
          </TouchableOpacity>
        )}
      </View>
    )
  }

  return (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {renderImage()}
      {renderUploadPrompt()}
    </View>
  )
}
