import React, { useEffect, useState } from 'react'
import { StyleSheet, View, useWindowDimensions } from 'react-native'
import { useTheme } from 'react-native-paper'

import AppItems from './desktop-left-sub-nav-items'

const LeftSideBar = ({ menuList, screenName, activeTab }) => {
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  const [data, setData] = useState([])

  useEffect(() => {
    setData(menuList[activeTab])
  }, [activeTab, screenName])

  const adStyle = () => {
    if (width <= 1366) {
      return {
        ...styles.overHover,
        ...styles.container,
        backgroundColor: colors.background,
      }
    }

    return styles.container
  }

  return (
    <View style={adStyle()}>
      <AppItems menuData={data} screenName={screenName} />
    </View>
  )
}
export default LeftSideBar

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: 200,
  },
  overHover: {
    position: 'absolute',
    zIndex: 1,
  },
})
