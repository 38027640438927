export const EventType = {
  CHANGECOLOURTODARK: 'Change-color-to-dark',
  CHANGECOLOURTOLIGHT: 'Change-color-to-light',
  ONFOCUS: 'Onfocus',
  SIGNIN: 'Sign-In',
  LOGOUT: 'Logout',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot-password',
  CHANGE_PASSWORD: 'Change-Password',
  RESET_PASSWORD: 'Reset-password',
  SETTINGS: 'Settings',
  PROFILE: 'Profile',
  UPDATE: 'Update Profile',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  VIEW_MORE: 'View More',
  ADD_NEW: 'Add new',
  TAB_SWITCH: 'Tab Switch',
  EDIT: 'edit',
}
