import { useNavigation } from '@react-navigation/native'
import { Platform } from 'react-native'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { capitalize } from '@camped/shared-screen-listing/utils/capitailize'
import { SecureStore, appUtil } from '@camped/utils'

const convertArrayToObjectAndCapitalize = (arr) => {
  const result = {}

  arr.forEach((item) => {
    result[item.name] = item.value
  })

  const fieldsToCapitalize = ['firstName', 'middleName', 'lastName']

  fieldsToCapitalize.forEach((item) => {
    if (result[item]) {
      result[item] = capitalize(result[item])
    }
  })

  return result
}

const useCreateUserMutation = ({ module, setIsLoading }) => {
  const navigation = useNavigation()
  const queryClient = useQueryClient()
  const screenIds = queryClient.getQueryData(['getScreenIds'])

  return useMutation(
    async (data) => {
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)

      if (module === 'student') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'add-student',
        )
        const response = await API.createStudentAPI({
          student: {
            ...convertArrayToObjectAndCapitalize(data?.details?.fields),
            tenant: userDetail?.[0]?.tenantId ?? '',
            user: tenant?.['custom:userId'] ?? '',
            isInvitationSent: data?.invite || false,
          },
          extraHeader: {
            screen: screen?._id,
          },
        })
        return response
      }
      if (module === 'staff') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'add-staff',
        )
        const response = await API.createStaffAPI({
          staff: {
            ...convertArrayToObjectAndCapitalize(data?.details?.fields),
            tenant: userDetail?.[0]?.tenantId ?? '',
            user: tenant?.['custom:userId'] ?? '',
            isInvitationSent: data?.invite || false,
          },
          extraHeader: {
            screen: screen?._id,
          },
        })
        return response
      }
      if (module === 'course') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'add-course',
        )
        const courseData = convertArrayToObjectAndCapitalize(
          { ...data?.details }?.fields,
        )

        const courseResponse = await API.createCourseAPI({
          course: courseData,
          extraHeader: {
            screen: screen?._id,
          },
        })
        return courseResponse
      }
      if (module === 'academics') {
        return {}
      }
      return {}
    },
    {
      onSuccess: (data) => {
        if (!data?.errors) {
          setIsLoading(false)
          if (Platform.OS === 'web') {
            window.history.go(-1)
          } else {
            navigation.goBack()
          }
          toast.show(`${appUtil.capitalize(module)} created successfully`, {
            type: 'success',
          })
          return data
        }
        setIsLoading(false)
        if (Platform.OS === 'web') {
          window.history.go(-1)
        } else {
          navigation.goBack()
        }
        toast.show(data?.errors?.[0]?.message, {
          type: 'danger',
        })
      },
    },
  )
}

export default useCreateUserMutation
