import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Button, CheckBox, Divider } from '@camped/components'
import { DynamicField } from '@camped/form-container'
import { Icon } from '@camped/icons'
import { DesktopContainer } from '@camped/shared-container'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout } from '@camped/utils'

export const AddEditDepartment = (props) => {
  const { colors, fonts } = useTheme()
  const { t } = useTranslation()

  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout?.isWeb(windowWidth)
  const {
    fileInputRef,
    handleAdd,
    handleAddImage,
    moduleData,
    navigation,
    onChangeText,
    setShowModal,
    setUser,
    showModal,
    errors,
    user,
    id,
    checked,
    setChecked,
    module,
    getLookupData,
    isFetching,
    isLoading,
  } = props
  const [list] = useState([])
  const showModalFun = () => {
    setShowModal(true)
  }
  const renderItem = ({ item }) => (
    <TouchableOpacity
      onPress={() => setUser({ ...user, picture: item })}
      style={{
        margin: spacing.spacing5,
        height: 112,
        width: 112,
        backgroundColor: colors.surface1,
        borderRadius: 56,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: user?.picture === item && 2,
        borderColor: user?.picture === item && colors.primary,
      }}
    >
      <Image
        resizeMode='contain'
        source={item}
        style={{
          height: 80,
          width: 80,
        }}
      />
      {user?.picture === item && (
        <View
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            backgroundColor: colors.primary,
            borderRadius: 32,
            padding: 8,
          }}
        >
          <Icon
            name='Selection'
            color={colors.onPrimary}
            style={{
              height: 12,
              width: 12,
              strokeWidth: 2,
            }}
          />
        </View>
      )}
    </TouchableOpacity>
  )

  const renderModal = () => (
    <Modal visible={showModal} transparent animationType='fade'>
      <View
        style={{
          alignItems: 'center',
          height: '100%',
          backgroundColor: colors.background,
        }}
      >
        <Surface
          style={{
            backgroundColor: colors.onPrimary,
            borderRadius: 8,
            height: 548,
            left: 20,
            marginRight: 30,
            top: 100,
            width: 736,
          }}
          elevation={2}
        >
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal: 24,
              paddingVertical: 16,
              width: '100%',
              backgroundColor: colors.background,
              borderTopLeftRadius: spacing.spacing3,
              borderTopRightRadius: spacing.spacing3,
            }}
          >
            <Text
              style={{ ...fonts.headlineMedium, color: colors.onBackground }}
            >
              {t('Settings.PROFILE_IMAGE')}
            </Text>
            <TouchableOpacity onPress={() => setShowModal(false)}>
              <Icon
                color={colors.onBackground}
                name='CloseIcon'
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </TouchableOpacity>
          </View>
          <Divider />
          <ScrollView
            style={{
              paddingHorizontal: spacing.spacing3,
            }}
          >
            <FlatList
              data={list}
              keyExtractor={(item, index) => index.toString()}
              numColumns={5}
              renderItem={renderItem}
            />
          </ScrollView>
          <Surface
            style={{
              backgroundColor: colors.onPrimary,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: spacing.spacing6,
              paddingVertical: spacing.spacing4,
            }}
            elevation={1}
          >
            <View
              style={{
                color: colors.primary,
                borderColor: colors.primary,
                borderWidth: 1,
                borderRadius: 32,
                paddingHorizontal: spacing.spacing5,
                paddingVertical: spacing.spacing4,
              }}
            >
              <TouchableOpacity onPress={() => handleAddImage()}>
                <Text
                  style={{
                    ...fonts.titleSmall,
                    color: colors.primary,
                  }}
                >
                  Upload Custom
                  {Platform.OS === 'web' && (
                    <input
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      type='file'
                      id='attachment'
                      name='attachment'
                      accept='image/*'
                      onChange={({ target: { files } }) => {
                        onChangeText({ valueType: 'photo' }, files[0])
                        setShowModal(false)
                      }}
                      onClick={() => {}}
                    />
                  )}
                </Text>
              </TouchableOpacity>
            </View>
            <Button
              mode='contained'
              variant='labelLarge'
              style={{
                borderRadius: 32,
                marginRight: spacing.spacing6,
                width: 140,
              }}
              onPress={() => {
                setShowModal(false)
              }}
            >
              {t('Settings.UPDATE')}
            </Button>
          </Surface>
        </Surface>
      </View>
    </Modal>
  )
  if (isFetching) {
    return (
      <DesktopContainer>
        <ActivityIndicator
          size='large'
          color={colors.primary}
          style={{ position: 'absolute', top: '40%', right: '50%' }}
        />
      </DesktopContainer>
    )
  }
  const displayName = () => {
    if (module === 'addDepartment' || module === 'updateDepartment') {
      return 'department'
    }
    if (module === 'addGraduation') {
      return 'graduation'
    }
    if (module === 'addDegree') {
      return 'degree'
    }
  }
  return (
    <View style={{ padding: isDesktop ? spacing.spacing6 : spacing.spacing1 }}>
      {renderModal()}
      <View
        style={{
          height: '100%',
        }}
      >
        <Text
          style={{
            marginLeft: spacing.spacing6,
            paddingBottom: spacing.spacing5,
            fontWeight: '400',
            color: colors.onSurface,
            ...fonts.bodyLarge,
          }}
        >
          {id
            ? t('Settings.UPDATE_IMAGE_OF_DEPARTMENT')
            : `Enter the name and upload image of the ${displayName()} that you want to add.`}
        </Text>
        {!id && (
          <View
            style={{
              alignItems: 'center',
              alignSelf: 'flex-start',
              flexDirection: 'row',
              backgroundColor: colors.surfaceVariant,
              borderRadius: spacing.spacing2,
              padding: spacing.spacing2,
              marginLeft: spacing.spacing6,
            }}
          >
            <Icon
              name='Info'
              style={{
                height: 16,
                marginRight: spacing.spacing3,
                width: 16,
              }}
              color={colors.onSurfaceVariant}
            />
            <Text
              style={{
                color: colors.onSurfaceVariant,
                ...fonts.labelMedium,
              }}
            >
              {`Please review and confirm your ${displayName()} details before saving. Once added you will not be able to remove or edit.`}
            </Text>
          </View>
        )}
        {moduleData?.sections?.map((item) => {
          const sortFieds = item?.fields?.sort(
            (a, b) => a.metadata.displayOrder - b.metadata.displayOrder,
          )
          return (
            <View style={styles.row}>
              {sortFieds?.map((data) => {
                const lookup = getLookupData(data?.metadata?.fieldName)
                const returnValue =
                  data?.metadata?.fieldName === 'graduation'
                    ? lookup?.find(
                        (localItem) =>
                          localItem?.id === user?.[data?.metadata?.fieldName],
                      )?.displayName
                    : user?.[data?.metadata?.fieldName]
                return (
                  <View
                    style={{
                      margin: spacing.spacing6,
                      width: '49%',
                    }}
                  >
                    <DynamicField
                      attachment={user?.picture ? user?.picture : null}
                      data={data.metadata}
                      hasUploadPromptText={t('UPLOAD DEPARTMENT IMAGE')}
                      isEdit
                      onChangeText={onChangeText}
                      showModalFun={showModalFun}
                      list={lookup ?? data?.options}
                      style={{
                        paddingHorizontal: 0,
                      }}
                      error={errors?.find(
                        (m) => m?.fieldName === data?.metadata.fieldName,
                      )}
                      value={returnValue}
                      disabled={id}
                    />
                  </View>
                )
              })}
            </View>
          )
        })}

        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            alignContent: 'flex-end',
            margin: spacing.spacing6,
            paddingBottom: spacing.spacing6,
            width: '100%',
          }}
        >
          <View style={{ flex: 2.5 }}>
            <Button
              mode='outlined'
              style={styles.button(colors)}
              textColor={colors.primary}
              variant='labelLarge'
              onPress={() => {
                if (
                  module === 'addDepartment' ||
                  module === 'updateDepartment'
                ) {
                  navigation.navigate('department-directory')
                } else if (module === 'addGraduation') {
                  navigation.navigate('graduation-directory')
                } else if (module === 'addDegree') {
                  navigation.navigate('degree-directory')
                }
              }}
            >
              Cancel
            </Button>
          </View>
          <View
            style={[
              styles.buttonView,
              {
                flex: 1,
              },
            ]}
          >
            <Button
              mode='contained'
              variant='labelLarge'
              isLoading={isLoading}
              onPress={() => {
                handleAdd().then((res) => {
                  if (res)
                    if (
                      module === 'addDepartment' ||
                      module === 'updateDepartment'
                    ) {
                      navigation.navigate('department-directory')
                    } else if (module === 'addGraduation') {
                      navigation.navigate('graduation-directory')
                    } else if (module === 'addDegree') {
                      navigation.navigate('degree-directory')
                    }
                })
              }}
            >
              {id ? 'Update' : 'Create'}
            </Button>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  image: {
    height: 266,
    width: 360,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  button: (colors) => ({
    borderColor: colors.primary,
    width: 120,
  }),
  buttonView: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
  },
})
