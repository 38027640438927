import React from 'react'
import { StyleSheet, View, useWindowDimensions } from 'react-native'
import { useTheme } from 'react-native-paper'

import {
  CalendarInput,
  CheckBox,
  DropDown2,
  Dropdown,
  PhoneNumberInput,
  SearchInput,
  Text,
  TextInput,
  UploadDocument,
} from '@camped/components'
import { ScreenLayout } from '@camped/utils'

import { processPhoneValue } from '../utils/process-phone-value'
import { PhotoField } from './components/photo'
import { SemesterButton } from './components/semester-button'

const DynamicField = (props) => {
  const { colors, spacing } = useTheme()
  const {
    attachment,
    checkBoxStyle,
    checked,
    countryCode,
    data,
    disabled,
    error,
    fieldName,
    fileInputRef,
    getSearchData,
    handleAddImage,
    hasUploadPromptText,
    index,
    isEdit,
    list,
    multiSelect,
    onChangeText,
    onCountrySelect,
    onFocus = () => {},
    pickPDF,
    searchData,
    setChecked,
    showModalFun = () => {},
    style,
    user,
    value,
    dropDownWidth,
    selectedSem,
    setSelectedSem,
    handleFileChange,
    pdfInputRef,
  } = props
  const { width } = useWindowDimensions()
  const isDesktop = ScreenLayout.isWeb(width)
  const renderInput = (type) => {
    if (data?.component === 'CalendarInput') {
      return (
        <CalendarInput
          dob={data?.fieldName === 'dateOfBirth'}
          key={data?.fieldName}
          label={`${data?.label} 
        ${data?.isMandatory ? '*' : ''}`}
          mode='outlined'
          placeholder={data?.placeholder}
          value={value}
          onChangeText={(text) =>
            onChangeText({ ...data, indexOrder: index }, text, fieldName)
          }
          disabled={disabled}
          error={error}
          onFocus={onFocus}
          style={style}
        />
      )
    }

    if (data?.component === 'selectSem') {
      return (
        <SemesterButton
          selectedSem={selectedSem}
          item={user?.totalSemesters?.value}
          setSelectedSem={setSelectedSem}
        />
      )
    }

    if (data?.component === 'Dropdown') {
      const stringValue = value?.displayName ?? value?.toString() ?? ''
      return (
        <Dropdown
          label={`${data?.label} 
        ${data?.isMandatory ? '*' : ''}`}
          mode='outlined'
          value={stringValue.toString()}
          list={list}
          error={error}
          onFocus={onFocus}
          setValue={(text) =>
            onChangeText({ ...data, indexOrder: index }, text, fieldName)
          }
          style={style}
          disabled={disabled}
        />
      )
    }

    if (data?.component === 'DropDown2') {
      const stringValue = value?.displayName ? value?.displayName : value ?? ''
      return (
        <DropDown2
          label={`${data?.label} 
        ${data?.isMandatory ? '*' : ''}`}
          value={stringValue}
          list={list}
          error={error}
          onFocus={onFocus}
          setValue={(text) =>
            onChangeText(data, text, fieldName ?? data?.fieldName)
          }
          placeholder={data?.placeholder}
          disabled={disabled}
          multiSelect={multiSelect}
          dropDownWidth={dropDownWidth}
        />
      )
    }

    if (data?.component === 'CustomDropdownMultiSelect') {
      const stringValue = value?.displayName ? value?.displayName : value ?? ''
      return (
        <DropDown2
          label={`${data?.label} 
        ${data?.isMandatory ? '*' : ''}`}
          value={stringValue}
          list={list}
          error={error}
          onFocus={onFocus}
          placeholder={data?.placeholder}
          setValue={(text) =>
            onChangeText(data, text, fieldName ?? data?.fieldName)
          }
          disabled={disabled}
          multiSelect
        />
      )
    }

    if (data?.component === 'AddressTextInput') {
      return (
        <TextInput
          key={data?.fieldName}
          label={`${data?.label} 
        ${data?.isMandatory ? '*' : ''}`}
          placeholder={data?.placeholder}
          value={value}
          onChangeText={(text) =>
            onChangeText({ ...data, indexOrder: index }, text, fieldName)
          }
          error={error}
          onFocus={() => {
            onFocus()
          }}
          style={{
            width: isDesktop
              ? 2 * (style.width + spacing.spacing5)
              : style.width,
            minWidth: isDesktop
              ? 2 * (style.width + spacing.spacing5)
              : style.width,
          }}
          disabled={disabled}
        />
      )
    }

    if (data?.component === 'PhoneNumberInput') {
      const code = String(user?.callingCode).length
      return (
        <PhoneNumberInput
          inputStyle={{ marginBottom: spacing.spacing5 }}
          label={`${data?.label} 
        ${data?.isMandatory ? '*' : ''}`}
          value={processPhoneValue(value, code, user)}
          onChangeText={(text) => {
            if (!Number.isNaN(text)) {
              onChangeText({ ...data, indexOrder: index }, text, fieldName)
            }
          }}
          error={error}
          countryCode={countryCode}
          keyboardType='numeric'
          maxLength={data?.maxLength}
          onCountrySelect={onCountrySelect}
          placeholder={data?.placeholder}
          disabled={disabled}
        />
      )
    }

    if (type === 'number') {
      return (
        <TextInput
          key={data?.fieldName}
          label={`${data?.label} 
        ${data?.isMandatory ? '*' : ''}`}
          placeholder={data?.placeholder}
          value={value || ''}
          maxLength={data?.maxLength}
          onChangeText={(text) => {
            if (!Number.isNaN(text))
              onChangeText(
                { ...data, indexOrder: index },
                text.replace(/\W/g, ''),
                fieldName,
              )
          }}
          keyboardType='numeric'
          error={error}
          onFocus={onFocus}
          disabled={disabled}
          style={style}
        />
      )
    }

    if (data?.component === 'SearchInput') {
      return (
        <SearchInput
          label={data?.label}
          placeholder={data?.placeholder}
          inputValue={value?.firstName ? value?.firstName : value ?? ''}
          fieldName={fieldName}
          setInputValue={onChangeText}
          error={error}
          data={data}
          onFocus={() => {
            onFocus()
          }}
          style={style}
          searchData={searchData}
          getSearchData={getSearchData}
          disabled={disabled}
        />
      )
    }

    if (data?.component === 'TextArea') {
      return (
        <TextInput
          key={data?.fieldName}
          label={`${data?.label} 
        ${data?.isMandatory ? '*' : ''}`}
          placeholder={data?.placeholder}
          value={value}
          onChangeText={(text) =>
            onChangeText({ ...data, indexOrder: index }, text, fieldName)
          }
          error={error}
          onFocus={() => {
            onFocus()
          }}
          multiline
          numberOfLines={8}
          style={{
            width: '100%',
          }}
          disabled={disabled}
        />
      )
    }
    if (data?.component === 'upload') {
      return (
        <UploadDocument
          pdfInputRef={pdfInputRef}
          handleFileChange={handleFileChange}
          data={data}
          pickPDF={pickPDF}
        />
      )
    }

    return (
      <TextInput
        key={data?.fieldName}
        label={`${data?.label} 
        ${data?.isMandatory ? '*' : ''}`}
        placeholder={data?.placeholder}
        value={value}
        onChangeText={(text) =>
          onChangeText({ ...data, indexOrder: index }, text, fieldName)
        }
        error={error}
        onFocus={() => {
          onFocus()
        }}
        style={style}
        disabled={disabled}
      />
    )
  }

  if (data?.valueType === 'photo') {
    return (
      <PhotoField
        data={data}
        fieldName={fieldName}
        fileInputRef={fileInputRef}
        handleAddImage={handleAddImage}
        hasUploadPromptText={hasUploadPromptText}
        onChangeText={onChangeText}
        showModalFun={showModalFun}
        attachment={attachment}
      />
    )
  }

  if (data?.valueType === 'CheckBox')
    return (
      <View
        style={StyleSheet.flatten([
          {
            flexDirection: 'row',
            marginBottom: spacing.spacing5,
            marginRight: spacing.spacing2,
            justifyContent: isDesktop ? 'center' : 'flex-start',
            alignItems: 'center',
            flex: isDesktop ? 1 : 0,
          },
          checkBoxStyle,
        ])}
      >
        <CheckBox
          onPress={() => {
            setChecked((prev) => {
              const newState = { ...prev }
              newState[data?.displayOrder] = !prev[data?.displayOrder]
              return newState
            })
            onChangeText(
              { ...data, indexOrder: index },
              checked?.[data?.displayOrder] ? 'checked' : 'unchecked',
              fieldName,
            )
          }}
          status={checked?.[data?.displayOrder] ? 'checked' : 'unchecked'}
        />
        <Text
          style={{ color: colors.onSurface1, marginLeft: spacing.spacing4 }}
        >
          {data?.label}
        </Text>
      </View>
    )

  if (isEdit) {
    return (
      <View
        style={{
          marginBottom:
            data?.component === 'PhoneNumberInput' ||
            data?.component === 'selectSem'
              ? spacing.spacing1
              : spacing.spacing4,
          paddingRight: style?.paddingHorizontal ?? spacing.spacing4,
        }}
      >
        {renderInput(data?.valueType)}
      </View>
    )
  }
}

export default DynamicField
