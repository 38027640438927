import React from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { spacing } from '@camped/theme-provider'

export const SemesterButton = ({ selectedSem, item, setSelectedSem }) => {
  const { colors } = useTheme()
  const itemElements = []
  for (let i = 0; i < Number(item); i += 1) {
    itemElements.push(i + 1)
  }

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      style={{
        marginBottom: spacing.spacing4,
      }}
    >
      {itemElements.map((sem) => (
        <TouchableOpacity
          onPress={() => {
            setSelectedSem(sem)
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              borderRadius: 8,
              borderWidth: 1,
              marginRight: spacing.spacing3,
              borderColor:
                selectedSem === sem ? colors.primary : colors.onSurface1,
              backgroundColor:
                selectedSem === sem ? colors.primary : colors.surface1,
              paddingVertical: 10,
              paddingHorizontal: 15,
            }}
          >
            <Text
              style={{
                fontSize: 18,
                color:
                  selectedSem === sem ? colors.onPrimary : colors.onSurface1,
              }}
            >
              {sem}
            </Text>
          </View>
        </TouchableOpacity>
      ))}
    </ScrollView>
  )
}
