import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text, TextInput } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

import { onChange } from '../../utils/helpers'
import BoxContainer from '../box-container'

export const EditQuestionPaper = (props) => {
  const { colors } = useTheme()
  const { question, setShowQuestionPaper, handleSubmit } = props
  const questionData = JSON.parse(question?.generatedQuestion || '{}')?.sections

  const [cleanedData, setCleanedData] = useState(questionData)
  if (!question?.errors) {
    return (
      <React.Fragment key={question._id}>
        <TouchableOpacity
          style={styles.backButton}
          onPress={() => {
            setShowQuestionPaper('')
          }}
        >
          <Icon
            name='BackArrow'
            color={colors.onSurface1}
            width={24}
            height={24}
          />
          <Text style={styles.backButtonText}>Back</Text>
        </TouchableOpacity>
        <View style={styles.mainView}>
          <BoxContainer
            title='Edit Question Paper'
            onCancel={() => {
              setCleanedData(questionData)
              setShowQuestionPaper('')
            }}
            onSave={() => handleSubmit({ cleanedData, question })}
          >
            {Object.entries(cleanedData)?.map(([key, item]) => (
              <View key={key} style={{ padding: spacing.spacing4 }}>
                <Text
                  variant='bodyMedium'
                  style={{
                    paddingBottom: spacing.spacing3,
                    paddingTop: spacing.spacing3,
                    paddingRight: spacing.spacing1,
                    fontWeight: '800',
                    color: colors.onSurface1,
                    justifyContent: 'center',
                  }}
                >
                  {`${item?.sectionName}`}
                </Text>
                {item?.sectionType === 'Normal' &&
                  item?.questions?.map((noramlQuestion, normalIndex) => (
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <TextInput
                        value={noramlQuestion?.question}
                        label={`Question ${noramlQuestion?.questionNumber}`}
                        style={{
                          marginBottom: spacing.spacing6,
                          marginRight: spacing.spacing3,
                          width: '100%',
                          height: 35,
                          paddingTop: 0,
                        }}
                        onChangeText={(text) =>
                          onChange({
                            text,
                            questionIndex: normalIndex,
                            key,
                            cleanedData,
                            setCleanedData,
                            type: item?.sectionType,
                          })
                        }
                      />
                    </View>
                  ))}
                {item?.sectionType === 'Either/Or' &&
                  item?.questions?.map((questions, questionskey) => (
                    <React.Fragment key={questionskey}>
                      {questions?.question?.map(
                        (EitherOrQuestions, EitherOrQuestionskey) => (
                          <>
                            <TextInput
                              value={EitherOrQuestions?.question}
                              label={`Question ${EitherOrQuestions?.questionNumber}`}
                              style={{
                                marginBottom: spacing.spacing6,
                                width: '100%',
                                height: 35,
                                paddingTop: 0,
                              }}
                              onChangeText={(text) =>
                                onChange({
                                  text,
                                  questionIndex: questionskey,
                                  subquestionIndex: EitherOrQuestionskey,
                                  key,
                                  type: item?.sectionType,
                                  cleanedData,
                                  setCleanedData,
                                })
                              }
                            />
                            {EitherOrQuestionskey === 0 && (
                              <Text
                                variant='bodyMedium'
                                style={[
                                  styles.noramlQuestionText(colors),
                                  {
                                    marginLeft: spacing.spacing6,
                                    paddingBottom: spacing.spacing6,
                                  },
                                ]}
                              >
                                (or)
                              </Text>
                            )}
                          </>
                        ),
                      )}
                    </React.Fragment>
                  ))}
                {item.sectionType === 'Multi-Choice' &&
                  item?.questions.map((questions, questionsIndex) => (
                    <View
                      style={{
                        justifyContent: 'flex-start',
                        width: '100%',
                        marginBottom: spacing.spacing5,
                      }}
                    >
                      <TextInput
                        value={questions?.question}
                        label={`Question ${questions?.questionNumber}`}
                        style={{
                          marginBottom: spacing.spacing3,
                          marginRight: spacing.spacing3,
                          width: '100%',
                          height: 35,
                          paddingTop: 0,
                        }}
                        onChangeText={(text) =>
                          onChange({
                            text,
                            questionIndex: questionsIndex,
                            key,
                            type: item?.sectionType,
                            cleanedData,
                            setCleanedData,
                          })
                        }
                      />
                      <View
                        style={{
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        {questions?.options?.map((option, optionIndex) => (
                          <TextInput
                            value={option}
                            label={`Option ${optionIndex + 1}`}
                            style={{
                              width: '30%',
                              marginBottom: spacing.spacing3,
                              marginRight: spacing.spacing3,
                              height: 35,
                            }}
                            onChangeText={(text) =>
                              onChange({
                                text,
                                questionIndex: questionsIndex,
                                key,
                                isOption: true,
                                type: item?.sectionType,
                                subquestionIndex: optionIndex,
                                cleanedData,
                                setCleanedData,
                              })
                            }
                          />
                        ))}
                      </View>
                    </View>
                  ))}
              </View>
            ))}
          </BoxContainer>
        </View>
      </React.Fragment>
    )
  }
}

const styles = StyleSheet.create({
  backButton: {
    display: 'flex',
    flexDirection: 'row',
    margin: spacing.spacing4,
    alignItems: 'center',
  },
  backButtonText: { marginLeft: spacing.spacing2 },
  semesterText: (colors) => ({
    flexDirection: 'row',
    fontWeight: '700',
    color: colors.onSurface1,
    fontSize: 18,
    justifyContent: 'center',
    textTransform: 'capitalize',
    paddingBottom: spacing.spacing4,
  }),
  mainView: {
    width: '100%',
    marginBottom: 24,
    padding: spacing.spacing3,
  },
  rowView: {
    width: '100%',
    flexDirection: 'row',
    padding: spacing.spacing3,
    justifyContent: 'space-between',
  },
  rowText: (colors) => ({
    fontWeight: '200',
    color: colors.onSurface1,
    paddingBottom: spacing.spacing6,
  }),
  markView: {
    flex: 1,
    paddingBottom: spacing.spacing3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '70%',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  markText: (colors) => ({
    color: colors.onSurface1,
    opacity: 1,
    textTransform: 'capitalize',
    fontWeight: '700',
    fontSize: 18,
  }),
  textStyle: (colors) => ({
    fontWeight: '200',
    color: colors.onSurface1,
    justifyContent: 'center',
  }),
  answerOnlyText: (colors) => ({
    color: colors.onSurface1,
    width: '100%',
    opacity: 1,
    textTransform: 'capitalize',
    fontWeight: '700',
    fontSize: 18,
    paddingBottom: spacing.spacing2,
  }),
  noramlQuestionText: (colors) => ({
    width: '100%',
    fontWeight: '200',
    color: colors.onSurface1,
  }),
})
