import { useAtom } from 'jotai'
import React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import CountryPicker, {
  DARK_THEME,
  DEFAULT_THEME,
} from 'react-native-country-picker-modal'
import { useTheme } from 'react-native-paper'

import { themeSwitchAtom } from '@camped/jotai-storage'
import { ScreenLayout } from '@camped/utils'

import back from 'assets/arrow.png'

import TextInput from '../TextInput'

const PhoneInput = (props) => {
  const { label, inputStyle, onCountrySelect, countryCode = '', value } = props
  const { spacing, colors } = useTheme()
  const { width } = useWindowDimensions()
  const isDesktop = ScreenLayout.isWeb(width)
  const [themeState] = useAtom(themeSwitchAtom)
  const [visible, setVisible] = React.useState(false)
  return (
    <View style={styles.row}>
      <View
        style={{
          width: 99,
          paddingRight: spacing.spacing4,
          opacity: props.disabled ? 0.3 : 1,
        }}
      >
        <TouchableOpacity
          style={styles.countryPickerContainer(spacing, colors)}
          onPress={() => setVisible(true)}
          disabled={props.disabled}
        >
          <CountryPicker
            {...{
              countryCode,
              withFilter: true,
              withFlag: true,
              withCallingCodeButton: true,
              withCountryNameButton: false,
              withAlphaFilter: true,
              withCallingCode: true,
              withEmoji: true,
              onSelect: onCountrySelect,
            }}
            closeButtonImage={back}
            visible={visible}
            modalProps={{
              animationType: 'slide',
            }}
            theme={themeState === 'DARK' ? DARK_THEME : DEFAULT_THEME}
            flatListProps={{
              style: {
                marginLeft: isDesktop ? spacing.spacing13 : spacing.spacing5,
              },
            }}
            onClose={() => setVisible(false)}
          />
        </TouchableOpacity>
      </View>
      <View style={{ minWidth: isDesktop ? 199 : 261, flex: 1 }}>
        <TextInput
          label={label}
          value={value}
          style={{ ...inputStyle }}
          {...props}
        />
      </View>
    </View>
  )
}

export default PhoneInput

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  countryPickerContainer: (spacing, colors) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderWidth: 1,
    alignItems: 'center',
    borderRadius: 4,
    borderColor: colors.onBackground,
    backgroundColor: colors.surface2,
    height: 48,
    marginTop: spacing.spacing3,
    paddingLeft: spacing.spacing3,
  }),
})
