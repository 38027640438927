import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FlatList,
  Modal,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Button, Divider, Row, Text } from '@camped/components'
import { Icon } from '@camped/icons'
import ProfileIcon from '@camped/shared-component-avatar'

const DesktopView = (props) => {
  const {
    allData,
    inputRef,
    setInputValue,
    inputValue,
    visible,
    setVisible,
    dropDownWidth,
    dropdownTop,
    dropdownLeft,
    getSearchData,
    DropdownButton,
    selectedTab,
    setSelectedTab,
    toggleDropdown,
    handleNavigate,
  } = props
  const { spacing, colors } = useTheme()
  const { t } = useTranslation()
  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={styles.item(spacing)}
      onPress={() => handleNavigate(item)}
    >
      <ProfileIcon size={32} />
      <View style={{ marginLeft: spacing.spacing3 }}>
        <Text
          variant='titleMedium'
          style={{
            color: colors.onBackground,
          }}
        >
          {`${item?.firstName} ${item?.lastName}`}
        </Text>
        <Text
          variant='titleSmall'
          style={{
            color: colors.outline,
          }}
        >
          {item?.registrationNumber || item?.employeeId}
        </Text>
      </View>
    </TouchableOpacity>
  )

  const renderDropdown = () => (
    <Modal
      visible={visible}
      transparent
      animationType='none'
      style={{
        marginTop: spacing.spacing12,
      }}
    >
      <TouchableOpacity
        style={styles.overlay}
        onPress={() => {
          setVisible(false)
        }}
      />

      <View style={[styles.dropdown, { top: dropdownTop, left: dropdownLeft }]}>
        <Row
          style={{
            height: 40,
            width: 300,
            borderWidth: 1,
            borderColor: colors.surfaceVariant,
            backgroundColor: colors.surface1,
            borderRadius: 8,
          }}
        >
          <View style={{ flex: 1, height: '100%', width: '100%' }}>
            <TextInput
              autoCorrect={false}
              style={{
                width: '90%',
                height: '100%',
                border: 'none',
                outline: 'none',
                flex: 1,
                paddingBottom: spacing.spacing2,
                paddingLeft: spacing.spacing5,
                color: colors.onBackground,
              }}
              ref={inputRef}
              onLayout={() => inputRef.current.focus()}
              onFocus={() => {
                setVisible(true)
              }}
              onChangeText={(text) => {
                setInputValue(text)
                getSearchData(text)
              }}
              placeholderTextColor={colors.onSurfaceVariant}
              value={inputValue}
            />
          </View>

          <TouchableOpacity
            style={{ paddingRight: spacing.spacing5 }}
            onPress={() => setInputValue('')}
          >
            <Icon name='CloseIcon' width={14} color={colors.onSurfaceVariant} />
          </TouchableOpacity>
        </Row>
        <Surface
          style={{
            width: dropDownWidth,
            height: 400,
            borderRadius: 8,
            marginTop: spacing.spacing2,
            backgroundColor: colors.surface1,
          }}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{
                flexDirection: 'row',
                paddingHorizontal: spacing.spacing6,
                paddingVertical: spacing.spacing5,
              }}
            >
              <Button
                mode='outlined'
                onPress={() => {
                  setSelectedTab('student')
                  getSearchData(inputValue)
                }}
                textColor={
                  selectedTab === 'student'
                    ? colors.onPrimary
                    : colors.onSurface
                }
                labelStyle={{ marginVertical: 0 }}
                style={{
                  borderWidth: selectedTab === 'student' ? 0 : 1,
                  backgroundColor:
                    selectedTab === 'student' ? colors.primary : 'transparent',
                  borderColor: colors.onSurface,
                  height: 28,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {t('Global.STUDENT')}
              </Button>
              <Button
                mode='outlined'
                onPress={() => {
                  setSelectedTab('staff')
                  getSearchData(inputValue)
                }}
                textColor={
                  selectedTab === 'staff' ? colors.onPrimary : colors.onSurface
                }
                labelStyle={{ marginVertical: 0 }}
                style={{
                  borderWidth: selectedTab === 'staff' ? 0 : 1,
                  backgroundColor:
                    selectedTab === 'staff' ? colors.primary : 'transparent',
                  borderColor: colors.onSurface,
                  height: 28,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: spacing.spacing5,
                }}
              >
                {t('Global.STAFF')}
              </Button>
            </View>

            <Divider style={{ backgroundColor: colors.surface2 }} />
            <FlatList
              data={allData}
              renderItem={renderItem}
              keyExtractor={(item, index) => index.toString()}
            />
          </View>
        </Surface>
      </View>
    </Modal>
  )

  return (
    <View style={{ position: 'relative' }}>
      <TouchableOpacity ref={DropdownButton}>
        <Row
          style={{
            height: 40,
            width: 300,
            borderWidth: 1,
            borderColor: colors.surfaceVariant,
            borderRadius: 8,
            backgroundColor: colors.surface1,
          }}
        >
          <View style={{ flex: 1, height: '100%', width: '100%' }}>
            <TextInput
              style={{
                width: '90%',
                height: '100%',
                border: 'none',
                outline: 'none',
                flex: 1,
                fontSize: 15,
                marginLeft: spacing.spacing4,
                paddingTop: spacing.spacing3,
                color: colors.onBackground,
              }}
              multiline
              autoCorrect={false}
              placeholder={t('Global.SEARCH')}
              // onFocus={() => toggleDropdown()}
              // onBlur={() => setVisible(false)}
              onChangeText={(text) => {
                toggleDropdown()
                setInputValue(text)
                getSearchData(text)
                setSelectedTab('student')
              }}
              placeholderTextColor={colors.onSurfaceVariant}
              value={inputValue}
            />
          </View>
          <View style={{ paddingRight: spacing.spacing5 }}>
            <Icon name='Search' width={14} fill={colors.onSurface1} />
          </View>

          {inputValue.length > 0 ? (
            <TouchableOpacity
              style={{ paddingRight: spacing.spacing5 }}
              onPress={() => setInputValue('')}
            >
              <Icon name='Close' width={14} />
            </TouchableOpacity>
          ) : null}
        </Row>
      </TouchableOpacity>

      {renderDropdown()}
    </View>
  )
}
const styles = StyleSheet.create({
  dropdown: {
    position: 'absolute',
    width: 144,
    borderBottomLeftRadius: 18,
    borderBottomRightRadius: 18,
  },
  overlay: {
    flex: 1,
    height: '100%',
  },
  item: (spacing) => ({
    paddingHorizontal: spacing.spacing4,
    paddingVertical: spacing.spacing4,
    flexDirection: 'row',
    alignItems: 'center',
  }),
})

export default DesktopView
