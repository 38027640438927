import { useNavigationState } from '@react-navigation/native'
import { useAtom } from 'jotai'
import React, { Fragment, useEffect } from 'react'
import { useWindowDimensions } from 'react-native'

import { useQuery } from '@tanstack/react-query'

import { API } from '@camped/api'
import { menuItems as menuItemsAtom } from '@camped/jotai-storage'
import { ScreenLayout, SecureStore } from '@camped/utils'

import Layout from './src/components/desktop-layout-wrapper'
import { BottomNavLayout } from './src/components/mobile-bottom-nav-bar'

const publicRoutes = [
  'login',
  'reset-password',
  'campus',
  'forgot-password',
  'change-password',
]
const immersiveScreens = ['student-detail', 'isEdit', 'isAdd']

const LayoutComponent = ({ children }) => {
  const currentRoute = useNavigationState(
    (state) => state?.routes?.[state.routes.length - 1],
  )

  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout.isWeb(windowWidth)
  const [menuItems, setMenuItems] = useAtom(menuItemsAtom)
  const isMobile = !isDesktop

  const isPublicRoute = publicRoutes?.includes(currentRoute?.name || 'login')

  const isImmersiveScreens =
    immersiveScreens?.includes(currentRoute?.name) ||
    immersiveScreens.some(
      (item) =>
        typeof currentRoute?.params === 'object' &&
        Object.keys(currentRoute?.params).includes(item),
    )

  let LocalLayoutComponent = Fragment

  const { data: menuData } = useQuery(
    ['getMenuMetadataAPI'],
    async () => {
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)
      return API.getMenuMetaDataAPI({
        filter: {
          role: userDetail?.[0]?.userRoleId ?? '',
          tenant: userDetail?.[0]?.tenantId ?? '',
        },
      })
    },
    {
      enabled: !isPublicRoute,
      initialData: menuItems ?? [],
    },
  )

  useEffect(() => {
    if (menuData) {
      setMenuItems(menuData)
    }
  }, [menuData])

  useQuery(
    ['getScreenIds'],
    async () => API.getScreensByFilterAPI({ filter: {} }),
    {
      enabled: !isPublicRoute,
      initialData: [],
    },
  )

  if (!isPublicRoute && isDesktop) {
    LocalLayoutComponent = Layout
  } else if (!isPublicRoute && !isImmersiveScreens) {
    LocalLayoutComponent = isMobile ? BottomNavLayout : Layout
  }

  return (
    <LocalLayoutComponent
      menuData={menuData?.sort((a, b) => a.displayOrder - b.displayOrder)}
    >
      {children}
    </LocalLayoutComponent>
  )
}

export default LayoutComponent
