import { useAtom } from 'jotai'
import React, { Suspense, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { API } from '@camped/api'
import { Text } from '@camped/components'
import { domainAtom } from '@camped/jotai-storage'
import { ScreenLayout } from '@camped/utils'

import CoverImg from 'assets/Vadim2.png'

import {
  Campus,
  ChangePassword,
  ForgotPassword,
  Login,
  ResetPassword,
} from '../../components'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const AuthScreen = (props) => {
  const route = props?.route
  const [domainDetails] = useAtom(domainAtom)

  const bgImage = domainDetails?.backgroundImageURL
    ? { uri: domainDetails?.backgroundImageURL }
    : CoverImg

  const routeMap = {
    campus: <Campus />,
    login: <Login domainDetails={domainDetails} />,
    'forgot-password': <ForgotPassword />,
    'change-password': <ChangePassword domainDetails={domainDetails} />,
    'reset-password': <ResetPassword />,
  }
  const RenderComponent = routeMap[route?.name] || null

  const viewProps = {
    bgImage,
    data: domainDetails,
    RenderComponent,
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default AuthScreen
