export const stage = 'qa'
export const lmsDomain = 'dev2-lms.camped.academy'
export const awsDomain = `dev-auth.camped.academy`
export const domainURL = 'https://www.camped.academy'

export const mailTo =
  'mailto:support@camped.academy?subject=SendMail&body=Description'

export const privacyPolicyUrl = `${domainURL}/privacy-policy`
export const termsConditionUrl = `${domainURL}/terms-and-conditions`
export const increscoUrl = 'https://increscotech.com/'
export const sentryUrl =
  'https://ddac1e05aaff4453b53e39273aa02f1e@o4505561489145856.ingest.sentry.io/4505561500614656'

export const baseUrl = `https://${stage}.camped.academy`

export const lmsUrl = `https://apps.${lmsDomain}/learning/course/`
export const lmsStudioUrl = `https://studio.${lmsDomain}/course/`
export const acePrepUrl = 'https://dev-aceprep.camped.academy'

export const apiUrl = `https://dev-api.camped.academy/graphql`
export const academicsApiUrl = `https://dev-api.camped.academy/graphql`
export const marketPlaceUrl = `https://camped-ecommerce-frontend.vercel.app`

export const socialUrl = 'https://camped-social.vercel.app'
export const feedsUrl = 'https://dev-outreach.camped.academy'
