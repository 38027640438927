import { useIsFocused, useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Linking,
  Platform,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native'
import { Surface, Text, useTheme } from 'react-native-paper'

import { useQueryClient } from '@tanstack/react-query'

import { Auth } from '@camped/api'
import { Audit } from '@camped/audit'
import { Button, PasswordTextInput, TextInput } from '@camped/components'
import { privacyPolicyUrl } from '@camped/core/src/config'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout, testProps } from '@camped/utils'

import {
  EventAction,
  EventEntityType,
  EventScreen,
  EventType,
} from '../constants/audit'
import { Behavior, ScreenName, TypeofControl } from '../constants/testing'
import useLogin from '../hooks/use-login'
import { LoginSkeleton } from '../skeleton/LoginSkeleton'
import { ContactUs } from './ContactUs'

export const Login = (props) => {
  const { domainDetails } = props
  const { t } = useTranslation()
  const { colors, fonts } = useTheme()
  const navigation = useNavigation()
  const { width } = useWindowDimensions()
  const queryClient = useQueryClient()

  const [isLoading, setIsLoading] = useState(false)
  const isDesktop = ScreenLayout.isWeb(width)
  const signInMode = domainDetails?.signInMode

  const isFetching = queryClient.isFetching(['getDomainDetails'])

  const { login } = useLogin()

  const goToPolicy = (policy) => {
    if (Platform.OS === 'web') {
      Linking.openURL(policy)
    } else {
      navigation.navigate('web-view', {
        uri: policy,
        headerTitle: 'Privacy Policy',
      })
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userName: '',
      password: '',
    },
  })
  const onSubmit = async (data) => {
    Audit.logEvent({
      action: EventAction.CLICK,
      entityType: EventEntityType.CAMPUS,
      eventType: EventType.SIGNIN,
      details: {
        Screen: EventScreen.LOGIN,
        Email: data?.userName,
      },
    })
    login({ data, setIsLoading, domain: domainDetails })
  }

  if (isFetching) {
    return <LoginSkeleton />
  }

  const googleIcon = () => <Icon name='Google' />

  return (
    <View>
      {signInMode?.toLowerCase() === 'email' ? (
        <>
          <Text
            variant={isDesktop ? 'headlineLarge' : 'headlineSmall'}
            style={{
              color: colors.onSurfaceVariant,
            }}
          >
            {t('Auth.SIGN_IN')}
          </Text>
          <View style={styles.container(spacing, isDesktop)}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  label={t('Auth.USERNAME_OR_EMAIL')}
                  placeholder={t('Auth.USERNAME_OR_EMAIL')}
                  right={<TextInput.Affix />}
                  style={{
                    backgroundColor: colors.surface2,
                  }}
                  autoFocus
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  error={errors?.userName}
                  {...testProps(
                    `${ScreenName.LOGIN}_${TypeofControl.INPUT}_${Behavior.EMAIL}`,
                  )}
                />
              )}
              name='userName'
              rules={{
                required: 'UserName / Email is Required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Please Enter Valid Email',
                },
              }}
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <PasswordTextInput
                  label={t('Auth.PASSWORD')}
                  placeholder={t('Auth.PASSWORD')}
                  right={<TextInput.Affix />}
                  secureTextEntry
                  onBlur={onBlur}
                  style={{
                    backgroundColor: colors.surface2,
                    marginTop: spacing.spacing4,
                  }}
                  value={value}
                  onChangeText={onChange}
                  method={handleSubmit}
                  error={errors?.password}
                  {...testProps(
                    `${ScreenName.LOGIN}_${TypeofControl.INPUT}_${Behavior.PASSWORD}`,
                  )}
                  testName={`${ScreenName.LOGIN}_${TypeofControl.BUTTON}_${Behavior.CLICK}`}
                />
              )}
              name='password'
              rules={{
                required: 'Password is Required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long',
                },
              }}
            />

            <Surface
              elevation={0}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: spacing.spacing4,
              }}
            >
              <Text
                style={{
                  color: colors.primary,
                  margin: 0,
                  ...(isDesktop ? fonts.labelLarge : fonts.bodyMedium),
                }}
                onPress={() => navigation.navigate('forgot-password')}
                {...testProps(
                  `${ScreenName.LOGIN}_${TypeofControl.BUTTON}_${Behavior.NAVIGATE}`,
                )}
              >
                {t('Auth.FORGOT_PASSWORD')}
              </Text>
              <Button
                loading={isLoading}
                disabled={isLoading}
                onPress={handleSubmit(onSubmit)}
                {...testProps(
                  `${ScreenName.LOGIN}_${TypeofControl.BUTTON}_${Behavior.SUBMIT}`,
                )}
              >
                {t('Auth.SIGN_IN')}
              </Button>
            </Surface>
          </View>
        </>
      ) : (
        <Button
          icon={googleIcon}
          mode='outlined'
          style={{
            marginTop: spacing.spacing4,
            borderColor: colors.skeleton,
          }}
          labelStyle={{
            ...(isDesktop ? fonts.titleMedium : fonts.bodyMedium),
          }}
          onPress={() => {
            Auth.federatedSignIn({ provider: 'Google' })
          }}
          {...testProps(
            `${ScreenName.LOGIN}_${TypeofControl.BUTTON}_${Behavior.SUBMIT}`,
          )}
        >
          {t('Auth.GOOGLE_SIGN_IN')}
        </Button>
      )}

      <View
        style={{
          justifyContent: 'space-evenly',
          marginTop: spacing.spacing9,
        }}
      >
        <Text
          variant='bodyLarge'
          style={{
            color: colors.onSurfaceVariant,
            marginTop: spacing.spacing7,
            textAlign: 'center',
          }}
        >
          {t('Auth.TERMS_ACCEPTANCE')}{' '}
        </Text>
        <Text
          style={{
            textAlign: 'center',
            marginLeft: 0,
            ...fonts.bodyMedium,
            color: colors.primary,
          }}
          onPress={() => goToPolicy(privacyPolicyUrl)}
          {...testProps(
            `${ScreenName.LOGIN}_${TypeofControl.LINK}_${Behavior.NAVIGATE}`,
          )}
        >
          {t('Auth.PRIVACY_POLICY')}
        </Text>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: spacing.spacing5,
          }}
        >
          <Text
            variant='bodyLarge'
            style={{
              textAlign: 'center',
              color: colors.onSurfaceVariant,
            }}
          >
            {t('Auth.SUPPORT_ASSISTANCE')}{' '}
          </Text>
          <ContactUs />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: (isDesktop) => ({
    marginTop: isDesktop ? spacing.spacing7 : spacing.spacing6,
    width: '100%',
  }),
})
