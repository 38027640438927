import { useNavigation } from '@react-navigation/native'
import { useAtom } from 'jotai'
import { Platform } from 'react-native'

import { API, Auth } from '@camped/api'
import {
  aceprepToken,
  pendingUserAtom,
  socialToken,
  userAtom,
  userRollAtom,
} from '@camped/jotai-storage'
import { SecureStore } from '@camped/utils'

import { getFCMToken } from '../utils/notificationHandler'

const useLogin = () => {
  const navigation = useNavigation()
  const [, setCurrentUser] = useAtom(userAtom)
  const [, setUser] = useAtom(pendingUserAtom)
  const [, setUserRoll] = useAtom(userRollAtom)
  const [, setAceprepToken] = useAtom(aceprepToken)
  const [, setSocialToken] = useAtom(socialToken)

  const storeFcmToken = async () => {
    if (Platform.OS === 'web') return
    const fcmToken = await getFCMToken()
    await API.addUserDeviceAPI({ deviceId: fcmToken })
  }

  const login = async ({ data, setIsLoading, domain, currentUser }) => {
    setIsLoading(true)
    const response = await Auth.login(
      data?.userName ?? currentUser?.username,
      data?.password,
    )
    if (response?.message) {
      toast.show(response?.message, {
        type: 'danger',
      })
      setIsLoading(false)
      return
    }

    setUser(response)
    if (response?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setIsLoading(false)
      navigation.navigate('change-password')
    } else if (response?.signInUserSession) {
      storeFcmToken()
      const tenant = await Auth.getTenantRoleDetails()
      const userRoleDetails = JSON.parse(tenant.userRoleDetails)
      if (userRoleDetails.some((item) => item.tenantId !== domain._id)) {
        setIsLoading(false)
        try {
          navigation.navigate('logout')
          toast.show('User is not in this domain', {
            type: 'danger',
          })
        } catch (error) {
          console.error(error)
        }
      }
      if (
        tenant &&
        userRoleDetails.some((item) => item.tenantId === domain._id)
      ) {
        SecureStore.setCookie('user-details', tenant)

        const tokenResponse = await API.getCampedPlatformTokensAPI({
          email: data?.userName ?? currentUser?.username,
        })
        setAceprepToken(tokenResponse.aceprep)
        setSocialToken(tokenResponse.social)

        const userRoles = JSON.parse(tenant.userRoleDetails)?.[0]
        const userId = tenant['custom:userId']
        setUser(userId)
        setCurrentUser(tenant)
        setUserRoll(userRoles?.userType.toLowerCase() || 'staff')
        setIsLoading(false)
        navigation.reset({
          index: 0,
          routes: [{ name: 'home' }],
        })
      }
    }
  }

  return { login }
}

export default useLogin
