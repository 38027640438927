import React from 'react'
import { useWindowDimensions } from 'react-native'
import { Button as RNPButton, useTheme } from 'react-native-paper'

import { ScreenLayout } from '@camped/utils'

const Button = (props) => {
  const { width } = useWindowDimensions()
  const isDesktop = ScreenLayout.isWeb(width)
  const { fonts } = useTheme()

  return (
    <RNPButton
      {...props}
      mode={props.mode || 'contained'}
      style={[{ borderRadius: 4 }, props.style]}
      labelStyle={[
        {
          ...(isDesktop ? fonts.titleMedium : fonts.bodyMedium),
        },
        props.labelStyle,
      ]}
    />
  )
}

export default Button
