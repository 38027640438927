import AsyncStorage from '@react-native-community/async-storage'
import Cookies from 'js-cookie'

export function deleteItemAsync(key) {
  return AsyncStorage.removeItem(key)
}

export function getItemAsync(key) {
  return AsyncStorage.getItem(key)
}

export function setItemAsync(key, data) {
  return AsyncStorage.setItem(key, data)
}

export function setCookie(name, value) {
  const options = {
    domain: '.camped.academy',
    path: '/',
    expires: 7,
    secure: false,
    sameSite: 'lax',
  }
  const data = JSON.stringify(value)
  return Cookies.set(name, data, options)
}
export function getCookie(name) {
  const data = Cookies.get(name)
  let parsedData
  if (data) {
    parsedData = JSON.parse(data)
  }
  return parsedData
}

export function removeCookie(name) {
  const removeItemOptions = {
    path: '/',
    domain: '.camped.academy',
  }
  return Cookies.remove(name, removeItemOptions)
}
