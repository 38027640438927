import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FlatList,
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTheme } from 'react-native-paper'

import { BottomDrawer, Button, CheckBox } from '@camped/components'
import { DynamicField } from '@camped/form-container'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

export const MobileAddEditDepartment = (props) => {
  const { colors, fonts } = useTheme()
  const { t } = useTranslation()

  const {
    fileInputRef,
    handleAdd,
    handleAddImage,
    moduleData,
    navigation,
    onChangeText,
    setShowModal,
    setUser,
    showModal,
    errors,
    user,
    id,
    checked,
    setChecked,
    getLookupData,
  } = props
  const [list] = useState([])
  const showModalFun = () => {
    setShowModal(true)
  }
  const renderItem = ({ item }) => (
    <TouchableOpacity
      onPress={() => {
        setUser({ ...user, picture: item.value })
        setShowModal(false)
      }}
      style={{
        marginTop: spacing.spacing5,
        flex: 0.25,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: 64,
          width: 64,
          borderRadius: 56,
          borderWidth: user?.picture === item?.value ? 2 : 0,
          borderColor: user?.picture === item?.value ? colors.primary : '',
          backgroundColor: colors.surface1,
        }}
      >
        <Image
          resizeMode='contain'
          source={item?.value}
          style={{
            height: 45,
            width: 45,
          }}
        />
        {user?.picture === item?.value && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: colors.primary,
              borderRadius: 12,
              padding: 4,
            }}
          >
            <Icon
              name='Selection'
              color={colors.onPrimary}
              style={{
                height: 12,
                width: 12,
                strokeWidth: 2,
              }}
            />
          </View>
        )}
      </View>
    </TouchableOpacity>
  )

  const renderModal = () => (
    <BottomDrawer
      showDrawer={showModal}
      setShowDrawer={setShowModal}
      drawerColor={colors.background}
    >
      <View style={{ paddingVertical: spacing.spacing5 }}>
        <View
          style={{
            alignSelf: 'center',
            height: spacing.spacing2,
            backgroundColor: colors.outline,
            width: spacing.spacing8,
            borderRadius: 10,
          }}
        />
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: spacing.spacing6,
            marginHorizontal: spacing.spacing5,
          }}
        >
          <Text
            variant='headlineSmall'
            style={{
              color: colors.onBackground,
              paddingVertical: spacing.spacing3,
              fontWeight: '500',
              fontSize: 20,
            }}
          >
            {t('Settings.SELECT_AVATARS')}
          </Text>
          <TouchableOpacity
            onPress={() => setShowModal(false)}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height: 32,
              width: 32,
            }}
          >
            <Icon
              color={colors.onBackground}
              name='CloseIcon'
              height={32}
              width={32}
            />
          </TouchableOpacity>
        </View>

        <FlatList
          data={list}
          keyExtractor={(item, index) => index.toString()}
          numColumns={4}
          renderItem={renderItem}
          contentContainerStyle={{
            backgroundColor: colors.surface,
            borderRadius: 8,
            marginBottom: spacing.spacing6,
            paddingHorizontal: spacing.spacing3,
            paddingBottom: 9,
            shadowOffset: { width: 1, height: 2 },
            shadowColor: colors.onSurface,
            shadowOpacity: 0.2,
            shadowRadius: 3,
            marginHorizontal: spacing.spacing5,
          }}
        />

        <View
          style={{
            color: colors.primary,
            borderRadius: 8,
            paddingVertical: spacing.spacing3,
            backgroundColor: colors.surface,
            height: spacing.spacing9,
            marginHorizontal: spacing.spacing5,
            marginBottom: 20,
          }}
        >
          <TouchableOpacity
            onPress={() => handleAddImage()}
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon
              name='ImageLibraryIcon'
              color={colors.onSurfaceVariant}
              style={{ height: spacing.spacing5, width: spacing.spacing5 }}
            />
            <Text
              style={{
                ...fonts.titleMedium,
                color: colors.onSurface,
                paddingHorizontal: spacing.spacing4,
              }}
            >
              {t('Settings.CHOOSE_LIBRARY')}
              {Platform.OS === 'web' && (
                <input
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  type='file'
                  id='attachment'
                  name='attachment'
                  accept='image/*'
                  onChange={({ target: { files } }) => {
                    onChangeText({ valueType: 'photo' }, files[0])
                    setShowModal(false)
                  }}
                />
              )}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </BottomDrawer>
  )

  return (
    <>
      {renderModal()}
      <View
        style={{
          height: '100%',
        }}
      >
        <Text
          style={{
            paddingBottom: spacing.spacing5,
            color: colors.onBackground,
            ...fonts.bodyLarge,
          }}
        >
          {t('Settings.NAME_AND_IMAGE_OF_DEPARTMENT')}
        </Text>
        {!id && (
          <View
            style={{
              alignItems: 'center',
              alignSelf: 'flex-start',
              backgroundColor: colors.surfaceVariant,
              borderRadius: spacing.spacing2,
              flexDirection: 'row',
              padding: spacing.spacing2,
              paddingHorizontal: spacing.spacing4,
            }}
          >
            <Icon
              name='Info'
              style={{
                height: 18,
                minWidth: 18,
              }}
              color={colors.onSurfaceVariant}
            />
            <Text
              style={{
                color: colors.onSurfaceVariant,
                padding: spacing.spacing4,
                ...fonts.labelMedium,
              }}
            >
              {t('Settings.REVIEW_AND_CONFIRM_DEPARTMENT')}
            </Text>
          </View>
        )}
        <View style={{ marginTop: 20 }}>
          {moduleData?.sections?.map((item) => {
            const sortFieds = item?.fields?.sort(
              (a, b) => a.metadata.displayOrder - b.metadata.displayOrder,
            )
            return (
              <View style={styles.row}>
                {sortFieds?.map((data) => {
                  const list = getLookupData(data?.metadata?.fieldName)
                  return (
                    <View
                      style={{
                        width: '100%',
                        marginBottom: 12,
                      }}
                    >
                      <DynamicField
                        attachment={user?.picture ? user?.picture : null}
                        data={data.metadata}
                        hasUploadPromptText={t('Upload Department Image')}
                        isEdit
                        onChangeText={onChangeText}
                        showModalFun={showModalFun}
                        list={list}
                        style={{
                          paddingHorizontal: 0,
                        }}
                        error={errors?.find(
                          (m) => m?.fieldName === data.metadata.fieldName,
                        )}
                        value={user?.name}
                        disabled={id}
                      />
                    </View>
                  )
                })}
              </View>
            )
          })}
        </View>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: spacing.spacing5,
            width: '100%',
          }}
        >
          <Button
            mode='outlined'
            style={styles.button(colors)}
            textColor={colors.primary}
            variant='labelLarge'
            onPress={() => {
              navigation.navigate('department-directory')
            }}
          >
            Cancel
          </Button>

          <Button
            mode='contained'
            variant='labelLarge'
            style={{
              width: 160,
              color: colors.primary,
              opacity: 1,
            }}
            onPress={() => {
              handleAdd().then((res) => {
                if (res) navigation.navigate('department-directory')
              })
            }}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  image: {
    height: 266,
    width: 360,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  button: (colors) => ({
    borderColor: colors.primary,
    marginRight: spacing.spacing5,
    width: 160,
  }),
})
