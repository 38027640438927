import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

export const UploadIcon = (props) => {
  const { onPress } = props
  const { colors } = useTheme()
  return (
    <TouchableOpacity
      onPress={() => onPress()}
      style={{
        alignItems: 'center',
        backgroundColor: colors.onPrimary,
        borderRadius: 32,
        bottom: 2,
        height: spacing.spacing6,
        justifyContent: 'center',
        position: 'absolute',
        right: 2,
        shadowColor: colors.onSurface,
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        width: spacing.spacing6,
      }}
    >
      <Icon
        name='Upload'
        color={colors.primary}
        style={{
          height: 14,
          width: 14,
          strokeWidth: 2,
        }}
      />
    </TouchableOpacity>
  )
}
