/* eslint-disable react/no-unstable-nested-components */
import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { List, useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'
import { typeScale } from '@camped/theme-provider/src/tokens'

const AppItems = ({ menuData, screenName }) => {
  const [active, setActive] = useState(0)

  const accordionList = menuData?.subMenuItems?.sort(
    (a, b) => a.displayOrder - b.displayOrder,
  )

  return (
    <List.Section style={{ paddingLeft: 4 }}>
      {accordionList?.map((item, index) => (
        <Accordion
          item={item}
          screenName={screenName}
          index={index}
          setActive={setActive}
          active={active}
        />
      ))}
    </List.Section>
  )
}

const Accordion = (props) => {
  const { item, screenName, index, active, setActive } = props
  const { colors, fonts } = useTheme()
  const navigation = useNavigation()
  const [, setHoveredIndex] = useState(null)
  const sortedListItem = item?.subMenuItems?.sort(
    (a, b) => a.displayOrder - b.displayOrder,
  )
  return (
    <List.Accordion
      key={index}
      title={item?.displayName}
      titleStyle={[
        typeScale.titleSmall,
        {
          fontSize: 15,
          color: colors.onSurface,
        },
      ]}
      id={item?.id}
      expanded={index === active}
      left={() => (
        <Icon
          name={item?.iconName}
          width={20}
          color={colors.onSurface}
          fill={colors.onPrimary}
          style={{
            opacity: 0.7,
          }}
        />
      )}
      right={() =>
        item.subMenuItems.length > 0 && (
          <Icon
            name={index === active ? 'MenuOpen' : 'MenuClose'}
            width={16}
            color={colors.onSurface}
          />
        )
      }
      style={styles.bgMenu}
      onPress={() => {
        if (item.subMenuItems.length === 0) {
          navigation.navigate(item?.navigationPath)
        }
        if (index === active) {
          setActive(null)
        } else {
          setActive(index)
        }
      }}
    >
      {sortedListItem?.map((data, i) => (
        <View
          onMouseEnter={() => {
            setHoveredIndex(i)
          }}
          onMouseLeave={() => {
            setHoveredIndex(null)
          }}
          style={{ padding: 0 }}
        >
          <List.Item
            key={i}
            title={data?.displayName}
            titleStyle={[
              data?.activePath?.includes(screenName?.name)
                ? styles.menuItems
                : styles.menuText(fonts),
              {
                color: colors.onSurface,
                opacity: 0.7,
              },
            ]}
            style={
              data?.activePath?.includes(screenName?.name)
                ? styles.menItems
                : styles.menuItemsHover
            }
            onPress={() => navigation.navigate(data?.navigationPath)}
          />
        </View>
      ))}
    </List.Accordion>
  )
}

export default AppItems

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  menuText: (fonts) => ({
    ...fonts.titleMedium,
    fontSize: 15,
    fontWeight: 500,
  }),
  menuTextBold: {
    fontWeight: '700',
  },
  menuItems: {
    fontSize: 14,
    fontWeight: '700',
  },
  bgMenu: {
    paddingLeft: spacing.spacing3,
  },
  menItems: {
    paddingLeft: spacing.spacing6,
  },
  menuItemsHover: {
    paddingLeft: spacing.spacing6,
  },
})
