import { useAtom } from 'jotai'
import React, { Suspense, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { aceprepToken } from '@camped/jotai-storage'
import { ScreenLayout } from '@camped/utils'

import { acePrepUrl } from '../../config'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const AcePrepScreen = (props) => {
  const { path } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const [token] = useAtom(aceprepToken)

  const appTheme = theme?.dark ? 'dark' : 'light'

  const uri = `${acePrepUrl}/sign-in?source=camped&from=${path}&isApp=true&theme=${appTheme}&token=${token?.token}&email=${token?.email}`

  const viewProps = { uri }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  return (
    <Suspense fallback={<Text>{t('Hostel.LOADING')}</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default AcePrepScreen
