import { useNavigation } from '@react-navigation/native'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Text } from '@camped/components'
import { ScreenLayout } from '@camped/utils'

import { useGlobalSearchData } from '../../hooks/useGlobalSearchData'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const GlobalSearch = () => {
  const [inputValue, setInputValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropDownWidth, setDropDownWidth] = useState(0)
  const [selectedTab, setSelectedTab] = useState('')
  const navigation = useNavigation()

  const DropdownButton = useRef()
  const inputRef = useRef()
  const type = selectedTab

  const { allData, refetch } = useGlobalSearchData({
    queryKey: ['search'],
    searchText: inputValue,
    type: selectedTab,
  })

  const getSearchData = useMemo(() => AwesomeDebouncePromise(refetch, 1000), [])

  useEffect(() => {
    if (inputValue.length === 0) {
      setVisible(false)
    }
  }, [inputValue])

  useEffect(() => {
    if (!selectedTab) return
    refetch()
  }, [selectedTab])

  const toggleDropdown = () => {
    if (visible) {
      setVisible(false)
      return
    }
    if (!visible) {
      DropdownButton?.current?.measure((_fx, _fy, _w, h, _px, py) => {
        setDropdownTop(py)
        setDropDownWidth(_w)
        setDropdownLeft(_px)
      })
      setVisible(true)
    }
  }

  const handleNavigate = (item) => {
    setVisible(false)
    const route =
      type === 'student' ? 'view-student-details' : 'view-staff-details'
    const params = {
      id: item?._id,
      firstName: item?.firstName,
    }
    navigation.navigate(route, params)
  }
  const viewProps = {
    allData,
    inputRef,
    setInputValue,
    inputValue,
    visible,
    setVisible,
    dropDownWidth,
    dropdownTop,
    dropdownLeft,
    getSearchData,
    DropdownButton,
    selectedTab,
    setSelectedTab,
    toggleDropdown,
    handleNavigate,
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default GlobalSearch
