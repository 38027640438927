import { Linking, Platform } from 'react-native'

// eslint-disable-next-line import/no-relative-packages
import { lmsUrl } from '../../../packages/core/src/config'

export const getIsNavigate = (data, i) => {
  const isNavigate = data?.[i]?.find((item) => item?.metadata?.navigateTo)
    ?.metadata?.navigateTo
  return isNavigate
}
export const openURL = async (id) => {
  const redirectUrl = `${lmsUrl}${id}/home`
  if (Platform.OS === 'web') {
    window.open(redirectUrl, '_blank')
  } else {
    const supported = await Linking.canOpenURL(redirectUrl)
    if (supported) {
      await Linking.openURL(redirectUrl)
    }
  }
}
export const getId = (data, i, fieldName) => {
  const id = data?.[i]?.find(
    (item) => item?.metadata?.fieldName === fieldName,
  )?.value
  return id
}

export const getTitle = (data, i) => {
  const title = data?.[i]?.find(
    (item) =>
      item?.metadata?.fieldName === 'unitName' ||
      item?.metadata?.fieldName === 'subjectName',
  )
  return title
}
export const getSemester = (data) => {
  const semester = data?.find(
    (item) => item?.metadata?.fieldName === 'semester',
  )?.value
  return semester
}
export const validateUrl = (value) => {
  const urlFormat =
    /^((https?|ftp|smtp|Https):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/
  return urlFormat.test(value)
}
export const urlOpenMethod = (val) => {
  Linking.canOpenURL(val).then((supported) => {
    if (supported && validateUrl(val)) {
      let tempUrl = val
      if (!val.includes('https://' || 'Https://' || 'http://' || 'Http://')) {
        tempUrl = `https://${val}`
      }
      Linking.openURL(tempUrl)
    }
  })
}
export const toDetermineStyle = (val, key, style, isDesktop) => {
  let width = '48%'
  if (key?.includes('mailId') || val?.length > 32) {
    width = '96%'
  }
  if (isDesktop) return style
  return {
    ...style,
    width,
  }
}

export const onChange = ({
  text,
  questionIndex,
  key,
  isOption = false,
  subquestionIndex,
  type,
  cleanedData,
  setCleanedData,
}) => {
  if (type === 'Normal') {
    cleanedData[key].questions[questionIndex].question = text
  } else if (type === 'Either/Or') {
    cleanedData[key].questions[questionIndex].question[
      subquestionIndex
    ].question = text
  } else if (isOption && type === 'Multi-Choice') {
    cleanedData[key].questions[questionIndex].options[subquestionIndex] = text
  } else {
    cleanedData[key].questions[questionIndex].question = text
  }
  setCleanedData({
    ...cleanedData,
  })
  return null
}
