import { useNavigation, useNavigationState } from '@react-navigation/native'
import React, { Fragment } from 'react'
import {
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { Chip, Surface, useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout, sortData } from '@camped/utils'

import {
  getId,
  getIsNavigate,
  getSemester,
  getTitle,
  openURL,
} from '../../utils/helpers'
import { RenderDisplayField } from './RenderDisplayField'

export const SubWrapper = (props) => {
  const {
    mainValue,
    selectedTab,
    isAdd,
    onSave,
    title,
    handleFileChange,
    pdfInputRef,
    selectedSem,
    setSelectedSem,
    user,
  } = props

  const navigation = useNavigation()
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  const isDesktop = ScreenLayout.isWeb(width)
  const getWidth = (localMainValue) => {
    if (isDesktop)
      return {
        minWidth: isDesktop ? 260 : 0,
        color: colors.onPrimary,
        width:
          localMainValue?.metadata?.component === 'TextArea' ? '100%' : '25%',
      }
    return {
      minWidth: isDesktop ? 260 : 0,
      color: colors.onPrimary,
      width: '48%',
    }
  }
  const showTitle = [
    'view-course-details',
    'view-syllabus-details',
    'course-listing',
  ]
  const currentRoute = useNavigationState(
    (state) => state?.routes?.[state.routes.length - 1],
  )?.name

  if (
    Array.isArray(mainValue) &&
    Array.isArray(mainValue[0]) &&
    mainValue.length > 0 &&
    typeof mainValue === 'object'
  ) {
    return (
      <Fragment key={{ mainValue }}>
        {mainValue?.map((m, i) => {
          const localTitle = getTitle(mainValue, i)
          const id = getId(mainValue, i, '_id')
          const lmsSubjectId = getId(mainValue, i, 'lmsSubjectId')
          const isNavigate = getIsNavigate(mainValue, i)
          const subjectSemester = getSemester(m)
          if (
            localTitle?.metadata?.arrayName !== 'subjectDetails' ||
            selectedSem.toString() === subjectSemester
          )
            if (mainValue?.length > 1 || isAdd) {
              return (
                <TouchableOpacity
                  style={{
                    width: '100%',
                  }}
                  disabled={!isNavigate}
                  onPress={() => {
                    if (currentRoute === 'course-listing') {
                      openURL(lmsSubjectId)
                    } else if (isNavigate) {
                      navigation.navigate(isNavigate, {
                        id,
                        firstName: localTitle?.value,
                      })
                    }
                  }}
                >
                  <Surface style={styles.surface(colors)}>
                    {showTitle.includes(currentRoute) && localTitle && (
                      <Text variant='headlineSmall' style={styles.titleText}>
                        {localTitle?.value || ''}
                      </Text>
                    )}
                    <SubWrapper
                      key={i.toString()}
                      isAdd={isAdd}
                      mainValue={
                        Array.isArray(m) ? sortData(m, 'displayOrder') : m
                      }
                      selectedTab={selectedTab}
                      onSave={onSave}
                      title={localTitle}
                      handleFileChange={handleFileChange}
                      pdfInputRef={pdfInputRef}
                      user={user}
                      setSelectedSem={setSelectedSem}
                      selectedSem={selectedSem}
                    />
                  </Surface>
                </TouchableOpacity>
              )
            } else {
              return (
                <SubWrapper
                  key={i.toString()}
                  isAdd={isAdd}
                  mainValue={Array.isArray(m) ? sortData(m, 'displayOrder') : m}
                  selectedTab={selectedTab}
                  onSave={onSave}
                  title={localTitle}
                  handleFileChange={handleFileChange}
                  pdfInputRef={pdfInputRef}
                  user={user}
                  setSelectedSem={setSelectedSem}
                  selectedSem={selectedSem}
                />
              )
            }
          return null
        })}
      </Fragment>
    )
  }
  if (
    Array.isArray(mainValue) &&
    mainValue.length > 0 &&
    typeof mainValue === 'object'
  ) {
    return (
      <Fragment key={{ mainValue }}>
        {mainValue?.map((m, i) => (
          <SubWrapper
            key={i.toString()}
            isAdd={isAdd}
            mainValue={Array.isArray(m) ? sortData(m, 'displayOrder') : m}
            selectedTab={selectedTab}
            onSave={onSave}
            title={title}
            handleFileChange={handleFileChange}
            pdfInputRef={pdfInputRef}
            user={user}
            setSelectedSem={setSelectedSem}
            selectedSem={selectedSem}
          />
        ))}
      </Fragment>
    )
  }

  if (mainValue?.metadata?.valueType === 'chip') {
    let list = mainValue?.value
    list = list && list?.filter((str) => str !== '')
    return (
      <View
        style={{
          flexWrap: 'wrap',
          marginBottom: 24,
          marginRight: spacing.spacing4,
          flexDirection: 'row',
          width: '100%',
        }}
      >
        {mainValue?.value ? (
          list.map((item) => (
            <Chip
              selectedColor={colors.primary}
              onClose={() => {
                onSave(mainValue.metadata.fieldName, item, mainValue.metadata)
              }}
              style={{
                borderRadius: 50,
                backgroundColor: colors.primaryContainer,
                margin: 5,
                height: 35,
                alignSelf: 'flex-start',
              }}
            >
              {item?.replace(/[A-Z]/g, ' $&')?.trim()}
            </Chip>
          ))
        ) : (
          <Text>-</Text>
        )}
      </View>
    )
  }
  if (mainValue?.metadata?.valueType === 'upload') {
    return (
      <Surface style={styles.container(colors)}>
        <View style={styles.infoContainer}>
          <Text style={styles.fileName(colors)}>SampleDocument.pdf</Text>
        </View>
        <View style={styles.iconContainer}>
          <Text style={styles.size(colors)}>(20 KB)</Text>
          <TouchableOpacity onPress={{}}>
            <Icon
              color={colors.onBackground}
              width={18}
              height={18}
              name='EditIcon'
              style={styles.icon}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={{}}>
            <Icon
              color={colors.onBackground}
              width={18}
              height={18}
              name='TrashIcon'
              style={styles.icon}
            />
          </TouchableOpacity>
        </View>
        {Platform.OS === 'web' && (
          <input
            ref={pdfInputRef}
            style={{ display: 'none' }}
            type='file'
            id='attachment'
            name='attachment'
            accept='.pdf'
            onChange={handleFileChange}
            onClick={() => {}}
          />
        )}
      </Surface>
    )
  }
  if (mainValue?.permissions?.view && !mainValue?.metadata?.isHidden)
    return (
      <RenderDisplayField
        keys={mainValue?.metadata}
        user={user}
        setSelectedSem={setSelectedSem}
        selectedSem={selectedSem}
        value={mainValue?.value}
        style={getWidth(mainValue)}
      />
    )
}

const styles = StyleSheet.create({
  container: (colors) => ({
    marginBottom: 24,
    backgroundColor: colors.background,
    marginRight: 25,
    height: spacing.spacing9,
    borderRadius: spacing.spacing3,
    width: '98%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }),
  infoContainer: {
    paddingTop: spacing.spacing5,
    paddingLeft: spacing.spacing5,
  },
  fileName: (colors) => ({
    fontSize: 16,
    color: colors.onBackground,
  }),
  size: (colors) => ({
    fontSize: 14,
    color: colors.onBackground,
  }),
  surface: (colors) => ({
    paddingTop: spacing.spacing5,
    paddingLeft: spacing.spacing5,
    paddingRight: spacing.spacing5,
    marginBottom: spacing.spacing4,
    backgroundColor: colors.surface2,
    borderRadius: spacing.spacing2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
  }),
  titleText: {
    fontWeight: '700',
    paddingBottom: spacing.spacing5,
    textTransform: 'capitalize',
    width: '100%',
  },
  iconContainer: {
    marginBottom: 24,
    height: spacing.spacing9,
    marginRight: spacing.spacing4,
    paddingTop: spacing.spacing5,
    flexDirection: 'row',
  },
  icon: {
    alignSelf: 'center',
    paddingLeft: spacing.spacing6,
  },
})
