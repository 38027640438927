import React from 'react'
import { AnimatedCircularProgress } from 'react-native-circular-progress'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'

const CircularProgress = ({ value, text }) => {
  const { colors } = useTheme()
  return (
    <AnimatedCircularProgress
      size={84}
      width={12}
      fill={value}
      tintColor='rgba(32, 109, 14, 0.5)'
      backgroundColor={colors.background}
    >
      {() => <Text variant='titleMedium'>{text || value}</Text>}
    </AnimatedCircularProgress>
  )
}

export default CircularProgress
