import React, { useEffect } from 'react'
import { ScrollView } from 'react-native'

import { DesktopContainer } from '@camped/shared-container'

import { AddEditDepartment } from '../../components/department/addUpdateDepartment'
import { HeaderLeft } from '../../components/header'

const DesktopView = (props) => {
  const {
    fileInputRef,
    handleAdd,
    handleAddImage,
    moduleData,
    navigation,
    onChangeText,
    setShowModal,
    showModal,
    setUser,
    errors,
    user,
    id,
    checked,
    setChecked,
    module,
    isFetching,
    getLookupData,
    isLoading,
  } = props

  useEffect(() => {
    if (id) {
      navigation.setOptions({
        headerLeft: () => localHeaderLeft(user),
      })
    }
  }, [user])

  return (
    <DesktopContainer>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        showsVerticalScrollIndicator={false}
      >
        <AddEditDepartment
          isFetching={isFetching}
          fileInputRef={fileInputRef}
          handleAdd={handleAdd}
          handleAddImage={handleAddImage}
          moduleData={moduleData}
          navigation={navigation}
          onChangeText={onChangeText}
          setShowModal={setShowModal}
          setUser={setUser}
          showModal={showModal}
          errors={errors}
          user={user}
          id={id}
          checked={checked}
          setChecked={setChecked}
          module={module}
          getLookupData={getLookupData}
          isLoading={isLoading}
        />
      </ScrollView>
    </DesktopContainer>
  )
}

export default DesktopView

const localHeaderLeft = (user) => <HeaderLeft title={user?.name} hasBack />
