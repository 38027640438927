import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@camped/components'
import { ScreenLayout, SecureStore } from '@camped/utils'

import { marketPlaceUrl } from '../../config'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const MarketPlaceScreen = () => {
  const { t } = useTranslation()

  const [tenant, setTenant] = useState({})

  useEffect(() => {
    getTenant()
  }, [])

  const getTenant = async () => {
    const data = await SecureStore.getCookie('user-details')
    setTenant(data)
  }

  const uri = `${marketPlaceUrl}/account/login?source=camped&&email=${tenant?.email}&password=Medusa@123`

  const viewProps = { uri }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  return (
    <Suspense fallback={<Text>{t('Hostel.LOADING')}</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default MarketPlaceScreen
