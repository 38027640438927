/* eslint-disable no-nested-ternary */
import React from 'react'
import { ScrollView, View } from 'react-native'

import { Divider } from '@camped/components'
import { Loading } from '@camped/components/Loading'
import { DesktopContainer } from '@camped/shared-container'

import Filter from '../components/desktop-overlay-filter'
import EdgeCase from '../components/edge-case'
import { ListItem } from '../components/table/table-body'
import { Header } from '../components/table/table-row-header'
import { TopSection } from '../components/table/table-top-action'
import DisableUserModal from '../components/user-disable-model'
import ViewMoreButton from '../components/view-more/viewmore'

const DesktopView = (props) => {
  const {
    disableField,
    fetchNextPage,
    filterList,
    filterValue,
    getLookupData,
    goTo,
    handleCheckBox,
    handleInviteClick,
    handleMultipleCheckBox,
    hasMultipleSelect,
    hasNextPage,
    isFetchingNextPage,
    isFilterVisible,
    tableHeader,
    module,
    onSortSelected,
    refetch,
    selectedIDs,
    selectedSortValue,
    setErrors,
    setIsFilterVisible,
    sortList,
    totalCount,
    handleDropdownChange,
    userFetching,
    userLists,
    isModalVisible,
    setModalVisible,
    handleDisableUser,
    profileImageList,
    totalCountRefecth,
  } = props

  return (
    <DesktopContainer>
      <View
        style={{
          flex: 1,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
      >
        <TopSection
          module={module}
          onSortSelected={onSortSelected}
          selectedIDs={selectedIDs}
          selectedSortValue={selectedSortValue}
          setIsFilterVisible={setIsFilterVisible}
          sortList={sortList}
          fieldData={filterList}
          userLists={userLists}
          totalCount={totalCount}
          filterApplied={Object.keys(filterValue).length}
        />
        {userFetching && !isFetchingNextPage ? (
          <Loading />
        ) : !userLists || userLists.length === 0 ? (
          <EdgeCase module={module} />
        ) : (
          <>
            <Header
              tableHeader={tableHeader}
              hasMultipleSelect={hasMultipleSelect}
              selectedIDs={selectedIDs}
              totalCount={totalCount}
              module={module}
              handleMultipleCheckBox={handleMultipleCheckBox}
            />
            <ScrollView
              contentContainerStyle={{ flexGrow: 1 }}
              showsVerticalScrollIndicator={false}
            >
              <Divider style={{ height: 1 }} />
              <ListItem
                userLists={userLists}
                profileImageList={profileImageList}
                tableHeader={tableHeader}
                selectedIDs={selectedIDs}
                handleInviteClick={handleInviteClick}
                goTo={goTo}
                module={module}
                handleCheckBox={handleCheckBox}
              />
              {hasNextPage && (
                <ViewMoreButton
                  onPress={fetchNextPage}
                  isLoading={isFetchingNextPage}
                />
              )}
            </ScrollView>
          </>
        )}
        <Filter
          data={filterList}
          getLookupData={getLookupData}
          isFilterVisible={isFilterVisible}
          setIsFilterVisible={setIsFilterVisible}
          hasDelete
          refetch={refetch}
          handleDropdownChange={handleDropdownChange}
          filterValue={filterValue}
          disableField={disableField}
          setErrors={setErrors}
          module={module}
          totalCountRefecth={totalCountRefecth}
        />
      </View>
      <DisableUserModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        handleDisableUser={handleDisableUser}
      />
    </DesktopContainer>
  )
}

export default DesktopView
