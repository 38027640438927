import React from 'react'
import { ScrollView } from 'react-native'

import { DesktopContainer } from '@camped/shared-container'
import { WebView } from '@camped/shared-webview'

const DesktopView = (props) => {
  const { uri } = props

  return (
    <DesktopContainer>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <WebView uri={uri} />
      </ScrollView>
    </DesktopContainer>
  )
}
export default DesktopView
