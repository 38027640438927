import React, { Fragment } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import {
  CheckBox,
  DataTableCell,
  DataTableRow,
  Divider,
  Text,
} from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

import { Title } from './table-coulmn-body-name'

export const ListItem = (props) => {
  const {
    module,
    userLists,
    tableHeader,
    selectedIDs,
    handleInviteClick,
    goTo,
    handleCheckBox,
    profileImageList,
  } = props
  const { colors } = useTheme()
  const checkbox = (m) => (
    <View
      style={{
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        marginRight: spacing.spacing4,
      }}
    >
      <CheckBox
        status={selectedIDs.includes(m?._id) ? 'checked' : 'unchecked'}
        onPress={() => handleCheckBox(m?._id)}
      />
    </View>
  )
  return (
    <Fragment key={module}>
      {userLists?.map((m, i) => (
        <TouchableOpacity key={i} onPress={() => goTo('detail', m)}>
          <Fragment key={i}>
            <DataTableRow
              selectedIDs={selectedIDs}
              paddingHorizontal={spacing.spacing4}
              backgroundColor={
                selectedIDs.includes(m?._id) ? colors.surface2 : colors.surface1
              }
              handleInviteClick={() => handleInviteClick(m)}
              module={module}
            >
              {module === 'student' || module === 'staff' ? checkbox(m) : null}
              {tableHeader?.map((c, index) => {
                // eslint-disable-next-line no-param-reassign
                c = c?.metadata || c
                if (c?.valueType === 'image') {
                  return
                }
                if (c?.valueType === 'Array')
                  return (
                    <Fragment key={index}>
                      <DataTableCell
                        link
                        onClick={() => goTo('detail', m)}
                        flexN={3}
                      >
                        <Title
                          module={module}
                          item={m}
                          profileImageList={profileImageList}
                        />
                      </DataTableCell>
                    </Fragment>
                  )
                if (module === 'rolelisting') {
                  if (c?.fieldName === 'role') {
                    return (
                      <Fragment key={index}>
                        <DataTableCell
                          textStyle={{
                            textTransform: 'uppercase',
                            fontWeight: '300',
                          }}
                        >
                          {m?.role?.name}
                        </DataTableCell>
                      </Fragment>
                    )
                  }
                  if (
                    c?.fieldName === 'enabledOn' ||
                    c?.fieldName === 'disabledOn'
                  ) {
                    return (
                      <Fragment key={index}>
                        <DataTableCell
                          textStyle={{
                            textTransform: 'uppercase',
                            fontWeight: '300',
                          }}
                        >
                          {m?.[c?.fieldName]}
                        </DataTableCell>
                      </Fragment>
                    )
                  }
                  if (c?.fieldName === 'isActive') {
                    return (
                      <Fragment key={index}>
                        <DataTableCell
                          textStyle={{
                            textTransform: 'uppercase',
                            fontWeight: '300',
                            color: m?.[c?.fieldName]
                              ? colors.success
                              : colors.error,
                          }}
                        >
                          {m?.[c?.fieldName] ? 'ACTIVE' : 'INACTIVE'}
                        </DataTableCell>
                      </Fragment>
                    )
                  }
                  return (
                    <Fragment key={index}>
                      <DataTableCell
                        textStyle={{
                          textTransform: 'uppercase',
                          fontWeight: '300',
                        }}
                      >
                        {m?.user?.[c?.fieldName]}
                      </DataTableCell>
                    </Fragment>
                  )
                }
                if (
                  c?.valueType === 'Boolean' &&
                  c?.fieldName === 'isInvitationSent'
                ) {
                  return (
                    <Fragment key={index}>
                      <DataTableCell>
                        <View
                          style={{
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: 6,
                          }}
                        >
                          {m?.[c?.fieldName] ? (
                            <Icon
                              name='Selection'
                              style={{
                                height: 14,
                                width: 14,
                              }}
                              color={colors.success}
                            />
                          ) : (
                            <Icon
                              name='CloseIcon'
                              style={{
                                height: 14,
                                width: 14,
                              }}
                              color={colors.error}
                            />
                          )}
                          <Text
                            style={{
                              color: m?.[c?.fieldName]
                                ? colors.success
                                : colors.error,
                            }}
                          >
                            {m?.[c?.fieldName] ? 'Sent' : 'Not sent'}
                          </Text>
                        </View>
                      </DataTableCell>
                    </Fragment>
                  )
                }
                if (c?.referenceName) {
                  return (
                    <Fragment key={index}>
                      <DataTableCell
                        textStyle={{
                          textTransform: 'uppercase',
                          fontWeight: '300',
                        }}
                      >
                        {m?.[c?.referenceName]?.[c?.fieldName]}
                      </DataTableCell>
                    </Fragment>
                  )
                }
                if (
                  c?.fieldName === 'specialization' ||
                  c?.fieldName === 'graduationType' ||
                  c?.fieldName === 'yearOfStart' ||
                  c?.fieldName === 'academicYear'
                ) {
                  return (
                    <Fragment key={index}>
                      <DataTableCell
                        textStyle={{
                          textTransform: 'uppercase',
                          fontWeight: '300',
                        }}
                      >
                        {m?.[c?.fieldName]}
                      </DataTableCell>
                    </Fragment>
                  )
                }
                return (
                  <Fragment key={index}>
                    <DataTableCell>{m?.[c?.fieldName]}</DataTableCell>
                  </Fragment>
                )
              })}
            </DataTableRow>
          </Fragment>
          <Divider style={{ height: 1.5 }} />
        </TouchableOpacity>
      ))}
    </Fragment>
  )
}
