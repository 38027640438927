export const roleListing = {
  role: [],
  metadata: [
    {
      _id: '64e4d8e1678e27c05f0a1718',
      userPerson: 'STAFF',
      screenName: 'role-details',
      fieldName: 'role',
      placeholder: null,
      label: null,
      component: null,
      displayOrder: 3,
      errorMessage: null,
      isMandatory: null,
      maxLength: null,
      mode: null,
      multiline: null,
      valueType: 'String',
      displayName: 'Role',
      options: [],
      arrayName: null,
      link: false,
    },
    {
      _id: '64e4d9a3678e27c05f0a171c',
      userPerson: 'STAFF',
      screenName: 'role-details',
      fieldName: 'nameAndEmail',
      placeholder: null,
      label: null,
      component: null,
      displayOrder: 1,
      errorMessage: null,
      isMandatory: null,
      maxLength: null,
      mode: null,
      multiline: null,
      valueType: 'Array',
      displayName: 'Name And Email',
      options: [],
      arrayName: null,
      link: false,
    },
    {
      _id: '64e4d831678e27c05f0a1715',
      userPerson: 'STAFF',
      screenName: 'role-details',
      fieldName: 'enabledOn',
      placeholder: null,
      label: null,
      component: null,
      displayOrder: 2,
      errorMessage: null,
      isMandatory: null,
      maxLength: null,
      mode: null,
      multiline: null,
      valueType: 'String',
      displayName: 'Enabled On',
      options: [],
      arrayName: null,
      link: false,
    },
    {
      _id: '64e4d86c678e27c05f0a1716',
      userPerson: 'STAFF',
      screenName: 'role-details',
      fieldName: 'disabledOn',
      placeholder: null,
      label: null,
      component: null,
      displayOrder: 6,
      errorMessage: null,
      isMandatory: null,
      maxLength: null,
      mode: null,
      multiline: null,
      valueType: 'String',
      displayName: 'Disabled On',
      options: [],
      arrayName: null,
      link: true,
    },
    {
      _id: '64e4d970678e27c05f0a171b',
      userPerson: 'STAFF',
      screenName: 'role-details',
      fieldName: 'isActive',
      placeholder: null,
      label: null,
      component: null,
      displayOrder: 5,
      errorMessage: null,
      isMandatory: null,
      maxLength: null,
      mode: null,
      multiline: null,
      valueType: 'String',
      displayName: 'Employee Status',
      options: [],
      arrayName: null,
      link: false,
    },
  ],
  permissions: {
    view: true,
    edit: true,
    add: true,
    delete: true,
  },
}
