import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, ScrollView, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { useQueryClient } from '@tanstack/react-query'

import { BottomDrawer, Button, Text } from '@camped/components'
import { DynamicField } from '@camped/form-container'
import { Icon } from '@camped/icons'

import { capitalize } from '../utils/capitailize'

export const Filter = ({
  showDrawer,
  setShowDrawer,
  fieldData,
  handleDropdownChange,
  disableField,
  filterValue,
  hasDelete,
  setErrors,
  refetch = () => {},
  module,
  getLookupData,
}) => {
  const { colors, spacing } = useTheme()
  const queryClient = useQueryClient()
  const deleteStyle = { borderWidth: 0, color: colors.primary }
  const { t } = useTranslation()
  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      showsVerticalScrollIndicator={false}
    >
      <BottomDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        containerStyle={{
          height: (Dimensions.get('screen').height / 100) * 87,
          paddingBottom: spacing.spacing10,
          paddingLeft: spacing.spacing4,
        }}
        drawerColor={colors.surface1}
      >
        <View
          style={{
            paddingHorizontal: spacing.spacing6,
            paddingVertical: spacing.spacing6,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Text variant='headlineSmall' style={{ color: colors.onSurface1 }}>
            {t('Directory.FILTER_BY')}
          </Text>
          <TouchableOpacity onPress={() => setShowDrawer(false)}>
            <Icon
              name='CloseIcon'
              width={22}
              height={22}
              color={colors.onBackground}
            />
          </TouchableOpacity>
        </View>
        <View
          style={{
            flexDirection: 'column',
            gap: spacing.spacing3,
          }}
        >
          {fieldData?.map((metaData) => {
            const lookupData = getLookupData(metaData?.fieldName)
            const multiSelect = ['course', 'degree']?.includes(
              (item) => item === metaData?.fieldName,
            )
            const value =
              metaData?.fieldName === 'isInvitationSent'
                ? filterValue?.[metaData?.fieldName] === true
                  ? 'true'
                  : filterValue?.[metaData?.fieldName] === false
                  ? 'false'
                  : ''
                : filterValue?.[metaData?.fieldName]
            return (
              <DynamicField
                data={metaData}
                onChangeText={handleDropdownChange}
                isEdit
                value={value}
                list={lookupData?.length ? lookupData : metaData?.options}
                onFocus={setErrors}
                disabled={disableField(metaData)}
                multiSelect={multiSelect}
              />
            )
          })}
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: hasDelete ? 'space-between' : 'flex-end',
            flex: 1,
          }}
        >
          {hasDelete && (
            <Button
              mode='outlined'
              style={{
                alignSelf: 'flex-end',
                marginLeft: spacing.spacing2,
                borderColor: colors.error,
                ...deleteStyle,
              }}
              textColor={deleteStyle?.color ? deleteStyle?.color : colors.error}
              variant='titleMedium'
              onPress={() => {
                queryClient.setQueriesData(
                  [`filter${capitalize(module)}Value`],
                  () => ({}),
                )
                setTimeout(() => {
                  refetch()
                }, 300)
                setShowDrawer(false)
              }}
            >
              {t('Directory.CLEAR')}
            </Button>
          )}

          <Button
            mode='contained'
            style={{
              backgroundColor: colors.primary,
              alignSelf: 'flex-end',
              marginRight: spacing.spacing5,
            }}
            labelStyle={{
              color: colors.onPrimary,
            }}
            onPress={() => {
              refetch()
              setShowDrawer(false)
            }}
          >
            {t('Directory.APPLY_FILTER')}
          </Button>
        </View>
      </BottomDrawer>
    </ScrollView>
  )
}
