export const groupMetaData = (data) => {
  const groupedData = {}
  const sortedSections = data?.sections?.sort(
    (a, b) => a.metadata.displayOrder - b.metadata.displayOrder,
  )
  sortedSections?.forEach((section) => {
    const tabId = section.metadata?.tab?._id
    const tabFieldName =
      section?.parent ||
      data?.tabs?.find((tab) => tab?.metadata?._id === tabId)?.metadata
        ?.fieldName
    const sectionFieldName = section.metadata.displayName
    if (!groupedData[tabFieldName]) {
      groupedData[tabFieldName] = {}
    }

    if (!groupedData[tabFieldName][sectionFieldName]) {
      groupedData[tabFieldName][sectionFieldName] = []
    }

    sortedSections?.forEach((field) => {
      if (sectionFieldName === field.metadata.displayName) {
        groupedData[tabFieldName][sectionFieldName] = field
      }
    })
  })
  return groupedData
}
