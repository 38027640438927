import { useNavigation } from '@react-navigation/native'
import { useAtom } from 'jotai'
import React from 'react'
import { ScrollView, TouchableOpacity } from 'react-native'

import { userRollAtom } from '@camped/jotai-storage'
import { DesktopContainer } from '@camped/shared-container'
import { spacing } from '@camped/theme-provider'

import FeedCard from '../../components/feed/FeedCard'
import { HomeBarChart } from '../../components/home/barChart'
import { Greeting } from '../../components/home/greeting'

const DesktopView = (props) => {
  const {
    data,
    studentAnalytics,
    newsFeeds,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = props
  const navigation = useNavigation()
  const [userRoll] = useAtom(userRollAtom)

  return (
    <DesktopContainer>
      <ScrollView
        style={{
          paddingVertical: spacing.spacing4,
          padding: spacing.spacing5,
          backgroundColor: '#F1F1F1',
        }}
      >
        <Greeting user={{ first_name: data?.email }} />
        {userRoll !== 'student' ? (
          <TouchableOpacity
            style={{ width: '100%', maxWidth: 500 }}
            onPress={() => navigation.navigate('student-directory')}
          >
            <HomeBarChart chartData={studentAnalytics} />
          </TouchableOpacity>
        ) : null}
        {userRoll === 'student' ? (
          <FeedCard
            data={newsFeeds}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        ) : null}
      </ScrollView>
    </DesktopContainer>
  )
}

export default DesktopView
