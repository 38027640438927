export const staffValidRoute = [
  'student-directory',
  'course-directory',
  'academics-directory',
  'staff-directory',
  'view-student-details',
  'view-staff-details',
  'view-course-details',
  'view-syllabus-details',
  'add-staff',
  'add-course',
  'add-academics',
  'add-student',
  'update-deparment',
  'configure-role',
  'module-permissions',
  'campus-configuration',
  'department-directory',
  'add-department',
  'home',
  'dashboard',
  'logout',
  'market-place',
  'login',
  'campus',
  'forgot-passwor',
  'web-view',
  'reset-password',
  'change-password',
  'my-profile',
  'configure-role-screen',
  'configure-role-menu',
  'mock-interview',
  'tailored-practice',
  'coding-problems',
  'frontend-practice',
  'roles',
  'role-details',
  'interview-events',
  'resume-builder',
  'add-role',
  'assign-role',
  'graduation-directory',
  'add-graduation',
  'degree-directory',
  'add-degree',
  'social-dashboard',
  'social-settings',
  'social-posts',
  'social-reports',
]
