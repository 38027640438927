import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { Chip, Surface, Text, useTheme } from 'react-native-paper'

import ImageUpload from '@camped/shared-component-edit-image'

import Section from '../profile-top-section'

const MobileView = (props) => {
  const {
    attachment,
    fileInputRef,
    onUpload,
    handleAddImage,
    getValueByFieldName,
    module,
    image,
  } = props
  const { colors, spacing } = useTheme()
  const { t } = useTranslation()
  return (
    <Surface
      style={{
        backgroundColor: colors.surface1,
        paddingTop: spacing.spacing4,
      }}
      elevation={0}
    >
      <View style={{ alignItems: 'center' }}>
        <ImageUpload
          photo={image}
          attachment={attachment}
          fileInputRef={fileInputRef}
          handleAddImage={handleAddImage}
          onUpload={onUpload}
        />
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Text
          variant='headlineSmall'
          style={{
            color: colors.primary,
          }}
        >
          {`${getValueByFieldName('firstName')} ${getValueByFieldName(
            'lastName',
          )}`}
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {getValueByFieldName('registrationNumber') ||
          (getValueByFieldName('employeeId') && (
            <Chip
              selectedColor={colors.onSurface1}
              style={{
                borderRadius: 50,
                backgroundColor: colors.surface2,
                alignSelf: 'flex-start',
              }}
            >
              {module === 'student'
                ? getValueByFieldName('registrationNumber')
                : getValueByFieldName('employeeId')}
            </Chip>
          ))}
        {getValueByFieldName('accommodationType') && (
          <Chip
            selectedColor={colors.onPrimary}
            style={{
              borderRadius: 50,
              backgroundColor: colors.primaryContainer,
              alignSelf: 'flex-start',
            }}
          >
            {getValueByFieldName('accommodationType')}
          </Chip>
        )}
      </View>
      <View style={{ alignItems: 'center', paddingTop: 10 }}>
        <Text
          variant='titleSmall'
          style={{
            color: colors.onPrimaryContainer,
            marginBottom: 15,
            textTransform: 'uppercase',
          }}
        >
          {getValueByFieldName('course')}-
          {getValueByFieldName('specialization')}
        </Text>
      </View>
      <Surface
        elevation={0}
        style={{
          backgroundColor: colors.surface1,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          {module !== 'student' ? (
            <>
              <Section
                title={t('Directory.DEPARTMENT')}
                description={getValueByFieldName('department')}
              />
              <Section
                title={t('Directory.EMPLOYMENT_TYPE')}
                showLine
                description={getValueByFieldName('employeeType')}
              />
            </>
          ) : null}

          <Section
            title={t(
              module === 'student'
                ? 'Directory.ACADEMIC_YEAR'
                : 'Directory.DESIGNATION',
            )}
            description={
              module === 'student'
                ? `${getValueByFieldName(
                    'academicStartYear',
                  )}-${getValueByFieldName('academicEndYear')}`
                : getValueByFieldName('designation')
            }
            showLine
          />
        </View>
      </Surface>
    </Surface>
  )
}

export default MobileView
