import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Button, Row, Text } from '@camped/components'
import CustomSwipe from '@camped/components/Swipeable'
import { Icon } from '@camped/icons'
import ProfileIcon from '@camped/shared-component-avatar'

export const Card = ({
  item,
  goTo,
  selectedIDs,
  handleCheckBox,
  handleInviteClick,
  expanded,
  setExpanded,
  tableHeader,
  module,
  profileImageList,
}) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation()
  return (
    <CustomSwipe
      handleInviteClick={() => handleInviteClick(item)}
      disabled={module !== 'staff' && module !== 'student'}
    >
      <TouchableOpacity
        onLongPress={
          module === 'staff' || module === 'student'
            ? () => {
                handleCheckBox(item?._id)
              }
            : null
        }
        onPress={() => (expanded ? setExpanded('') : setExpanded(item?._id))}
      >
        <Surface
          style={[
            styles.card(colors),
            {
              backgroundColor: selectedIDs.includes(item?._id)
                ? colors.primaryContainer
                : colors.surface1,
            },
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              padding:
                module === 'academics' || module === 'course'
                  ? spacing.spacing5
                  : spacing.spacing3,
            }}
          >
            {module !== 'academics' &&
              module !== 'course' &&
              (selectedIDs.includes(item?._id) ? (
                <View style={styles.profileSurface(colors)}>
                  <Icon
                    name='Selection'
                    width={24}
                    height={32}
                    marginTop={6}
                    color={colors.onBackground}
                    style={styles.iconView}
                  />
                </View>
              ) : (
                <ProfileIcon
                  imageUrl={
                    profileImageList?.find((items) => items?.id === item?._id)
                      ?.imageUrl
                  }
                  size={40}
                />
              ))}

            <View
              style={{ marginLeft: spacing.spacing4, flexDirection: 'column' }}
            >
              <View style={{ flexDirection: 'row' }}>
                <Text variant='bodyMedium' style={{ color: colors.onSurface }}>
                  {item?.firstName}{' '}
                </Text>
                {item?.middleName && item?.middleName !== 'true' ? (
                  <Text
                    variant='bodyMedium'
                    style={{ color: colors.onSurface }}
                  >
                    {item?.middleName}{' '}
                  </Text>
                ) : null}
                {item?.courseName && item?.courseName !== 'true' ? (
                  <Text
                    variant='bodyMedium'
                    style={{ color: colors.onSurface }}
                  >
                    {item?.courseName}{' '}
                  </Text>
                ) : null}
                {item?.user && item?.courseName !== 'true' ? (
                  <Text
                    variant='bodyMedium'
                    style={{ color: colors.onSurface }}
                  >
                    {item?.user?.email}{' '}
                  </Text>
                ) : null}
                {item?.deparmentName && item?.deparmentName !== 'true' ? (
                  <Text
                    variant='bodyMedium'
                    style={{ color: colors.onSurface }}
                  >
                    {item?.deparmentName}{' '}
                  </Text>
                ) : null}

                <Text variant='bodyMedium' style={{ color: colors.onSurface }}>
                  {item?.lastName}
                </Text>
              </View>
              <Text variant='bodyMedium' style={{ color: colors.onSurface }}>
                {item?.registrationNumber ||
                  item?.employeeId ||
                  item?.role?.name}
              </Text>
            </View>
          </View>

          {item?._id === expanded && (
            <View style={styles.expandedView(colors)}>
              <View
                style={{
                  flexDirection: 'row',
                  padding: spacing.spacing3,
                  flexWrap: 'wrap',
                }}
              >
                {tableHeader?.map((c, i) => {
                  const fieldName =
                    c?.metadata?.referenceName ??
                    c?.metadata?.fieldName ??
                    c?.fieldName
                  return (
                    <React.Fragment key={i}>
                      {fieldName !== 'firstName' &&
                        fieldName !== 'courses' &&
                        !c?.metadata?.isHidden &&
                        fieldName !== 'nameAndEmail' &&
                        fieldName !== 'role' &&
                        fieldName !== 'isInvitationSent' && (
                          <View
                            style={{ flexDirection: 'column', width: '33%' }}
                          >
                            <View style={{ padding: spacing.spacing3 }}>
                              <Text
                                variant='labelMedium'
                                style={{
                                  color: colors.onSurface,
                                  opacity: 0.4,
                                  fontWeight: '100',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {c.metadata?.label ??
                                  c?.label ??
                                  c?.displayName}
                              </Text>
                            </View>
                            {fieldName === 'isActive' && (
                              <View
                                style={{
                                  padding: spacing.spacing3,
                                }}
                              >
                                <Text
                                  style={{
                                    color: item?.[fieldName]
                                      ? colors.success
                                      : colors.error,
                                    textTransform: 'capitalize',
                                    fontWeight: '500',
                                  }}
                                >
                                  {item?.[fieldName] ? 'Active' : 'Inactive'}
                                </Text>
                              </View>
                            )}
                            <View
                              style={{
                                padding: spacing.spacing3,
                              }}
                            >
                              <Text
                                style={{
                                  color: colors.onSurface,
                                  textTransform: 'capitalize',
                                  fontWeight: '500',
                                }}
                              >
                                {c?.metadata?.referenceName
                                  ? item?.[fieldName]?.[c?.metadata?.fieldName]
                                  : item?.[fieldName] ?? '-'}
                              </Text>
                            </View>
                          </View>
                        )}
                    </React.Fragment>
                  )
                })}
              </View>
              <Row style={styles.row(spacing)}>
                <View
                  style={{
                    flexDirection: 'row',
                    gap: 8,
                  }}
                />
                <Button
                  icon='ArrowIcon'
                  textColor={colors.onPrimary}
                  style={{
                    height: 40,
                    justifyContent: 'center',
                    borderRadius: 4,
                    backgroundColor: colors.primary,
                  }}
                  contentStyle={{ flexDirection: 'row-reverse' }}
                  onPress={() => goTo('detail', item)}
                >
                  {t('Directory.VIEW')}
                </Button>
              </Row>
            </View>
          )}
        </Surface>
      </TouchableOpacity>
    </CustomSwipe>
  )
}
const styles = StyleSheet.create({
  iconView: { alignSelf: 'center', justifyContent: 'center', flex: 1 },
  expandedView: (colors) => ({
    backgroundColor: colors.surface2,
    paddingVertical: 10,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  }),
  expandedIcon: (colors) => ({
    width: 30,
    height: 30,
    backgroundColor: colors.surfaceVariant,
    borderRadius: 20,
  }),
  row: (spacing) => ({
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: spacing.spacing5,
  }),
  card: (colors) => ({
    borderWidth: 1,
    borderColor: colors.surface2,
  }),
  profileSurface: (colors) => ({
    width: 40,
    height: 40,
    backgroundColor: colors.surface1,
    borderRadius: 20,
  }),
})
