import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { DynamicField, FormContainer } from '@camped/form-container'
import { MobileContainer } from '@camped/shared-container'
import { mobileData } from '@camped/shared-mock-data'

import Accodion from '../../components/accordion'
import ModulePermissions from '../../components/modulePermissions'

const MobileView = (props) => {
  const {
    selectedTab,

    onDropdownChange,
    tabsData,

    module,
  } = props

  const { colors } = useTheme()
  const renderItem = (data) => (
    <DynamicField
      data={data}
      isEdit
      value={selectedTab?.displayName}
      list={tabsData?.map((item) => item?.tab)}
      multiSelect={data.multiSelect}
      onChangeText={onDropdownChange}
      style={{
        maxWidth: 360,
        width: '90%',
      }}
    />
  )
  return (
    <MobileContainer
      style={{
        backgroundColor: colors.background,
      }}
    >
      {!!tabsData?.length && module === 'Role' && (
        <View style={{ margin: 10 }}>
          <FormContainer
            data={mobileData}
            renderItem={renderItem}
            width={250}
          />
        </View>
      )}
      {module === 'Menu' && <Features {...props} />}
      {module === 'Role' && <ModulePermissions {...props} />}
    </MobileContainer>
  )
}
const Features = (props) => {
  const { toggleMenuSwitch, featureData } = props
  return featureData?.map((feature, index) => (
    <Accodion
      title={feature?.feature?.displayName}
      index={index}
      togglebutton={(value) => {
        toggleMenuSwitch(feature, value)
      }}
      isEnabled={feature?.isEnabled}
      style={{ paddingVertical: 8, paddingHorizontal: 8 }}
      showIcon={!!feature?.subMenuItems?.length}
    >
      {feature?.subMenuItems?.map((item) => (
        <Accodion
          title={item?.feature?.displayName}
          index={index}
          togglebutton={(value) => {
            toggleMenuSwitch(item, value)
          }}
          isEnabled={item?.isEnabled}
          style={{ paddingVertical: 8, paddingHorizontal: 8 }}
          showIcon={!!item?.subMenuItems?.length}
        >
          {item?.subMenuItems?.map((subMenu) => (
            <Accodion
              title={subMenu?.feature?.displayName}
              index={index}
              togglebutton={(value) => {
                toggleMenuSwitch(subMenu, value)
              }}
              isEnabled={subMenu?.isEnabled}
              style={{ paddingVertical: 8, paddingHorizontal: 8 }}
              showIcon={!!subMenu?.subMenuItems?.length}
            >
              {/* <Text>Text</Text> */}
            </Accodion>
          ))}
        </Accodion>
      ))}
    </Accodion>
  ))
}

export default MobileView
