import React from 'react'
import {
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native'
import { Checkbox, useTheme } from 'react-native-paper'

import {
  DataTableCell,
  DataTableHeader,
  DataTableRow,
  DataTableTitle,
  Text,
} from '@camped/components'
import { DynamicField, FormContainer } from '@camped/form-container'
import Overlay from '@camped/shared-component-overlay'
import { ModuleData } from '@camped/shared-mock-data'
import { spacing } from '@camped/theme-provider'

import Accodion from '../../components/accordion'
import ToggleAccodion from '../../components/accordion/ToggleAccordion'

const DesktopView = (props) => {
  const {
    visible,
    setVisible,
    selectedTab,
    fieldsData,
    sectionsData,
    onDropdownChange,
    tabsData,
    toggleSwitch,
    handleSectionEnable,
    roleData,
    module,
    handleTabEnable,
    isLoading,
  } = props
  const renderItem = (data) => (
    <DynamicField
      data={data}
      isEdit
      value={selectedTab?.displayName}
      list={tabsData?.map((item) => item?.tab)}
      multiSelect={data.multiSelect}
      dropDownWidth={218}
      onChangeText={onDropdownChange}
    />
  )
  const { colors } = useTheme()
  return (
    <Overlay
      isVisible={visible}
      setVisible={() => {
        setVisible(false)
      }}
      width='50%'
      title={`${module} permissions for ${roleData?.role?.name}`}
      headerTextVariant='headlineMedium'
    >
      {isLoading ? (
        <ActivityIndicator
          size='large'
          color={colors.primary}
          style={{
            right: '50%',
            position: 'absolute',
            marginTop: spacing.spacing13,
          }}
        />
      ) : (
        <View style={{ margin: 10 }}>
          <View style={{ marginTop: 20 }}>
            {!!tabsData?.length && module === 'Role' && (
              <>
                <Text variant='titleMedium' style={{ padding: 10 }}>
                  Module Information
                </Text>
                <View style={{ margin: 10 }}>
                  <FormContainer
                    data={ModuleData}
                    renderItem={renderItem}
                    width={250}
                  />
                </View>
              </>
            )}

            {module === 'Role' && (
              <>
                {!!tabsData?.length && (
                  <>
                    <DataTableHeader
                      style={{
                        borderTopLeftRadius: spacing.spacing4,
                        borderTopRightRadius: spacing.spacing4,
                        height: 40,
                        alignItems: 'center',
                        marginHorizontal: 10,
                        paddingHorizontal: 18,
                      }}
                    >
                      <DataTableTitle
                        style={{ justifyContent: 'center' }}
                        textVariant='titleMedium'
                      >
                        {selectedTab?.displayName}
                      </DataTableTitle>
                      <DataTableTitle
                        style={{
                          marginLeft: 18,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <TouchableOpacity
                          onPress={() =>
                            handleTabEnable(!selectedTab?.isEnabled)
                          }
                        >
                          <Text
                            style={{
                              color: selectedTab?.isEnabled
                                ? colors.primary
                                : colors.onBackground,
                            }}
                          >
                            {selectedTab?.isEnabled ? 'Enabled' : 'Disabled'}
                          </Text>
                        </TouchableOpacity>
                      </DataTableTitle>
                    </DataTableHeader>
                    <View style={{ height: 1 }} />
                  </>
                )}

                <DataTableHeader
                  style={{
                    // borderTopLeftRadius: spacing.spacing4,
                    // borderTopRightRadius: spacing.spacing4,
                    height: 40,
                    alignItems: 'center',
                    marginHorizontal: 10,
                  }}
                >
                  <DataTableTitle
                    style={{ flex: 2, justifyContent: 'center' }}
                    textVariant='titleMedium'
                  >
                    Fields
                  </DataTableTitle>
                  <DataTableTitle
                    style={{ flex: 1, justifyContent: 'center' }}
                    textVariant='titleMedium'
                  >
                    View
                  </DataTableTitle>
                  <DataTableTitle
                    style={{ flex: 1, justifyContent: 'center' }}
                    textVariant='titleMedium'
                  >
                    Add
                  </DataTableTitle>
                  <DataTableTitle
                    style={{ flex: 1, justifyContent: 'center' }}
                    textVariant='titleMedium'
                  >
                    Edit
                  </DataTableTitle>
                  <DataTableTitle
                    style={{ flex: 1, justifyContent: 'center' }}
                    textVariant='titleMedium'
                  >
                    Delete
                  </DataTableTitle>
                  <DataTableTitle
                    style={{ flex: 0.2, justifyContent: 'center' }}
                    textVariant='titleMedium'
                  />
                </DataTableHeader>
              </>
            )}
            <ScrollView
              style={{
                flex: 1,
                marginBottom: 18,
              }}
              alwaysBounceVertical
            >
              {module === 'Menu' && <Features {...props} />}
              {module === 'Role' &&
                sectionsData?.map((section, index) => (
                  <ToggleAccodion
                    title={section?.section?.displayName}
                    index={index}
                    togglebutton={handleSectionEnable}
                    isEnabled={section?.permissions?.view === true}
                    section={section}
                  >
                    <View style={{ padding: 10 }}>
                      {fieldsData?.map((field) => {
                        if (
                          field?.field?.section._id === section?.section?._id
                        ) {
                          return (
                            <DataTableRow
                              key={field?.field?._id}
                              hasHover={false}
                            >
                              <DataTableCell
                                flexN={2}
                                style={{ justifyContent: 'center' }}
                                textVariant='bodyMedium'
                              >
                                {field?.field?.label}
                              </DataTableCell>
                              {['view', 'add', 'edit', 'delete'].map(
                                (permission, i) => (
                                  <DataTableCell
                                    key={i}
                                    style={{ justifyContent: 'center' }}
                                  >
                                    <Checkbox
                                      status={
                                        field?.permissions?.[permission]
                                          ? 'checked'
                                          : 'unchecked'
                                      }
                                      onPress={() => {
                                        toggleSwitch(
                                          permission,
                                          field,
                                          !field?.permissions?.[permission],
                                        )
                                      }}
                                      disabled={
                                        field?.permissions?.[permission] ===
                                          null ||
                                        field?.permissions?.[permission] ===
                                          'null' ||
                                        field?.permissions?.[permission] ===
                                          undefined ||
                                        (field?.permissions?.view === false &&
                                          permission !== 'view')
                                      }
                                    />
                                  </DataTableCell>
                                ),
                              )}
                            </DataTableRow>
                          )
                        }
                        return null
                      })}
                    </View>
                  </ToggleAccodion>
                ))}
            </ScrollView>
          </View>
        </View>
      )}
    </Overlay>
  )
}

const Features = (props) => {
  const { toggleMenuSwitch, featureData } = props
  return featureData?.map((feature, index) => (
    <Accodion
      title={feature?.feature?.displayName}
      index={index}
      togglebutton={(value) => {
        toggleMenuSwitch(feature, value)
      }}
      isEnabled={feature?.isEnabled}
      style={{ paddingVertical: 8, paddingHorizontal: 8 }}
      showIcon={!!feature?.subMenuItems?.length}
    >
      {feature?.subMenuItems?.map((item) => (
        <Accodion
          title={item?.feature?.displayName}
          index={index}
          togglebutton={(value) => {
            toggleMenuSwitch(item, value)
          }}
          isEnabled={item?.isEnabled}
          style={{ paddingVertical: 8, paddingHorizontal: 8 }}
          showIcon={!!item?.subMenuItems?.length}
        >
          {item?.subMenuItems?.map((subMenu) => (
            <Accodion
              title={subMenu?.feature?.displayName}
              index={index}
              togglebutton={(value) => {
                toggleMenuSwitch(subMenu, value)
              }}
              isEnabled={subMenu?.isEnabled}
              style={{ paddingVertical: 8, paddingHorizontal: 8 }}
              showIcon={!!subMenu?.subMenuItems?.length}
            >
              {/* <Text>Text</Text> */}
            </Accodion>
          ))}
        </Accodion>
      ))}
    </Accodion>
  ))
}
export default DesktopView
