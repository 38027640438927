import React, { useEffect, useRef } from 'react'
import { Animated, Dimensions, PanResponder } from 'react-native'

export const BottomSheet = (props) => {
  const { dismiss, isEnable = true } = props
  const screenHeight = Dimensions.get('screen').height
  const panY = useRef(new Animated.Value(screenHeight)).current

  const resetPositionAnim = Animated.timing(panY, {
    toValue: 0,
    duration: 300,
    useNativeDriver: true,
  })

  const closeAnim = Animated.timing(panY, {
    toValue: screenHeight,
    duration: 500,
    useNativeDriver: true,
  })

  const translateY = panY.interpolate({
    inputRange: [-1, 0, 1],
    outputRange: [0, 0, 1],
  })

  const handleDismiss = () => closeAnim.start(() => dismiss())
  useEffect(() => {
    resetPositionAnim.start()
  }, [resetPositionAnim])

  const panResponders = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => false,
      onPanResponderMove: Animated.event([null, { dy: panY }], {
        useNativeDriver: false,
      }),
      onPanResponderRelease: (_, gs) => {
        if (
          (gs.dy > 0 && gs.vy > 2) ||
          gs.moveY > screenHeight - screenHeight / 4
        ) {
          if (isEnable) return handleDismiss()
        }
        return resetPositionAnim.start()
      },
    }),
  ).current

  return (
    <Animated.View
      style={
        isEnable
          ? {
              transform: [{ translateY }],
            }
          : null
      }
      {...panResponders.panHandlers}
    >
      {props.children}
    </Animated.View>
  )
}
