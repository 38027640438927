import { useTranslation } from 'react-i18next'

import {
  checkingStr,
  isBetween,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from '../utils/validationUtils'

const useValidation = () => {
  const { t } = useTranslation()
  const validationErrors = []
  const validate = ({
    userData,
    dataFields,
    isCreate = false,
    isMultiValueSelected = false,
  }) => {
    if (Array.isArray(dataFields)) {
      dataFields?.forEach((elements, i) => {
        elements?.forEach((element) => {
          if (!isCreate || i < 1) {
            if (
              !element?.permissions?.view ||
              (!element?.permissions?.edit && !isCreate)
            ) {
              return
            }
            const field = element.metadata
            const { label, isMandatory, valueType } = field
            const fieldName = field?.referenceName ?? field?.fieldName
            const value = element?.metadata?.arrayName
              ? userData?.[element?.metadata?.arrayName]?.[i]?.find(
                  (ele) => ele?.name === fieldName,
                )?.value
              : userData?.[fieldName]?.value
            if (fieldName === 'dateOfBirth') {
              const dateOfBirth = new Date(value)
              const today = new Date()
              today.setUTCHours(0, 0, 0, 0)
              if (dateOfBirth >= today) {
                validationErrors.push({
                  fieldName,
                  value: value ?? '',
                  message: `${label} ${t('Global.IS_INVALID')}`,
                })
              }
            }
            if (
              (fieldName === 'primaryContactNumber' ||
                fieldName === 'mobileNumber') &&
              !isValidPhoneNumber(value)
            ) {
              validationErrors.push({
                fieldName,
                value: value ?? 0,
                message: `${label} ${t('Global.IS_INVALID')}`,
              })
            }

            if (fieldName === 'dateOfJoining') {
              const dateOfJoining = new Date(value)
              const today = new Date()
              today.setUTCHours(0, 0, 0, 0)
              if (dateOfJoining >= today) {
                validationErrors.push({
                  fieldName,
                  value: value ?? '',
                  message: `${label} ${t('Global.IS_INVALID')}`,
                })
              } else if (dateOfJoining >= userData.dateOfBirth) {
                validationErrors.push({
                  fieldName,
                  value,
                  message: `${label} ${t('Global.IS_INVALID')}`,
                })
              }
            }
            if (
              (fieldName === 'emailId' ||
                fieldName === 'collegeEmailId' ||
                fieldName === 'personalEmailId') &&
              value &&
              !isValidEmail(value)
            ) {
              validationErrors.push({
                fieldName,
                value: value ?? '',
                message: `${label} ${t('Global.IS_INVALID')}`,
              })
            }

            if (fieldName === 'noOfRooms' && value > 50) {
              validationErrors.push({
                fieldName,
                value,
                message: t('Global.MAX_ROOM_50'),
              })
            }
            if (fieldName === 'floorNumber' && value > 20) {
              validationErrors.push({
                fieldName,
                value,
                message: t('Global.MAX_ROOM_20'),
              })
            }
            if (!isValidName(value) && valueType === 'name' && isMandatory) {
              validationErrors.push({
                fieldName,
                value: value ?? '',
                message: `${label} ${t('Global.IS_INVALID')}`,
              })
            }
            if (
              isMandatory &&
              (value === undefined ||
                value === null ||
                value?.length === 0 ||
                value === 0)
            ) {
              validationErrors.push({
                fieldName,
                value: value ?? '',
                message: `${label} ${t('Global.MANDATORY')}`,
              })
            }
            if (checkingStr(value) && isMandatory) {
              validationErrors.push({
                fieldName,
                value: value ?? '',
                message: `${label} ${t('Global.CANT_EMPTY')}`,
              })
            }
            if (
              (isMultiValueSelected ||
                userData.bedsInEachRoom === '4' ||
                userData.noOfBeds === '4') &&
              (fieldName === 'bedsInEachRoomText' ||
                fieldName === 'noOfBedsText') &&
              (!isBetween(userData.bedsInEachRoom, 4, 20) ||
                !isBetween(userData.noOfBeds, 4, 20))
            ) {
              validationErrors.push({
                fieldName,
                value,
                message: `${label} ${t('Global.BETWEEN_4_20')}`,
              })
            }
          }
        })
      })
    }
  }
  return { validationErrors, validate }
}

export default useValidation
