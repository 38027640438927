export const EventEntityType = {
  SETTINGS: 'Settings',
  PROFILE: 'Profile',
  CAMPUS: 'Campus',
  LOGIN: 'Login',
  FORGET_PASSWORD: 'Forget Password',
  STUDENT: 'Student',
  APPLICATION: 'Application',
  CHANGE_PASSWORD: 'Change Password',
  RESET_PASSWORD: 'Reset Password',
  LOGOUT: 'Logout',
  UPDATE: 'Update profile',
  EDIT: 'Edit',
  SAVE: 'Save',
  VIEWMORE: 'View More',
  ADD_NEW: 'Add new entry',
}
