import React, { useState } from 'react'
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Surface, useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout } from '@camped/utils'

const Accodion = (props) => {
  const {
    title,
    index,
    togglebutton,
    style,
    titleStyle,
    children,
    isEnabled,
    paddingHorizontal = spacing.spacing4,
    showIcon = true,
  } = props

  const { width } = useWindowDimensions()
  const isDesktop = ScreenLayout.isWeb(width)
  const { colors } = useTheme()
  const [expanded, setExpanded] = useState(!togglebutton ? true : index === 0)
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }
  const getPadding = () => {
    if (titleStyle) {
      return titleStyle
    }
    return {
      paddingTop: !isDesktop ? spacing.spacing5 : spacing.spacing4,
      paddingBottom: !isDesktop ? spacing.spacing5 : spacing.spacing4,
    }
  }
  const getAccordionFunc = (toggle) => {
    if (toggle) {
      return (
        <TouchableOpacity
          onPress={() => {
            toggle(!isEnabled)
          }}
        >
          <Text
            style={{ color: isEnabled ? colors.primary : colors.onBackground }}
          >
            {isEnabled ? 'Enabled' : 'Disabled'}
          </Text>
        </TouchableOpacity>
      )
    }
  }
  return (
    <Surface
      Surface
      style={StyleSheet.flatten(styles.container(colors, spacing), style)}
    >
      {Boolean(title) && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal,
            backgroundColor: colors.surface2,
            borderTopLeftRadius: spacing.spacing2,
            borderTopRightRadius: spacing.spacing2,
          }}
        >
          <TouchableOpacity onPress={toggleExpanded}>
            <Text
              variant='headlineSmall'
              style={[
                styles.systemAdmin(colors, spacing, isDesktop),
                getPadding(),
              ]}
            >
              {title}
            </Text>
          </TouchableOpacity>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {getAccordionFunc(togglebutton)}
            <TouchableOpacity
              onPress={toggleExpanded}
              style={{
                paddingLeft: spacing.spacing3,
                width: 17,
                height: 17,
              }}
            >
              {showIcon && (
                <Icon
                  name={expanded ? 'MenuOpen' : 'MenuClose'}
                  color={colors.onBackground}
                  width={17}
                  height={17}
                />
              )}
            </TouchableOpacity>
          </View>
        </View>
      )}
      {expanded && isEnabled ? children : null}
      {!togglebutton && expanded ? children : null}
    </Surface>
  )
}

const styles = StyleSheet.create({
  container: (colors) => ({
    backgroundColor: colors.surface1,
    marginRight: spacing.spacing4,
    marginLeft: spacing.spacing4,
  }),
  systemAdmin: (colors) => ({
    paddingHorizontal: 12,
    color: colors.onSurface1,
    fontWeight: '700',
    textTransform: 'capitalize',
  }),
})

export default Accodion
