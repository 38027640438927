import { appUtil } from '@camped/utils'

export const isValidDate = (dateString) => {
  const today = new Date().toISOString().split('T')[0]
  return dateString !== undefined && dateString >= today
}

export const isValidEmail = (email) => email && appUtil.isValidEmail(email)

export const isValidName = (name) => name && appUtil.isValidName(name)

export const isValidPhoneNumber = (phoneNumber) =>
  phoneNumber && appUtil.isValidMobileNumber(phoneNumber)

export const isBetween = (value, minValue, maxValue) =>
  value >= minValue && value <= maxValue

export const checkingStr = (str) => typeof str === 'string' && str.trim() === ''
