import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { DesktopContainer } from '@camped/shared-container'
import { spacing } from '@camped/theme-provider'

import { CampEdBranding, Footer } from '../../components'

const DesktopView = (props) => {
  const { data, bgImage, RenderComponent } = props

  const { colors } = useTheme()
  const { t } = useTranslation()

  return (
    <DesktopContainer>
      <View
        style={{
          flexDirection: 'row',
          minHeight: '97%',
        }}
      >
        <View
          style={{
            flex: 0.6,
            backgroundColor: colors.background,
            paddingLeft: spacing.spacing12,
            paddingRight: spacing.spacing11,
            paddingVertical: spacing.spacing8,
          }}
        >
          <CampEdBranding bgImage={bgImage} />
        </View>
        <View
          style={{
            backgroundColor: colors.surface3,
            flex: 0.4,
            paddingRight: spacing.spacing12,
            paddingLeft: spacing.spacing10,
            paddingTop: spacing.spacing8,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'flex-start',
              marginBottom: spacing.spacing8,
            }}
          >
            <View
              style={{ height: 61, flexDirection: 'row', alignItems: 'center' }}
            >
              {!data?.logoUrl ? (
                <Text
                  variant='headlineLarge'
                  style={{
                    color: colors.onSurfaceVariant,
                    textAlignVertical: 'center',
                  }}
                >
                  {t('Auth.CAMPED_WELCOME')}
                </Text>
              ) : (
                <Image
                  source={{ uri: data?.logoUrl }}
                  style={{
                    width: 144,
                    height: 61,
                  }}
                  resizeMode='contain'
                />
              )}
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {RenderComponent}
            </View>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Footer />
      </View>
    </DesktopContainer>
  )
}
const styles = StyleSheet.create({
  footer: {
    alignItems: 'center',
    bottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: spacing.spacing12,
    width: '100%',
  },
})

export default DesktopView
