import { CommonActions, useNavigation } from '@react-navigation/native'
import { useAtom } from 'jotai'
import React, { Suspense, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'

import { API, Auth } from '@camped/api'
import { Text } from '@camped/components'
import { baseUrl } from '@camped/core/src/config'
import {
  aceprepToken,
  domainAtom,
  userAtom,
  userRollAtom,
} from '@camped/jotai-storage'
import { ScreenLayout, SecureStore } from '@camped/utils'

import { getFCMToken } from '../../utils/notificationHandler'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const Logout = (props) => {
  const navigation = useNavigation()
  const [domainDetails] = useAtom(domainAtom)
  const [, setAcePrepToken] = useAtom(aceprepToken)
  const [, setTenantDetails] = useAtom(userAtom)
  const [, setUserRoll] = useAtom(userRollAtom)
  const { t } = useTranslation()

  const logout = async () => {
    removeTokens()
    await Auth.logout()
    SecureStore.removeCookie('user-details')
    if (domainDetails)
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: 'login' }],
        }),
      )
    else {
      if (Platform.OS === 'web') {
        window.location.href = `${baseUrl}/campus`
        return
      }
      RootNavigator.reset('campus')
      return
    }
  }

  const removeTokens = async () => {
    if (Platform.OS === 'web') {
      removeLocalStorage()
      setAcePrepToken('')
      setUserRoll('')
      setTenantDetails({})
      return
    }
    const fcmToken = await getFCMToken()
    await API.deleteUserDeviceAPI({ deviceId: fcmToken })
  }

  useEffect(() => {
    logout()
  }, [props])

  const viewProps = {}

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  return (
    <Suspense fallback={<Text>{t('Hostel.LOADING')}</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default Logout
