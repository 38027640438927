import React from 'react'
import { View } from 'react-native'

import { Text } from '@camped/components'
import ProfileIcon from '@camped/shared-component-avatar'
import { appUtil } from '@camped/utils'

export const Title = (props) => {
  const { item, module, profileImageList } = props
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {module !== 'academics' && module !== 'course' && (
        <ProfileIcon
          imageUrl={
            profileImageList?.find((items) => items?.id === item?._id)?.imageUrl
          }
          size={40}
        />
      )}
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'left',
          marginLeft: 8,
        }}
      >
        <Text
          style={{ flexDirection: 'row', fontSize: 16, fontWeight: '300' }}
          variant='titleMedium'
        >
          {module === 'student' || (module === 'staff' && item?.firstName)
            ? `${appUtil.capitalize(item.firstName)} `
            : null}
          {module === 'student' || (module === 'staff' && item?.middleName)
            ? `${appUtil.capitalize(item.middleName)} `
            : null}
          {module === 'student' || (module === 'staff' && item?.lastName)
            ? appUtil.capitalize(item.lastName)
            : null}
          {module === 'course' || (module === 'academics' && item?.courseName)
            ? appUtil.capitalize(item?.courseName)
            : null}
          {module === 'rolelisting' ? item?.user?.email : null}
          {module === 'academics' ||
          (module === 'academics' && item?.deparmentName)
            ? appUtil.capitalize(item?.deparmentName)
            : null}
        </Text>

        {item?.registrationNumber || item?.employeeId ? (
          <Text style={{ flex: 1, fontWeight: '300' }} variant='labelSmall'>
            {item?.registrationNumber || item?.employeeId}
          </Text>
        ) : null}
      </View>
    </View>
  )
}
