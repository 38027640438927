import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

export const ViewQuestionPaper = (props) => {
  const { colors } = useTheme()
  const { question, subject, setShowQuestionPaper } = props
  if (!question?.errors) {
    const generatedQuestion = JSON.parse(question?.generatedQuestion || '{}')
    return (
      <React.Fragment key={question._id}>
        <TouchableOpacity
          style={styles.backButton}
          onPress={() => setShowQuestionPaper('')}
        >
          <Icon
            name='BackArrow'
            color={colors.onSurface1}
            width={24}
            height={24}
          />
          <Text style={styles.backButtonText}>Back</Text>
        </TouchableOpacity>
        <View style={styles.mainView}>
          <Text variant='bodyMedium' style={styles.semesterText(colors)}>
            {`${question?.examName} Examination`}
          </Text>
          <View
            style={{
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Text variant='bodyMedium' style={styles.textStyle(colors)}>
              {`${question?.subject?.course?.courseName}`}
            </Text>
            <Text variant='bodyMedium' style={styles.textStyle(colors)}>
              {`Semester ${question?.subject?.semester}`}
            </Text>
            <Text variant='bodyMedium' style={styles.textStyle(colors)}>
              {`${question?.subject?.subjectCode} ${subject}`}
            </Text>
          </View>
          <View style={styles.rowView}>
            <Text variant='bodyMedium' style={styles.rowText(colors)}>
              {`Total Time: ${question?.timeLimit}`}
            </Text>
            <Text variant='bodyMedium' style={styles.rowText(colors)}>
              Answer All Questions
            </Text>
            <Text variant='bodyMedium' style={styles.rowText(colors)}>
              {`Max. Mark ${question?.totalMarks}`}
            </Text>
          </View>
          {generatedQuestion?.sections?.map((items, i) => {
            const questionPaperSection = question?.questionPaperSection[i]
            return (
              <React.Fragment key={i}>
                <View style={styles.markView}>
                  <Text variant='labelMedium' style={styles.markText(colors)}>
                    {`${items?.sectionName} (${questionPaperSection.questionsAnswered} X ${questionPaperSection.eachQuestionMark} = ${questionPaperSection.sectionMarks})`}
                  </Text>
                </View>
                {questionPaperSection.questionsAnswered !==
                  questionPaperSection.totalQuestions && (
                  <Text
                    variant='labelMedium'
                    style={styles.answerOnlyText(colors)}
                  >
                    {`Answer Only ${questionPaperSection.questionsAnswered} Question :`}
                  </Text>
                )}
                {items?.sectionType === 'Normal' &&
                  items?.questions?.map((noramlQuestion) => (
                    <Text
                      variant='bodyMedium'
                      style={[
                        styles.noramlQuestionText(colors),
                        { paddingBottom: spacing.spacing6 },
                      ]}
                    >
                      {`${noramlQuestion?.questionNumber})   ${noramlQuestion?.question}`}
                    </Text>
                  ))}
                {items?.sectionType.toLowerCase() === 'either/or' &&
                  items?.questions?.map((questions, questionskey) => (
                    <React.Fragment key={questionskey}>
                      {questions?.question?.map(
                        (EitherOrQuestions, EitherOrQuestionskey) => (
                          <>
                            <Text
                              variant='bodyMedium'
                              style={[
                                styles.noramlQuestionText(colors),
                                {
                                  paddingBottom:
                                    // eslint-disable-next-line no-unsafe-optional-chaining
                                    questions?.question?.length - 1 ===
                                    EitherOrQuestionskey
                                      ? spacing.spacing6
                                      : spacing.spacing2,
                                },
                              ]}
                            >
                              {`${EitherOrQuestions?.questionNumber} ${EitherOrQuestions?.question}`}
                            </Text>
                            {EitherOrQuestionskey === 0 && (
                              <Text
                                variant='bodyMedium'
                                style={[
                                  styles.noramlQuestionText(colors),
                                  {
                                    marginLeft: spacing.spacing6,
                                    paddingBottom: spacing.spacing2,
                                  },
                                ]}
                              >
                                (or)
                              </Text>
                            )}
                          </>
                        ),
                      )}
                    </React.Fragment>
                  ))}
                {items.sectionType.toLowerCase() === 'multi-choice' &&
                  items?.questions.map((questions) => (
                    <>
                      <Text
                        variant='bodyMedium'
                        style={[
                          styles.noramlQuestionText(colors),
                          {
                            paddingBottom: spacing.spacing2,
                          },
                        ]}
                      >
                        {`${questions?.questionNumber} ${questions?.question}`}
                      </Text>

                      {questions?.options?.map((option, optionIndex) => (
                        <Text
                          variant='bodyMedium'
                          style={[
                            styles.noramlQuestionText(colors),
                            {
                              marginLeft: spacing.spacing6,
                              paddingBottom:
                                optionIndex ===
                                (questions?.options.length || 1) - 1
                                  ? spacing.spacing6
                                  : spacing.spacing1,
                            },
                          ]}
                        >
                          {`${String.fromCharCode(
                            97 + optionIndex,
                          )}) ${option}`}
                        </Text>
                      ))}
                    </>
                  ))}
                <br></br>
              </React.Fragment>
            )
          })}
        </View>
      </React.Fragment>
    )
  }
}

const styles = StyleSheet.create({
  backButton: {
    display: 'flex',
    flexDirection: 'row',
    margin: spacing.spacing4,
    alignItems: 'center',
  },
  backButtonText: { marginLeft: spacing.spacing2 },
  semesterText: (colors) => ({
    flexDirection: 'row',
    fontWeight: '700',
    color: colors.onSurface1,
    fontSize: 18,
    justifyContent: 'center',
    textTransform: 'capitalize',
    paddingBottom: spacing.spacing4,
  }),
  mainView: {
    width: '100%',
    marginBottom: 24,
    alignItems: 'center',
    padding: spacing.spacing3,
  },
  rowView: {
    width: '100%',
    flexDirection: 'row',
    padding: spacing.spacing3,
    justifyContent: 'space-between',
  },
  rowText: (colors) => ({
    fontWeight: '200',
    color: colors.onSurface1,
    paddingBottom: spacing.spacing6,
  }),
  markView: {
    flex: 1,
    paddingBottom: spacing.spacing3,
  },
  markText: (colors) => ({
    color: colors.onSurface1,
    opacity: 1,
    textTransform: 'capitalize',
    fontWeight: '700',
    fontSize: 18,
  }),
  textStyle: (colors) => ({
    fontWeight: '200',
    color: colors.onSurface1,
    justifyContent: 'center',
  }),
  answerOnlyText: (colors) => ({
    color: colors.onSurface1,
    width: '100%',
    opacity: 1,
    textTransform: 'capitalize',
    fontWeight: '700',
    fontSize: 18,
    paddingBottom: spacing.spacing2,
    marginVertical: spacing.spacing5,
    marginLeft: spacing.spacing2,
  }),
  noramlQuestionText: (colors) => ({
    width: '100%',
    fontWeight: '200',
    color: colors.onSurface1,
    marginLeft: spacing.spacing5,
  }),
})
