import React, { Suspense, useCallback, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { API } from '@camped/api'
import { Text } from '@camped/components'
import {
  data,
  getRoleFormPermissionByFilter,
  tableData,
} from '@camped/shared-mock-data'
import { ScreenLayout, SecureStore } from '@camped/utils'

import DesktopView from './DesktopView'
import MobileView from './MobileView'

const Permissions = (props) => {
  const { module } = props
  const [user, setUser] = useState({})
  const [selectedScreen, setSelectedScreen] = useState({})
  const [selectedRole, setSelectedRole] = useState({})

  const { data: permissionData, isFetching } = useQuery(
    [`permissionData-${module}`],
    async () => {
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)

      if (module === 'Role') {
        const rolesData = await API.getRoleScreenPermissionsByFilterAPI({
          filter: {
            tenant: userDetail?.[0]?.tenantId ?? '',
          },
        })

        setSelectedScreen(rolesData?.[0]?.screen)

        const filteredData = rolesData?.filter(
          (role) => role.screen._id === rolesData?.[0]?.screen._id,
        )
        setUser({ rolesData: filteredData })
        return rolesData
      }

      const rolesData = await API.getRolesByFilterAPI({
        filter: {
          tenant: userDetail?.[0]?.tenantId ?? '',
        },
      })

      const roles = rolesData?.map((item) => ({ role: item }))
      setUser({
        rolesData: roles,
      })
    },
    {
      enabled: true,
      initialData: [],
    },
  )

  const onDropdownChange = (data, text) => {
    const filteredData = permissionData?.filter(
      (role) => role.screen._id === text?._id,
    )
    setUser({ rolesData: filteredData })
    setSelectedScreen(text)
  }

  const toggleSwitch = async (i, permission, value) => {
    const newData = { ...user }
    let permissions
    let isEnabled
    newData.rolesData = newData?.rolesData?.map((item, index) => {
      if (index === i) {
        if (permission === 'view' && value === false) {
          permissions = {
            edit: false,
            view: false,
            delete: false,
            add: false,
          }
          isEnabled = value
          return {
            ...item,
            permissions,
          }
        }
        if (permission === 'view') {
          isEnabled = value
        }
        permissions = {
          ...item.permissions,
          [permission]: value,
        }
        return {
          ...item,
          permissions,
        }
      }
      return item
    })
    setUser(newData)
    const response = await API.updateRoleScreenPermissionAPI({
      id: newData?.rolesData?.[i]?._id,
      roleScreenPermission: {
        permissions,
        isEnabled,
      },
    })
    if (!response?.errors) {
      setUser(newData)
      toast.show('Permission updated successfully', { type: 'success' })
    } else {
      toast.show(response?.errors?.[0]?.message, { type: 'danger' })
    }
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )
  const uniqueScreen = (arr) => {
    const unique = []
    const uniqueId = []
    for (const item of arr) {
      if (!uniqueId.includes(item._id)) {
        unique.push(item)
        uniqueId.push(item._id)
      }
    }
    return unique
  }

  const viewprops = {
    data,
    user,
    toggleSwitch,
    selectedScreen,
    onDropdownChange,
    permissionData,
    getRoleFormPermissionByFilter,
    tableData,
    isFetching,
    selectedRole,
    module,
    setSelectedRole,
    uniqueScreen,
    ...props,
  }
  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewprops} />
    </Suspense>
  )
}

export default Permissions
