import React from 'react'
import { useTheme } from 'react-native-paper'

import { Loading } from '@camped/components/Loading'
import { MobileContainer } from '@camped/shared-container'

import EdgeCase from '../components/edge-case'
import { Filter } from '../components/mobile-drawer-filter'
import { Sort } from '../components/mobile-drawer-sort'
import { TopSection } from '../components/mobile-list-card/list-actions'
import { ListItems } from '../components/mobile-list-card/list-item'
import ViewMoreButton from '../components/view-more/viewmore'

const MobileView = (props) => {
  const {
    disableField,
    expanded,
    setExpanded,
    filterList,
    filterValue,
    getLookupData,
    goTo,
    handleCheckBox,
    handleDropdownChange,
    handleInviteClick,
    module,
    navigation,
    onChangeText,
    onSortSelected,
    refetch,
    selectedIDs,
    selectedSortValue,
    setErrors,
    setShowDrawer,
    setShowSortDrawer,
    showDrawer,
    showSortDrawer,
    sortList,
    staffFetching,
    user,
    userFetching,
    userLists,
    tableHeader,
    profileImageList,
    handleBulkInvite,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    totalCountRefecth,
  } = props
  const { colors, spacing } = useTheme()
  if (userFetching || !userLists || (staffFetching && module === 'staff')) {
    return <Loading />
  }
  return (
    <MobileContainer style={{ backgroundColor: colors.surface1 }}>
      <TopSection
        spacing={spacing}
        colors={colors}
        navigation={navigation}
        module={module}
        userLists={userLists}
        setShowSortDrawer={setShowSortDrawer}
        setShowDrawer={setShowDrawer}
        filterList={filterList}
        filterApplied={Object.keys(filterValue).length}
      />
      {userLists?.length === 0 ? (
        <EdgeCase module={module} />
      ) : (
        <>
          <ListItems
            userLists={userLists}
            profileImageList={profileImageList}
            selectedIDs={selectedIDs}
            goTo={goTo}
            colors={colors}
            handleCheckBox={handleCheckBox}
            handleInviteClick={handleInviteClick}
            expanded={expanded}
            setExpanded={setExpanded}
            tableHeader={tableHeader}
            module={module}
            handleBulkInvite={handleBulkInvite}
          />
          {hasNextPage && (
            <ViewMoreButton
              onPress={fetchNextPage}
              isLoading={isFetchingNextPage}
            />
          )}
        </>
      )}

      <Filter
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        handleDropdownChange={handleDropdownChange}
        filterValue={filterValue}
        fieldData={filterList}
        onChangeText={onChangeText}
        setErrors={setErrors}
        user={user}
        disableField={disableField}
        hasDelete
        refetch={refetch}
        module={module}
        getLookupData={getLookupData}
        totalCountRefecth={totalCountRefecth}
      />
      <Sort
        showDrawer={showSortDrawer}
        setShowDrawer={setShowSortDrawer}
        sortList={sortList}
        onSortSelected={onSortSelected}
        selectedSortValue={selectedSortValue}
      />
    </MobileContainer>
  )
}

export default MobileView
