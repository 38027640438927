import React from 'react'

import { Text } from '@camped/components'
import { MobileContainer } from '@camped/shared-container'

const MobileView = () => (
  <MobileContainer>
    <Text>Logging Out</Text>
  </MobileContainer>
)

export default MobileView
