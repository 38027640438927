import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { Chip, useTheme } from 'react-native-paper'

import { CircularProgress, Divider, Row } from '@camped/components'
import ImageUpload from '@camped/shared-component-edit-image'
import { spacing } from '@camped/theme-provider'

const DesktopView = ({
  module,
  attachment,
  fileInputRef,
  onUpload,
  handleAddImage,
  getValueByFieldName,
  image,
}) => {
  const { colors, fonts } = useTheme()
  const { t } = useTranslation()
  return (
    <View style={{ flex: 0.2, height: '100%' }}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: colors.surface1 }}
      >
        <View style={styles.main(colors)}>
          <View style={{ alignSelf: 'center' }}>
            <ImageUpload
              attachment={attachment}
              photo={image}
              fileInputRef={fileInputRef}
              handleAddImage={handleAddImage}
              onUpload={onUpload}
            />
          </View>

          <View style={styles.container}>
            {module === 'staff' ||
            module === 'staffProfile' ||
            module === 'adminProfile' ? (
              <View>
                <Text
                  variant='bodyMedium'
                  style={{
                    color: colors.onSurface1,
                    fontWeight: '300',
                    alignSelf: 'center',
                  }}
                >
                  {getValueByFieldName('firstName')}{' '}
                  {getValueByFieldName('lastName')}
                </Text>
                <Row
                  style={{
                    justifyContent: 'center',
                    gap: spacing.spacing3,
                    paddingTop: spacing.spacing3,
                  }}
                >
                  <Chip
                    selectedColor={colors.onSurface1}
                    style={{
                      borderRadius: 50,
                      backgroundColor: colors.surfaceVariant,
                      alignSelf: 'center',
                      ...fonts.labelMedium,
                    }}
                  >
                    {getValueByFieldName('employeeId')}
                  </Chip>
                  {getValueByFieldName('accommodationType') && (
                    <Chip
                      selectedColor={colors.onSurface1}
                      style={{
                        borderRadius: 50,
                        backgroundColor: colors.surfaceVariant,
                        alignSelf: 'center',

                        ...fonts.labelMedium,
                      }}
                    >
                      {getValueByFieldName('accommodationType')}
                    </Chip>
                  )}
                </Row>
                <Divider style={{ marginVertical: spacing.spacing4 }} />

                <View style={styles.courseView}>
                  <View
                    style={{
                      width: '50%',
                    }}
                  >
                    <Text
                      variant='labelMedium'
                      style={{ color: colors.onSurface1, opacity: 0.4 }}
                    >
                      {t('Directory.TITLE')}
                    </Text>
                    <Text
                      variant='labelLarge'
                      style={{ color: colors.onSurface1, fontWeight: '300' }}
                    >
                      {getValueByFieldName('title')}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '50%',
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      variant='labelMedium'
                      style={{ color: colors.onSurface1, opacity: 0.4 }}
                    >
                      {t('Directory.DEPARTMENT')}
                    </Text>
                    <Text
                      variant='bodyMedium'
                      style={{ color: colors.onSurface1, fontWeight: '300' }}
                    >
                      {getValueByFieldName('department')}
                    </Text>
                  </View>
                </View>
                <View
                  style={[styles.courseView, { paddingTop: spacing.spacing3 }]}
                >
                  <View
                    style={{
                      width: '50%',
                    }}
                  >
                    <Text
                      variant='labelMedium'
                      style={{ color: colors.onSurface1, opacity: 0.4 }}
                    >
                      {t('Directory.DESIGNATION')}
                    </Text>
                    <Text
                      variant='bodyMedium'
                      style={{ color: colors.onSurface1, fontWeight: '300' }}
                    >
                      {getValueByFieldName('designation')}
                    </Text>
                  </View>
                </View>
                <Divider style={{ marginVertical: spacing.spacing4 }} />
                <View>
                  <Text
                    variant='labelMedium'
                    style={{
                      color: colors.onSurface1,
                      marginBottom: spacing.spacing2,
                      opacity: 0.4,
                    }}
                  >
                    {t('Directory.EMPLOYMENT_TYPE')}
                  </Text>
                  <Text
                    variant='bodyMedium'
                    style={{ color: colors.onSurface1, fontWeight: '300' }}
                  >
                    {getValueByFieldName('employeeType')}
                  </Text>
                </View>
                <Divider style={{ marginVertical: spacing.spacing4 }} />
                <View style={{ gap: spacing.spacing5 }}>
                  {getValueByFieldName('attendance') && (
                    <View
                      style={{
                        borderRadius: 8,
                        backgroundColor: 'rgba(165, 247, 137, 0.12)',
                        padding: spacing.spacing4,
                      }}
                    >
                      <Text
                        variant='labelMedium'
                        style={{
                          color: colors.error,
                          marginBottom: spacing.spacing3,
                        }}
                      >
                        {t('Directory.ATTENDANCE')}
                      </Text>
                      <CircularProgress
                        value={getValueByFieldName('attendance')}
                        text={`${getValueByFieldName('attendance')}%`}
                      />
                    </View>
                  )}
                </View>
              </View>
            ) : (
              <View>
                <Text
                  variant='bodyMedium'
                  style={{
                    color: colors.onSurface1,
                    fontWeight: '300',
                    alignSelf: 'center',
                  }}
                >
                  {getValueByFieldName('firstName')}{' '}
                  {getValueByFieldName('lastName')}
                </Text>
                <Row
                  style={{
                    justifyContent: 'center',
                    gap: spacing.spacing3,
                    paddingTop: spacing.spacing3,
                  }}
                >
                  {getValueByFieldName('registrationNumber') && (
                    <Chip
                      selectedColor={colors.onSurface1}
                      style={{
                        borderRadius: 50,
                        backgroundColor: colors.surfaceVariant,
                        alignSelf: 'center',
                        ...fonts.labelMedium,
                      }}
                    >
                      {getValueByFieldName('registrationNumber')}
                    </Chip>
                  )}
                  {getValueByFieldName('accommodationType') && (
                    <Chip
                      selectedColor={colors.onSurface1}
                      style={{
                        borderRadius: 50,
                        backgroundColor: colors.surfaceVariant,
                        alignSelf: 'center',

                        ...fonts.labelMedium,
                      }}
                    >
                      {getValueByFieldName('accommodationType')}
                    </Chip>
                  )}
                </Row>
                <Divider style={{ marginVertical: spacing.spacing4 }} />

                <View style={styles.courseView}>
                  <View
                    style={{
                      width: '50%',
                    }}
                  >
                    <Text
                      variant='labelMedium'
                      style={{ color: colors.onSurface1, opacity: 0.4 }}
                    >
                      {t('Directory.COURSE')}
                    </Text>
                    <Text
                      variant='bodyMedium'
                      style={{ color: colors.onSurface1, fontWeight: '300' }}
                    >
                      {getValueByFieldName('course')?.toUpperCase()}
                    </Text>
                  </View>
                  <View>
                    <Text
                      variant='labelMedium'
                      style={{ color: colors.onSurface1, opacity: 0.4 }}
                    >
                      {t('Directory.DEGREE')}
                    </Text>
                    <Text
                      variant='bodyMedium'
                      style={{ color: colors.onSurface1, fontWeight: '300' }}
                    >
                      {getValueByFieldName('degree')?.toUpperCase()}
                    </Text>
                  </View>
                </View>
                <Divider style={{ marginVertical: spacing.spacing4 }} />
                <View>
                  <Text
                    variant='labelMedium'
                    style={{
                      color: colors.onSurface1,
                      marginBottom: spacing.spacing2,
                      opacity: 0.4,
                    }}
                  >
                    {t('Directory.ACADEMIC_YEAR')}
                  </Text>
                  <Text
                    variant='bodyMedium'
                    style={{ color: colors.onSurface1, fontWeight: '300' }}
                  >
                    {getValueByFieldName('academicStartYear')} -{' '}
                    {getValueByFieldName('academicEndYear')}
                  </Text>
                </View>
                <Divider style={{ marginVertical: spacing.spacing4 }} />
                <View style={{ gap: spacing.spacing5 }}>
                  {getValueByFieldName('attendance') && (
                    <View
                      style={{
                        borderRadius: 8,
                        backgroundColor: 'rgba(165, 247, 137, 0.12)',
                        padding: spacing.spacing4,
                      }}
                    >
                      <Text
                        variant='titleMedium'
                        style={{
                          color: colors.error,
                          marginBottom: spacing.spacing3,
                        }}
                      >
                        {t('Directory.ATTENDANCE')}
                      </Text>
                      <CircularProgress
                        value={getValueByFieldName('attendance') || 80}
                        text={`${getValueByFieldName('attendance')}%`}
                      />
                    </View>
                  )}
                  {getValueByFieldName('CGPA') && (
                    <View
                      style={{
                        borderRadius: 8,
                        backgroundColor: 'rgba(165, 247, 137, 0.12)',
                        padding: spacing.spacing4,
                      }}
                    >
                      <Text
                        variant='titleMedium'
                        style={{
                          color: colors.error,
                          marginBottom: spacing.spacing3,
                          opacity: 0.4,
                        }}
                      >
                        {t('Directory.CGPA')}
                      </Text>
                      <CircularProgress
                        value={getValueByFieldName('CGPA') * 10}
                        text={getValueByFieldName('CGPA')}
                      />
                    </View>
                  )}
                </View>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  divider: (colors) => ({
    backgroundColor: colors.primaryContainer,
    height: '100%',
  }),
  container: {
    marginTop: spacing.spacing4,
  },
  courseView: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  main: (colors) => ({
    padding: spacing.spacing4,
    backgroundColor: colors.surface1,
    flex: 1,
  }),
  button: (colors) => ({
    borderColor: colors.primary,
    marginRight: spacing.spacing5,
  }),
  buttonView: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    marginVertical: spacing.spacing6,
  },
  loader: (colors) => ({
    flex: 1,
    backgroundColor: colors.surface1,
    justifyContent: 'center',
  }),
})

export default DesktopView
