export const addAcademics = {
  _id: null,
  profileImageUrl: null,
  sections: [
    {
      name: 'basicInfo',
      fields: [
        [
          {
            value: '',
            metadata: {
              _id: '64d5fda1886e9880859b3fa4',
              userPerson: 'ACADEMICS',
              collectionName: 'academics',
              screenName: 'addAcademics',
              fieldName: 'graduationType',
              placeholder: 'Graduation Type',
              label: 'Graduation Type',
              component: 'Dropdown',
              displayOrder: 1,
              errorMessage: '',
              isMandatory: true,
              maxLength: 100,
              mode: 'outlined',
              multiline: false,
              valueType: 'select',
              displayName: 'basicInfo',
              options: [
                {
                  displayName: 'PG',
                  value: 'pg',
                  displayOrder: 1,
                },
                {
                  displayName: 'UG',
                  value: 'ug',
                  displayOrder: 2,
                },
              ],
              arrayName: null,
              link: null,
            },
            parent: 'basicInfo',
            type: 'field',
            permissions: {
              edit: true,
              view: true,
              add: true,
              delete: true,
            },
          },
          {
            value: '',
            metadata: {
              _id: '64d5fda1886e9880859b3f9d',
              userPerson: 'ACADEMICS',
              collectionName: 'academics',
              screenName: 'addAcademics',
              fieldName: 'course',
              placeholder: 'Course',
              label: 'Course',
              component: 'Dropdown',
              displayOrder: 5,
              errorMessage: '',
              isMandatory: true,
              maxLength: 100,
              mode: 'outlined',
              multiline: false,
              valueType: 'string',
              displayName: 'basicInfo',
              options: [
                {
                  displayName: 'BE',
                  value: 'be',
                  displayOrder: 1,
                },
                {
                  displayName: 'BTech',
                  value: 'btech',
                  displayOrder: 2,
                },
              ],
              arrayName: null,
              link: null,
            },
            parent: 'basicInfo',
            type: 'field',
            permissions: {
              edit: true,
              view: true,
              add: true,
              delete: true,
            },
          },
          {
            value: '',
            metadata: {
              _id: '64d5fda1886e9880859b3f9f',
              userPerson: 'ACADEMICS',
              collectionName: 'academics',
              screenName: 'addAcademics',
              fieldName: 'department',
              placeholder: 'Department',
              label: 'Department',
              component: 'Dropdown',
              displayOrder: 8,
              errorMessage: '',
              isMandatory: true,
              maxLength: 100,
              mode: 'outlined',
              multiline: false,
              valueType: 'string',
              displayName: 'basicInfo',
              options: [
                {
                  displayName: 'CSE',
                  value: 'cse',
                  displayOrder: 1,
                },
                {
                  displayName: 'IT',
                  value: 'it',
                  displayOrder: 2,
                },
              ],
              arrayName: null,
              link: null,
            },
            parent: 'basicInfo',
            type: 'field',
            permissions: {
              edit: true,
              view: true,
              add: true,
              delete: true,
            },
          },
          {
            value: '',
            metadata: {
              _id: '64d5fda1886e9880859b3f9f',
              userPerson: 'ACADEMICS',
              collectionName: 'academics',
              screenName: 'addAcademics',
              fieldName: 'specialiation',
              placeholder: 'Specialiation',
              label: 'Specialiation',
              component: 'Dropdown',
              displayOrder: 3,
              errorMessage: '',
              isMandatory: true,
              maxLength: 100,
              mode: 'outlined',
              multiline: false,
              valueType: 'string',
              displayName: 'basicInfo',
              options: [
                {
                  displayName: 'CSE',
                  value: 'cse',
                  displayOrder: 1,
                },
                {
                  displayName: 'IT',
                  value: 'it',
                  displayOrder: 2,
                },
              ],
              arrayName: null,
              link: null,
            },
            parent: 'basicInfo',
            type: 'field',
            permissions: {
              edit: true,
              view: true,
              add: true,
              delete: true,
            },
          },
          {
            value: '',
            metadata: {
              _id: '64d5fda1886e9880859b3fa5',
              userPerson: 'ACADEMICS',
              collectionName: 'academics',
              screenName: 'addAcademics',
              fieldName: 'yearOfStarting',
              placeholder: 'Year Of Starting',
              label: 'Year Of Starting',
              component: 'Dropdown',
              displayOrder: 4,
              errorMessage: '',
              isMandatory: true,
              maxLength: 100,
              mode: 'outlined',
              multiline: false,
              valueType: 'select',
              displayName: 'basicInfo',
              options: [
                {
                  displayName: '2021',
                  value: '2021',
                  displayOrder: 1,
                },
                {
                  displayName: '2022',
                  value: '2022',
                  displayOrder: 2,
                },
                {
                  displayName: '2019',
                  value: '2019',
                  displayOrder: 3,
                },
                {
                  displayName: '2018',
                  value: '2018',
                  displayOrder: 4,
                },
                {
                  displayName: '2017',
                  value: '2017',
                  displayOrder: 5,
                },
                {
                  displayName: '2016',
                  value: '2016',
                  displayOrder: 6,
                },
              ],
              arrayName: null,
              link: null,
            },
            parent: 'basicInfo',
            type: 'field',
            permissions: {
              edit: true,
              view: true,
              add: true,
              delete: true,
            },
          },
          {
            value: '',
            metadata: {
              _id: '64d5fda1886e9880859b3f9b',
              userPerson: 'ACADEMICS',
              collectionName: 'academics',
              screenName: 'addAcademics',
              fieldName: 'numberOfYears',
              placeholder: 'Number Of Years',
              label: 'Number Of Years',
              component: 'TextInput',
              displayOrder: 5,
              errorMessage: '',
              isMandatory: true,
              maxLength: 100,
              mode: 'outlined',
              multiline: false,
              valueType: 'string',
              displayName: 'basicInfo',
              options: [],
              arrayName: null,
              link: null,
            },
            parent: 'basicInfo',
            type: 'field',
            permissions: {
              edit: true,
              view: true,
              add: true,
              delete: true,
            },
          },
          {
            value: '',
            metadata: {
              _id: '64d5fda1886e9880859b3f9b',
              userPerson: 'ACADEMICS',
              collectionName: 'academics',
              screenName: 'addAcademics',
              fieldName: 'numberOfSemester',
              placeholder: 'Number Of Semester',
              label: 'Number Of Semester',
              component: 'TextInput',
              displayOrder: 6,
              errorMessage: '',
              isMandatory: true,
              maxLength: 100,
              mode: 'outlined',
              multiline: false,
              valueType: 'select',
              displayName: 'basicInfo',
              options: [],
              arrayName: null,
              link: null,
            },
            parent: 'basicInfo',
            type: 'field',
            permissions: {
              edit: true,
              view: true,
              add: true,
              delete: true,
            },
          },
          {
            value: '',
            metadata: {
              _id: '64d5fda1886e9880859b3fa3',
              userPerson: 'ACADEMICS',
              collectionName: 'academics',
              screenName: 'addAcademics',
              fieldName: 'modeOFStudy',
              placeholder: 'Mode OF Study',
              label: 'Mode OF Study',
              component: 'Dropdown',
              displayOrder: 7,
              errorMessage: '',
              isMandatory: true,
              maxLength: 100,
              mode: 'outlined',
              multiline: false,
              valueType: 'select',
              displayName: 'basicInfo',
              options: [
                {
                  displayName: 'Full Time',
                  value: 'fullTime',
                  displayOrder: 1,
                },
                {
                  displayName: 'Part Time',
                  value: 'partTime',
                  displayOrder: 2,
                },
              ],
              arrayName: null,
              link: null,
            },
            parent: 'basicInfo',
            type: 'field',
            permissions: {
              edit: true,
              view: true,
              add: true,
              delete: true,
            },
          },
        ],
      ],
      metadata: {
        _id: '64d6010c886e9880859b3ff2',
        userPerson: 'ACADEMICS',
        collectionName: 'academics',
        screenName: 'addAcademics',
        fieldName: 'basicInfo',
        placeholder: null,
        label: null,
        component: 'section',
        displayOrder: 1,
        errorMessage: null,
        isMandatory: null,
        maxLength: null,
        mode: null,
        multiline: null,
        valueType: null,
        displayName: 'Basic Info',
        options: [],
        arrayName: null,
        link: null,
      },
      parent: 'personalInfo',
      type: 'section',
      permissions: {
        edit: true,
        view: true,
        add: true,
        delete: true,
      },
    },
  ],
  tabs: [
    {
      name: null,
      fields: null,
      metadata: {
        _id: '64d6017f886e9880859b3ff5',
        userPerson: 'ACADEMICS',
        collectionName: 'academics',
        screenName: 'addAcademics',
        fieldName: 'personalInfo',
        placeholder: null,
        label: null,
        component: 'tab',
        displayOrder: 1,
        errorMessage: null,
        isMandatory: null,
        maxLength: null,
        mode: null,
        multiline: null,
        valueType: null,
        displayName: 'Personal Info',
        options: [],
        arrayName: null,
        link: null,
      },
      parent: '',
      type: 'tab',
      permissions: {
        edit: true,
        view: true,
        add: true,
        delete: true,
      },
    },
  ],
}
