import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { API } from '@camped/api'
import { Text } from '@camped/components'
import { ScreenLayout } from '@camped/utils'

import DesktopView from './desktop-view'
import MobileView from './mobile-view'

const ProfileSideSection = (props) => {
  const { summaryInfo } = props
  const { t } = useTranslation()
  const [image, setImage] = useState()
  const getImage = async () => {
    const res = await API.getViewProfileImageUrlAPI({
      s3Data: [{ path: getValueByFieldName('profileImage') }],
    })
    setImage(res?.[0]?.imageUrl)
  }
  useEffect(() => {
    getImage()
  }, [])
  const getValueByFieldName = (fieldName) => {
    let value = null
    summaryInfo?.sections?.forEach((section) => {
      section.fields?.forEach((fieldGroup) => {
        fieldGroup.forEach((field) => {
          if (
            field.metadata.fieldName === fieldName ||
            field.metadata.referenceName === fieldName
          ) {
            value = field.value
          }
        })
      })
    })
    return value
  }
  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )
  const viewProps = {
    getValueByFieldName,
    image,
    ...props,
  }
  return (
    <Suspense fallback={<Text>{t('Directory.LOADING')}</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default ProfileSideSection
