import React, { useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { DesktopContainer } from '@camped/shared-container'

import { EditQuestionPaper } from './edit-question-paper'
import QuestionPaperCard from './question-paper-card'
import { ViewQuestionPaper } from './view-questionpaper'

const QuestioPaperListing = (props) => {
  const { colors, spacing } = useTheme()
  const [showQuestionPaper, setShowQuestionPaper] = useState('')
  const [selectedQuestioPaper, setselectedQuestioPaper] = useState(false)
  const { mainValue, subject, handleEditQuestionPaper } = props
  const questionsPapers = mainValue?.fields?.[0]?.[0]?.value?.questionsFilter
  const handleClick = (questionPaper, viewType) => {
    setShowQuestionPaper(viewType)
    setselectedQuestioPaper(questionPaper)
  }
  if (!showQuestionPaper && questionsPapers?.length !== 0 && questionsPapers) {
    return (
      <DesktopContainer>
        <View
          style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}
        >
          {questionsPapers?.map((questionPaper) => (
            <QuestionPaperCard
              key={questionPaper?._id}
              item={questionPaper}
              onClick={handleClick}
            />
          ))}
        </View>
      </DesktopContainer>
    )
  }
  if (showQuestionPaper === 'edit') {
    return (
      <EditQuestionPaper
        question={selectedQuestioPaper}
        subject={subject}
        setShowQuestionPaper={setShowQuestionPaper}
        handleSubmit={handleEditQuestionPaper}
      />
    )
  }
  if (showQuestionPaper === 'view') {
    return (
      <ViewQuestionPaper
        question={selectedQuestioPaper}
        subject={subject}
        setShowQuestionPaper={setShowQuestionPaper}
        handleSubmit={handleEditQuestionPaper}
      />
    )
  }
  return (
    <Text
      style={{
        flexDirection: 'row',
        fontWeight: '700',
        color: colors.onSurface1,
        fontSize: 18,
        justifyContent: 'center',
        textTransform: 'capitalize',
        paddingBottom: spacing.spacing4,
        paddingTop: spacing.spacing4,
      }}
    >
      Please Generate Question
    </Text>
  )
}

export default QuestioPaperListing
