import React from 'react'
import { FlatList, Text, View, useWindowDimensions } from 'react-native'
import { useTheme } from 'react-native-paper'
import { BarChart } from 'react-native-svg-charts'

import { ScreenLayout } from '@camped/utils'

const CustomXAxis = ({ chartData }) => {
  const numColumns = chartData?.length
  const columnWidth = `${100 / numColumns}%`
  const { colors } = useTheme()

  return (
    <FlatList
      data={chartData}
      numColumns={numColumns}
      renderItem={({ item }) => (
        <View style={{ width: columnWidth, alignItems: 'center' }}>
          <View>
            <Text style={{ fontSize: 14, color: colors.onSurface }}>
              {item?.department?.substring(0, 4)}
            </Text>
            <Text
              style={{
                fontSize: 14,
                color: colors.onSurface,
                textAlign: 'center',
              }}
            >
              {(item?.femaleCount ?? 0) + (item?.maleCount ?? 0)}
            </Text>
          </View>
        </View>
      )}
      keyExtractor={(item, index) => index.toString()}
    />
  )
}

// const HoverComponent = ({ visible, x, y, data }) => {
//   if (!visible) return null

//   return (
//     <View
//       style={{
//         position: 'absolute',
//         top: y - 80, // Adjust the vertical position of the hover component
//         left: x - 60, // Adjust the horizontal position of the hover component
//         backgroundColor: 'white',
//         padding: 10,
//         borderRadius: 5,
//         shadowColor: 'black',
//         shadowOffset: { width: 0, height: 2 },
//         shadowOpacity: 0.2,
//         elevation: 2,
//       }}
//     >
//       <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
//         {data.course}-{data.department}
//       </Text>
//       <Text>Males: {data.maleCount}</Text>
//       <Text>Females: {data.femaleCount}</Text>
//     </View>
//   )
// }

export const HomeBarChart = (props) => {
  const { colors } = useTheme()

  const windowWidth = useWindowDimensions().width

  const isDesktop = ScreenLayout?.isWeb(windowWidth)
  //   const [tooltipData, setTooltipData] = useState(null)
  //   const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
  //   const hoverTimeout = useRef(null)

  const { chartData } = props

  if (!chartData?.studentAnalyticCount) {
    return null
  }

  //   const handleBarRelease = () => {
  //     // Clear the hover timeout
  //     if (hoverTimeout.current) {
  //       clearTimeout(hoverTimeout.current)
  //     }

  //     // Hide the hover component
  //     setTooltipData(null)
  //   }

  const groupedData = chartData?.studentAnalyticCount?.reduce(
    (result, item) => {
      const key = `${item.department}`
      if (!result[key]) {
        result[key] = {
          department: item.department,
          maleCount: 0,
          femaleCount: 0,
        }
      }

      if (item.gender === 'Male') {
        result[key].maleCount = item.count
      } else if (item.gender === 'Female') {
        result[key].femaleCount = item.count
      }

      return result
    },
    {},
  )

  const finalChartData = Object.values(groupedData)

  //   const handleBarPress = ({ index, x, y }) => {
  //     const data = finalChartData[index]
  //     console.log({ data })
  //     setTooltipData(data)
  //     setTooltipPosition({ x, y })

  //     // Show the hover component after a delay (e.g., 500 milliseconds)
  //     hoverTimeout.current = setTimeout(() => {
  //       // Show the hover component
  //       setTooltipData(data)
  //     }, 500)
  //   }

  const barData = [
    {
      data: finalChartData?.map((value, index) => ({
        value: value?.femaleCount,
        index,
      })),
      svg: {
        key: 'female',
        fill: '#FF9FF0',
        // onMouseEnter: (event, index) => {
        //   console.log(event)
        //   alert(index)
        //   handleBarPress({
        //     index: 0,
        //     x: event?.x,
        //     y: event?.y,
        //   })
        // },
        // onMouseLeave: () => {
        //   handleBarRelease()
        // },
      },
    },
    {
      data: finalChartData?.map((value) => ({ value: value?.maleCount })),
      svg: {
        key: 'male',
        fill: '#9FDCFF',
        // onMouseEnter: (event, index) => {
        //   console.log(event)
        //   alert(index)
        //   handleBarPress({
        //     index: 0,
        //     x: event?.x,
        //     y: event?.y,
        //   })
        // },
        // onMouseLeave: () => {
        //   handleBarRelease()
        // },
      },
    },
  ]

  return (
    <View
      style={{
        width: '100%',
        backgroundColor: colors.surface2,
        height: '100%',
        borderColor: colors.surfaceVariant,
        borderRadius: 8,
        borderWidth: 1,
        padding: 20,
      }}
    >
      <TopSection title='Student' />
      <View style={{ flexDirection: isDesktop ? 'row' : 'column' }}>
        <View style={{ marginRight: 40, marginTop: 30 }}>
          <Text
            style={{ fontSize: 40, fontWeight: '600', color: colors.onSurface }}
          >
            {chartData?.total}
          </Text>
          <Text style={{ color: colors.onSurface }}>total students</Text>
        </View>
        <View style={{ width: finalChartData.length * 50 }}>
          <BarChart
            style={{ height: 200, zIndex: 1000 }}
            data={barData}
            yAccessor={({ item }) => item?.value}
            contentInset={{ top: 30, bottom: 30 }}
            spacingInner={0.4}
            spacingOuter={0.2}
          >
            {/* <Grid /> */}
          </BarChart>
          {/* <HoverComponent
            visible={!!tooltipData}
            x={tooltipPosition.x}
            y={tooltipPosition.y}
            data={tooltipData}
          /> */}
          <CustomXAxis chartData={finalChartData} />
        </View>
      </View>
    </View>
  )
}

const TopSection = ({ title }) => {
  const { colors } = useTheme()
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Text
        style={{ fontSize: 22, color: colors.onSurface, fontWeight: '600' }}
      >
        {title}
      </Text>
      <Legend>
        <LegendItem label='Female' color='#FF9FF0' />
        <LegendItem label='Male' color='#9FDCFF' />
      </Legend>
    </View>
  )
}

const Legend = ({ children }) => (
  <View style={{ flexDirection: 'row', gap: 16 }}>{children}</View>
)

const LegendItem = ({ label, color }) => {
  const { colors } = useTheme()
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <View
        style={{
          width: 12,
          height: 12,
          backgroundColor: color,
          marginRight: 8,
        }}
      />
      <Text style={{ color: colors.onSurface }}>{label}</Text>
    </View>
  )
}
