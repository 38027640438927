import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  FlatList,
  Modal,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'

import CheckBox from '../CheckBox'
import Text from '../Text'
import TextInput from '../TextInput'

const DropDown2 = (props) => {
  const {
    disabled,
    dropDownWidth,
    dropDownItemSelectedStyle,
    dropDownItemStyle,
    error,
    field,
    iconStyle,
    label,
    list,
    multiSelect = false,
    placeholder,
    setValue,
    value,
  } = props
  const { colors, spacing } = useTheme()
  const DropdownButton = useRef()
  const [visible, setVisible] = useState(false)
  const [displayValue, setDisplayValue] = useState('')
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropDownLeft, setDropDownLeft] = useState(0)
  const [dropDownRight, setDropDownRight] = useState(0)

  const toggleDropdown = () => (visible ? setVisible(false) : openDropdown())

  const openDropdown = () => {
    DropdownButton.current.measure((_fx, _fy, _w, h, _px, py) => {
      setDropdownTop(py + h)
      setDropDownLeft(_px - _fx)
      setDropDownRight(_px - _fx)
      setVisible(true)
    })
  }

  useEffect(() => {
    if (multiSelect) {
      const labels = list
        ?.filter(
          (_) => value?.indexOf(_.value) !== -1 || value?.indexOf(_.id) !== -1,
        )
        ?.map((_) => _.label || _?.displayName || _?.value)
      setDisplayValue(labels)
    } else {
      let labelLocal
      if (field) {
        labelLocal = list?.find((_) => _?.[field] === value)?.[field]
      } else {
        const lcl = list?.find(
          (_) =>
            _?.value === value ||
            _?.label === value ||
            _?.displayName === value,
        )
        labelLocal = lcl?.label || lcl?.displayName || lcl?.value
      }
      if (labelLocal) {
        setDisplayValue(labelLocal)
      }
    }
  }, [list, value])

  const setActive = useCallback(
    (currentValue) => {
      if (multiSelect) {
        const valueIndex = value?.indexOf(currentValue)
        if (valueIndex === -1) {
          setValue([...value, currentValue])
        } else {
          setValue([...value].filter((value1) => value1 !== currentValue))
        }
      } else {
        setValue(currentValue)
      }
    },
    [(value, setValue)],
  )

  const isActive = useCallback(
    (currentValue) => {
      if (multiSelect) {
        return value?.indexOf(currentValue) !== -1
      }
      return value === currentValue
    },
    [value],
  )
  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={[
        styles(colors, spacing).item,
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      ]}
      onPress={() => {
        if (multiSelect) {
          setActive(item?.id || item.value)
        } else {
          setActive(item || item?.[field])
          setVisible(false)
        }
      }}
    >
      <Text
        style={{
          fontSize: 13,
          color: colors.text,
          fontWeight: isActive(item?.displayName || item?.value)
            ? '800'
            : '400',
          paddingHorizontal: spacing.spacing4,
          ...(isActive(item.value)
            ? dropDownItemSelectedStyle
            : dropDownItemStyle),
        }}
        variant='bodyLarge'
      >
        {item.custom || item.label || item?.[field] || item?.displayName}
      </Text>
      {multiSelect && (
        <CheckBox
          iconName={
            isActive(item?.id || item?.value) ? 'Selection' : 'DeSelection'
          }
          status={isActive(item?.id || item?.value) ? 'checked' : 'unchecked'}
          onPress={() => setActive(item?.id || item?.value)}
        />
      )}
    </TouchableOpacity>
  )
  const renderDropdown = () => (
    <Modal visible={visible} transparent animationType='none'>
      <TouchableOpacity
        onPress={() => {
          setVisible(false)
        }}
      >
        <View
          style={{
            paddingHorizontal: spacing.spacing5,
            height: '100%',
          }}
        >
          <View
            style={{
              top: dropdownTop,
              left: Platform.OS === 'android' ? 0 : dropDownLeft,
              marginRight: Platform.OS === 'android' ? 0 : dropDownRight,
              maxHeight: 240,
              borderRadius: 4,
              backgroundColor: colors.background,
              width: dropDownWidth,
            }}
          >
            <FlatList
              data={list}
              renderItem={renderItem}
              keyExtractor={(item, index) => index.toString()}
            />
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  )

  return (
    <TouchableOpacity
      ref={DropdownButton}
      onPress={() => {
        toggleDropdown()
        props?.onFocus?.()
      }}
      disabled={disabled}
    >
      {renderDropdown()}
      <TextInput
        mode='outlined'
        label={label}
        value={displayValue.length ? displayValue : ''}
        error={error}
        pointerEvents='none'
        editable={false}
        disabled={disabled}
        placeholder={placeholder}
        style={{ fontSize: 13, backgroundColor: colors.surface2, height: 40 }}
      />
      <Icon
        name='DownArrowFilled'
        color={colors.onSurfaceVariant}
        style={[
          {
            height: spacing.spacing4,
            width: spacing.spacing4,
            position: 'absolute',
            right: spacing.spacing4,
            top: Platform.OS === 'web' ? '40%' : '20%',
            opacity: disabled ? 0.5 : 1,
          },
          iconStyle,
        ]}
      />
    </TouchableOpacity>
  )
}

const styles = (colors, spacing) =>
  StyleSheet.create({
    item: {
      paddingHorizontal: spacing.spacing3,
      paddingVertical: spacing.spacing3,
      backgroundColor: colors.surface2,
      borderColor: colors.black,
    },
  })

export default DropDown2
