import React from 'react'
import { ScrollView, View } from 'react-native'
import { Checkbox, Surface, Switch, useTheme } from 'react-native-paper'

import {
  DataTableCell,
  DataTableHeader,
  DataTableRow,
  DataTableTitle,
  Text,
} from '@camped/components'
import { spacing } from '@camped/theme-provider'

import ToggleAccodion from '../accordion/ToggleAccordion'

const ModulePermissions = (props) => {
  const {
    selectedTab,
    fieldsData,
    sectionsData,
    tabsData,
    toggleSwitch,
    handleSectionEnable,
    module,
    handleTabEnable,
  } = props
  const { colors } = useTheme()

  return (
    <>
      {!!tabsData?.length && (
        <Surface
          style={{
            backgroundColor: colors.surface2,
            display: 'flex',
            flexDirection: 'row',
            paddingHorizontal: 20,
            alignItems: 'center',
            marginHorizontal: 12,
            marginTop: 20,
            padding: 15,
            marginBottom: 10,
            borderRadius: 10,
          }}
        >
          <Text variant='titleMedium' style={{ flex: 1 }}>
            Personal Info
          </Text>
          <Switch
            trackColor={{ false: '#767577', true: '#81b0ff' }}
            thumbColor={selectedTab?.isEnabled ? '#f5dd4b' : '#f4f3f4'}
            ios_backgroundColor='#3e3e3e'
            onValueChange={(value) => handleTabEnable(value)}
            value={selectedTab?.isEnabled}
            style={{ width: 10 }}
          />
        </Surface>
      )}

      <DataTableHeader
        style={{
          borderTopLeftRadius: spacing.spacing4,
          borderTopRightRadius: spacing.spacing4,
          height: 40,
          alignItems: 'center',
          marginHorizontal: 10,
        }}
      >
        <DataTableTitle
          style={{ flex: 2, justifyContent: 'center' }}
          textVariant='titleMedium'
        >
          Fields
        </DataTableTitle>
        <DataTableTitle
          style={{ flex: 1, justifyContent: 'center' }}
          textVariant='titleMedium'
        >
          View
        </DataTableTitle>
        <DataTableTitle
          style={{ flex: 1, justifyContent: 'center' }}
          textVariant='titleMedium'
        >
          Add
        </DataTableTitle>
        <DataTableTitle
          style={{ flex: 1, justifyContent: 'center' }}
          textVariant='titleMedium'
        >
          Edit
        </DataTableTitle>
        <DataTableTitle
          style={{ flex: 1, justifyContent: 'center' }}
          textVariant='titleMedium'
        >
          Delete
        </DataTableTitle>
        <DataTableTitle
          style={{ flex: 0.2, justifyContent: 'center' }}
          textVariant='titleMedium'
        />
      </DataTableHeader>
      <ScrollView
        style={{
          marginBottom: 18,
        }}
      >
        {module === 'Role' &&
          sectionsData?.map((section, index) => (
            <ToggleAccodion
              title={section?.section?.displayName}
              index={index}
              togglebutton={handleSectionEnable}
              isEnabled={section?.permissions?.view === true}
              section={section}
            >
              <View style={{ padding: 10 }}>
                {fieldsData?.map((field) => {
                  if (field?.field?.section._id === section?.section?._id) {
                    return (
                      <DataTableRow key={field?.field?._id} hasHover={false}>
                        <DataTableCell
                          flexN={2}
                          style={{
                            justifyContent: 'center',
                          }}
                          textVariant='bodyMedium'
                        >
                          {field?.field?.label}
                        </DataTableCell>
                        {['view', 'add', 'edit', 'delete'].map(
                          (permission, i) => (
                            <DataTableCell
                              key={i}
                              style={{ justifyContent: 'center' }}
                            >
                              <Checkbox
                                status={
                                  field?.permissions?.[permission]
                                    ? 'checked'
                                    : 'unchecked'
                                }
                                onPress={() => {
                                  toggleSwitch(
                                    permission,
                                    field,
                                    !field?.permissions?.[permission],
                                  )
                                }}
                              />
                            </DataTableCell>
                          ),
                        )}
                      </DataTableRow>
                    )
                  }
                  return null
                })}
              </View>
            </ToggleAccodion>
          ))}
      </ScrollView>
    </>
  )
}

export default ModulePermissions
