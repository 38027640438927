import React from 'react'

import { Text } from '@camped/components'
import { DesktopContainer } from '@camped/shared-container'

const DesktopView = () => (
  <DesktopContainer>
    <Text>Logging Out</Text>
  </DesktopContainer>
)

export default DesktopView
