import React, { useEffect, useState } from 'react'
import { StyleSheet, View, useWindowDimensions } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Button, Text } from '@camped/components'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout } from '@camped/utils'

const BoxContainer = (props) => {
  const {
    children,
    handleAdd,
    handleEdit,
    isLoading,
    onCancel,
    onSave,
    style,
    title,
    permission,
    setErrors,
    getUserData,
    userDetail,
  } = props
  const { colors, fonts } = useTheme()
  const { width } = useWindowDimensions()
  const isDesktop = ScreenLayout.isWeb(width)

  const [showBottomActions, setShowBottomActions] = useState(false)

  useEffect(() => {
    if (
      !['Edit Question Paper', 'General', 'Section'].includes(title) &&
      typeof onCancel === 'function'
    ) {
      onCancel()
      setShowBottomActions(false)
    }
    if (title === 'Edit Question Paper') {
      setShowBottomActions(true)
    }
  }, [title])

  const renderButtons = () => (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {handleAdd && permission?.add && (
        <View style={{ marginRight: 5 }}>
          <Button
            labelStyle={{
              margin: isDesktop ? 6 : 8,
              marginHorizontal: 8,
              ...fonts.labelMedium,
            }}
            onPress={() => {
              if ((onSave || onCancel) && title !== 'Section')
                setShowBottomActions(true)
              handleAdd()
            }}
          >
            Add
          </Button>
        </View>
      )}
      {handleEdit && permission?.edit && (
        <Button
          labelStyle={{
            margin: isDesktop ? 6 : 8,
            marginHorizontal: 8,
            ...fonts.labelMedium,
          }}
          onPress={() => {
            if (onSave || onCancel) setShowBottomActions(true)
            handleEdit()
          }}
        >
          {title === 'question Paper Preview'
            ? 'Generate New Question Paper'
            : 'Edit'}
        </Button>
      )}
    </View>
  )

  return (
    <View style={StyleSheet.flatten(styles.container(colors, spacing), style)}>
      <View style={styles.topHeader(colors)}>
        {title ? (
          <Text
            variant={isDesktop ? 'headlineSmall' : 'titleMedium'}
            style={styles.systemAdmin(colors)}
          >
            {title}
          </Text>
        ) : null}

        {!showBottomActions ? (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {renderButtons()}
          </View>
        ) : null}
      </View>
      <View>
        {children}
        {showBottomActions ? (
          <View
            style={{
              marginBottom: spacing.spacing4,
              marginRight: spacing.spacing4,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {onCancel ? (
              <Button
                mode='outlined'
                style={{
                  height: 38,
                  justifyContent: 'center',
                  marginRight: spacing.spacing5,
                }}
                labelStyle={{
                  color: colors.primary,
                }}
                onPress={() => {
                  setShowBottomActions(false)
                  setErrors([])
                  getUserData(userDetail)
                  onCancel()
                }}
              >
                Cancel
              </Button>
            ) : null}
            {onSave ? (
              <Button
                loading={isLoading}
                disabled={isLoading}
                style={{
                  height: 38,
                  justifyContent: 'center',
                }}
                onPress={() => {
                  onSave()
                }}
              >
                Save
              </Button>
            ) : null}
          </View>
        ) : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: (colors) => ({
    backgroundColor: colors.surface1,
    borderRadius: spacing.spacing2,
    marginHorizontal: spacing.spacing4,
    marginTop: spacing.spacing4,
    borderWidth: 1,
    borderColor: colors.surface2,
  }),
  systemAdmin: (colors) => ({
    color: colors.onSurface1,
    fontWeight: '700',
    textTransform: 'capitalize',
    paddingVertical: spacing.spacing4,
  }),
  topHeader: (colors) => ({
    alignItems: 'center',
    backgroundColor: colors.surface2,
    borderTopLeftRadius: spacing.spacing2,
    borderTopRightRadius: spacing.spacing2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: spacing.spacing4,
  }),
})

export default BoxContainer
