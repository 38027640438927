import { useQuery, useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { addAcademics } from '@camped/shared-mock-data'
import { SecureStore } from '@camped/utils'

export const useGetListing = ({ module, id }) => {
  const queryKey = `${module}Detail-${id}`
  const queryClient = useQueryClient()
  const arrayUpdatedSection = (sectionName, data, response, isNew = false) => {
    const section =
      data.sections.find(
        (item) =>
          item?.name === sectionName ||
          item?.metadata?.fieldName === sectionName,
      ) || {}
    const sectionData = isNew ? section?.fields : section?.fields?.[0] || []

    const responseData =
      sectionName === 'subjectDetails' ? response : response?.[sectionName]

    const subjectarray =
      responseData?.map((singleSubject) =>
        sectionData?.map((fieldItem) => {
          if (fieldItem.metadata.referenceName) {
            return {
              ...fieldItem,
              value:
                singleSubject?.[fieldItem.metadata.referenceName]?.[
                  fieldItem?.metadata?.fieldName
                ] ?? '',
            }
          }
          return {
            ...fieldItem,
            value: singleSubject?.[fieldItem?.metadata?.fieldName] ?? '',
          }
        }),
      ) || []
    const updatedSection = {
      ...section,
      fields: subjectarray,
    }

    return updatedSection
  }
  const singleupdatedSection = (sectionName, data, response, isNew = false) => {
    const Section =
      data.sections.find(
        (item) =>
          item?.name === sectionName ||
          item?.metadata?.fieldName === sectionName,
      ) || {}
    const sectionData = isNew ? Section?.fields : Section?.fields?.[0]

    const fieldsMapping = sectionData?.map((fieldItem) => {
      if (fieldItem.metadata.referenceName) {
        return {
          ...fieldItem,
          value:
            response?.[fieldItem.metadata.referenceName]?.[
              fieldItem?.metadata?.fieldName
            ] ?? '',
        }
      }
      return {
        ...fieldItem,
        value: response?.[fieldItem?.metadata?.fieldName] ?? '',
      }
    })
    const updatedSection = {
      ...Section,
      fields: [fieldsMapping],
    }
    return updatedSection
  }
  const questionPaperSection = (sectionName, data, response, isNew = false) => {
    const Section =
      data.sections.find(
        (item) =>
          item?.name === sectionName ||
          item?.metadata?.fieldName === sectionName,
      ) || {}
    const sectionData = isNew ? Section?.fields : Section?.fields?.[0]

    const fieldsMapping = sectionData?.map((fieldItem) => ({
      ...fieldItem,
      value: response ?? '',
    }))

    const updatedSection = {
      ...Section,
      fields: [fieldsMapping],
    }
    return updatedSection
  }

  const screenIds = queryClient.getQueryData(['getScreenIds'])
  const { data, isFetching, refetch } = useQuery(
    [queryKey],
    async () => {
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)
      let screenName

      if (module === 'student') {
        if (id) {
          screenName = 'view-student-details'
        } else {
          screenName = 'add-student'
        }
        const screen = screenIds?.find((item) => item?.fieldName === screenName)
        const studentMetaData = await API.getMetaDataAPI({
          metaData: {
            role: userDetail?.[0]?.userRoleId ?? '',
            tenant: userDetail?.[0]?.tenantId ?? '',
            screen: screen?._id,
          },
          extraHeader: {
            screen: screen?._id,
          },
        })
        if (id) {
          const studentData = await API.getStudentByIdAPI({
            id,
            extraHeader: {
              screen: screen?._id,
            },
          })
          const updatedBasicDetailsSection = singleupdatedSection(
            'basicDetails',
            studentMetaData,
            studentData,
            true,
          )
          const updatedContactInfoSection = singleupdatedSection(
            'contactInfo',
            studentMetaData,
            studentData,
            true,
          )
          const updatedAddressSection = singleupdatedSection(
            'address',
            studentMetaData,
            studentData,
            true,
          )
          const updatedacademicInfoSection = singleupdatedSection(
            'academicInfo',
            studentMetaData,
            studentData,
            true,
          )
          const updatedaccomodationSection = singleupdatedSection(
            'accomodation',
            studentMetaData,
            studentData,
            true,
          )
          const updatedMedicalConditionsSection = singleupdatedSection(
            'medicalConditions',
            studentMetaData,
            studentData,
            true,
          )
          const updatedCastAndReligionSection = singleupdatedSection(
            'castAndReligion',
            studentMetaData,
            studentData,
            true,
          )
          const updatedfeeDetailsSection = singleupdatedSection(
            'feeDetails',
            studentMetaData,
            studentData,
            true,
          )
          const updatedextrahobbiesSection = singleupdatedSection(
            'extraCurricularActivity',
            studentMetaData,
            studentData,
            true,
          )
          const updatedhobbiesSection = singleupdatedSection(
            'hobbies',
            studentMetaData,
            studentData,
            true,
          )
          const updatedcourseDurationSection = singleupdatedSection(
            'courseDuration',
            studentMetaData,
            studentData,
            true,
          )
          const updatedFamilyInfoSection = arrayUpdatedSection(
            'familyInfo',
            studentMetaData,
            studentData,
            true,
          )
          const updatedpreviousAcademicDetailsSection = arrayUpdatedSection(
            'previousAcademicDetails',
            studentMetaData,
            studentData,
            true,
          )
          const updatedplacementsSection = arrayUpdatedSection(
            'placements',
            studentMetaData,
            studentData,
            true,
          )
          const updatedachievementsSection = arrayUpdatedSection(
            'achievements',
            studentMetaData,
            studentData,
            true,
          )
          const updatedSections = studentMetaData.sections.map((item) => {
            if (item?.metadata?.fieldName === 'basicDetails') {
              return updatedBasicDetailsSection
            }
            if (item?.metadata?.fieldName === 'contactInfo') {
              return updatedContactInfoSection
            }
            if (item?.metadata?.fieldName === 'address') {
              return updatedAddressSection
            }
            if (item?.metadata?.fieldName === 'accomodation') {
              return updatedaccomodationSection
            }
            if (item?.metadata?.fieldName === 'medicalConditions') {
              return updatedMedicalConditionsSection
            }
            if (item?.metadata?.fieldName === 'castAndReligion') {
              return updatedCastAndReligionSection
            }
            if (item?.metadata?.fieldName === 'academicInfo') {
              return updatedacademicInfoSection
            }
            if (item?.metadata?.fieldName === 'extraCurricularActivity') {
              return updatedextrahobbiesSection
            }
            if (item?.metadata?.fieldName === 'feeDetails') {
              return updatedfeeDetailsSection
            }
            if (item?.metadata?.fieldName === 'hobbies') {
              return updatedhobbiesSection
            }
            if (item?.metadata?.fieldName === 'courseDuration') {
              return updatedcourseDurationSection
            }
            if (
              item?.metadata?.fieldName === 'familyInfo' &&
              updatedFamilyInfoSection.fields.length !== 0
            ) {
              return updatedFamilyInfoSection
            }
            if (
              item?.metadata?.fieldName === 'previousAcademicDetails' &&
              updatedpreviousAcademicDetailsSection.fields.length !== 0
            ) {
              return updatedpreviousAcademicDetailsSection
            }
            if (
              item?.metadata?.fieldName === 'placements' &&
              updatedplacementsSection.fields.length !== 0
            ) {
              return updatedplacementsSection
            }
            if (
              item?.metadata?.fieldName === 'achievements' &&
              updatedachievementsSection.fields.length !== 0
            ) {
              return updatedachievementsSection
            }

            return {
              metadata: item.metadata,
              permissions: item.permissions,
              fields: [item.fields],
            }
          })

          const finalCourse = {
            ...studentMetaData,
            sections: updatedSections,
          }
          return finalCourse
        }
        return studentMetaData
      }
      if (module === 'studentProfile') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'my-profile',
        )

        const studentMetaData = await API.getMetaDataAPI({
          metaData: {
            role: userDetail?.[0]?.userRoleId ?? '',
            tenant: userDetail?.[0]?.tenantId ?? '',
            screen: screen?._id,
          },
          extraHeader: {
            screen: screen?._id,
          },
        })
        const studentData = await API.getStudentsByFilterAPI({
          filter: {
            tenant: userDetail?.[0]?.tenantId ?? '',
            user: tenant?.['custom:userId'] ?? '',
          },
          extraHeader: {
            screen: screen?._id,
          },
        })
        const updatedBasicDetailsSection = singleupdatedSection(
          'basicDetails',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedContactInfoSection = singleupdatedSection(
          'contactInfo',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedAddressSection = singleupdatedSection(
          'address',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedacademicInfoSection = singleupdatedSection(
          'academicInfo',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedaccomodationSection = singleupdatedSection(
          'accomodation',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedMedicalConditionsSection = singleupdatedSection(
          'medicalConditions',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedCastAndReligionSection = singleupdatedSection(
          'castAndReligion',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedextrahobbiesSection = singleupdatedSection(
          'extraCurricularActivities',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedhobbiesSection = singleupdatedSection(
          'hobbies',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedcourseDurationSection = singleupdatedSection(
          'courseDuration',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedFamilyInfoSection = arrayUpdatedSection(
          'familyInfo',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedpreviousAcademicDetailsSection = arrayUpdatedSection(
          'previousAcademicDetails',
          studentMetaData,
          studentData?.[0],
          true,
        )
        const updatedSections = studentMetaData.sections.map((item) => {
          if (item?.metadata?.fieldName === 'basicDetails') {
            return updatedBasicDetailsSection
          }
          if (item?.metadata?.fieldName === 'contactInfo') {
            return updatedContactInfoSection
          }
          if (item?.metadata?.fieldName === 'address') {
            return updatedAddressSection
          }
          if (item?.metadata?.fieldName === 'accomodation') {
            return updatedaccomodationSection
          }
          if (item?.metadata?.fieldName === 'medicalConditions') {
            return updatedMedicalConditionsSection
          }
          if (item?.metadata?.fieldName === 'castAndReligion') {
            return updatedCastAndReligionSection
          }
          if (item?.metadata?.fieldName === 'academicInfo') {
            return updatedacademicInfoSection
          }
          if (item?.metadata?.fieldName === 'extraCurricularActivities') {
            return updatedextrahobbiesSection
          }
          if (item?.metadata?.fieldName === 'hobbies') {
            return updatedhobbiesSection
          }
          if (item?.metadata?.fieldName === 'courseDuration') {
            return updatedcourseDurationSection
          }
          if (
            item?.metadata?.fieldName === 'familyInfo' &&
            updatedFamilyInfoSection.fields.length !== 0
          ) {
            return updatedFamilyInfoSection
          }
          if (
            item?.metadata?.fieldName === 'previousAcademicDetails' &&
            updatedpreviousAcademicDetailsSection.fields.length !== 0
          ) {
            return updatedpreviousAcademicDetailsSection
          }

          return {
            metadata: item.metadata,
            permissions: item.permissions,
            fields: [item.fields],
          }
        })

        const finalCourse = {
          ...studentMetaData,
          sections: updatedSections,
        }
        return finalCourse
      }

      if (module === 'staff') {
        if (id) {
          screenName = 'view-staff-details'
        } else {
          screenName = 'add-staff'
        }

        const screen = screenIds?.find((item) => item?.fieldName === screenName)

        const staffMetaData = await API.getMetaDataAPI({
          metaData: {
            role: userDetail?.[0]?.userRoleId ?? '',
            tenant: userDetail?.[0]?.tenantId ?? '',
            screen: screen?._id,
          },
          extraHeader: {
            screen: screen?._id,
          },
        })
        if (id) {
          const staffData = await API.getStaffByIdAPI({
            id,
            extraHeader: {
              screen: screen?._id,
            },
          })
          const updatedBasicDetailsSection = singleupdatedSection(
            'basicDetails',
            staffMetaData,
            staffData,
            true,
          )
          const updatedContactInfoSection = singleupdatedSection(
            'contactInfo',
            staffMetaData,
            staffData,
            true,
          )
          const updatedAddressSection = singleupdatedSection(
            'address',
            staffMetaData,
            staffData,
            true,
          )
          const updatedAccommodationAndTransportSection = singleupdatedSection(
            'accommodationAndTransport',
            staffMetaData,
            staffData,
            true,
          )
          const updatedMedicalConditionsSection = singleupdatedSection(
            'medicalConditions',
            staffMetaData,
            staffData,
            true,
          )
          const updatedCastAndReligionSection = singleupdatedSection(
            'castAndReligion',
            staffMetaData,
            staffData,
            true,
          )
          const updatedEducationDetailsSection = arrayUpdatedSection(
            'educationDetails',
            staffMetaData,
            staffData,
            true,
          )
          const updatedEmploymentDetailsSection = arrayUpdatedSection(
            'employmentDetails',
            staffMetaData,
            staffData,
            true,
          )
          const updatedAchievementsSection = arrayUpdatedSection(
            'achievements',
            staffMetaData,
            staffData,
            true,
          )
          const updatedSections = staffMetaData.sections.map((item) => {
            if (item?.metadata?.fieldName === 'basicDetails') {
              return updatedBasicDetailsSection
            }
            if (item?.metadata?.fieldName === 'contactInfo') {
              return updatedContactInfoSection
            }
            if (item?.metadata?.fieldName === 'address') {
              return updatedAddressSection
            }
            if (item?.metadata?.fieldName === 'accommodationAndTransport') {
              return updatedAccommodationAndTransportSection
            }
            if (item?.metadata?.fieldName === 'medicalConditions') {
              return updatedMedicalConditionsSection
            }
            if (item?.metadata?.fieldName === 'castAndReligion') {
              return updatedCastAndReligionSection
            }
            if (
              item?.metadata?.fieldName === 'educationDetails' &&
              updatedEducationDetailsSection.fields.length !== 0
            ) {
              return updatedEducationDetailsSection
            }
            if (
              item?.metadata?.fieldName === 'employmentDetails' &&
              updatedEmploymentDetailsSection.fields.length !== 0
            ) {
              return updatedEmploymentDetailsSection
            }
            if (
              item?.metadata?.fieldName === 'achievements' &&
              updatedAchievementsSection.fields.length !== 0
            ) {
              return updatedAchievementsSection
            }

            return {
              metadata: item.metadata,
              permissions: item.permissions,
              fields: [item.fields],
            }
          })
          const finalCourse = {
            ...staffMetaData,
            sections: updatedSections,
          }
          return finalCourse
        }
        return staffMetaData
      }
      if (module === 'staffProfile' || module === 'adminProfile') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'my-profile',
        )
        const staffMetaData = await API.getMetaDataAPI({
          metaData: {
            role: userDetail?.[0]?.userRoleId ?? '',
            tenant: userDetail?.[0]?.tenantId ?? '',
            screen: screen?._id,
          },
          extraHeader: {
            screen: screen?._id,
          },
        })
        const staffData = await API.getStaffsByFilterAPI({
          filter: {
            tenant: userDetail?.[0]?.tenantId ?? '',
            user: tenant?.['custom:userId'] ?? '',
          },
          extraHeader: {
            screen: screen?._id,
          },
        })
        const updatedBasicDetailsSection = singleupdatedSection(
          'basicDetails',
          staffMetaData,
          staffData?.[0],
          true,
        )
        const updatedContactInfoSection = singleupdatedSection(
          'contactInfo',
          staffMetaData,
          staffData?.[0],
          true,
        )
        const updatedAddressSection = singleupdatedSection(
          'address',
          staffMetaData,
          staffData?.[0],
          true,
        )
        const updatedAccommodationAndTransportSection = singleupdatedSection(
          'accommodationAndTransport',
          staffMetaData,
          staffData?.[0],
          true,
        )
        const updatedMedicalConditionsSection = singleupdatedSection(
          'medicalConditions',
          staffMetaData,
          staffData?.[0],
          true,
        )
        const updatedCastAndReligionSection = singleupdatedSection(
          'castAndReligion',
          staffMetaData,
          staffData?.[0],
          true,
        )
        const updatedEducationDetailsSection = arrayUpdatedSection(
          'educationDetails',
          staffMetaData,
          staffData?.[0],
          true,
        )
        const updatedEmploymentDetailsSection = arrayUpdatedSection(
          'employmentDetails',
          staffMetaData,
          staffData?.[0],
          true,
        )
        const updatedAchievementsSection = arrayUpdatedSection(
          'achievements',
          staffMetaData,
          staffData?.[0],
          true,
        )
        const updatedSections = staffMetaData.sections.map((item) => {
          if (item?.metadata?.fieldName === 'basicDetails') {
            return updatedBasicDetailsSection
          }
          if (item?.metadata?.fieldName === 'contactInfo') {
            return updatedContactInfoSection
          }
          if (item?.metadata?.fieldName === 'address') {
            return updatedAddressSection
          }
          if (item?.metadata?.fieldName === 'accommodationAndTransport') {
            return updatedAccommodationAndTransportSection
          }
          if (item?.metadata?.fieldName === 'medicalConditions') {
            return updatedMedicalConditionsSection
          }
          if (item?.metadata?.fieldName === 'castAndReligion') {
            return updatedCastAndReligionSection
          }
          if (
            item?.metadata?.fieldName === 'educationDetails' &&
            updatedEducationDetailsSection.fields.length !== 0
          ) {
            return updatedEducationDetailsSection
          }
          if (
            item?.metadata?.fieldName === 'employmentDetails' &&
            updatedEmploymentDetailsSection.fields.length !== 0
          ) {
            return updatedEmploymentDetailsSection
          }
          if (
            item?.metadata?.fieldName === 'achievements' &&
            updatedAchievementsSection.fields.length !== 0
          ) {
            return updatedAchievementsSection
          }

          return {
            metadata: item.metadata,
            permissions: item.permissions,
            fields: [item.fields],
          }
        })
        const finalCourse = {
          ...staffMetaData,
          sections: updatedSections,
        }
        return finalCourse
      }

      if (module === 'course') {
        const addCourseMetaData = await API.getMetaDataAPI({
          metaData: {
            role: userDetail?.[0]?.userRoleId ?? '',
            tenant: userDetail?.[0]?.tenantId ?? '',
            screen: '653102298a1b174970205a00',
          },
        })
        return addCourseMetaData
      }
      if (module === 'academics') {
        return addAcademics
      }
      if (module === 'viewCourse') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'view-course-details',
        )
        const courseDetail = await API.getMetaDataAPI({
          metaData: {
            role: userDetail?.[0]?.userRoleId ?? '',
            tenant: userDetail?.[0]?.tenantId ?? '',
            screen: '653102298a1b1749702059fe',
          },
        })
        const courseResponse = await API.getCourseByIdAPI({
          id,
          extraHeader: {
            screen: screen?._id,
          },
        })

        const subjectResponse = await API.getSubjectsByFilterAPI({
          filter: { course: id },
        })
        const updatedCourseInfoSection = singleupdatedSection(
          'basicInfo',
          courseDetail,
          courseResponse,
          true,
        )
        const updatedsemSection = singleupdatedSection(
          'selectSemester',
          courseDetail,
          courseResponse,
          true,
        )
        const updatedSubjectDetailSection = arrayUpdatedSection(
          'subjectDetails',
          courseDetail,
          subjectResponse,
          true,
        )
        const updatedSections = courseDetail.sections.map((item) => {
          if (
            item?.metadata?.fieldName === 'subjectDetails' &&
            updatedSubjectDetailSection?.fields?.length !== 0
          ) {
            return updatedSubjectDetailSection
          }
          if (item?.metadata?.fieldName === 'basicInfo') {
            return updatedCourseInfoSection
          }
          if (item?.metadata?.fieldName === 'selectSemester') {
            return updatedsemSection
          }
          return {
            metadata: item.metadata,
            permissions: item.permissions,
            fields: [item.fields],
          }
        })

        const finalCourse = {
          ...courseDetail,
          sections: updatedSections,
        }
        return finalCourse
      }
      if (module === 'viewSyllabus') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'view-syllabus-details',
        )
        const syllabusDetails = await API.getMetaDataAPI({
          metaData: {
            role: userDetail?.[0]?.userRoleId ?? '',
            tenant: userDetail?.[0]?.tenantId ?? '',
            screen: '653102298a1b174970205a01',
          },
        })
        const subjectResponse = await API.getSubjectByIdAPI({
          id,
          extraHeader: {
            screen: screen?._id,
          },
        })
        const questionsFilter = await API.getQuestionPapersByFilterAPI({
          filter: { subject: id },
        })

        const updatedobjectivesSection = singleupdatedSection(
          'objectives',
          syllabusDetails,
          subjectResponse,
          true,
        )
        const updatedOutcomesSection = singleupdatedSection(
          'outcomes',
          syllabusDetails,
          subjectResponse,
          true,
        )
        const updatedQuestionPaperSection = questionPaperSection(
          'questionsFilter',
          syllabusDetails,
          { questionsFilter },
          true,
        )
        const updatedTextBookSection = arrayUpdatedSection(
          'textBooks',
          syllabusDetails,
          subjectResponse,
          true,
        )
        const updatedReferencesSection = arrayUpdatedSection(
          'references',
          syllabusDetails,
          subjectResponse,
          true,
        )
        const updatedUnitsSection = arrayUpdatedSection(
          'units',
          syllabusDetails,
          subjectResponse,
          true,
        )
        const updatedSubjectDetailsSection = singleupdatedSection(
          'subjectDetail',
          syllabusDetails,
          subjectResponse,
          true,
        )

        const updatedSections = syllabusDetails.sections.map((item) => {
          if (
            item?.metadata?.fieldName === 'objectives' &&
            updatedobjectivesSection?.fields?.length !== 0
          ) {
            return updatedobjectivesSection
          }
          if (
            item?.metadata?.fieldName === 'outcomes' &&
            updatedOutcomesSection?.fields?.length !== 0
          ) {
            return updatedOutcomesSection
          }
          if (
            item?.metadata?.fieldName === 'textBooks' &&
            updatedTextBookSection?.fields?.length !== 0
          ) {
            return updatedTextBookSection
          }
          if (
            item?.metadata?.fieldName === 'references' &&
            updatedReferencesSection?.fields?.length !== 0
          ) {
            return updatedReferencesSection
          }
          if (
            item?.metadata?.fieldName === 'units' &&
            updatedUnitsSection?.fields?.length !== 0
          ) {
            return updatedUnitsSection
          }
          if (
            item?.metadata?.fieldName === 'questionsFilter' &&
            updatedQuestionPaperSection?.fields?.length !== 0
          ) {
            return updatedQuestionPaperSection
          }
          if (item?.metadata?.fieldName === 'subjectDetail') {
            return updatedSubjectDetailsSection
          }
          return {
            metadata: item.metadata,
            permissions: item.permissions,
            fields: [item.fields],
          }
        })
        const finalCourse = {
          ...syllabusDetails,
          sections: updatedSections,
        }
        return finalCourse
      }
      if (module === 'campus-configuration') {
        const screen = screenIds?.find(
          (item) => item?.fieldName === 'campus-configuration',
        )
        const campusDetailsMetadata = await API.getMetaDataAPI({
          metaData: {
            role: userDetail?.[0]?.userRoleId ?? '',
            tenant: userDetail?.[0]?.tenantId ?? '',
            screen: screen?._id,
          },
          extraHeader: {
            screen: screen?._id,
          },
        })
        const campusDetail = await API.getTenantByIdAPI({
          id: userDetail?.[0]?.tenantId,
          extraHeader: {
            screen: screen?._id,
          },
        })

        const updatedBasicInfoSection = singleupdatedSection(
          'basicInfo',
          campusDetailsMetadata,
          campusDetail,
          true,
        )
        const updatedContactDetailsSection = singleupdatedSection(
          'contactDetails',
          campusDetailsMetadata,
          campusDetail,
          true,
        )
        const updateduniversityDetailsSection = singleupdatedSection(
          'universityDetails',
          campusDetailsMetadata,
          campusDetail,
          true,
        )
        const updatedSections = campusDetailsMetadata.sections.map((item) => {
          if (item?.metadata?.fieldName === 'basicInfo') {
            return updatedBasicInfoSection
          }
          if (item?.metadata?.fieldName === 'contactDetails') {
            return updatedContactDetailsSection
          }
          if (item?.metadata?.fieldName === 'universityDetails') {
            return updateduniversityDetailsSection
          }

          return item
        })
        const finalCourse = {
          ...campusDetailsMetadata,
          sections: updatedSections,
        }

        return finalCourse
      }
      if (module === 'course-listing') {
        const studentCourseDetail = await API.getMetaDataAPI({
          metaData: {
            role: userDetail?.[0]?.userRoleId ?? '',
            tenant: userDetail?.[0]?.tenantId ?? '',
            screen: '653102298a1b1749702059fe',
          },
        })

        const screen = screenIds?.find(
          (item) => item?.fieldName === 'view-course-details',
        )

        const student = await API.getStudentsByFilterAPI({
          filter: {
            tenant: userDetail?.[0]?.tenantId ?? '',
            user: tenant?.['custom:userId'] ?? '',
          },
          extraHeader: {
            screen: screen?._id,
          },
        })
        const courseId = student?.[0]?.course._id

        const courseResponse = await API.getCourseByIdAPI({
          id: courseId,
          extraHeader: {
            screen: screen?._id,
          },
        })
        const subjectResponse = await API.getSubjectsByFilterAPI({
          filter: { course: courseId },
          extraHeader: {
            screen: screen?._id,
          },
        })
        const updatedCourseInfoSection = singleupdatedSection(
          'basicInfo',
          studentCourseDetail,
          courseResponse,
          true,
        )
        const updatedsemSection = singleupdatedSection(
          'selectSemester',
          studentCourseDetail,
          courseResponse,
          true,
        )
        const updatedSubjectDetailSection = arrayUpdatedSection(
          'subjectDetails',
          studentCourseDetail,
          subjectResponse,
          true,
        )
        const updatedSections = studentCourseDetail.sections.map((item) => {
          if (
            item?.metadata?.fieldName === 'subjectDetails' &&
            updatedSubjectDetailSection?.fields?.length !== 0
          ) {
            return updatedSubjectDetailSection
          }
          if (item?.metadata?.fieldName === 'basicInfo') {
            return updatedCourseInfoSection
          }
          if (item?.metadata?.fieldName === 'selectSemester') {
            return updatedsemSection
          }
          return {
            metadata: item.metadata,
            permissions: item.permissions,
            fields: [item.fields],
          }
        })

        const finalCourse = {
          ...studentCourseDetail,
          sections: updatedSections,
        }
        return finalCourse
      }
      return []
    },
    {
      initialData: [],
      enabled: !!screenIds?.length,
      retry: false,
      onError: () => {},
    },
  )

  return { data, isFetching, refetch }
}
