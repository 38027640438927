import React from 'react'
import { View } from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Text } from '@camped/components'

const Section = ({ title, description, showLine }) => {
  const { colors } = useTheme()
  return (
    <>
      {showLine && (
        <View
          style={{
            width: 2,
            height: 40,
            backgroundColor: colors.onBackground,
            borderRadius: 16,
          }}
        />
      )}
      <Surface
        elevation={0}
        style={{
          backgroundColor: colors.surface1,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            paddingBottom: 15,
          }}
        >
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <Text
              variant='labelMedium'
              style={{
                color: colors.onSurface,
                fontWeight: '700',
              }}
            >
              {title}
            </Text>
            <Text
              variant='labelLarge'
              style={{
                color: colors.onSurface,
                marginTop: 5,
              }}
            >
              {description}
            </Text>
          </View>
        </View>
      </Surface>
    </>
  )
}

export default Section
