import { Hub } from 'aws-amplify'
import { useAtom } from 'jotai'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import React, { Suspense, useEffect } from 'react'
import { Linking, Platform } from 'react-native'
import { useTheme } from 'react-native-paper'

import {
  domainAtom,
  themeSwitchAtom,
  userRollAtom,
} from '@camped/jotai-storage'
import LayoutComponent from '@camped/shared-layout-menu'
import { RootNavigator, SecureStore } from '@camped/utils'

import HomeStackNavigator from './StackNavigator'

export const AppNavigator = () => {
  const [domainDetails] = useAtom(domainAtom)
  const [themeState] = useAtom(themeSwitchAtom)
  const [, setUserRoll] = useAtom(userRollAtom)
  const { colors } = useTheme()

  useEffect(() => {
    if (Platform.OS !== 'web') return

    const root = document.documentElement
    root.style.setProperty('--autofill-text', colors.onSurface)
  }, [themeState])
  const showSnackBar = () => {
    toast.show('This user not registered with google', { type: 'danger' })
  }

  const loginSuccess = async () => {
    try {
      const response = await SecureStore.getCookie('user-details')
      if (response) {
        let userRoles
        if (response?.userRoleDetails) {
          userRoles = JSON.parse(response?.userRoleDetails)?.[0]
        }
        setUserRoll(userRoles?.userType.toLowerCase() || 'staff')
        if (Platform.OS === 'web') {
          window.location.href = `${window.location.protocol}//${domainDetails?.domain}.${window.location.host}/home`
          return
        }
        RootNavigator.reset('home')
        return
      }
      if (domainDetails?.domain) {
        window.location.href = `${window.location.protocol}//${domainDetails?.domain}.${window.location.host}/login`
        return
      }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    async function handleInitialUrl() {
      try {
        const initialUrl = await Linking.getInitialURL()
        if (initialUrl) {
          const userDetails = await SecureStore.getCookie('user-details')
          const parsedUrl = new URL(initialUrl)
          const domainName = parsedUrl.hostname.split('.')[0]
          if (domainDetails?.domain !== domainName) {
            if (userDetails) {
              RootNavigator.reset('campus')
              return
            }
          }
          const urlParams = new URLSearchParams(initialUrl)
          const error = urlParams.get('error')
          if (error) {
            showSnackBar()
            return error
          }
        }
      } catch (error) {
        return error
      }
    }

    // Call the function to handle the initial URL when the app loads
    handleInitialUrl()
  }, [])

  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'cognitoHostedUI' || 'signIn':
          if (data.username.toLowerCase().startsWith('google')) {
            const token = data.signInUserSession?.idToken?.jwtToken
            const decoded = jwt_decode(token)
            SecureStore.setCookie('user-details', decoded)
            loginSuccess()
          }
          break
        case 'signOut':
          break
        case 'parsingCallbackUrl':
          if (data?.url?.includes('error_description')) showSnackBar()
          break

        default:
          break
      }
    })
  }, [])

  return (
    <Suspense>
      <LayoutComponent>
        <HomeStackNavigator />
      </LayoutComponent>
    </Suspense>
  )
}
