import { useQueryClient } from '@tanstack/react-query'

/* eslint-disable no-nested-ternary */
const useOnChangeText = () => {
  const queryClient = useQueryClient()
  const onChangeText = ({
    data,
    text,
    isAdd = false,
    user,
    updateValue,
    setUser,
    setUpdateValue,
    editSection,
    module,
  }) => {
    const finalValue = []
    const fieldName = data?.referenceName ?? data?.fieldName
    setUser((prevUser) => {
      const updatedUser = { ...prevUser }
      if (Array.isArray(user?.[data?.arrayName])) {
        const index = isAdd ? 0 : data?.indexOrder
        const update = isAdd ? updateValue : user
        const updateVal = update?.[data?.arrayName]?.map((item, ind) =>
          item?.map((field) => {
            if (field?.name === fieldName && ind === index) {
              return {
                ...field,
                value:
                  data?.component === 'Dropdown'
                    ? text?.displayName
                    : data?.valueType === 'chip'
                    ? text.split(',')
                    : text,
              }
            }
            return field
          }),
        )
        setUpdateValue({ ...updateValue, [data?.arrayName]: updateVal })
        if (data?.valueType === 'multilineText') {
          const values = updatedUser[editSection].filter((val) => val !== '')
          if (text.endsWith(',')) {
            values.push('')
          }
          const newValues = text.split(',')
          newValues.forEach((value, ind) => {
            if (ind < values.length) {
              values[ind] = value
            } else {
              values.push(value)
            }
          })
          updatedUser[editSection] = values
        } else if (fieldName === 'academicStartYear') {
          const courses = queryClient.getQueryData(['course-lookup-Detail'])

          if (courses) {
            const course = courses.find(
              (item) => item?.courseName === user?.course?.value,
            )

            const noOfYear = Number(course?.courseDuration)
            setUser({
              ...user,
              academicEndYear: {
                value: (Number(text.displayName) + noOfYear).toString(),
                metadata: data._id,
              },
              academicStartYear: {
                value: Number(text.displayName).toString(),
                metadata: data._id,
              },
            })

            updatedUser.academicEndYear = {
              ...updatedUser[editSection],
              value: (Number(text.displayName) + noOfYear).toString(),
              name: fieldName,
            }
          }
        } else if (data?.component === 'Dropdown') {
          updatedUser[data.arrayName][index].find((item, i) => {
            if (fieldName === item.name) {
              updatedUser[data.arrayName][index][i] = {
                ...updatedUser[editSection],
                value: text.displayName,
                metadata: data._id,
                name: fieldName,
              }
            }
            return null
          })
        } else if (data?.component === 'CheckBox') {
          updatedUser[data.arrayName][index].find((item, i) => {
            if (fieldName === item.name) {
              updatedUser[data.arrayName][index][i] = {
                ...updatedUser[editSection],
                value: text === 'checked' ? 'No' : 'Yes',
                metadata: data._id,
                name: fieldName,
              }
            }
            return null
          })
        } else if (data?.component === 'PhoneNumberInput') {
          updatedUser[fieldName] = {
            ...updatedUser[editSection],
            value: `${text}`,
            name: fieldName,
          }
        } else {
          updatedUser?.[data.arrayName]?.[index]?.find((item, i) => {
            if (fieldName === item.name) {
              updatedUser[data.arrayName][index][i] = {
                ...updatedUser[editSection],
                value: text,
                metadata: data._id,
                name: fieldName,
              }
            }
            return null
          })
        }
        return updatedUser
      }
      if (data?.valueType === 'multilineText') {
        const values = updatedUser[editSection].filter((val) => val !== '')
        if (text.endsWith(',')) {
          values.push('')
        }
        const newValues = text.split(',')
        newValues.forEach((value, index) => {
          if (index < values.length) {
            values[index] = value
          } else {
            values.push(value)
          }
        })
        updatedUser[editSection] = values
      }
      if (
        fieldName === 'academicStartYear' ||
        fieldName === 'academicEndYear'
      ) {
        const courses = queryClient.getQueryData(['course-lookup-Detail'])
        if (courses) {
          const course = courses.find(
            (item) => item?.courseName === user?.course?.value,
          )

          const noOfYear = Number(course?.courseDuration)
          setUser({
            ...user,
            academicEndYear: {
              value: (Number(text.displayName) + noOfYear).toString(),
              name: 'academicEndYear',
            },
            academicStartYear: {
              value: Number(text.displayName).toString(),
              name: 'academicStartYear',
            },
          })

          updatedUser.academicEndYear = {
            ...updatedUser[editSection],
            value: (Number(text.displayName) + noOfYear).toString(),
            name: 'academicEndYear',
          }
          updatedUser[fieldName] = {
            ...updatedUser[editSection],
            value: Number(text.displayName).toString(),
            name: fieldName,
          }
        }
      } else if (data?.component === 'Dropdown') {
        updatedUser[fieldName] = {
          ...updatedUser[editSection],
          value: text.displayName,
          name: fieldName,
        }
      } else if (data?.callingCode) {
        updatedUser.callingCode = {
          ...updatedUser[editSection],
          value: data.callingCode[0],
          name: 'callingCode',
        }
        updatedUser.countryCode = {
          ...updatedUser[editSection],
          value: data.cca2,
          name: 'countryCode',
        }
      } else if (data?.component === 'CheckBox') {
        updatedUser[fieldName] = {
          ...updatedUser[editSection],
          value: text === 'checked' ? 'No' : 'Yes',
          name: fieldName,
        }
      } else if (data?.component === 'PhoneNumberInput') {
        updatedUser[fieldName] = {
          ...updatedUser[editSection],
          value: `${user?.callingCode?.value}-${text}`,
          name: fieldName,
        }
      } else {
        updatedUser[fieldName] = {
          ...updatedUser[editSection],
          value:
            data.valueType === 'int'
              ? text.replace(/\D/g, '')
              : text.toString(),
          name: fieldName,
        }
      }

      if (!data?.callingCode) {
        if (data.component === 'PhoneNumberInput') {
          finalValue.push({
            value: `${user?.callingCode?.value}-${text}`,
            name: fieldName,
          })
        } else if (fieldName === 'academicStartYear') {
          finalValue.push(updatedUser?.[fieldName])
          finalValue.push(updatedUser?.academicEndYear)
        } else if (fieldName === 'department') {
          finalValue.push({
            value: text?.id,
            name: fieldName,
          })
        } else if (fieldName === 'graduation') {
          finalValue.push({
            value: text?.id,
            name: 'graduation',
          })
        } else if (fieldName === 'degree') {
          finalValue.push({
            value: text?.id,
            name: 'degree',
          })
        } else if (fieldName === 'course' && module !== 'course') {
          finalValue.push({
            value: text?.id,
            name: 'course',
          })
        } else {
          finalValue.push(updatedUser?.[fieldName])
        }
      }
      setUpdateValue((preValue) => {
        const prevValue = { ...preValue }
        return {
          ...prevValue,
          fields: [
            ...(prevValue?.fields?.filter((item) => item.name !== fieldName) ||
              prevValue),
            ...finalValue,
          ],
        }
      })
      return updatedUser
    })
  }
  return { onChangeText }
}

export default useOnChangeText
