import { Auth } from 'aws-amplify'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

export const forgotPasswordAsync = async (userName) => {
  try {
    const res = await Auth.forgotPassword(userName)
    return res
  } catch (error) {
    return error
  }
}

export const resetPasswordAsync = async (username, code, newPassword) => {
  try {
    const res = await Auth.forgotPasswordSubmit(username, code, newPassword)
    return res
  } catch (error) {
    return error
  }
}

export const changePasswordAsync = async (currentPassword, newPassword) => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const res = await Auth.changePassword(user, currentPassword, newPassword)
    return res
  } catch (error) {
    return error
  }
}

export const completeNewPasswordAsync = async (user, newPassword) => {
  try {
    const res = await Auth.completeNewPassword(user, newPassword)
    if (res) {
      return res
    }
  } catch (error) {
    return error
  }
}

export const getTenantRoleDetails = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser()

    if (response) {
      const token = response.signInUserSession.idToken.jwtToken

      const decoded = jwt_decode(token)
      return decoded
    }
    return null
  } catch (error) {
    return null
  }
}

export const logout = async () => {
  try {
    const response = await Auth.signOut()

    return response
  } catch (error) {
    return null
  }
}
export const login = async (userName, password) => {
  try {
    const response = await Auth.signIn(userName?.trim().toLowerCase(), password)

    return response
  } catch (error) {
    return error
  }
}

export const federatedSignIn = async ({ provider }) => {
  try {
    const response = await Auth.federatedSignIn({ provider })

    return response
  } catch (error) {
    return null
  }
}
