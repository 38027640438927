import { useNavigation } from '@react-navigation/native'
import { useAtom } from 'jotai'
import React from 'react'
import {
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { useTheme } from 'react-native-paper'

import { Audit } from '@camped/audit'
import { Row, Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { domainAtom } from '@camped/jotai-storage'
import { ScreenLayout } from '@camped/utils'

import UniversityLogo from 'assets/universityOfCambridgeLogo.png'

import { EventAction } from '../../constants/audit'

const HeaderLeft = (props) => {
  const { title, hasBack, hasImage } = props

  const [details] = useAtom(domainAtom)
  const windowWidth = useWindowDimensions().width
  const { colors, spacing } = useTheme()
  const isDesktop = ScreenLayout.isWeb(windowWidth)
  const navigation = useNavigation()

  const handleBackNavigation = () => {
    if (Platform.OS === 'web') {
      window.history.go(-1)
    } else {
      navigation.goBack()
    }
  }
  const handleEventAnalysis = async (module) => {
    await Audit.logEvent({
      action: EventAction.CLICK,
      eventtype: title,
      entityType: module,
      details: {
        Screen: title,
      },
    })
  }
  if (hasImage) {
    return (
      <Row style={{ paddingLeft: spacing.spacing4 }}>
        <Text variant='headlineSmall' style={{ color: colors.onSurface1 }}>
          {details?.institutionName && details?.institutionName.length >= 11
            ? `${details?.institutionName.substring(0, 10)}...`
            : details?.institutionName}
        </Text>
      </Row>
    )
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: isDesktop ? spacing.spacing4 : spacing.spacing5,
      }}
    >
      {hasBack && (
        <TouchableOpacity onPress={() => handleBackNavigation()}>
          <Icon
            name='BackArrow'
            color={colors.onSurface1}
            width={24}
            height={24}
            style={{ marginRight: spacing.spacing4 }}
          />
        </TouchableOpacity>
      )}
      <View>
        <Text
          style={{
            color: colors.onSurface2,
          }}
          variant={isDesktop ? 'headlineMedium' : 'headlineSmall'}
          onPress={handleEventAnalysis(title)}
        >
          {title}
        </Text>
      </View>
    </View>
  )
}

export default HeaderLeft

const styles = StyleSheet.create({
  logoUrl: {
    width: 38,
    height: 31,
    alignSelf: 'center',
  },
})
