import { useNavigation, useNavigationState } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View, useWindowDimensions } from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import DesktopLeftMainNav from './desktop-left-main-nav'
import LeftSideBar from './desktop-left-sub-nav'
import RightSideBar from './desktop-right-quick-action'
import GlobalHeader from './global-header'

const Layout = (props) => {
  const { children, globalUser, userType, menuData } = props
  const { spacing, colors } = useTheme()
  const { width } = useWindowDimensions()
  const navigation = useNavigation()

  const [, setHoveredItem] = useState({})
  const [activeTab, setActiveTab] = React.useState(0)
  const [expanded, setExpanded] = useState(true)
  const [isBulkClick, setIsBulkClick] = useState(false)
  const [showActionView, setShowActionView] = useState(false)

  const dontShowLeftNavBar = ['profileinfo', 'home', 'my-profile']
  const currentRoute = useNavigationState(
    (state) => state?.routes?.[state.routes.length - 1],
  )

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        setShowActionView,
        isBulkClick,
        setIsBulkClick,
      })
    }
    return child
  })
  useEffect(() => {
    if (width <= 1366 || dontShowLeftNavBar.includes(currentRoute.name)) {
      setExpanded(false)
    } else setExpanded(true)
  }, [width])

  useEffect(() => {
    setHoveredItem()
  }, [currentRoute])

  const handleLeftMainNavClick = async (data, position, index) => {
    if (position === 'top') {
      setActiveTab(index)
      if (
        data.iconName === 'Hamburger' &&
        !dontShowLeftNavBar.includes(currentRoute.name)
      ) {
        setExpanded(!expanded)
      } else {
        const firstModule = data.subMenuItems[0]
        if (firstModule) {
          setExpanded(true)
          const navigationPath =
            firstModule.subMenuItems?.length !== 0
              ? firstModule.subMenuItems[0]?.navigationPath
              : firstModule.navigationPath
          navigation.navigate(navigationPath)
        } else {
          setExpanded(false)
          navigation.navigate(data.navigationPath)
        }
      }
    } else if (position === 'bottom') {
      if (data?.subMenuItems.length > 0) {
        setExpanded(true)
      } else {
        setExpanded(false)
      }
      navigation.navigate(data?.screenName)
    }
  }

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      <DesktopLeftMainNav
        menuList={menuData}
        screenName={currentRoute}
        setActiveTab={setActiveTab}
        onClick={(data, position, index) =>
          handleLeftMainNavClick(data, position, index)
        }
      />
      <View style={styles.rowWidth}>
        <View style={{ width: '100%', flex: 1 }}>
          <GlobalHeader globalUser={globalUser} userType={userType} />
          <View style={styles.container}>
            <View style={styles.row}>
              {expanded && (
                <LeftSideBar
                  screenName={currentRoute}
                  menuList={menuData}
                  setExpanded={setExpanded}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              )}
              <View style={[styles.containerBg(spacing, colors, expanded)]}>
                <Surface
                  style={{
                    flex: 1,
                    borderRadius: 8,
                    overflow: 'hidden',
                  }}
                  onMouseEnter={() => {
                    if (width <= 1366) setExpanded(false)
                  }}
                  elevation={0}
                >
                  {childrenWithProps}
                </Surface>
              </View>
              {showActionView && (
                <RightSideBar
                  handleBulkInvitation={() => {
                    setIsBulkClick(true)
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default Layout

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  rowWidth: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
  },
  containerBg: (spacing, colors, expanded) => ({
    paddingBottom: spacing.spacing4,
    paddingRight: spacing.spacing4,
    paddingLeft: expanded ? 0 : spacing.spacing4,
    flex: 1,
    borderColor: colors.surface2,
  }),
})
