import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Surface } from 'react-native-paper'

import { Row, Text } from '@camped/components'
import { Icon } from '@camped/icons'

import { capitalize } from '../../utils/capitailize'

export const TopSection = ({
  spacing,
  colors,
  navigation,
  module,
  userLists,
  setShowSortDrawer,
  setShowDrawer,
  filterList,
  filterApplied,
}) => {
  const { t } = useTranslation()
  return (
    <View
      style={{
        paddingHorizontal: spacing.spacing6,
        backgroundColor: colors.surface1,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',

          paddingTop: spacing.spacing5,
        }}
      >
        <Text
          style={{
            color: colors.Background,
          }}
          variant='headlineSmall'
        >
          {module.toUpperCase()}
        </Text>
        <TouchableOpacity
          onPress={async () => {
            if (module.toLowerCase() === 'academics') {
              navigation.push(`add-course`)
            } else {
              navigation.push(`add-${module}`)
            }
          }}
        >
          <Icon
            name='AddIcon'
            color={colors.onPrimary}
            width={33}
            height={33}
            fill={colors.primary}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.container(spacing)}>
        <Text variant='labelMedium' style={{ color: colors.onBackground }}>
          {`${userLists?.length} ${capitalize(module)} ${t(
            'Directory.AVAILABLE',
          )}`}
        </Text>

        <Row>
          <Surface
            elevation={2}
            style={{
              borderRadius: 8,
              marginHorizontal: 8,
              backgroundColor: colors.surface2,
            }}
          >
            <TouchableOpacity
              style={styles.icon(colors, spacing)}
              onPress={() => setShowSortDrawer(true)}
            >
              <Icon
                name='SortIcon'
                width={18}
                height={18}
                fill={colors.onSurface}
              />
            </TouchableOpacity>
          </Surface>

          {filterList && module !== 'rolelisting' && (
            <Surface elevation={2} style={{ borderRadius: 8 }}>
              <TouchableOpacity
                style={styles.icon(colors, spacing)}
                onPress={() => setShowDrawer(true)}
              >
                <View>
                  {filterApplied ? (
                    <>
                      <Icon
                        name='FilterIcon'
                        color={colors.onSurface}
                        style={{
                          height: 18,
                          width: 18,
                        }}
                      />
                      <View
                        style={{
                          backgroundColor: colors.tertiary,
                          width: 8,
                          height: 8,
                          position: 'absolute',
                          right: -2,
                          top: -2,
                          borderRadius: 14,
                        }}
                      />
                    </>
                  ) : (
                    <Icon
                      name='FilterIcon'
                      color={colors.onSurface}
                      style={{
                        height: 18,
                        width: 18,
                      }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            </Surface>
          )}
        </Row>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  iconView: { alignSelf: 'center', justifyContent: 'center', flex: 1 },
  container: (spacing) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: spacing.spacing4,
  }),
  icon: (colors, spacing) => ({
    height: 32,
    justifyContent: 'center',
    backgroundColor: colors.surface2,
    paddingHorizontal: spacing.spacing4,
    borderRadius: 8,
  }),
})
