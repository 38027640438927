import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'
import { StyleSheet } from 'react-native-web'

import { Audit } from '@camped/audit'
import { Icon } from '@camped/icons'
import { themeSwitchAtom } from '@camped/jotai-storage'
import { spacing } from '@camped/theme-provider'

import { EventAction, EventEntityType, EventScreen, EventType } from '../audit'

const ThemeSwitch = () => {
  const { colors } = useTheme()
  const [themeState, setThemeState] = useAtom(themeSwitchAtom)

  const handleThemeSwitch = () => {
    if (themeState === 'LIGHT') {
      Audit.logEvent({
        action: EventAction.TOGGLE,
        entityType: EventEntityType.APPLICATION,
        eventType: EventType.CHANGECOLOURTODARK,
        details: {
          Screen: EventScreen.ALL,
        },
      })
      return setThemeState('DARK')
    }
    Audit.logEvent({
      action: EventAction.TOGGLE,
      entityType: EventEntityType.APPLICATION,
      eventType: EventType.CHANGECOLOURTOLIGHT,
      details: {
        Screen: EventScreen.ALL,
      },
    })
    return setThemeState('LIGHT')
  }

  useEffect(() => {
    if (Platform.OS !== 'web') return

    const root = document.documentElement
    root.style.setProperty('--autofill-text', colors.onSurface)
  }, [themeState])

  return (
    <TouchableOpacity onPress={() => handleThemeSwitch()}>
      <View
        style={[styles.switchContainer, { backgroundColor: colors.surface2 }]}
      >
        <View
          style={{
            flex: 1,
            borderRadius: 25,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor:
              themeState === 'LIGHT' ? colors.primary : 'transparent',
          }}
        >
          <Icon name='LightSwitch' height={14} fill={colors.onPrimary} />
        </View>
        <View
          style={{
            flex: 1,
            borderRadius: 25,
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor:
              themeState === 'DARK' ? colors.primary : 'transparent',
          }}
        >
          <Icon name='DarkSwitch' height={14} fill={colors.onSurface1} />
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  switchContainer: {
    height: 28,
    width: 58,
    borderRadius: 44,
    flexDirection: 'row',
    alignSelf: 'center',
    padding: spacing.spacing1,
  },
})

export default ThemeSwitch
