import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

const DataTableHeader = ({
  children,
  style,
  theme,
  paddingHorizontal,
  paddingVertical,
  backgroundColor,
  ...rest
}) => {
  const { colors } = useTheme()
  return (
    <View
      {...rest}
      style={[
        styles.header(paddingHorizontal),
        {
          backgroundColor: backgroundColor || colors.surface2,
          paddingVertical,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  header: (paddingHorizontal) => ({
    flexDirection: 'row',
    paddingHorizontal,
  }),
})

export default DataTableHeader
