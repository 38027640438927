import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { ShimmerPlaceholder } from '@camped/components'
import { spacing } from '@camped/theme-provider'

export const DesktopView = () => {
  const { colors } = useTheme()
  return (
    <Surface
      style={{
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          backgroundColor: colors.surface1,
        }}
      >
        <Header />
        <View
          style={{
            height: '100%',
            width: 1,
          }}
        />
        <ProfileDetails />
      </View>
    </Surface>
  )
}

const Header = () => (
  <View style={{ flex: 0.2, height: '100%' }}>
    <View style={styles.container}>
      <View style={{ alignItems: 'center' }}>
        <ShimmerPlaceholder style={styles.header} />
        <ShimmerPlaceholder style={styles.shimmerPlaceholder(94, 24)} />
        <ShimmerPlaceholder style={styles.shimmerPlaceholder(74, 28)} />
      </View>

      <View style={{ flexDirection: 'row', marginTop: 16 }}>
        <ShimmerPlaceholder style={styles.shimmerPlaceholderRight(74, 36)} />
        <ShimmerPlaceholder style={styles.shimmerPlaceholder(74, 36)} />
      </View>
      <View style={{ flexDirection: 'row', marginTop: 4 }}>
        <ShimmerPlaceholder style={styles.shimmerPlaceholderRight(74, 36)} />
        <ShimmerPlaceholder style={styles.shimmerPlaceholder(74, 36)} />
      </View>
      <View style={{ alignSelf: 'flex-start', marginLeft: 0 }}>
        <ShimmerPlaceholder style={styles.shimmerPlaceholder(98, 36)} />
      </View>
    </View>
  </View>
)

const ProfileDetails = () => {
  const Field = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  const Row = [1, 2]
  const Tab = [1, 2, 3, 4, 5]
  return (
    <View style={{ flex: 0.8 }}>
      <View style={styles.profileView}>
        {Tab?.map(() => (
          <ShimmerPlaceholder style={styles.tabShimmer} />
        ))}
      </View>

      {Row?.map(() => (
        <View style={styles.container}>
          <ShimmerPlaceholder style={styles.row} />

          <View style={styles.rowView}>
            {Field?.map(() => (
              <ShimmerPlaceholder style={styles.fieldShimmer} />
            ))}
          </View>
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  shimmerPlaceholder: (width, height) => ({
    width,
    height,
    borderRadius: spacing.spacing2,
    marginTop: spacing.spacing3,
  }),

  shimmerPlaceholderRight: (width, height) => ({
    width,
    height,
    borderRadius: spacing.spacing2,
    marginTop: spacing.spacing3,
    marginRight: spacing.spacing3,
  }),
  container: {
    paddingHorizontal: spacing.spacing5,
    paddingVertical: spacing.spacing6,
  },
  profileView: {
    flexDirection: 'row',
    paddingLeft: spacing.spacing5,
    paddingVertical: spacing.spacing4,
  },
  header: {
    width: 94,
    height: 94,
    borderRadius: 100,
  },
  tabShimmer: {
    width: 94,
    height: 28,
    borderRadius: spacing.spacing2,
    marginTop: spacing.spacing3,
    marginRight: spacing.spacing26,
  },
  row: {
    width: 94,
    height: 28,
    borderRadius: spacing.spacing2,
    marginTop: spacing.spacing3,
  },
  rowView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  fieldShimmer: {
    width: '30%',
    height: 38,
    borderRadius: spacing.spacing2,
    justifyContent: 'center',
    marginTop: spacing.spacing6,
    marginRight: spacing.spacing3,
  },
})
