import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'

const DataTableRow = (props) => {
  const {
    module,
    onPress,
    style,
    theme,
    selectedIDs,
    children,
    backgroundColor,
    hasHover = true,
    paddingHorizontal,
    handleInviteClick = () => {},
    ...rest
  } = props
  const [hover, setHover] = useState(false)
  const { colors, spacing } = useTheme()

  const getBackgroundColor = () => {
    if (hover && hasHover) {
      return colors.surface2
    }
    if (backgroundColor) {
      return backgroundColor
    }
    return colors.background
  }

  return (
    <Surface
      elevation={hover && hasHover ? 4 : 0}
      onPress={onPress}
      disabled={!onPress}
      style={[
        styles(colors, spacing).container(paddingHorizontal),
        { backgroundColor: getBackgroundColor() },
        style,
      ]}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      {...rest}
    >
      <View style={styles(colors, spacing).content}>
        {children}
        {hasHover && hover && selectedIDs?.length === 0 && (
          <QuickActions
            handleInviteClick={handleInviteClick}
            hover={hover}
            module={module}
          />
        )}
      </View>
    </Surface>
  )
}

const QuickActions = ({ handleInviteClick, module }) => {
  const { colors, spacing } = useTheme()
  return (
    <View style={styles(colors, spacing).QuickActions}>
      {(module === 'student' || module === 'staff') && (
        <IconContainer iconName='InviteIcon' onPress={handleInviteClick} />
      )}
    </View>
  )
}
const IconContainer = ({ iconName, onPress = () => {} }) => {
  const { colors, spacing } = useTheme()
  const [hover, setHover] = useState(false)

  return (
    <View
      style={[
        styles(colors, spacing).IconContainer,
        hover && styles(colors, spacing).hoveredIconContainer,
      ]}
    >
      <TouchableOpacity
        style={{
          width: 36,
          height: 36,
          borderRadius: 22,
          backgroundColor: colors.primary,
          alignItems: 'center',
          justifyContent: 'center',
          opacity: hover ? 1 : 0.4,
          zIndex: 1,
        }}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
        onPress={onPress}
      >
        <Icon
          name={iconName}
          width={20}
          height={20}
          color={colors.onPrimary}
          style={styles(colors, spacing).iconView}
        />
      </TouchableOpacity>
    </View>
  )
}

DataTableRow.displayName = 'DataTable.Row'

const styles = (colors, spacing) =>
  StyleSheet.create({
    iconView: { alignSelf: 'center', justifyContent: 'center', flex: 1 },
    container: (paddingHorizontal) => ({
      minHeight: 54,
      paddingHorizontal,
      borderBottomWidth: 1,
      borderColor: colors.surface1,
    }),
    content: {
      flex: 1,
      flexDirection: 'row',
    },
    IconContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: spacing.spacing3,
    },
    QuickActions: {
      minHeight: 48,
      flexDirection: 'row',
      height: '100%',
      width: '8%',
      position: 'absolute',
      right: -16,
    },
  })

export default DataTableRow
