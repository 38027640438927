import React, {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Platform, ScrollView, View, useWindowDimensions } from 'react-native'
import { Checkbox, Menu, TouchableRipple, useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'
import { ScreenLayout } from '@camped/utils'

import TextInput from '../TextInput'

const DropDown = forwardRef((props, ref) => {
  const activeTheme = useTheme()
  const { colors, spacing } = useTheme()
  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout?.isWeb(windowWidth)
  const {
    multiSelect = false,
    value,
    setValue,
    activeColor,
    label,
    placeholder,
    inputProps,
    list,
    dropDownContainerMaxHeight,
    dropDownContainerHeight,
    theme,
    dropDownStyle,
    dropDownItemStyle,
    dropDownItemSelectedStyle,
    dropDownItemTextStyle,
    dropDownItemSelectedTextStyle,
    accessibilityLabel,
    field,
    error,
    style,
    disabled,
    iconStyle,
  } = props
  const [showDropDown, setShowDropDown] = React.useState(false)
  const [displayValue, setDisplayValue] = useState('')
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  })

  const onLayout = (event) => {
    setInputLayout(event.nativeEvent.layout)
  }

  useEffect(() => {
    if (multiSelect) {
      const labels = list
        .filter((_) => value.indexOf(_.value) !== -1)
        .map((_) => _.label)
        .join(', ')
      setDisplayValue(labels)
    } else {
      let labelLocal
      if (field) {
        labelLocal = list?.find((_) => _?.[field] === value)?.[field]
      } else {
        labelLocal = list?.find((_) => _?.value === value)?.label
      }
      if (labelLocal) {
        setDisplayValue(labelLocal)
      }
    }
  }, [list, value])

  const isActive = useCallback(
    (currentValue) => {
      if (multiSelect) {
        return value.indexOf(currentValue) !== -1
      }
      return value === currentValue
    },
    [value],
  )

  const setActive = useCallback(
    (currentValue) => {
      if (multiSelect) {
        const valueIndex = value.indexOf(currentValue)
        const values = value.split(',')
        if (valueIndex === -1) {
          setValue([...values, currentValue].join(','))
        } else {
          setValue(
            [...values].filter((value1) => value1 !== currentValue).join(','),
          )
        }
      } else {
        setValue(currentValue)
      }
    },
    [value, setValue],
  )

  return (
    <Menu
      visible={showDropDown}
      onDismiss={() => setShowDropDown(false)}
      theme={{
        colors: {
          onSurface: colors.onSurface,
          backgroundColor: colors.surface1,
        },
      }}
      anchor={
        <TouchableRipple
          ref={ref}
          onPress={() => {
            setShowDropDown(true)
            props?.onFocus?.()
          }}
          onLayout={onLayout}
          accessibilityLabel={accessibilityLabel}
          disabled={disabled}
        >
          <View pointerEvents='none'>
            <TextInput
              value={displayValue}
              label={label}
              placeholder={placeholder}
              pointerEvents='none'
              theme={theme}
              outlineColor={colors.surface4 || props.outlineColor}
              placeholderTextColor={
                colors.onSurface || props.placeholderTextColor
              }
              error={error}
              style={style}
              {...inputProps}
              {...props}
            />
            <Icon
              name='DownArrowFilled'
              color={error ? 'red' : colors.onSurfaceVariant}
              style={[
                {
                  height: spacing.spacing4,
                  width: spacing.spacing4,
                  position: 'absolute',
                  right: isDesktop ? '5%' : '15%',
                  top: Platform.OS === 'web' ? '42%' : '30%',
                  opacity: disabled ? 0.5 : 1,
                },
                iconStyle,
              ]}
            />
          </View>
        </TouchableRipple>
      }
      style={{
        maxWidth: inputLayout?.width,
        width: inputLayout?.width,
        marginTop: inputLayout?.height,
        ...dropDownStyle,
      }}
      contentStyle={{ backgroundColor: colors.surface2 }}
    >
      <ScrollView
        bounces={false}
        style={{
          ...(dropDownContainerHeight
            ? {
                height: dropDownContainerHeight,
              }
            : {
                maxHeight: dropDownContainerMaxHeight || 200,
              }),
        }}
      >
        {list?.map((_item) => (
          <Fragment key={_item.value}>
            <TouchableRipple
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
              onPress={() => {
                setActive(_item || _item?.[field])
                setShowDropDown(false)
              }}
            >
              <>
                <Menu.Item
                  titleStyle={{
                    color: isActive(_item?.displayName || _item?.value)
                      ? activeColor || (theme || activeTheme).colors.primary
                      : (theme || activeTheme).colors.text,
                    ...(isActive(_item.value)
                      ? dropDownItemSelectedTextStyle
                      : dropDownItemTextStyle),
                  }}
                  title={
                    _item.custom ||
                    _item.displayName ||
                    _item.label ||
                    _item?.[field]
                  }
                  style={{
                    flex: 1,
                    maxWidth: inputLayout?.width,
                    ...(isActive(_item.value)
                      ? dropDownItemSelectedStyle
                      : dropDownItemStyle),
                  }}
                />
                {multiSelect && (
                  <Checkbox.Android
                    theme={{
                      colors: { accent: activeTheme?.colors.primary },
                    }}
                    status={isActive(_item.value) ? 'checked' : 'unchecked'}
                    onPress={() => setActive(_item)}
                  />
                )}
              </>
            </TouchableRipple>
          </Fragment>
        ))}
      </ScrollView>
    </Menu>
  )
})

export default DropDown
