import { useTheme } from '@react-navigation/native'
import React from 'react'
import { FlatList, Image, Linking, TouchableOpacity, View } from 'react-native'

import { Share, Text } from '@camped/components'
import { Icon } from '@camped/icons'

import ViewMoreButton from '../view-more/viewmore'

const FeedCard = ({
  data,
  isMobile = false,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
}) => {
  const { colors } = useTheme()
  const getPlatform = (platform) => {
    if (platform?.toLowerCase() === 'facebook') {
      return 'Facebook'
    }
    if (platform?.toLowerCase() === 'instagram') {
      return 'Instagram'
    }
    if (platform?.toLowerCase() === 'x') {
      return 'X'
    }
    if (platform?.toLowerCase() === 'linkedin') {
      return 'Linkedin'
    }
    return ''
  }
  return (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 40,
      }}
    >
      <FlatList
        data={data}
        numColumns={1}
        renderItem={({ item: feed }, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => Linking.openURL(feed.postUrl)}
          >
            <View
              style={[
                {
                  backgroundColor: '#FFFFFF',
                  borderRadius: 10,
                },
                isMobile ? { marginTop: 10 } : { maxWidth: 360, margin: 10 },
              ]}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 15,
                }}
              >
                {['facebook', 'instagram', 'x', 'twitter', 'linkedin'].includes(
                  feed?.platform?.toLowerCase(),
                ) ? (
                  <View style={{ flexDirection: 'row' }}>
                    <View
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 15,
                        backgroundColor: '#F1F1F1',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon
                        name='HostelBuilding'
                        color={colors.primary}
                        height={15}
                        width={15}
                        style={{
                          strokeWidth: 2,
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        fontSize: 16,
                        marginLeft: 10,
                      }}
                    >
                      Camped University
                    </Text>
                  </View>
                ) : null}
                <Icon
                  name={getPlatform(feed?.platform)}
                  style={{
                    height: 1,
                    width: '100%',
                    fill: '#F1F1F1',
                  }}
                />
              </View>
              {feed?.imageUrl ? (
                <Image
                  source={{ uri: feed?.imageUrl }}
                  style={{
                    width: '100%',
                    height: 189,
                    borderRadius: 5,
                    overflow: 'hidden',
                  }}
                />
              ) : null}
              <View style={{ padding: 10 }}>
                <Text
                  numberOfLines={2}
                  style={{ fontSize: 16, fontWeight: '400' }}
                >
                  {feed?.text !== ''
                    ? feed?.text?.replace('/n', ' ')
                    : feed?.title}
                </Text>
              </View>
              <View style={{ flexDirection: 'column', flex: 1, padding: 10 }}>
                <View
                  style={{
                    height: 1,
                    width: '100%',
                    backgroundColor: colors.border,
                    marginBottom: 10,
                  }}
                />
                <RenderTemplate
                  platform={feed?.platform?.toLowerCase()}
                  feed={feed}
                />
              </View>
            </View>
          </TouchableOpacity>
        )}
        keyExtractor={(item) => item?._id}
      />
      {hasNextPage ? (
        <ViewMoreButton
          onPress={fetchNextPage}
          isLoading={isFetchingNextPage}
        />
      ) : null}
    </View>
  )
}

export default FeedCard
const RenderTemplate = ({ platform, feed }) => {
  if (platform === 'facebook') {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        <Icon
          name='ThumbsUp'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Icon
          name='Comments'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Share data={feed}>
          <Icon
            name='ShareIcon'
            color='#000000'
            height={20}
            width={20}
            style={{
              strokeWidth: 1,
              opacity: 0.75,
            }}
          />
        </Share>
      </View>
    )
  }
  if (platform === 'linkedin') {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        <Icon
          name='ThumbsUp'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Icon
          name='Comments'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Icon
          name='Repost'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Share data={feed}>
          <Icon
            name='Send'
            color='#000000'
            height={20}
            width={20}
            style={{
              strokeWidth: 1,
              opacity: 0.75,
            }}
          />
        </Share>
      </View>
    )
  }
  if (platform === 'instagram') {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        <Icon
          name='FavoriteIcon'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Icon
          name='Comments'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Icon
          name='BookmarkIcon'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
      </View>
    )
  }
  if (platform === 'x') {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        <Icon
          name='Comments'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Icon
          name=''
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Icon
          name='FavoriteIcon'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Icon
          name='Finance'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Icon
          name='BookmarkIcon'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Icon
          name='Upload'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 2,
            opacity: 0.75,
          }}
        />
      </View>
    )
  }
  return (
    <View style={{ flexDirection: 'column', flex: 1 }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Icon
          name='BookmarkIcon'
          color='#000000'
          height={20}
          width={20}
          style={{
            strokeWidth: 1,
            opacity: 0.75,
          }}
        />
        <Share data={feed}>
          <Icon
            name='ShareIcon'
            color='#000000'
            height={20}
            width={20}
            style={{
              strokeWidth: 1,
              opacity: 0.75,
            }}
          />
        </Share>
      </View>
    </View>
  )
}
