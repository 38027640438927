import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Audit } from '@camped/audit'
import { Button, Text } from '@camped/components'

import {
  EventAction,
  EventEntityType,
  EventScreen,
  EventType,
} from '../../constants/audit'

const ViewMoreButton = (props) => {
  const { onPress = () => {}, label = 'View more', isLoading } = props
  const { spacing, colors } = useTheme()

  const hanldeViewMoreButton = () => {
    Audit.logEvent({
      action: EventAction.CLICK,
      eventtype: EventType.VIEW_MORE,
      entityType: EventEntityType.VIEWMORE,
      details: {
        Screen: EventScreen.DIRECTORY,
      },
    })
  }

  return (
    <View style={styles.rowCenter(spacing)}>
      <View style={[styleProps(colors).buttonLine]} />
      <Button
        mode={props.mode || 'outlined'}
        onPress={() => {
          hanldeViewMoreButton()
          onPress()
        }}
        style={{
          borderColor: colors.surface1,
          borderWidth: 1,
          backgroundColor: '#FFFFFF',
        }}
      >
        {isLoading ? (
          <ActivityIndicator size='small' color={colors.primary} />
        ) : (
          <Text>{label}</Text>
        )}
      </Button>
      <View style={[styleProps(colors).buttonLine]} />
    </View>
  )
}

const styles = StyleSheet.create({
  rowCenter: (spacing) => ({
    marginVertical: spacing.spacing3,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }),
})
const styleProps = (colors) => ({
  buttonLine: {
    backgroundColor: colors.surface1,
    height: 1,
    flex: 1,
    width: '100%',
  },
})

export default ViewMoreButton
