import React, { Suspense, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { Text } from '@camped/components'
import { ScreenLayout, SecureStore } from '@camped/utils'

import useUploadImage from '../../hooks/use-upload-department'
import { useGetDepartmentListing } from '../../hooks/useGetDepartment'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const DepartmentDetail = (props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const screenIds = queryClient.getQueryData(['getScreenIds'])
  const [checked, setChecked] = useState(false)
  const [errors, setErrors] = useState()
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState({})

  const module = props?.module ?? ''
  const { id } = props.route?.params || ''

  const validationErrors = []
  const {
    data: departmentDetails,
    isFetching,
    refetch: refetchListDepartments,
  } = useGetDepartmentListing({
    module,
    setUser,
    id,
  })

  const { fileInputRef, handleAddImage, uploadImage } = useUploadImage({
    refetchListDepartments,
  })
  const { data: lookupData } = useQuery(
    [`graduation-filter-Detail`],
    async () => {
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)
      return API.getGraduationsByFilterAPI({
        filter: { tenant: userDetail?.[0]?.tenantId },
      })
    },

    {
      initialData: [],
      retry: false,
      onError: (error) => error,
    },
  )
  const onChangeText = (data, text) => {
    if (data?.valueType === 'photo') {
      handleAddImage()
      setUser({
        ...user,
        picture: text,
      })
    } else if (data?.fieldName === 'graduation') {
      handleAddImage()
      setUser({
        ...user,
        graduation: text?.id,
      })
    } else {
      setUser({
        ...user,
        [data.fieldName]: text,
      })
    }
  }

  const validate = ({ dataFields }) => {
    dataFields?.forEach((elements) => {
      elements?.forEach((element) => {
        const field = element.metadata
        const { label, isMandatory } = field
        const fieldName = field?.referenceName ?? field?.fieldName
        const value = user?.[fieldName]
        if (
          isMandatory &&
          (value === undefined ||
            value === null ||
            value?.length === 0 ||
            value === 0)
        ) {
          validationErrors.push({
            fieldName,
            value: value ?? '',
            message: `${label} ${t('Global.MANDATORY')}`,
          })
        }
      })
    })
    return validationErrors
  }

  const handleAdd = async () => {
    departmentDetails?.metaData?.sections?.map((item) =>
      validate({
        userData: user,
        dataFields: [item?.fields],
      }),
    )
    setErrors(validationErrors)
    if (validationErrors.length > 0 && module !== 'updateDepartment') {
      return
    }
    setIsLoading(true)
    const tenant = await SecureStore.getCookie('user-details')
    const userDetail = JSON.parse(tenant?.userRoleDetails)
    if (module === 'addDepartment' || module === 'updateDepartment') {
      uploadImage({ module, user, id })
    } else if (module === 'addGraduation') {
      const screen = screenIds?.find(
        (item) => item?.fieldName === 'add-graduation',
      )
      const graduation = await API.createGraduationAPI({
        graduation: {
          name: user.name,
          tenant: userDetail?.[0]?.tenantId,
        },
        extraHeader: {
          screen: screen?._id,
        },
      })
      if (!graduation.errors) {
        toast.show('Graduation Type Added Successfully', { type: 'success' })
      }
    } else if (module === 'addDegree') {
      const screen = screenIds?.find(
        (item) => item?.fieldName === 'add-graduation',
      )
      const degree = await API.createDegreeAPI({
        degree: {
          name: user.name,
          graduation: user.graduation,
          tenant: userDetail?.[0]?.tenantId,
        },
        extraHeader: {
          screen: screen?._id,
        },
      })
      if (!degree.errors) {
        toast.show('Degree Added Successfully', { type: 'success' })
      }
    }

    setErrors()
    setUser({})
    setIsLoading(false)
    return true
  }
  const getLookupData = (fieldName) => {
    const options = []
    if (lookupData.length > 0 && fieldName === 'graduation') {
      lookupData?.map((item, i) => {
        options.push({
          displayName: item?.name,
          value: item?.name?.replace(/ /g, ''),
          id: item._id,
          displayOrder: i,
        })
        return null
      })
    }
    return options
  }

  const viewProps = {
    ...props,
    errors,
    fileInputRef,
    handleAdd,
    handleAddImage,
    isFetching,
    moduleData: departmentDetails.metaData,
    onChangeText,
    setErrors,
    setShowModal,
    setUser,
    showModal,
    user,
    id,
    checked,
    setChecked,
    module,
    getLookupData,
    isLoading,
    setIsLoading,
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )
  return (
    <Suspense fallback={<Text>{t('Settings.LOADING')}</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default DepartmentDetail
