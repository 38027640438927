import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Text, useTheme } from 'react-native-paper'

import { SecureStore } from '@camped/utils'

export const ProfileHeader = (props) => {
  const { globalUser } = props
  const { colors, spacing } = useTheme()
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    // When the component mounts, fetch the user data
    async function fetchData() {
      const data = await SecureStore.getCookie('user-details')
      setUserData(data) // Store the user data in state
    }

    fetchData()
  }, [])
  return (
    <View
      style={{
        flexDirection: 'row',
        margin: 18,
        justifyContent: 'space-evenly',
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          marginLeft: spacing.spacing3,
        }}
      >
        <Text
          variant='headlineSmall'
          style={{
            color: colors.onSurface1,
            alignItems: 'center',
          }}
        >
          {globalUser?.given_name}
        </Text>
        <Text
          variant='bodySmall'
          style={{
            color: colors.onSurface1,
          }}
        >
          {globalUser?.email ? globalUser?.email : userData?.email}
        </Text>
      </View>
    </View>
  )
}
