import React from 'react'

export const HoverHighlightLink = (props) => {
  const { text, onHoverColor, textColor, style, onPress } = props

  return (
    <span
      style={{
        color: textColor,
        cursor: 'pointer',
        ...style,
      }}
      onFocus={(e) => {
        e.target.style.color = onHoverColor
      }}
      onBlur={(e) => {
        e.target.style.color = textColor
      }}
      onClick={onPress}
    >
      {text}
    </span>
  )
}
