import { useAtom } from 'jotai'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import uuid from 'react-native-uuid'

import { useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { userRollAtom } from '@camped/jotai-storage'
import { FileHandler, SecureStore } from '@camped/utils'

const useUploadImage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const screenIds = queryClient.getQueryData(['getScreenIds'])
  const [userPerson] = useAtom(userRollAtom)

  const uploadImage = async ({ user, id }) => {
    const tenant = await SecureStore.getCookie('user-details')
    const userDetail = JSON.parse(tenant?.userRoleDetails)
    const screen = screenIds?.find(
      (item) => item?.fieldName === 'add-department',
    )
    const departmentId = uuid.v4()
    let newDepartment
    if (user?.picture) {
      // for custom image upload function in s3 upload | fetching s3 signed url
      const response = await API.getS3ImageUploadUrlAPI({
        s3Data: {
          id: departmentId,
          userPerson,
          folderName: 'department',
        },
      })
      if (response) {
        await FileHandler.uploadFileToS3({
          url: response.uploadUrl,
          file: user?.picture,
        })
        if (id) {
          newDepartment = await API.updateDepartmentAPI({
            id,
            department: {
              name: user?.name,
              image: `department/${userPerson}/${departmentId}`,
              tenant: userDetail?.[0]?.tenantId,
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
        } else {
          newDepartment = await API.createDepartmentAPI({
            department: {
              name: user.name,
              image: `department/${userPerson}/${departmentId}`,
              tenant: userDetail?.[0]?.tenantId,
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
        }
      }
    } else {
      newDepartment = await API.createDepartmentAPI({
        department: {
          name: user.name,
          tenant: userDetail?.[0]?.tenantId,
        },
        extraHeader: {
          screen: screen?._id,
        },
      })
    }
    if (!newDepartment.errors) {
      toast.show(
        id
          ? t('Success_Messages.DEPARTMENT_UPDATED')
          : t('Success_Messages.DEPARTMENT_ADDED'),
        { type: 'success' },
      )
    } else {
      toast.show(newDepartment?.errors?.[0]?.message, { type: 'Danger' })
    }
  }
  const handleAddImage = () => {
    if (Platform.OS === 'web') {
      openFilePicker()
    }
  }
  // image upload

  const fileInputRef = useRef(null)
  const openFilePicker = () => fileInputRef?.current?.click()
  const getBase64 = async (f) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(f)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  return { fileInputRef, getBase64, handleAddImage, uploadImage }
}

export default useUploadImage
