import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

const Divider = (props) => {
  const { style, color } = props
  const { colors } = useTheme()
  return (
    <View
      style={StyleSheet.flatten([
        {
          height: 1,
          backgroundColor: color || colors.surface2,
          width: '100%',
        },
        style,
      ])}
    />
  )
}

export default Divider
