import React from 'react'
import { ScrollView, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import MobileView from './MobileView'

const DesktopView = (props) => {
  const { colors, spacing } = useTheme()

  return (
    <View
      style={{
        flex: 1,
        paddingHorizontal: spacing.spacing3,
        backgroundColor: colors.surfaceVariant,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      }}
    >
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <MobileView {...props} />
      </ScrollView>
    </View>
  )
}
export default DesktopView
