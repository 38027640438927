import React from 'react'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

import Text from '../Text'

const UploadDocument = (props) => {
  const { pdfInputRef, handleFileChange, data, pickPDF } = props
  const { colors } = useTheme()
  return (
    <TouchableOpacity
      onPress={() => {
        pickPDF()
      }}
    >
      <Surface style={styles.container(colors)}>
        <View style={styles.iconContainer}>
          <Icon
            color={colors.onBackground}
            width={22}
            height={22}
            name='Upload'
            style={styles.icon}
          />
        </View>
        <View style={styles.infoContainer}>
          <Text style={styles.fileName(colors)}>
            {`upload the ${data?.label.toLowerCase()}`}
          </Text>
        </View>
        {Platform.OS === 'web' && (
          <input
            ref={pdfInputRef}
            style={{ display: 'none' }}
            type='file'
            id='attachment'
            name='attachment'
            accept='.pdf'
            onChange={handleFileChange}
            onClick={() => {}}
          />
        )}
      </Surface>
    </TouchableOpacity>
  )
}
export default UploadDocument
const styles = StyleSheet.create({
  container: (colors) => ({
    backgroundColor: colors.surface2,
    height: spacing.spacing9,
    borderRadius: spacing.spacing3,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: colors.onSurface1,
    width: '100%',
    flexDirection: 'row',
  }),
  infoContainer: {
    paddingTop: spacing.spacing5,
    paddingLeft: spacing.spacing5,
  },
  fileName: (colors) => ({
    fontSize: 16,
    color: colors.onSurface1,
    opacity: 0.6,
  }),

  iconContainer: {
    marginBottom: 24,
    height: spacing.spacing9,
    marginRight: spacing.spacing2,
    flexDirection: 'row',
  },
  icon: {
    alignSelf: 'center',
    paddingLeft: spacing.spacing6,
  },
})
