import { createNativeStackNavigator } from '@react-navigation/native-stack'
import React from 'react'

import { Stacks } from './Stacks'

const Stack = createNativeStackNavigator()

const createNavigators = (initialRouteName, props) => (
  <Stack.Navigator initialRouteName={initialRouteName}>
    {(() => Stacks(Stack, props))()}
  </Stack.Navigator>
)

const HomeStackNavigator = (props) => createNavigators('', props)
export default HomeStackNavigator
