import apiCall from './apiCall'
import { graphqlOperations } from './graphqlOperations'

const generateAPIs = () => {
  const API = {}
  graphqlOperations.forEach(
    ({ tgt, name, operation, isAuth = true, endPointName }) => {
      API[`${name}API`] = async (requestBody) => {
        const response = await apiCall(
          tgt,
          operation,
          requestBody,
          isAuth,
          endPointName,
        )
        if (!response?.errors) return response[name]

        return response
      }
    },
  )
  return API
}

export default generateAPIs()
