import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import {
  Animated,
  Dimensions,
  Modal,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTheme } from 'react-native-paper'
import Toast from 'react-native-toast-notifications'

import { Button, Divider, Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

const Overlay = ({
  isVisible,
  setVisible,
  primaryButtonLabel,
  secondaryButtonLabel,
  handlePrimaryButton,
  handleSecondaryButton,
  headerTextVariant,
  children,
  title,
  width,
}) => {
  const { colors } = useTheme()
  const screenWidth = Dimensions.get('screen').width
  const panX = useRef(new Animated.Value(screenWidth)).current

  const openAnim = Animated.timing(panX, {
    toValue: 0,
    duration: 250,
    useNativeDriver: true,
  })

  const closeAnim = Animated.timing(panX, {
    toValue: screenWidth,
    duration: 250,
    useNativeDriver: true,
  })

  const translateX = panX.interpolate({
    inputRange: [-1, 0, 1],
    outputRange: [0, 0, 1],
  })

  const backgroundColor = panX.interpolate({
    inputRange: [0, screenWidth],
    outputRange: ['rgba(0,0,0,0.5)', 'rgba(0,0,0,0)'],
  })

  useEffect(() => {
    if (isVisible) {
      openAnim.start()
    }
  }, [isVisible])

  const handleClose = () => {
    closeAnim.start(() => {
      setVisible(false)
    })
  }

  return (
    <Modal visible={isVisible} transparent onRequestClose={handleClose}>
      <Animated.View style={[styles.overlayBackground(backgroundColor)]}>
        <TouchableOpacity style={{ flex: 1 }} onPress={handleClose} />
        <Animated.View
          style={[
            styles.overlay(colors, width),
            { transform: [{ translateX }] },
          ]}
        >
          <View style={styles.innerOverlay}>
            <Text
              variant={headerTextVariant || 'headlineSmall'}
              style={styles.title(colors)}
            >
              {title}
            </Text>
            <TouchableOpacity
              onPress={handleClose}
              style={styles.overlayInnerContainer}
            >
              <Icon
                name='CloseIcon'
                width={22}
                height={22}
                color={colors.onBackground}
              />
            </TouchableOpacity>
          </View>
          <Divider style={{ opacity: 0.3 }} />
          <ScrollView>{children}</ScrollView>

          {handlePrimaryButton || handleSecondaryButton ? (
            <View style={styles.buttonContainer}>
              {handlePrimaryButton && (
                <Button mode='text' style={styles.button}>
                  {primaryButtonLabel}
                </Button>
              )}
              {handleSecondaryButton && (
                <Button mode='contained' style={styles.button}>
                  {secondaryButtonLabel}
                </Button>
              )}
            </View>
          ) : null}
        </Animated.View>
      </Animated.View>
      <Toast
        ref={(ref) => {
          global.toast = ref
        }}
      />
    </Modal>
  )
}

Overlay.propTypes = {
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  handlePrimaryButton: PropTypes.func,
  handleSecondaryButton: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
}
Overlay.defaultProps = {
  isVisible: false,
  primaryButtonLabel: '',
  secondaryButtonLabel: '',
  handlePrimaryButton: null,
  handleSecondaryButton: null,
  children: null,
  title: '',
}

const styles = StyleSheet.create({
  button: {
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlayBackground: (backgroundColor) => ({
    flexDirection: 'row',
    flex: 1,
    backgroundColor,
  }),
  overlay: (colors, width) => ({
    flexDirection: 'column',
    height: '100%',
    width: width || 320,
    alignSelf: 'flex-end',
    backgroundColor: colors.surface1,
    paddingVertical: spacing.spacing4,
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  }),
  innerOverlay: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  overlayInnerContainer: {
    paddingRight: spacing.spacing5,
  },
  title: (colors) => ({
    color: colors.onSurface1,
    paddingLeft: spacing.spacing5,
  }),
  buttonContainer: {
    paddingHorizontal: spacing.spacing6,
    paddingVertical: spacing.spacing4,
  },
})

export default Overlay
