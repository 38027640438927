import { useNavigation } from '@react-navigation/native'
import { useAtom } from 'jotai'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Image,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { Divider, Menu, Text, useTheme } from 'react-native-paper'

import { Audit } from '@camped/audit'
import { domainAtom } from '@camped/jotai-storage'
import { aceprepToken, userAtom, userRollAtom } from '@camped/jotai-storage'
import ProfileIcon from '@camped/shared-component-avatar'
import { ScreenLayout } from '@camped/utils'

import { EventAction, EventEntityType, EventScreen, EventType } from './audit'
import { ProfileHeader } from './component/profile-header'

export const ProfileMenu = (props) => {
  const { globalUser } = props
  const { colors, spacing } = useTheme()
  const [visible, setVisible] = React.useState(false)
  const navigation = useNavigation()
  const [domainDetails] = useAtom(domainAtom)
  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout?.isWeb(windowWidth)

  const { t } = useTranslation()
  const eventClickLogout = () => {
    Audit.logEvent({
      action: EventAction.CLICK,
      entityType: EventEntityType.LOGOUT,
      eventType: EventType.LOGOUT,
      details: {
        Screen: EventScreen.ALL,
      },
    })
  }
  const eventClickProfile = () => {
    Audit.logEvent({
      action: EventAction.CLICK,
      entityType: EventEntityType.PROFILE,
      eventType: EventType.PROFILE,
      details: {
        Screen: EventScreen.ALL,
      },
    })
  }

  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchor={
        <TouchableOpacity onPress={() => setVisible(true)}>
          {isDesktop ? (
            <View
              style={{
                flexDirection: 'row',
                padding: spacing.spacing2,
                paddingLeft: spacing.spacing5,
                alignItems: 'center',
                justifyContent: 'space-between',
                height: spacing.spacing8,
                width: 210,
                backgroundColor: colors.surface1,
                borderRadius: spacing.spacing3,
                borderColor: colors.surfaceVariant,
                borderWidth: 1,
              }}
            >
              <Text variant='bodyMedium' style={{ color: colors.neutral }}>
                {domainDetails?.institutionName &&
                domainDetails?.institutionName.length >= 11
                  ? `${domainDetails?.institutionName.substring(0, 10)}...`
                  : domainDetails?.institutionName}
              </Text>
              {domainDetails?.logoURL ? (
                <Image
                  source={{ uri: domainDetails?.logoURL }}
                  style={{
                    width: 146,
                    height: 52,
                  }}
                  resizeMode='contain'
                />
              ) : (
                <ProfileIcon size={32} />
              )}
            </View>
          ) : (
            <ProfileIcon size={32} />
          )}
        </TouchableOpacity>
      }
      contentStyle={{
        backgroundColor: colors.surface1,
        marginTop: spacing.spacing8,
        borderRadius: spacing.spacing3,
      }}
    >
      <ProfileHeader
        globalUser={globalUser}
        profileURL={domainDetails?.logoURL}
      />
      <Divider
        style={{
          marginRight: spacing.spacing3,
          marginLeft: spacing.spacing3,
          backgroundColor: colors.surface2,
        }}
      />
      <Menu.Item
        onPress={() => {
          navigation.navigate('my-profile')
          eventClickProfile()
          setVisible(false)
        }}
        leadingIcon='DefaultImage'
        title={t('Global.PROFILE_INFO')}
        style={{
          backgroundColor: colors.surface1,
        }}
        titleStyle={{
          color: colors.onSurface1,
        }}
      />
      <Menu.Item
        onPress={() => {
          eventClickLogout()
          navigation.navigate('logout')
        }}
        title='Logout'
        leadingIcon='Logout'
        style={{
          backgroundColor: colors.surface1,
        }}
        titleStyle={{
          color: colors.onSurface1,
        }}
      />
    </Menu>
  )
}
