import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, ScrollView, StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { MobileContainer } from '@camped/shared-container'
import { spacing } from '@camped/theme-provider'

import { CampEdBranding } from '../../components'

const MobileView = (props) => {
  const { data, bgImage, RenderComponent } = props
  const { colors } = useTheme()
  const { t } = useTranslation()

  return (
    <MobileContainer hasKeyBoard style={{ backgroundColor: colors.surface3 }}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, minHeight: '100%' }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            backgroundColor: colors.background,
            alignItems: 'center',
          }}
        >
          <CampEdBranding bgImage={bgImage} />
        </View>
        {!data?.logoUrl ? (
          <Text
            variant='headlineLarge'
            style={{
              color: colors.onSurfaceVariant,
              marginVertical: spacing.spacing6,
              marginLeft: spacing.spacing6,
            }}
          >
            {t('Auth.CAMPED_WELCOME')}
          </Text>
        ) : (
          <Image
            source={{ uri: data?.logoUrl }}
            style={styles.logoUrl}
            resizeMode='contain'
          />
        )}
        <View style={{ paddingHorizontal: spacing.spacing6 }}>
          {RenderComponent}
        </View>
      </ScrollView>
    </MobileContainer>
  )
}

const styles = StyleSheet.create({
  institutionName: {
    marginVertical: spacing.spacing6,
    paddingLeft: spacing.spacing6,
  },
  logoUrl: {
    height: 52,
    marginLeft: spacing.spacing6,
    marginVertical: spacing.spacing6,
    width: 146,
  },
})

export default MobileView
