export const studentValidRoute = [
  'my-profile',
  'home',
  'dashboard',
  'logout',
  'login',
  'campus',
  'forgot-passwor',
  'web-view',
  'course-listing',
  'reset-password',
  'change-password',
  'mock-interview',
  'tailored-practice',
  'coding-problems',
  'frontend-practice',
  'resume-builder',
  'market-place',
]
