import React, { useState } from 'react'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import { Checkbox, Surface, useTheme } from 'react-native-paper'

import {
  DataTableCell,
  DataTableHeader,
  DataTableRow,
  DataTableTitle,
  Divider,
  Text,
} from '@camped/components'
import { DynamicField, FormContainer } from '@camped/form-container'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

import FieldPermissions from '../fieldPermissions'

const DesktopView = (props) => {
  const {
    data,
    selectedScreen,
    onDropdownChange,
    toggleSwitch,
    user,
    isFetching,
    selectedRole,
    setSelectedRole,
    permissionData,
    module,
    uniqueScreen,
  } = props

  const [visible, setVisible] = useState(false)
  const { colors } = useTheme()
  const renderItem = (localData) => {
    localData.filter = true
    return (
      <DynamicField
        data={localData}
        isEdit
        value={selectedScreen?.displayName}
        list={uniqueScreen(permissionData?.map((item) => item?.screen))}
        multiSelect={localData.multiSelect}
        dropDownWidth={218}
        onChangeText={onDropdownChange}
      />
    )
  }
  return (
    <Surface
      style={{
        flexDirection: 'column',
        flex: 1,
        backgroundColor: colors.background,
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          flex: 1,
          backgroundColor: colors.surface1,
          paddingHorizontal: spacing.spacing3,
          paddingVertical: spacing.spacing4,
          borderRadius: 3,
        }}
      >
        {module === 'Role' && (
          <>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: spacing.spacing5,
              }}
            >
              <View style={{ flex: 0.3 }}>
                <FormContainer
                  data={data}
                  renderItem={renderItem}
                  width={300}
                />
              </View>
            </View>
            <Divider />
            <DataTableHeader
              backgroundColor={colors.surface2}
              style={{
                borderWidth: 1,
                borderColor: colors.surface4,
              }}
            >
              <View
                style={{
                  flex: 2.4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  borderRightWidth: 1,
                  borderRightColor: colors.surface4,
                }}
              >
                <DataTableTitle
                  style={{
                    justifyContent: 'center',
                    marginHorizontal: 20,
                    paddingBottom: '40px',
                  }}
                  textStyle={{ fontSize: '14px' }}
                />

                <DataTableCell
                  style={{
                    marginHorizontal: 30,
                    justifyContent: 'center',
                  }}
                  textSize={12}
                >
                  ROLE
                </DataTableCell>
              </View>
              <View style={{ flex: 5 }}>
                <DataTableTitle
                  style={{
                    justifyContent: 'center',
                    padding: '10px',
                    backgroundColor: colors.background,
                    borderRightWidth: 1,
                    borderRightColor: colors.surface4,
                  }}
                >
                  RECORD PERMISSIONS
                </DataTableTitle>
                <Divider color={colors.surface4} />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {['VIEW', 'ADD', 'EDIT', 'DELETE'].map((label, index) => (
                    <DataTableCell
                      key={index}
                      style={{
                        justifyContent: 'center',
                        borderRightWidth: 1,
                        borderRightColor: colors.surface4,
                        padding: spacing.spacing2,
                      }}
                      textSize={12}
                    >
                      {label}
                    </DataTableCell>
                  ))}
                </View>
              </View>
              <View style={{ flex: 2 }}>
                <DataTableTitle
                  style={{
                    justifyContent: 'center',
                    padding: '10px',
                    backgroundColor: colors.background,
                  }}
                >
                  FIELD PERMISSIONS
                </DataTableTitle>
                <Divider color={colors.surface4} />
                <DataTableCell
                  style={{ justifyContent: 'center', paddingBottom: '5px' }}
                  textSize={12}
                >
                  <Text />
                </DataTableCell>
              </View>
            </DataTableHeader>
          </>
        )}

        {module === 'Menu' && (
          <DataTableHeader backgroundColor={colors.surface2}>
            <View style={{ flex: 1 }}>
              <DataTableTitle style={{ padding: '10px', marginLeft: 12 }}>
                ROLE
              </DataTableTitle>
              <Divider color={colors.surface1} />
            </View>
            <View style={{ flex: 1 }}>
              <DataTableTitle
                style={{ justifyContent: 'center', padding: '10px' }}
              >
                MENU PERMISSIONS
              </DataTableTitle>
              <Divider color={colors.surface1} />
            </View>
          </DataTableHeader>
        )}
        <Divider />
        {isFetching && (
          <ActivityIndicator
            size='large'
            color={colors.primary}
            style={{ position: 'absolute', top: '40%', right: '50%' }}
          />
        )}

        {!isFetching &&
          user?.rolesData?.map((item, index) => (
            <DataRow
              i={index}
              item={item}
              onToggleSwitch={toggleSwitch}
              isVisible={setVisible}
              setSelectedRole={setSelectedRole}
              module={module}
            />
          ))}
      </View>
      <FieldPermissions
        visible={visible}
        selectedRole={selectedRole}
        setVisible={setVisible}
        roleData={selectedRole}
        module={module}
      />
    </Surface>
  )
}

const DataRow = ({
  i,
  item,
  onToggleSwitch,
  isVisible,
  setSelectedRole,
  module,
}) => {
  const { colors } = useTheme()
  return (
    <>
      <DataTableRow
        paddingHorizontal={spacing.spacing4}
        backgroundColor={colors.surface1}
        hasHover={false}
        style={{
          borderWidth: 1,
          borderColor: colors.surface4,
          borderTopWidth: 0,
        }}
      >
        <View
          style={{
            flex: 1.84,
            borderRightWidth: 1,
            borderRightColor: colors.surface4,
          }}
        >
          <DataTableCell
            style={{ paddingLeft: spacing.spacing3 }}
            textVariant='bodyMedium'
          >
            {item?.role?.name}
          </DataTableCell>
        </View>
        {module === 'Role' && (
          <View
            style={{
              flex: 4,
              flexDirection: 'row',
            }}
          >
            {['view', 'add', 'edit', 'delete'].map((permission, index) => (
              <DataTableCell
                key={index}
                style={{
                  justifyContent: 'center',
                  borderRightWidth: 1,
                  borderRightColor: colors.surface4,
                }}
                link
              >
                <Checkbox
                  status={
                    item?.permissions?.[permission] ? 'checked' : 'unchecked'
                  }
                  onPress={() => {
                    onToggleSwitch(
                      i,
                      permission,
                      !item?.permissions?.[permission],
                    )
                  }}
                  disabled={
                    item?.permissions?.[permission] === null ||
                    item?.permissions?.[permission] === 'null' ||
                    item?.permissions?.[permission] === undefined ||
                    (item?.permissions?.view === false && permission !== 'view')
                  }
                />
              </DataTableCell>
            ))}
          </View>
        )}

        <View style={{ flex: 1.52 }}>
          <DataTableCell style={{ justifyContent: 'center' }}>
            <TouchableOpacity
              onPress={() => {
                setSelectedRole(item)
                isVisible(true)
              }}
              disabled={!item?.permissions?.view && module === 'Role'}
            >
              <Icon
                name='FieldPermission'
                fill={colors.onBackground}
                height={21}
                width={50}
              />
            </TouchableOpacity>
          </DataTableCell>
        </View>
      </DataTableRow>
      <Divider />
    </>
  )
}

export default DesktopView
