import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Divider, Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

import ThemeSwitch from './theme-switch'

const DesktopLeftMainNav = (props) => {
  const { menuList, onClick, screenName, setActiveTab } = props
  const { colors } = useTheme()

  const getIsNavMenuActive = (data, i) => {
    const isModuleActive = data?.subMenuItems?.some((item) =>
      item?.subMenuItems?.some((accordion) =>
        accordion?.activePath?.includes(screenName?.name),
      ),
    )
    const isScreenNameMatch = data?.activePath.includes(screenName?.name)
    const isModuleNameIncluded = data?.moduleNames?.includes(screenName?.name)
    const isActive = isModuleActive || isScreenNameMatch || isModuleNameIncluded
    if (isActive) {
      setActiveTab(i)
    }
    return isActive
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={styles(colors).container}>
        <View style={styles(colors).iconContainer}>
          {menuList
            ?.filter((m) => m.placement === 'top')
            ?.map((data, i) => (
              <React.Fragment key={i}>
                {i === 1 && (
                  <Divider
                    style={{
                      backgroundColor: colors.surface2,
                      paddingHorizontal: spacing.spacing2,
                    }}
                  />
                )}
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  {data?.placement === 'top' && (
                    <NavMenu
                      iconName={data?.iconName}
                      active={getIsNavMenuActive(data, i)}
                      onPress={() => {
                        onClick(data, 'top', i)
                      }}
                    />
                  )}
                </View>
              </React.Fragment>
            ))}
        </View>
        <View>
          {menuList
            ?.filter((m) => m.placement === 'bottom')
            ?.map((data, index) => (
              <View style={styles(colors).iconContainer} key={index}>
                {index === 0 && (
                  <Divider
                    style={{
                      backgroundColor: colors.surface2,
                    }}
                  />
                )}
                <NavMenu
                  iconName={data?.iconName}
                  active={data?.screenName === screenName?.name}
                  onPress={() => {
                    onClick(data, 'bottom', index)
                  }}
                />
              </View>
            ))}
          <ThemeSwitch />
          <View style={{ marginBottom: 24 }} />
        </View>
      </View>
    </View>
  )
}

const NavMenu = (props) => {
  const { colors } = useTheme()
  const {
    iconName,
    onPress = () => {},
    active,
    label,
    width = 24,
    height = 24,
  } = props
  return (
    <TouchableOpacity style={styles(colors).content} onPress={onPress}>
      {active ? (
        <Icon
          name={iconName}
          width={width}
          height={height}
          fill={active ? colors.onSurface : false}
          color={colors.onPrimary}
        />
      ) : (
        <Icon
          name={iconName}
          width={width}
          height={height}
          color={colors.onSurface}
          style={{ opacity: 0.5 }}
        />
      )}
      {active && (
        <Icon
          name='MenuActiveIndicator'
          width={width}
          height={height}
          fill={colors.background}
          style={{
            width: 16,
            height: 48,
            position: 'absolute',
            right: -14,
          }}
        />
      )}
      {label && (
        <Text variant='labelSmall' style={styles(colors).label}>
          {label}
        </Text>
      )}
    </TouchableOpacity>
  )
}

const styles = (colors) =>
  StyleSheet.create({
    container: {
      width: 72,
      backgroundColor: colors.surface1,
      height: '100%',
      justifyContent: 'space-between',
    },
    content: {
      width: 48,
      height: 30,
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: spacing.spacing5,
      borderRadius: spacing.spacing7,
    },
    label: {
      fontSize: 10,
      marginTop: spacing.spacing2,
    },
    iconContainer: {
      width: '100%',
      alignItems: 'center',
    },
  })

export default DesktopLeftMainNav
