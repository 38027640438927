import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useSendInvitation = ({
  message,
  onSuccess,
  queryFunc,
  queryKey,
}) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data) => {
      const response = await queryFunc(data)
      return response
    },
    {
      onSuccess: (data, context) => {
        if (!data.errors) {
          onSuccess()
          toast.show(message, { type: 'success' })

          queryClient.setQueryData([queryKey], (localData) => {
            const filteredData = localData.pages.map((page) => {
              const updatedItems = page.items.map((item) => {
                const matchingUserId = context?.userId.find(
                  (id) => id === item?.userId,
                )
                if (matchingUserId) {
                  return { ...item, sendInvitation: true }
                }
                return { ...item }
              })

              return { ...page, items: updatedItems }
            })

            return {
              ...localData,
              pages: filteredData,
            }
          })
        } else {
          toast.show(data?.errors?.[0]?.message, { type: 'danger' })
        }
      },
    },
  )
}
