import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

const RightSideBar = ({ handleBulkInvitation }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <View style={styles.mainContainer}>
      <View style={styles.layout(colors)}>
        <View style={styles.container}>
          <IconContainer
            label={t('Directory.SEND_INVITE')}
            iconName='InviteIcon'
            onPress={handleBulkInvitation}
          />
        </View>
      </View>
    </View>
  )
}
export default RightSideBar
const IconContainer = ({ label, iconName, onPress = () => {} }) => {
  const { colors } = useTheme()
  const [hover, setHover] = useState(false)
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: spacing.spacing7,
      }}
    >
      <TouchableOpacity
        style={[
          styles.expandedIcon,
          { backgroundColor: colors.primary },
          { opacity: hover ? 1 : 0.4 },
        ]}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
        onPress={onPress}
      >
        <Icon
          name={iconName}
          width={21}
          height={15}
          color={colors.onPrimary}
          style={styles.iconView}
        />
      </TouchableOpacity>
      <Text style={{ textAlign: 'center', color: colors.onBackground }}>
        {label}
      </Text>
    </View>
  )
}
const styles = StyleSheet.create({
  mainContainer: {
    marginRight: spacing.spacing4,
    paddingBottom: spacing.spacing2,
    borderRadius: spacing.spacing3,
    maxWidth: 240,
    height: '100%',
  },
  iconView: { alignSelf: 'center', justifyContent: 'center', flex: 1 },
  expandedIcon: {
    width: 40,
    height: 40,
    borderRadius: spacing.spacing6,
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  layout: (colors) => ({
    borderRadius: spacing.spacing3,
    width: 80,
    height: '99%',
    backgroundColor: colors.surface1,
    borderColor: colors.surfaceVariant,
    borderWidth: 1,
  }),
})
