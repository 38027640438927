import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Chip, HelperText, useTheme } from 'react-native-paper'

import { Button, Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'
import { appUtil } from '@camped/utils'

const QuestionPaperUi = (props) => {
  const { data, selectedTopics, setselectedTopics, selectedUnitsValidation } =
    props
  const { colors } = useTheme()
  const [showTopic, setShowTopic] = useState('')
  const allUnitTopics = {}
  const unit = data?.sections?.find(
    (item) => item?.metadata?.fieldName === 'units',
  )?.fields
  unit?.forEach((items) => {
    const topics = items?.find(
      (item) => item?.metadata?.fieldName === 'unitDetails',
    )?.value
    const unitName = items?.find(
      (item) => item?.metadata?.fieldName === 'unitName',
    )?.value
    allUnitTopics[unitName] = topics?.split(',')
  })
  const addTopic = (topic, i, unitName) => {
    setselectedTopics((prevSelectedTopics) => {
      const updatedSelectedTopics = [...prevSelectedTopics]

      if (i === -1) {
        updatedSelectedTopics.push({
          unitName,
          topics: [topic],
        })
      } else {
        updatedSelectedTopics[i] = {
          unitName,
          topics: [topic, ...updatedSelectedTopics[i].topics],
        }
      }

      return updatedSelectedTopics
    })
  }

  const removeTopic = (topic, i, unitName) => {
    setselectedTopics((prevSelectedTopics) => {
      const updatedSelectedTopics = [...prevSelectedTopics]
      const updatedTopic = updatedSelectedTopics?.[i]?.topics.filter(
        (item) => item !== topic,
      )
      updatedSelectedTopics?.find((item, index) => {
        if (item.unitName === unitName) {
          updatedSelectedTopics[index] = { unitName, topics: updatedTopic }
        }
      })

      const filteredTopics = updatedSelectedTopics?.filter(
        (item) => item.topics.length !== 0,
      )

      return filteredTopics
    })
  }

  return (
    <View>
      <Text
        style={{
          fontSize: 18,
          color: selectedUnitsValidation ? colors.error : colors.text,
          marginTop: spacing.spacing4,
          marginLeft: spacing.spacing4,
        }}
        variant='bodyLarge'
      >
        Select Your Topic To Generate Question Paper
      </Text>
      {selectedUnitsValidation && (
        <HelperText
          type='error'
          visible
          padding='none'
          numberOfLines={2}
          style={{ width: '95%', marginLeft: spacing.spacing4 }}
        >
          {selectedUnitsValidation}
        </HelperText>
      )}
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginBottom: spacing.spacing1,
          marginLeft: spacing.spacing4,
        }}
      >
        {unit?.map((item, i) => {
          const unitNameItem = item?.find(
            (_) => _?.metadata?.fieldName === 'unitName',
          )
          const unitName = unitNameItem ? unitNameItem.value : null

          return unitName ? (
            <Button
              mode={showTopic === unitName ? '' : 'outlined'}
              style={{
                backgroundColor:
                  showTopic === unitName ? colors.primary : 'transparent',
                height: 32,
                justifyContent: 'center',
                marginRight: spacing.spacing6,
                marginVertical: spacing.spacing3,
                padding: 0,
                alignItems: 'center',
              }}
              labelStyle={{
                color:
                  showTopic === unitName ? colors.onPrimary : colors.primary,
                marginBottom: 9,
                fontSize: 14,
              }}
              onPress={() => {
                setShowTopic(unitName)
              }}
            >
              Unit {i + 1}
            </Button>
          ) : null
        })}
      </View>
      {showTopic && (
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: spacing.spacing1,
            marginLeft: spacing.spacing4,
          }}
        >
          {allUnitTopics[showTopic]?.map((item) => (
            <RenderItem
              item={item}
              unitName={showTopic}
              removeTopic={removeTopic}
              colors={colors}
              addTopic={addTopic}
              selectedTopics={selectedTopics}
            />
          ))}
        </View>
      )}
    </View>
  )
}
const RenderItem = ({
  item,
  unitName,
  selectedTopics,
  removeTopic,
  addTopic,
  colors,
}) => {
  const i = selectedTopics.findIndex(
    (selectedTopic) => selectedTopic.unitName === unitName,
  )
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
      }}
      onPress={() => {
        if (selectedTopics?.[i]?.topics?.includes(item)) {
          removeTopic(item, i, unitName)
        } else {
          addTopic(item, i, unitName)
        }
      }}
    >
      <Chip
        mode='outlined'
        selectedColor={
          selectedTopics?.[i]?.topics?.includes(item)
            ? colors.onPrimary
            : colors.onSurface2
        }
        style={{
          fontSize: 18,
          marginRight: spacing.spacing4,
          marginBottom: spacing.spacing2,
          backgroundColor: selectedTopics?.[i]?.topics?.includes(item)
            ? colors.primary
            : 'transparent',
        }}
      >
        <Icon
          name={
            selectedTopics?.[i]?.topics?.includes(item)
              ? 'Selection'
              : 'PlusIcon'
          }
          color={
            selectedTopics?.[i]?.topics?.includes(item)
              ? colors.onPrimary
              : colors.onSurface1
          }
          width={13}
          height={13}
          style={{ marginRight: spacing.spacing2 }}
        />
        {appUtil.capitalize(item.trim().replaceAll('\n', ' '))}
      </Chip>
    </TouchableOpacity>
  )
}

export default QuestionPaperUi
