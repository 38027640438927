import { useNavigation, useNavigationState } from '@react-navigation/native'
import React, { useState } from 'react'
import {
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { Surface, useTheme } from 'react-native-paper'

import { Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

import BottomLogo from 'assets/bottomLogo.png'

import AllApps from './mobile-drawer-all-apps'

export const BottomNavLayout = (props) => {
  const { children, menuData } = props
  const { colors } = useTheme()
  const navigation = useNavigation()
  const [showAppDrawer, setShowAppDrawer] = useState(false)
  const [title, setTitle] = useState('')
  const currentRoute = useNavigationState(
    (state) => state?.routes?.[state.routes.length - 1],
  )

  const windowWidth = useWindowDimensions().width

  const renderBottomIcon = (name, label, route, screen, isDisable = false) => {
    const isActiveIcon = () =>
      Array.isArray(route)
        ? route.some((ele) => ele === currentRoute?.name)
        : route === currentRoute?.name
    return (
      <TouchableOpacity
        style={styles.iconContainer}
        onPress={() => {
          if (screen === 'drawer') {
            setTitle(route[0])
            setShowAppDrawer(true)
          } else if (route !== '') navigation.navigate(route)
        }}
        disabled={isDisable}
      >
        <View style={styles.iconStyle}>
          <Icon
            color={isActiveIcon() ? colors.onPrimary : colors.onSurface}
            fill={isActiveIcon() ? colors.onSurface : colors.background}
            name={name}
            height={24}
            width={24}
            strokeWidth={isActiveIcon() ? 2 : 1}
          />
        </View>

        <Text
          style={{
            color: isActiveIcon() ? colors.onSurface : colors.onSurface,
            fontWeight: '300',
            textTransform: 'capitalize',
          }}
        >
          {label}
        </Text>
      </TouchableOpacity>
    )
  }
  return (
    <View
      style={{
        flexGrow: 1,
      }}
    >
      {children}
      <Surface
        style={{
          position: Platform.OS === 'web' ? 'fixed' : 'absolute',
          bottom: 0,
          zIndex: 10,
          backgroundColor: colors.background,
          width: windowWidth,
          height: 82,
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingTop: spacing.spacing4,
        }}
        elevation={1}
      >
        <View style={styles.icons}>
          {renderBottomIcon('Dashboard', 'Dashboard', '', 'screen')}
        </View>
        <View style={styles.icons}>
          {renderBottomIcon('Feeds', 'Feeds', 'home', 'screen')}
        </View>
        <View style={[styles.icons]}>
          <MainIcon
            localTitle='Academics'
            setShowAppDrawer={setShowAppDrawer}
            setTitle={setTitle}
            colors={colors}
          />
        </View>
        <View style={styles.icons}>
          {renderBottomIcon('Academy', 'Academic', '', 'screen')}
        </View>
        <View style={styles.icons}>
          {renderBottomIcon(
            'Settings',
            'Settings',
            ['campus-configuration', 'configuration', 'useraccess'],
            'drawer',
            Platform.OS !== 'web',
          )}
        </View>
      </Surface>
      {showAppDrawer && (
        <AllApps
          showDrawer={showAppDrawer}
          setShowDrawer={setShowAppDrawer}
          screenName={title}
          menuData={menuData}
        />
      )}
    </View>
  )
}

const MainIcon = (mainProps) => {
  const { localTitle, setShowAppDrawer, setTitle, colors } = mainProps
  return (
    <TouchableOpacity
      onPress={() => {
        setShowAppDrawer(true)
        setTitle(localTitle)
      }}
      activeOpacity={1}
      style={[
        styles.iconContainer,
        {
          flex: 1,
          position: 'absolute',
          bottom: spacing.spacing7,
          borderRadius: spacing.spacing7,
        },
      ]}
    >
      <Image
        source={BottomLogo}
        style={[
          styles.focussedImgStyle,
          {
            backgroundColor: colors.background,
            shadowOffset: { width: -2, height: 4 },
            shadowColor: colors.onSurface,
            shadowOpacity: 0.2,
            shadowRadius: 3,
          },
        ]}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  focussedImgStyle: {
    resizeMode: 'contain',
    height: 64,
    width: 64,
    borderRadius: spacing.spacing10,
  },
  container: {
    top: -15,
  },
  circle: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 25,
  },
  tabBar: {
    height: 70,
    position: 'absolute',
  },
  iconContainer: {
    marginTop: 12,
    height: 40,
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  iconStyle: {
    width: 30,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    marginBottom: spacing.spacing2,
  },
  icons: { flex: 1 },
})
