import { useNavigation } from '@react-navigation/native'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, View, useWindowDimensions } from 'react-native'
import { Text, useTheme } from 'react-native-paper'

import { API } from '@camped/api'
import { Audit } from '@camped/audit'
import { Button, TextInput } from '@camped/components'
import { domainAtom } from '@camped/jotai-storage'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout, SecureStore, testProps } from '@camped/utils'

import {
  EventAction,
  EventEntityType,
  EventScreen,
  EventType,
} from '../constants/audit'
import { Behavior, ScreenName, TypeofControl } from '../constants/testing'
import { ContactUs } from './ContactUs'

export const Campus = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { width } = useWindowDimensions()
  const [isLoading, setIsLoading] = useState(false)
  const isDesktop = ScreenLayout.isWeb(width)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const navigation = useNavigation()

  const [userCampus] = useState('')

  const [domainDetails, setDomainDetails] = useAtom(domainAtom)

  const handleWebRedirection = async (response) => {
    const data = await SecureStore.getCookie('user-details')
    if (domainDetails?.domain === userCampus.toLowerCase() && data) {
      window.location.href = `${window.location.protocol}//${domainDetails?.domain}.${window.location.host}/home`
    } else {
      window.location.href = `${window.location.protocol}//${response?.domain}.${window.location.host}/login`
    }
  }
  const handleNavigation = () => {
    if (domainDetails?.domain === userCampus.toLowerCase()) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      })
    } else {
      navigation.navigate('login')
    }
  }

  const onSubmit = async (data) => {
    setIsLoading(true)
    Audit.logEvent({
      action: EventAction.CLICK,
      entityType: EventEntityType.CAMPUS,
      eventType: EventType.LOGIN,
      details: {
        Screen: EventScreen.CAMPUS,
      },
    })

    const response = await API.getTenantByFilterAPI({
      filter: { domain: data?.userCampus.toLowerCase() },
    })
    if (response) {
      setIsLoading(false)
      if (Platform.OS === 'web') {
        handleWebRedirection(response)
      } else {
        handleNavigation()
      }
      setDomainDetails(response)
    } else {
      setIsLoading(false)
      toast.show(
        'Domain is unregistered, please reach out support@camped.academy',
        {
          type: 'danger',
        },
      )
    }
  }

  return (
    <View>
      <Text
        variant={isDesktop ? 'headlineLarge' : 'bodyLarge'}
        style={{ color: colors.onSurfaceVariant }}
      >
        {t('Auth.WELCOME_CAMPUS')}
      </Text>
      <View style={styles.container(isDesktop)}>
        <Controller
          control={control}
          render={({ field }) => (
            <TextInput
              autoFocus
              label={t('Auth.CAMPUS_DOMAIN')}
              right={<TextInput.Affix text='.camped.academy' />}
              style={{
                backgroundColor: colors.surface2,
              }}
              value={field.value}
              onChangeText={field.onChange}
              error={errors?.userCampus}
              {...testProps(
                `${ScreenName.CAMPUS}_${TypeofControl.INPUT}_${Behavior.DOMAIN}`,
              )}
            />
          )}
          name='userCampus'
          rules={{ required: 'Campus Input is required' }}
        />

        <Button
          value='test'
          style={{
            alignSelf: 'flex-end',
            marginTop: spacing.spacing6,
          }}
          loading={isLoading}
          onPress={handleSubmit(onSubmit)}
          {...testProps(
            `${ScreenName.CAMPUS}_${TypeofControl.BUTTON}_${Behavior.SUBMIT}`,
          )}
        >
          {t('Auth.GET_STARTED')}
        </Button>
      </View>

      <View
        style={{
          marginTop: isDesktop ? spacing.spacing7 : spacing.spacing13,
          marginBottom: isDesktop ? 0 : spacing.spacing6,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Text
          variant='bodyLarge'
          style={{
            color: colors.onSurfaceVariant,
            textAlign: 'center',
            alignContent: 'center',
            marginRight: spacing.spacing2,
          }}
        >
          {t('Auth.CAMPUS_QUESTION')}
        </Text>
        <ContactUs />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: (isDesktop) => ({
    marginTop: isDesktop ? spacing.spacing7 : spacing.spacing6,
    width: '100%',
  }),
  text: (colors) => ({
    color: colors.onSurfaceVariant,
    textAlign: 'center',
  }),
  mobileText: (colors) => ({
    color: colors.onSurfaceVariant,
    textAlign: 'center',
    alignContent: 'center',
  }),
})
