import { useAtom } from 'jotai'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'

import { API } from '@camped/api'
import { Text } from '@camped/components'
import { userAtom } from '@camped/jotai-storage'
import { ScreenLayout, SecureStore } from '@camped/utils'

import { useGetNewFeeds } from '../../hooks/useGetNewFeeds'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const Home = () => {
  const { t } = useTranslation()
  const [globalUser] = useAtom(userAtom)
  const [userData, setUserData] = useState(null)
  const [refreshing, setRefreshing] = React.useState(false)

  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    refetch().then(() => {
      setRefreshing(false)
    })
  }, [])
  useEffect(() => {
    // When the component mounts, fetch the user data
    async function fetchData() {
      const data = await SecureStore.getCookie('user-details')
      setUserData(data) // Store the user data in state
    }

    fetchData()
  }, [])

  const { data: studentAnalytics } = useQuery(
    [`student-analytics`],
    async () => {
      const response = await API.getStudentAnalyticsAPI()
      return response?.[0]
    },

    {
      initialData: [],
      retry: false,
      onError: (error) => error,
    },
  )

  const {
    data: newsFeeds,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetNewFeeds({
    limit: 10,
    sortValue: {},
    filterValue: {},
    totalCount: 20,
  })

  const viewProps = {
    globalUser,
    data: userData,
    studentAnalytics,
    newsFeeds,
    refreshing,
    onRefresh,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  return (
    <Suspense fallback={<Text>{t('Hostel.LOADING')}</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default Home
