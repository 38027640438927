/* eslint-disable no-unused-vars */
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { roleListing } from '@camped/shared-mock-data'
import { SecureStore } from '@camped/utils'

import { capitalize } from '../utils/capitailize'

export const useGetListingData = (module, filterValue = {}) => {
  const queryClient = useQueryClient()
  const screenIds = queryClient.getQueryData(['getScreenIds'])
  const getUserDetails = async () => {
    const tenant = await SecureStore.getCookie('user-details')
    const userDetail = JSON.parse(tenant?.userRoleDetails)
    return userDetail
  }

  const getScreenId = async () => {
    const screen = await screenIds?.find(
      (item) => item?.fieldName === `${module}-directory`,
    )
    return screen
  }

  const { data: metaData } = useQuery(
    [`get${module}MetaData`],
    async () => {
      const userDetail = await getUserDetails()
      const screen = await getScreenId()
      const metaData = await API.getMetaDataAPI({
        metaData: {
          role: userDetail?.[0]?.userRoleId ?? '',
          tenant: userDetail?.[0]?.tenantId ?? '',
          screen: screen?._id,
        },
        extraHeader: {
          screen: screen?._id,
        },
      })
      return {
        metaData,
        permission: metaData?.screens?.[0]?.permissions,
      }
    },
    {
      initialData: [],
      enabled: screenIds?.length > 0,
    },
  )

  const { data: sortAndFilterData } = useQuery(
    [`get${capitalize(module)}SortAndFiltersByFilterAPI`],
    async () => {
      if (screenIds?.length === 0) return
      const screen = await getScreenId()
      const metaData = await API.getSortAndFiltersByFilterAPI({
        filter: {
          screen: screen?._id,
        },
        extraHeader: {
          screen: screen?._id,
        },
      })
      const filterList = metaData?.filter(
        (item) => item?.fieldType === 'filter',
      )
      const sortList = metaData?.filter((item) => item?.fieldType === 'sort')
      return {
        filterList,
        sortList,
      }
    },
    {
      initialData: [],
      enabled: !!screenIds?.length,
    },
  )

  const { data: totalCount, refetch: totalCountRefecth } = useQuery(
    [`get${capitalize(module)}sCountWithFilterAPI`],
    async () => {
      if (module !== 'rolelisting') {
        const userDetail = await getUserDetails()
        const filter = {
          tenant: userDetail?.[0]?.tenantId ?? '',
          ...filterValue,
        }
        const reqApi = `get${capitalize(module)}sCountWithFilterAPI`
        const count = await API[reqApi]({
          filter,
        })
        return count
      }
      return 0
    },
    {
      initialData: 0,
      enabled: true,
    },
  )

  const permissions =
    module === 'rolelisting'
      ? roleListing?.permissions
      : metaData?.permission || []
  const moduleMetaData =
    module === 'rolelisting' ? roleListing?.metadata : metaData?.metaData || []

  return {
    totalCount,
    metaData: moduleMetaData,
    permissions,
    filterList: sortAndFilterData?.filterList,
    sortList: sortAndFilterData?.sortList,
    totalCountRefecth,
  }
}
