import { useNavigation, useNavigationState } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, View, useWindowDimensions } from 'react-native'
import { Card, useTheme } from 'react-native-paper'

import { BottomDrawer, Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

const AllApps = ({ showDrawer, setShowDrawer, screenName, menuData }) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  const appData = menuData?.find((items) =>
    items?.subMenuItems?.find(
      (item) => item.id === screenName?.toString()?.toLowerCase(),
    ),
  )
  const sortedSubMenus = appData?.subMenuItems?.sort(
    (a, b) => a.displayOrder - b.displayOrder,
  )

  sortedSubMenus?.forEach((menuItem) => {
    if (menuItem.subMenuItems?.length > 0) {
      menuItem.subMenuItems.sort((a, b) => a.displayOrder - b.displayOrder)
    }
  })

  const navigation = useNavigation()
  return (
    <BottomDrawer
      showDrawer={showDrawer}
      setShowDrawer={setShowDrawer}
      drawerColor={colors.background}
    >
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, zIndex: 20 }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            paddingHorizontal: spacing.spacing5,
            paddingVertical: spacing.spacing5,
          }}
        >
          <View
            style={{
              alignSelf: 'center',
              height: 3,
              backgroundColor: colors.outline,
              width: 30,
              borderRadius: 10,
            }}
          />
          {!screenName && (
            <Text
              variant='headlineSmall'
              style={{
                color: colors.onBackground,
                paddingVertical: spacing.spacing3,
              }}
            >
              {t('Global.ALL_APPS')}
            </Text>
          )}
        </View>
        {sortedSubMenus?.map((data) => (
          <>
            <Text
              variant='titleLarge'
              style={{ paddingHorizontal: spacing.spacing5 }}
            >
              {data?.displayName}
            </Text>
            <View
              style={{
                padding: spacing.spacing5,
                minHeight: spacing.spacing12,
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {data?.subMenuItems?.map((item) => (
                <View style={{ marginVertical: spacing.spacing2 }}>
                  <AppCard
                    iconName={item?.iconName}
                    fieldName={item?.displayName}
                    onPress={() => {
                      navigation.navigate(item.navigationPath)
                      setShowDrawer(false)
                    }}
                    subModuleName={item?.subModuleName}
                    item={item}
                  />
                </View>
              ))}
              {data?.subMenuItems.length === 0 && (
                <AppCard
                  iconName={data?.iconName}
                  fieldName={data?.displayName}
                  onPress={() => {
                    navigation.navigate(data?.navigationPath)
                    setShowDrawer(false)
                  }}
                  moduleName={data?.moduleName}
                  item={data}
                />
              )}
            </View>
          </>
        ))}
      </ScrollView>
    </BottomDrawer>
  )
}

const AppCard = (props) => {
  const { colors } = useTheme()
  const { iconName, fieldName, onPress, moduleName, subModuleName } = props
  const currentScreen = useNavigationState((state) => {
    const routes = state?.routes
    if (routes && routes.length > 0) {
      return routes[routes.length - 1]
    }
    return null // Return a default value or handle the case where routes is empty
  })
  const { width } = useWindowDimensions()
  const getCurrentModule = () => {
    if (subModuleName)
      return subModuleName === currentScreen?.params?.subMenuItems
    return moduleName === currentScreen?.name
  }
  return (
    <Card
      mode='contained'
      style={{
        paddingHorizontal: spacing.spacing5,
        paddingVertical: spacing.spacing3,
        backgroundColor: getCurrentModule()
          ? colors.surface1
          : colors.surfaceVariant,
        width: (width - 48) / 2,
        borderRadius: spacing.spacing3,
      }}
      onPress={onPress}
    >
      <Icon
        color={getCurrentModule() ? colors.primary : colors.onSurface}
        fill={getCurrentModule() ? colors.onPrimary : colors.surface}
        name={iconName}
        height={20}
        width={20}
      />
      <Text
        style={{
          paddingTop: spacing.spacing3,
          color: getCurrentModule() ? colors.primary : colors.onSurface,
        }}
      >
        {fieldName}
      </Text>
    </Card>
  )
}

export default AllApps
