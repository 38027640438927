import { useRef, useState } from 'react'

const useUploadDocument = () => {
  const [selectedPDF, setselectedPDF] = useState([])
  const pdfInputRef = useRef(null)

  const pickPDF = () => {
    if (pdfInputRef.current) {
      pdfInputRef.current.click()
    }
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      setselectedPDF([selectedFile, ...selectedPDF])
    }
  }

  return { pickPDF, handleFileChange, pdfInputRef, selectedPDF }
}

export default useUploadDocument
