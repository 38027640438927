import React from 'react'
import { View } from 'react-native'

const FormContainer = (props) => {
  const {
    data,
    renderItem,
    style,
    index,
    width = 'auto',
    isAdd,
    filter = false,
  } = props
  return (
    <View
      style={[
        {
          flex: 1,
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        },
      ]}
    >
      {data?.map((item) => {
        if (
          filter ||
          ((item?.permissions?.view || isAdd) && !item.metadata.isHidden)
        ) {
          return (
            <View
              style={[
                style,
                {
                  zIndex: item?.metadata?.component === 'SearchInput' ? 2 : 1,
                  width:
                    item?.metadata?.component === 'TextArea' ||
                    item?.metadata?.component === 'upload'
                      ? '100%'
                      : width,
                },
              ]}
            >
              {renderItem(item.metadata || item, index, item.permissions)}
            </View>
          )
        }
        return null
      })}
    </View>
  )
}

export default FormContainer
