import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Image } from '@camped/components'
import { Icon } from '@camped/icons'

const ProfileIcon = (props) => {
  const { size = 44, imageUrl } = props
  const { colors } = useTheme()
  const [isValidImage, setIsValidImage] = useState(true)
  if (imageUrl && isValidImage)
    return (
      <Image
        imageUrl={imageUrl}
        onError={() => setIsValidImage(false)}
        size={size}
        aspectRatio='1:1'
        resizeMode='cover'
        borderRadius={100}
      />
    )

  return (
    <View
      style={{
        height: size,
        width: size,
        borderRadius: 60,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.surface2,
        flexDirection: 'row',
      }}
    >
      <Icon
        name='ProfileIcon'
        height={size / 2}
        width={size / 2}
        fill={colors.onSurface}
      />
    </View>
  )
}
ProfileIcon.propTypes = {
  size: PropTypes.number,
  imageUrl: PropTypes.string,
}

ProfileIcon.defaultProps = {
  size: 44,
  imageUrl: null,
}

export default ProfileIcon
