import React from 'react'
import { View, useWindowDimensions } from 'react-native'

import { Divider } from '@camped/components'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout, sortData } from '@camped/utils'

import { getSemester } from '../utils/helpers'
import DynamicField from './dynamic-field'
import FormContainer from './form-container'

const EditContainer = (props) => {
  const {
    add,
    addSection,
    checked,
    countryCode,
    editSection,
    error,
    handleFileChange,
    mainKeys,
    mainValue,
    onChangeText,
    pdfInputRef,
    getLookupData,
    pickPDF,
    selectedSem,
    setChecked,
    setErrors,
    user,
  } = props

  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout.isWeb(windowWidth)
  const renderItem = (data, index, permissions) => {
    const fieldName =
      data?.referenceName !== null ? data?.referenceName : data?.fieldName
    const arrayValue = data?.arrayName
      ? user?.[data?.arrayName]?.[index]?.find(
          (field) => field?.name === fieldName,
        )?.value
      : undefined
    const fieldValue = user?.[fieldName]?.value ?? ''
    const finalError = error?.find(
      (m) =>
        m?.fieldName === fieldName &&
        m?.value === (arrayValue !== undefined ? arrayValue : fieldValue),
    )
    const addValue = add?.[data?.arrayName]?.[0]?.find(
      (field) => field?.name === fieldName,
    )?.value
    const lookup = getLookupData(fieldName)
    return (
      <DynamicField
        checkBoxStyle={{ marginLeft: spacing.spacing6 }}
        checked={checked}
        countryCode={countryCode}
        data={data}
        disabled={mainKeys === addSection ? false : !permissions?.edit}
        error={
          mainKeys === addSection
            ? error?.find((m) => m?.fieldName === fieldName)
            : finalError
        }
        fieldName={mainKeys === addSection ? addSection : editSection}
        handleFileChange={handleFileChange}
        index={index}
        isEdit
        list={lookup.length !== 0 ? lookup : data?.options}
        onChangeText={onChangeText}
        onCountrySelect={onChangeText}
        onFocus={setErrors}
        pdfInputRef={pdfInputRef}
        pickPDF={pickPDF}
        setChecked={setChecked}
        style={
          !isDesktop
            ? {
                maxWidth: 400,
                width: '90%',
              }
            : { minWidth: 296, width: '100%' }
        }
        value={mainKeys === addSection ? addValue : arrayValue ?? fieldValue}
      />
    )
  }

  return (
    <View
      style={{
        paddingTop: spacing.spacing3,
        paddingLeft: spacing.spacing4,
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        flex: 1,
      }}
    >
      {mainKeys === addSection ? (
        <FormContainer
          data={sortData(mainValue[0], 'displayOrder')}
          renderItem={renderItem}
          style={{
            flex: mainValue?.metadata?.component === 'TextArea' ? 1 : undefined,
          }}
          width={isDesktop ? undefined : windowWidth}
          isAdd={mainKeys === addSection}
        />
      ) : (
        mainValue?.map((item, i) => {
          const subjectSemester = getSemester(item)
          if (
            mainKeys !== 'Subject Details' ||
            selectedSem?.toString() === subjectSemester
          ) {
            return (
              <>
                <FormContainer
                  data={sortData(item, 'displayOrder')}
                  index={i}
                  renderItem={renderItem}
                  width={isDesktop ? undefined : '100%'}
                  isAdd={false}
                />
                {mainValue.length - 1 !== i && (
                  <Divider style={{ marginBottom: spacing.spacing6 }} />
                )}
              </>
            )
          }
          return null
        })
      )}
    </View>
  )
}

export default EditContainer
