import { useNavigation } from '@react-navigation/native'
import { useAtom } from 'jotai'
import React from 'react'
import { ScrollView, TouchableOpacity } from 'react-native'

import { userRollAtom } from '@camped/jotai-storage'
import { MobileContainer } from '@camped/shared-container'

import FeedCard from '../../components/feed/FeedCard'
import { HomeBarChart } from '../../components/home/barChart'
import { RefreshControl } from '../../components/refreshControl'

const MobileView = (props) => {
  const {
    studentAnalytics,
    newsFeeds,
    refreshing,
    onRefresh,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = props

  const navigation = useNavigation()
  const [userRoll] = useAtom(userRollAtom)
  return (
    <MobileContainer style={{ backgroundColor: '#F1F1F1' }}>
      <ScrollView
        style={{
          paddingVertical: 15,
          paddingHorizontal: 20,
        }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {/* <Greeting user={{ first_name: data?.email }} /> */}
        {userRoll !== 'student' ? (
          <TouchableOpacity
            style={{ width: '100%', maxWidth: 500 }}
            onPress={() => navigation.navigate('student-directory')}
          >
            <HomeBarChart chartData={studentAnalytics} />
          </TouchableOpacity>
        ) : null}
        {userRoll === 'student' ? (
          <FeedCard
            data={newsFeeds}
            isMobile
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        ) : null}
      </ScrollView>
    </MobileContainer>
  )
}

export default MobileView
