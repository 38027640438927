import React, { useState } from 'react'
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Checkbox, Surface, useTheme } from 'react-native-paper'

import { DataTableCell, DataTableRow } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout } from '@camped/utils'

const ToggleAccodion = (props) => {
  const {
    title,
    index,
    togglebutton,
    style,
    titleStyle,
    children,
    isEnabled,
    section,
    showIcon = true,
  } = props
  const { width } = useWindowDimensions()
  const isDesktop = ScreenLayout.isWeb(width)
  const { colors } = useTheme()
  const [expanded, setExpanded] = useState(!togglebutton ? true : index === 0)
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }
  const getPadding = () => {
    if (titleStyle) {
      return titleStyle
    }
    return {
      paddingTop: !isDesktop ? spacing.spacing5 : spacing.spacing4,
      paddingBottom: !isDesktop ? spacing.spacing5 : spacing.spacing4,
    }
  }

  return (
    <Surface
      Surface
      style={StyleSheet.flatten(styles.container(colors, spacing), style)}
    >
      {Boolean(title) && (
        <DataTableRow
          hasHover={false}
          style={{
            paddingRight: 8,
          }}
        >
          <DataTableCell flexN={2} style={{ justifyContent: 'center' }}>
            <TouchableOpacity onPress={toggleExpanded}>
              <Text
                variant='headlineSmall'
                style={[
                  styles.systemAdmin(colors, spacing, isDesktop),
                  getPadding(),
                ]}
              >
                {title}
              </Text>
            </TouchableOpacity>
          </DataTableCell>

          {['view', 'add', 'edit', 'delete'].map((permission, i) => (
            <DataTableCell key={i} style={{ justifyContent: 'center' }}>
              <Checkbox
                status={
                  section?.permissions?.[permission] ? 'checked' : 'unchecked'
                }
                onPress={() => {
                  togglebutton(
                    permission,
                    section,
                    !section?.permissions?.[permission],
                  )
                }}
                disabled={
                  section?.permissions?.[permission] === null ||
                  section?.permissions?.[permission] === 'null' ||
                  section?.permissions?.[permission] === undefined ||
                  (section?.permissions?.view === false &&
                    permission !== 'view')
                }
              />
            </DataTableCell>
          ))}

          <DataTableCell flexN={0.2}>
            <TouchableOpacity
              onPress={toggleExpanded}
              style={{
                width: 17,
                height: 17,
                marginRight: 8,
              }}
            >
              {showIcon && (
                <Icon
                  name={expanded ? 'MenuOpen' : 'MenuClose'}
                  color={colors.onBackground}
                  width={17}
                  height={17}
                />
              )}
            </TouchableOpacity>
          </DataTableCell>
        </DataTableRow>
      )}

      {expanded && isEnabled ? children : null}
      {!togglebutton && expanded ? children : null}
    </Surface>
  )
}

const styles = StyleSheet.create({
  container: (colors) => ({
    backgroundColor: colors.surface1,
    marginRight: spacing.spacing4,
    marginLeft: spacing.spacing4,
  }),
  systemAdmin: (colors) => ({
    paddingHorizontal: 12,
    color: colors.onSurface1,
    fontWeight: '700',
    textTransform: 'capitalize',
  }),
})

export default ToggleAccodion
