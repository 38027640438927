import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { useTheme } from 'react-native-paper'

const keySequence = [
  ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
  ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
  ['Z', 'X', 'C', 'V', 'B', 'N', 'M'],
  ['DEL', 'ENTER'],
]

const Keyboard = (props) => {
  const { colors } = useTheme()
  const { onKeyPress, disabledKeyList } = props

  return (
    <>
      {keySequence.map((row, rowIndex) => (
        <View key={`key-row-${rowIndex}`} style={styles.row}>
          {row.map((key) => {
            const isDisabled = disabledKeyList.includes(key)
            return (
              <Pressable
                key={key}
                disabled={isDisabled}
                onPress={() => onKeyPress(key)}
              >
                <View
                  style={[
                    styles.cell(colors),
                    isDisabled && styles.cellDisabled,
                  ]}
                >
                  <Text
                    variant='titleSmall'
                    style={[
                      styles.text(colors),
                      isDisabled && styles.textDisabled,
                    ]}
                  >
                    {key}
                  </Text>
                </View>
              </Pressable>
            )
          })}
        </View>
      ))}
    </>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginBottom: 5,
    justifyContent: 'center',
  },
  cell: (colors) => ({
    padding: 5,
    paddingHorizontal: 8,
    margin: 4,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.onPrimaryContainer,
  }),
  cellDisabled: {
    borderColor: 'grey',
  },
  text: (colors) => ({
    color: colors.onPrimaryContainer,
    fontSize: 16,
  }),
  textDisabled: {
    color: 'grey',
  },
})

export default Keyboard
