import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Button } from '@camped/components'
import { spacing } from '@camped/theme-provider'

const BottomButtons = (props) => {
  const { module, handleSubmit, userDetail, isLoading } = props
  const { colors } = useTheme()
  const { t } = useTranslation()
  const primaryButtonModule = ['course', 'staff', 'student', 'academics']
  const secondaryButtonModule = ['staff', 'student']
  return (
    <View style={styles.buttonView}>
      {secondaryButtonModule.includes(module) && (
        <Button
          loading={isLoading}
          disabled={isLoading}
          mode='outlined'
          style={styles.button}
          variant='labelLarge'
          onPress={() => handleSubmit(userDetail, true)}
          labelStyle={{
            color: colors.onSurface1,
          }}
        >
          {t('Directory.CREATE_AND_INVITE')}
        </Button>
      )}
      {primaryButtonModule.includes(module) && (
        <Button
          loading={isLoading}
          disabled={isLoading}
          mode='contained'
          variant='labelLarge'
          style={{
            marginRight: spacing.spacing4,
          }}
          onPress={() => handleSubmit(userDetail)}
        >
          {t('Directory.CREATE')}
        </Button>
      )}
    </View>
  )
}

export default BottomButtons

const styles = StyleSheet.create({
  button: {
    marginRight: spacing.spacing5,
  },
  buttonView: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    marginVertical: spacing.spacing5,
  },
})
