import { useAtom } from 'jotai'
import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-paper'

import GlobalSearch from '@camped/core/src/screens/globalSearch'
import { Icon } from '@camped/icons'
import { userAtom, userRollAtom } from '@camped/jotai-storage'
import { ProfileMenu } from '@camped/shared-component-profile-menu'

const GlobalHeader = () => {
  const [globalUser] = useAtom(userAtom)
  const [userRoll] = useAtom(userRollAtom)
  const { colors, spacing } = useTheme()
  return (
    <View
      style={{
        height: 60,
        padding: spacing.spacing4,
        flexDirection: 'row',
      }}
    >
      <View style={{ width: 188 }}>
        <Icon name='LogoColor' width={115} height={49} fill={colors.white} />
      </View>
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            justifyContent: 'center',
          }}
        >
          {userRoll === 'admin' && <GlobalSearch />}
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ProfileMenu globalUser={globalUser} />
        </View>
      </View>
    </View>
  )
}

export default GlobalHeader
