import React from 'react'
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Button, Divider, Tab } from '@camped/components'
import {
  BoxContainer,
  EditContainer,
  ViewContentContainer,
} from '@camped/form-container'
import { DesktopContainer } from '@camped/shared-container'
import { spacing } from '@camped/theme-provider'

import { AddUserFields } from '../components/add-details-section'
import BottomButtons from '../components/bottom-button'
import ProfileSideSection from '../components/profile-side-top-section'
import QuestionPaperUi from '../components/question-paper-tab'
import { DesktopView as Skeleton } from '../skeleton/Desktop-view'
import { groupMetaData } from '../utils/group-meta-data'

const DesktopView = (props) => {
  const {
    updateValue,
    addSection,
    attachment,
    checked,
    clearEditSelection,
    countryCode,
    create,
    currentRoute,
    editSection,
    errors,
    fileInputRef,
    firstName,
    generateQuestion,
    getLookupData,
    handleAddImage,
    handleAddNew,
    handleAddSection,
    handleEditQuestionPaper,
    handleDelete,
    handleEdit,
    handleOnSave,
    handleSubmit,
    handleTabChange,
    isFetching,
    isLoading,
    module,
    onChangeText,
    onUpload,
    selectedTabDisplayName,
    selectedTabFieldName,
    setChecked,
    setEditSection,
    setErrors,
    showSideBar,
    tabData,
    user,
    userDetail,
    id,
    pickPDF,
    handleFileChange,
    pdfInputRef,
    selectedTopics,
    setselectedTopics,
    selectedSem,
    setSelectedSem,
    selectedUnitsValidation,
    handleCnacelGenerateQuestion,
    setSelectedUnitsValidation,
    getUserData,
  } = props

  const { colors } = useTheme()

  if (isFetching) {
    return (
      <DesktopContainer>
        <Skeleton />
      </DesktopContainer>
    )
  }

  if (
    id ||
    currentRoute === 'campus-configuration' ||
    currentRoute === 'course-listing' ||
    currentRoute === 'my-profile'
  ) {
    return (
      <DesktopContainer>
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
          }}
        >
          {showSideBar?.includes(currentRoute) && (
            <ProfileSideSection
              summaryInfo={userDetail}
              module={module}
              attachment={attachment}
              fileInputRef={fileInputRef}
              handleAddImage={handleAddImage}
              onUpload={onUpload}
            />
          )}
          <View
            style={{
              flex: showSideBar?.includes(currentRoute) ? 0.8 : 1,
            }}
          >
            {tabData?.length !== 0 && (
              <Tab
                data={tabData}
                selectedTab={selectedTabDisplayName}
                onPress={(item) => handleTabChange(item)}
              />
            )}

            <ScrollView
              style={{ height: '100%', paddingBottom: spacing.spacing4 }}
              contentContainerStyle={{ flexGrow: 1 }}
              showsVerticalScrollIndicator={false}
            >
              {Object.entries(
                groupMetaData(userDetail)[selectedTabFieldName] ||
                  groupMetaData(userDetail)[''] ||
                  {},
              ).map(([mainKeys, mainValue]) => {
                if (editSection !== 'question Paper Preview') {
                  if (mainKeys === 'Section' || mainKeys === 'General') {
                    return
                  }
                } else if (mainKeys === 'question Paper Preview') {
                  return
                }
                const permission = mainValue?.permissions
                const hasEdit =
                  mainValue?.metadata?.displayName === 'Subject Details'
                    ? mainValue?.fields[0]?.some((item) =>
                        item?.hasOwnProperty('value'),
                      )
                    : true
                return (
                  <BoxContainer
                    title={mainKeys}
                    permission={permission}
                    handleEdit={
                      hasEdit
                        ? () =>
                            handleEdit(mainKeys, mainValue, false, selectedSem)
                        : null
                    }
                    handleAdd={
                      mainKeys === 'Section'
                        ? () => handleAddSection(mainValue)
                        : () =>
                            handleEdit(mainKeys, mainValue, true, selectedSem)
                    }
                    onCancel={() => clearEditSelection()}
                    onSave={
                      mainKeys === addSection
                        ? () => create(mainValue?.fields, addSection)
                        : () => handleOnSave(mainValue?.fields)
                    }
                    isLoading={isLoading}
                    setErrors={setErrors}
                    getUserData={getUserData}
                    userDetail={userDetail}
                  >
                    <>
                      {mainValue?.metadata?.tab?._id ===
                        '65310bcf5578ed88bf066cfe' &&
                        mainKeys !== 'question Paper Preview' && (
                          <>
                            {mainKeys === 'General' && (
                              <QuestionPaperUi
                                data={userDetail}
                                selectedTopics={selectedTopics}
                                setselectedTopics={setselectedTopics}
                                selectedUnitsValidation={
                                  selectedUnitsValidation
                                }
                              />
                            )}
                            <EditContainer
                              add={updateValue}
                              addSection={addSection}
                              checked={checked}
                              countryCode={countryCode}
                              editSection={editSection}
                              error={errors}
                              handleFileChange={handleFileChange}
                              mainKeys={mainKeys}
                              mainValue={mainValue?.fields}
                              getLookupData={getLookupData}
                              onChangeText={onChangeText}
                              pdfInputRef={pdfInputRef}
                              pickPDF={pickPDF}
                              setChecked={setChecked}
                              setErrors={setErrors}
                              user={user}
                              selectedSem={selectedSem}
                            />
                          </>
                        )}
                      {mainKeys !== editSection &&
                        mainKeys !== addSection &&
                        mainKeys !== 'Section' &&
                        mainKeys !== 'General' && (
                          <ViewContentContainer
                            title={mainKeys}
                            mainKeys={mainKeys}
                            mainValue={mainValue}
                            handleEdit={handleEdit}
                            pickPDF={pickPDF}
                            user={user}
                            subject={firstName}
                            setSelectedSem={setSelectedSem}
                            selectedSem={selectedSem}
                            handleFileChange={handleFileChange}
                            pdfInputRef={pdfInputRef}
                            handleOnSave={handleOnSave}
                            handleEditQuestionPaper={handleEditQuestionPaper}
                          />
                        )}
                      {(mainKeys === editSection ||
                        mainKeys === addSection) && (
                        <EditContainer
                          add={updateValue}
                          addSection={addSection}
                          checked={checked}
                          countryCode={countryCode}
                          editSection={editSection}
                          error={errors}
                          handleFileChange={handleFileChange}
                          getLookupData={getLookupData}
                          mainKeys={mainKeys}
                          mainValue={mainValue?.fields}
                          onChangeText={onChangeText}
                          pdfInputRef={pdfInputRef}
                          pickPDF={pickPDF}
                          setChecked={setChecked}
                          setErrors={setErrors}
                          user={user}
                          selectedSem={selectedSem}
                        />
                      )}
                      {mainKeys === 'Section' && (
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            mode='outlined'
                            labelStyle={{
                              margin: 6,
                              marginHorizontal: 1,
                              color: colors.primary,
                              paddingLeft: spacing.spacing6,
                              paddingRight: spacing.spacing6,
                            }}
                            style={{
                              paddingHorizontal: spacing.spacing2,
                              alignSelf: 'flex-end',
                              marginBottom: spacing.spacing4,
                              marginRight: spacing.spacing3,
                            }}
                            onPress={() => {
                              handleCnacelGenerateQuestion(mainValue?.fields)
                              getUserData(userDetail)
                              setEditSection('')
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            labelStyle={{
                              margin: 6,
                              marginHorizontal: 1,
                              paddingLeft: spacing.spacing6,
                              paddingRight: spacing.spacing6,
                            }}
                            loading={isLoading}
                            disabled={isLoading}
                            style={{
                              paddingHorizontal: spacing.spacing2,
                              alignSelf: 'flex-end',
                              marginBottom: spacing.spacing4,
                            }}
                            onPress={() => {
                              generateQuestion()
                            }}
                          >
                            Generate Question
                          </Button>
                        </View>
                      )}
                    </>
                  </BoxContainer>
                )
              })}
            </ScrollView>
          </View>
        </View>
      </DesktopContainer>
    )
  }

  return (
    <DesktopContainer>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
        showsVerticalScrollIndicator={false}
      >
        <Divider />
        {isFetching ? (
          <View style={[styles.loader(colors)]}>
            <ActivityIndicator size='large' color={colors.primary} />
          </View>
        ) : (
          <AddUserFields
            onChangeText={onChangeText}
            user={user}
            setErrors={setErrors}
            countryCode={countryCode}
            data={userDetail}
            errors={errors}
            getLookupData={getLookupData}
            module={module}
            paddingHorizontal={spacing.spacing3}
            handleAdd={handleAddNew}
            handleDelete={handleDelete}
            dividerStyle={{
              marginTop: spacing.spacing5,
              marginHorizontal: spacing.spacing6,
            }}
          />
        )}
        <BottomButtons
          module={module}
          handleSubmit={handleSubmit}
          userDetail={userDetail}
          isLoading={isLoading}
        />
      </ScrollView>
    </DesktopContainer>
  )
}

export default DesktopView

const styles = StyleSheet.create({
  divider: (colors) => ({
    backgroundColor: colors.primaryContainer,
    height: '100%',
  }),
  container: {
    marginTop: spacing.spacing4,
  },
  main: (colors) => ({
    padding: spacing.spacing4,
    backgroundColor: colors.surface1,
    flex: 1,
  }),
  button: (colors) => ({
    borderColor: colors.primary,
    marginRight: spacing.spacing5,
  }),
  buttonView: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    marginVertical: spacing.spacing6,
  },
  loader: (colors) => ({
    flex: 1,
    backgroundColor: colors.surface1,
    justifyContent: 'center',
  }),
})
