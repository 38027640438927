import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { BottomDrawer, Text } from '@camped/components'
import { Icon } from '@camped/icons'

export const Sort = ({
  showDrawer,
  setShowDrawer,
  sortList,
  onSortSelected,
  selectedSortValue,
}) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation()
  return (
    <BottomDrawer
      showDrawer={showDrawer}
      setShowDrawer={setShowDrawer}
      drawerColor={colors.surface1}
    >
      <View
        style={{
          paddingHorizontal: spacing.spacing6,
          paddingVertical: spacing.spacing5,
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Text variant='headlineSmall' style={{ color: colors.onSurface1 }}>
          {t('Directory.SORT_BY')}
        </Text>
        <TouchableOpacity onPress={() => setShowDrawer(false)}>
          <Icon
            name='CloseIcon'
            width={22}
            height={22}
            color={colors.onBackground}
          />
        </TouchableOpacity>
      </View>
      <ScrollView>
        <View
          style={{
            paddingHorizontal: spacing.spacing6,
            paddingBottom: spacing.spacing9,
          }}
        >
          {sortList?.map((item) => (
            <TouchableOpacity
              style={{ paddingTop: spacing.spacing7 }}
              onPress={() => {
                onSortSelected(item.displayName)
                setShowDrawer(false)
              }}
            >
              <Text
                variant='bodyLarge'
                style={{
                  color:
                    item.displayName === selectedSortValue.displayName
                      ? colors.primary
                      : colors.onSurface,
                }}
              >
                {item?.displayName}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>
    </BottomDrawer>
  )
}
