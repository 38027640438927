import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Button, Text } from '@camped/components'
import Overlay from '@camped/shared-component-overlay'
import { spacing } from '@camped/theme-provider'

const DisableUserModal = ({
  isModalVisible,
  setModalVisible,
  handleDisableUser,
}) => {
  const { colors, fonts } = useTheme()
  return (
    <Overlay
      isVisible={isModalVisible}
      setVisible={setModalVisible}
      title='Disable User'
    >
      <View style={styles.modalView}>
        <Text
          variant='titleMedium'
          style={{
            marginTop: spacing.spacing7,
            alignSelf: 'center',
          }}
        >
          Do You Want Disable This User?
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            flex: 1,
            marginTop: spacing.spacing6,
          }}
        >
          <Button
            mode='outlined'
            style={{
              alignSelf: 'flex-end',
              marginLeft: spacing.spacing3,
              borderColor: colors.error,
            }}
            labelStyle={{
              ...fonts.titleSmall,
            }}
            onPress={() => {
              setModalVisible(false)
            }}
          >
            Cancel
          </Button>

          <Button
            mode='contained'
            style={{
              backgroundColor: colors.primary,
              alignSelf: 'flex-end',
              marginRight: spacing.spacing5,
              justifyContent: 'center',
            }}
            labelStyle={{
              color: colors.onPrimary,
              ...fonts.titleSmall,
              marginHorizontal: spacing.spacing3,
            }}
            onPress={() => {
              handleDisableUser()
            }}
          >
            Disable
          </Button>
        </View>
      </View>
    </Overlay>
  )
}

const styles = StyleSheet.create({
  modalView: {
    flex: 1,
    height: '100%',
    justifyContent: 'space-between',
    paddingTop: spacing.spacing13,
    paddingLeft: spacing.spacing3,
  },
})

export default DisableUserModal
