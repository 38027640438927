import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

export const CampEdLogo = ({ isDesktop }) => {
  const { colors } = useTheme()
  return (
    <View style={{ alignItems: !isDesktop && 'center' }}>
      <Icon
        name='LogoColor'
        width={isDesktop ? 144 : 114}
        height={isDesktop ? 61 : 48}
        fill={colors.white}
        style={{ marginVertical: !isDesktop && spacing.spacing5 }}
      />
    </View>
  )
}
