import { useIsFocused } from '@react-navigation/native'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Platform, View, useWindowDimensions } from 'react-native'
import { Text, useTheme } from 'react-native-paper'

import { Auth } from '@camped/api'
import { Audit } from '@camped/audit'
import { Button, PasswordTextInput, TextInput } from '@camped/components'
import { pendingUserAtom } from '@camped/jotai-storage'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout, testProps } from '@camped/utils'

import {
  EventAction,
  EventEntityType,
  EventScreen,
  EventType,
} from '../constants/audit'
import { Behavior, ScreenName, TypeofControl } from '../constants/testing'
import useLogin from '../hooks/use-login'

export const ChangePassword = (props) => {
  const { domainDetails } = props
  const { t } = useTranslation()
  const { colors, fonts } = useTheme()
  const { width } = useWindowDimensions()
  const isDesktop = ScreenLayout.isWeb(width)

  const { login } = useLogin()

  const [isLoading, setIsLoading] = useState(false)
  const [currentUser] = useAtom(pendingUserAtom)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { confirmPassword: '', password: '' },
  })

  const handleResetSubmit = async (data) => {
    if (data?.password === data?.confirmPassword) {
      setIsLoading(true)
      try {
        await Auth.completeNewPasswordAsync(currentUser, data?.password)
        Audit.logEvent({
          action: EventAction.CLICK,
          entityType: EventEntityType.CAMPUS,
          eventType: EventType.SIGNIN,
          details: {
            Screen: EventScreen.LOGIN,
            Email: data?.userName,
          },
        })
        login({ data, setIsLoading, domain: domainDetails, currentUser })
      } catch (error) {
        setIsLoading(false)
        if (error.message) {
          toast.show(error.message, { type: 'danger' })
        }
      }
    } else if (data?.password !== data?.confirmPassword) {
      toast.show(t('Error_Message.AUTH_PASSWORD'), { type: 'danger' })
    }
  }

  return (
    <View>
      <View>
        <Text
          variant={isDesktop ? 'headlineLarge' : 'headlineSmall'}
          style={{
            color: colors.onSurfaceVariant,
            marginBottom: isDesktop ? spacing.spacing8 : spacing.spacing6,
          }}
        >
          {t('Auth.NEW_PASSWORD_SETUP')}
        </Text>
        <Text
          variant='bodyLarge'
          style={{
            color: colors.onSurfaceVariant,
            marginBottom: spacing.spacing6,
          }}
        >
          {t('Auth.TEMPORARY_PASSWORD_SIGNIN')}
        </Text>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <PasswordTextInput
              label={t('Auth.NEW_PASSWORD')}
              placeholder={t('Auth.NEW_PASSWORD')}
              right={<TextInput.Affix />}
              secureTextEntry
              style={{
                backgroundColor: colors.surfaceVariant,
                marginBottom: spacing.spacing6,
              }}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              error={errors?.password}
              method={handleSubmit}
              {...testProps(
                `${ScreenName.RESET_PASSWORD}_${TypeofControl.INPUT}_${Behavior.NEW_PASSWORD}`,
              )}
              testName={`${ScreenName.RESET_PASSWORD}_${TypeofControl.BUTTON}_${Behavior.CLICK}`}
            />
          )}
          name='password'
          rules={{
            required: 'Password is Required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters long',
            },
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
              message: t('Auth.PASSWORD_COMPLEXITY_REQUIREMENTS'),
            },
          }}
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <PasswordTextInput
              label={t('Auth.CONFIRM_PASSWORD')}
              placeholder={t('Auth.CONFIRM_PASSWORD')}
              right={<TextInput.Affix />}
              secureTextEntry
              style={{
                backgroundColor: colors.surfaceVariant,
              }}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              error={errors?.confirmPassword}
              method={handleSubmit}
              {...testProps(
                `${ScreenName.RESET_PASSWORD}_${TypeofControl.INPUT}_${Behavior.CONFIRM_PASSWORD}`,
              )}
              testName={`${ScreenName.RESET_PASSWORD}_${TypeofControl.BUTTON}_${Behavior.CLICK}`}
            />
          )}
          name='confirmPassword'
          rules={{
            required: 'confirm Password is Required',
            minLength: {
              value: 8,
              message: 'confirm Password must be at least 8 characters long',
            },
          }}
        />
      </View>

      <Button
        mode='contained'
        style={{
          backgroundColor: colors.primary,
          marginTop: isDesktop ? spacing.spacing8 : spacing.spacing6,
          marginBottom: isDesktop ? 0 : spacing.spacing13,
          alignSelf: 'flex-end',
        }}
        loading={isLoading}
        disabled={isLoading}
        labelStyle={{
          color: colors.onPrimary,
          ...(isDesktop ? fonts.titleMedium : fonts.bodyMedium),
        }}
        onPress={handleSubmit(handleResetSubmit)}
        {...testProps(
          `${ScreenName.CHANGE_PASSWORD}_${TypeofControl.BUTTON}_${Behavior.SUBMIT}`,
        )}
      >
        {t('Auth.UPDATE_AND_CONTINUE')}
      </Button>
    </View>
  )
}
