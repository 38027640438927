import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'
import Tooltip from 'react-native-walkthrough-tooltip'

import { useQueryClient } from '@tanstack/react-query'

import { Icon } from '@camped/icons'

const TooltipOnHover = ({
  content,
  position,
  permission,
  selectedrole,
  moduleName,
  field,
}) => {
  const [visible, setVisible] = useState(false)
  const queryClient = useQueryClient()

  const { colors } = useTheme()
  const getIcon = (scope) => {
    if (scope === 1) {
      return 'NoData'
    }
    if (scope === 2) {
      return 'MyData'
    }
    if (scope === 3) {
      return 'ReporteesData'
    }
    if (scope === 4) {
      return 'ResporteeDataMyData'
    }
    if (scope === 5) {
      return 'AllData'
    }
  }
  const getScope = (icon) => {
    if (icon === 'NoData') {
      return 1
    }
    if (icon === 'ProfileIcon') {
      return 2
    }
    if (icon === 'ReporteesData') {
      return 3
    }
    if (icon === 'ResporteeDataMyData') {
      return 4
    }
    return 5
  }
  const [selectedIcon, setSelectedIcon] = useState(getIcon(permission.scope))

  const showTooltip = () => {
    setVisible(true)
  }

  const onValueChange = (icon) => {
    const queryData = {
      Permission: permission.permission,
      scope: getScope(selectedIcon),
      role: selectedrole,
    }

    if (moduleName && field) {
      queryData.moduleName = moduleName
      queryData.field = field
    }

    queryClient.setQueriesData([`toolTipData`], () => queryData, [selectedrole])
    setSelectedIcon(icon)
    setVisible(false)
  }

  return (
    <View style={styles.container}>
      <Tooltip
        isVisible={visible}
        content={
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: 40,
              backgroundColor: colors.background,
            }}
          >
            {content.map((iconName) => (
              <TouchableOpacity
                style={{
                  width: 35,
                  height: 35,
                  borderRadius: 20,
                  margin: 5,
                  borderWidth: iconName === selectedIcon ? 1.5 : 1,
                  borderColor:
                    iconName === selectedIcon
                      ? colors.primary
                      : colors.onBackground,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={() => {
                  onValueChange(iconName)
                }}
              >
                <Icon name={iconName} fill={colors.onBackground} />
              </TouchableOpacity>
            ))}
          </View>
        }
        contentStyle={styles.tooltipContainer(colors)}
        placement={position || 'bottom'}
      >
        <TouchableOpacity onPress={showTooltip}>
          <Icon name={selectedIcon} fill={colors.onBackground} />
        </TouchableOpacity>
      </Tooltip>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  tooltipContainer: (colors) => ({
    width: '100%',
    backgroundColor: colors.background,
  }),
})

export default TooltipOnHover
