import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTheme } from 'react-native-paper'

import { Button, Divider, Text } from '@camped/components'
import { Icon } from '@camped/icons'
import ProfileIcon from '@camped/shared-component-avatar'
import { MobileContainer } from '@camped/shared-container'

const MobileView = (props) => {
  const {
    allData,
    setInputValue,
    inputValue,
    dropdownTop,
    dropdownLeft,
    getSearchData,
    DropdownButton,
    selectedTab,
    setSelectedTab,
    handleNavigate,
  } = props
  const navigation = useNavigation()
  const { spacing, colors } = useTheme()
  const { t } = useTranslation()
  const headerSearch = () => (
    <View
      style={{
        paddingHorizontal: spacing.spacing6,
        paddingVertical: spacing.spacing6,
      }}
    >
      <TouchableOpacity ref={DropdownButton}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TouchableOpacity
            onPress={() =>
              Platform.OS === 'web'
                ? window.history.go(-1)
                : navigation.goBack()
            }
          >
            <Icon name='BackArrow' width={24} color={colors.onSurfaceVariant} />
          </TouchableOpacity>
          <TextInput
            autoCorrect={false}
            placeholder={t('Global.SEARCH')}
            style={{
              width: '100%',
              height: '100%',
              borderColor: 'transparent',
              flex: 1,
              borderWidth: 0,
              paddingHorizontal: spacing.spacing7,
              color: colors.onBackground,
            }}
            // onFocus={() => toggleDropdown()}
            // onBlur={() => setVisible(false)}
            onChangeText={(text) => {
              setInputValue(text)
              getSearchData(text)
              setSelectedTab('student')
            }}
            placeholderTextColor={colors.onSurfaceVariant}
            value={inputValue}
          />

          {inputValue.length > 0 ? (
            <TouchableOpacity
              style={{ paddingRight: spacing.spacing5 }}
              onPress={() => setInputValue('')}
            >
              <Icon
                name='CloseIcon'
                width={20}
                color={colors.onSurfaceVariant}
              />
            </TouchableOpacity>
          ) : (
            <Icon name='Search' width={20} fill={colors.primary} />
          )}
        </View>
      </TouchableOpacity>
    </View>
  )

  return (
    <MobileContainer style={{ backgroundColor: colors.background }}>
      {headerSearch()}
      <Divider style={{ backgroundColor: colors.surface2 }} />
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps='handled'
      >
        <View>
          <TouchableOpacity style={styles.overlay} />

          <View
            style={[styles.dropdown, { top: dropdownTop, left: dropdownLeft }]}
          >
            <View
              style={{
                flexDirection: 'row',
                paddingHorizontal: spacing.spacing6,
                paddingVertical: spacing.spacing5,
              }}
            >
              <Button
                mode='outlined'
                onPress={() => {
                  setSelectedTab('student')
                  getSearchData(inputValue)
                }}
                textColor={colors.primary}
                labelStyle={{ marginVertical: 0 }}
                style={{
                  borderWidth: selectedTab === 'student' ? 0 : 1,
                  backgroundColor:
                    selectedTab === 'student'
                      ? colors.primaryContainer
                      : 'transparent',
                  borderColor: colors.primary,
                  justifyContent: 'center',
                }}
              >
                {t('Global.STUDENT')}
              </Button>
              <Button
                mode='outlined'
                onPress={() => {
                  setSelectedTab('staff')
                  getSearchData(inputValue)
                }}
                textColor={colors.primary}
                labelStyle={{ marginVertical: 0 }}
                style={{
                  borderWidth: selectedTab === 'staff' ? 0 : 1,
                  backgroundColor:
                    selectedTab === 'staff'
                      ? colors.primaryContainer
                      : 'transparent',
                  borderColor: colors.primary,
                  justifyContent: 'center',
                  marginLeft: spacing.spacing5,
                }}
              >
                {t('Global.STAFF')}
              </Button>
            </View>
            <Divider style={{ backgroundColor: colors.surface2 }} />
            {allData?.map((item) => (
              <TouchableOpacity
                style={styles.item(spacing)}
                onPress={() => handleNavigate(item)}
              >
                <ProfileIcon size={32} />
                <View style={{ marginLeft: spacing.spacing3 }}>
                  <Text
                    variant='titleSmall'
                    style={{
                      color: colors.onBackground,
                    }}
                  >
                    {`${item?.firstName} ${item?.lastName}`}
                  </Text>
                  <Text
                    variant='titleSmall'
                    style={{
                      color: colors.onSurfaceVariant,
                    }}
                  >
                    {item?.registrationNumber || item?.employeeId}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </ScrollView>
    </MobileContainer>
  )
}

const styles = StyleSheet.create({
  dropdown: {
    borderBottomLeftRadius: 18,
    borderBottomRightRadius: 18,
  },
  overlay: {
    flex: 1,
    height: '100%',
  },
  item: (spacing) => ({
    paddingHorizontal: spacing.spacing4,
    paddingVertical: spacing.spacing4,
    flexDirection: 'row',
    alignItems: 'center',
  }),
})

export default MobileView
