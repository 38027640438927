import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useWindowDimensions } from 'react-native'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { Text } from '@camped/components'
import { ScreenLayout, SecureStore } from '@camped/utils'

import DesktopView from './DesktopView'
import MobileView from './MobileView'

const FieldPermissions = (props) => {
  const { visible } = props
  const roleData = props?.roleData || props?.route?.params?.roleData
  const module = props?.module || props?.route?.params?.module
  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout?.isWeb(windowWidth)

  const [selectedTab, setSelectedTab] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const queryClient = useQueryClient()
  const { data: fieldsData, refetch: refetchField } = useQuery(
    [`fieldData${roleData?._id}`],
    async () => {
      const res = await API.getRoleFieldPermissionsByFilterAPI({
        filter: {
          tenant: roleData?.tenant?._id,
          screen: roleData?.screen?._id,
          role: roleData?.role?._id,
          tab: selectedTab?._id,
        },
      })
      return res
    },
    {
      enabled: false,
      initialData: [],
      onSuccess: () => {
        setIsLoading(false)
      },
    },
  )
  const { data: tabsData, refetch: refetchTab } = useQuery(
    [`tabsData${roleData?._id}`],
    async () => {
      const res = await API.getRoleTabPermissionsByFilterAPI({
        filter: {
          tenant: roleData?.tenant?._id,
          screen: roleData?.screen?._id,
          role: roleData?.role?._id,
        },
      })
      setSelectedTab({
        ...res?.[0]?.tab,
        isEnabled: res?.[0]?.isEnabled,
        id: res?.[0]?._id,
      })
      return res
    },
    {
      enabled: false,
      initialData: [],
      onSuccess: () => {
        // refetchSection()
      },
    },
  )

  const { data: sectionsData, refetch: refetchSection } = useQuery(
    [`sectionsData${roleData?._id}`],
    async () => {
      const res = await API.getRoleSectionPermissionsByFilterAPI({
        filter: {
          tenant: roleData?.tenant?._id,
          screen: roleData?.screen?._id,
          role: roleData?.role?._id,
          tab: selectedTab?._id,
        },
      })
      return res
    },
    {
      enabled: false,
      initialData: [],
    },
  )

  const { data: featureData, refetch: refetchFeature } = useQuery(
    [`featureData${roleData?._id}`],
    async () => {
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)
      const res = await API.getRoleFeaturePermissionsByFilterAPI({
        filter: {
          tenant: userDetail?.[0]?.tenantId ?? '',
          role: roleData?.role._id,
        },
      })
      const groupedFeatures = groupFeatures(res)

      return { features: res, groupedFeatures }
    },
    {
      enabled: false,
      initialData: [],
      onSuccess: () => {
        setIsLoading(false)
      },
    },
  )

  useEffect(() => {
    if (!roleData || (!visible && isDesktop)) return

    if (module === 'Role') {
      refetchTab()
    }
  }, [roleData])

  useEffect(() => {
    if ((!visible && isDesktop) || !roleData) return
    setIsLoading(true)
    if (module === 'Menu') {
      refetchFeature()
    } else {
      refetchSection()
      refetchField()
    }
  }, [selectedTab, roleData, module])

  const onDropdownChange = (data, text) => {
    const filteredData = tabsData?.find((item) => item?.tab?._id === text?._id)
    setSelectedTab({
      ...text,
      isEnabled: filteredData?.isEnabled,
      id: filteredData?._id,
    })
  }

  const toggleSwitch = async (permission, field, value) => {
    let permissions
    queryClient.setQueryData([`fieldData${roleData?._id}`], (data) => {
      const filteredData = data.map((ele) => {
        if (ele?._id === field?._id) {
          if (permission === 'view' && value === false) {
            permissions = {
              ...ele.permissions,
              edit: false,
              view: false,
              delete: false,
              add: false,
            }
            return {
              ...ele,
              permissions,
            }
          }
          permissions = { ...ele.permissions, [permission]: value }
          return {
            ...ele,
            permissions,
          }
        }
        return ele
      })
      return filteredData
    })

    const response = await API.updateRoleFieldPermissionAPI({
      id: field?._id,
      roleFieldPermission: {
        permissions,
      },
    })

    if (!response?.errors) {
      toast.show('Permission updated successfully', { type: 'success' })
    } else {
      toast.show(response?.errors?.[0]?.message, { type: 'danger' })
    }
  }

  const toggleMenuSwitch = async (feature, value) => {
    queryClient.setQueryData([`featureData${roleData?._id}`], (data) => {
      const filteredData = data?.features.map((features) => {
        if (feature?._id === features?._id)
          return { ...features, isEnabled: value }

        return features
      })

      const groupedFeatures = groupFeatures(filteredData)
      return { features: filteredData, groupedFeatures }
    })

    const response = await API.updateRoleFeaturePermissionAPI({
      id: feature?._id,
      roleFeaturePermission: {
        isEnabled: value,
      },
    })

    if (!response?.errors) {
      toast.show('Permission updated successfully', { type: 'success' })
    } else {
      toast.show(response?.errors?.[0]?.message, { type: 'danger' })
    }
  }

  function groupFeatures(features) {
    // Filter out top-level features (those without a parent)
    const topLevelFeatures = features.filter(
      (feature) => !feature.feature.parentFeature,
    )

    const filteredData = topLevelFeatures?.map((feature) => {
      const res = features?.filter(
        (item) => feature?.feature?._id === item?.feature?.parentFeature?._id,
      )
      if (res) {
        return { ...feature, subMenuItems: res }
      }
      return feature
    })

    const finalData = filteredData?.map((feature) => {
      const subMenu = feature?.subMenuItems?.map((item) => {
        const res = features?.filter(
          (i) => item?.feature?._id === i?.feature?.parentFeature?._id,
        )
        if (res) {
          return { ...item, subMenuItems: res }
        }
        return item
      })
      return { ...feature, subMenuItems: subMenu }
    })

    return finalData
  }
  const handleSectionEnable = async (permission, section, value) => {
    let permissions
    let isEnabled
    queryClient.setQueryData([`sectionsData${roleData?._id}`], (data) => {
      const filteredData = data.map((ele) => {
        if (ele?._id === section?._id) {
          if (permission === 'view' && value === false) {
            permissions = {
              view: false,
              edit: false,
              add: false,
              delete: false,
            }
            isEnabled = value
            return {
              ...ele,
              permissions,
            }
          }
          if (permission === 'view') {
            isEnabled = value
          }
          permissions = { ...ele.permissions, [permission]: value }
          return {
            ...ele,
            permissions,
          }
        }
        return ele
      })
      return filteredData
    })

    const response = await API.updateRoleSectionPermissionAPI({
      id: section?._id,
      roleSectionPermission: {
        permissions,
        isEnabled,
      },
    })

    if (!response?.errors) {
      toast.show('Permission updated successfully', { type: 'success' })
    } else {
      toast.show(response?.errors?.[0]?.message, { type: 'danger' })
    }
  }

  const handleTabEnable = async (value) => {
    setSelectedTab({ ...selectedTab, isEnabled: value })
    const response = await API.updateRoleTabPermissionAPI({
      id: selectedTab?.id,
      roleTabPermission: {
        isEnabled: value,
      },
    })

    if (!response?.errors) {
      queryClient.invalidateQueries([`fieldData${roleData?._id}`])
      toast.show('Permission updated successfully', { type: 'success' })
    } else {
      toast.show(response?.errors?.[0]?.message, { type: 'danger' })
    }
  }
  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const viewprops = {
    selectedTab,
    fieldsData,
    sectionsData,
    tabsData,
    onDropdownChange,
    toggleSwitch,
    handleSectionEnable,
    roleData,
    featureData: featureData?.groupedFeatures,
    module,
    toggleMenuSwitch,
    handleTabEnable,
    isLoading,
    ...props,
  }
  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewprops} />
    </Suspense>
  )
}

export default FieldPermissions
