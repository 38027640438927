import React from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Divider, Text, useTheme } from 'react-native-paper'

const Tab = (props) => {
  const { data, selectedTab, onPress, isMobile, hasButton = false } = props
  const { spacing, colors } = useTheme()

  if (isMobile) {
    return (
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <View style={styles.tabContainer(colors, spacing)}>
          {data?.map((item, index) => (
            <TabButton
              key={index.toString()}
              onPress={() => {
                onPress(item)
              }}
              active={selectedTab === item}
              tabName={item}
              hasButton={hasButton}
            />
          ))}
        </View>
        <Divider />
      </ScrollView>
    )
  }
  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      <View
        style={{
          width: '100%',
          height: 40,
          backgroundColor: colors.surface1,
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: spacing.spacing5,
        }}
      >
        {data?.map((item, i) => (
          <TouchableOpacity
            style={{
              marginRight: spacing.spacing8,
              borderBottomColor: colors.primary,
              borderBottomWidth: selectedTab === item ? 4 : 0,
              height: '100%',
              justifyContent: 'center',
            }}
            key={i.toString()}
            onPress={() => onPress(item)}
          >
            <Text
              variant='titleMedium'
              style={{
                textAlign: 'center',
                color: selectedTab === item ? colors.primary : colors.onSurface,
              }}
            >
              {item}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </ScrollView>
  )
}

const TabButton = (props) => {
  const { tabName, active, onPress = () => {}, hasButton } = props
  const { colors, spacing } = useTheme()
  if (hasButton) {
    return (
      <TouchableOpacity
        onPress={() => {
          onPress()
        }}
        style={{
          paddingHorizontal: spacing.spacing4,
          paddingBottom: spacing.spacing4,
          marginRight: spacing.spacing4,
          borderBottomColor: active ? colors.primary : 'transparent',
          borderBottomWidth: 3,
        }}
      >
        <Text
          variant='titleSmall'
          style={{
            color: active ? colors.primary : colors.onSurface1,
            opacity: !active ? 0.5 : 1,
            fontWeight: '800',
          }}
        >
          {tabName}
        </Text>
      </TouchableOpacity>
    )
  }
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        paddingHorizontal: spacing.spacing4,
        paddingVertical: spacing.spacing3,
        borderColor: colors.primaryContainer,
        borderWidth: 1,
        borderRadius: 40,
        marginRight: spacing.spacing4,
        backgroundColor: active ? colors.primaryContainer : 'transparent',
      }}
    >
      <Text
        variant='titleSmall'
        style={{
          color: colors.onPrimaryContainer,
        }}
      >
        {tabName}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  tabContainer: (colors, spacing) => ({
    marginLeft: spacing.spacing2,
    marginTop: spacing.spacing4,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: colors.onBackground,
    borderBottomWidth: 1,
    backgroundColor: colors.background,
  }),
})

export default Tab
