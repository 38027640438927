/**
 * Do not edit directly
 * Generated on Mon, 26 Dec 2022 10:58:27 GMT
 */

export const MdSysColorPrimary = '#3a3bff'
export const MdSysColorOnPrimary = '#ffffff'
export const MdSysColorPrimaryContainer = '#e1e0ff'
export const MdSysColorOnPrimaryContainer = '#04006d'
export const MdSysColorSecondary = '#835500'
export const MdSysColorOnSecondary = '#ffffff'
export const MdSysColorSecondaryContainer = '#ffddb5'
export const MdSysColorOnSecondaryContainer = '#291800'
export const MdSysColorTertiary = '#be0034'
export const MdSysColorOnTertiary = '#ffffff'
export const MdSysColorTertiaryContainer = '#ffdada'
export const MdSysColorOnTertiaryContainer = '#40000b'
export const MdSysColorError = '#ba1a1a'
export const MdSysColorErrorContainer = '#ffdad6'
export const MdSysColorOnError = '#ffffff'
export const MdSysColorOnErrorContainer = '#410002'
export const MdSysColorBackground = '#fffbff'
export const MdSysColorOnBackground = '#3e0021'
export const MdSysColorSurface = '#fffbff'
export const MdSysColorOnSurface = '#3e0021'
export const MdSysColorSurfaceVariant = '#e4e1ec'
export const MdSysColorOnSurfaceVariant = '#46464f'
export const MdSysColorOutline = '#777680'
export const MdSysColorInverseOnSurface = '#ffecf0'
export const MdSysColorInverseSurface = '#5d1137'
export const MdSysColorInversePrimary = '#c0c1ff'
export const MdSysColorShadow = '#000000'
export const MdSysColorSurfaceTint = '#3a3bff'
export const MdSysColorOutlineVariant = '#c7c5d0'
export const MdSysColorScrim = '#000000'
export const MdSysColorPrimaryLight = '#3a3bff'
export const MdSysColorOnPrimaryLight = '#ffffff'
export const MdSysColorPrimaryContainerLight = '#D6DDFF'
export const MdSysColorOnPrimaryContainerLight = '#04006d'
export const MdSysColorSecondaryLight = '#835500'
export const MdSysColorOnSecondaryLight = '#ffffff'
export const MdSysColorSecondaryContainerLight = '#ffddb5'
export const MdSysColorOnSecondaryContainerLight = '#291800'
export const MdSysColorTertiaryLight = '#be0034'
export const MdSysColorOnTertiaryLight = '#ffffff'
export const MdSysColorTertiaryContainerLight = '#ffdada'
export const MdSysColorOnTertiaryContainerLight = '#40000b'
export const MdSysColorErrorLight = '#ba1a1a'
export const MdSysColorErrorContainerLight = '#ffdad6'
export const MdSysColorOnErrorLight = '#ffffff'
export const MdSysColorOnErrorContainerLight = '#410002'
export const MdSysColorBackgroundLight = '#ffffff'
export const MdSysColorOnBackgroundLight = '#1C1B1F'
export const MdSysColorSurfaceLight = '#fffbff'
export const MdSysColorSurfaceLight1 = '#F5F1FF'
export const MdSysColorSurfaceLight2 = '#EFECFF'
export const MdSysColorSurfaceLight3 = '#E9E6FF'
export const MdSysColorSurfaceLight4 = '#E7E4FF'
export const MdSysColorSurfaceLight5 = '#E3E0FF'
export const MdSysColorOnSurfaceLight = '#3e0021'
export const MdSysColorSurfaceVariantLight = '#EFF0F5'
export const MdSysColorOnSurfaceVariantLight = '#46464f'
export const MdSysColorOutlineLight = '#777680'
export const MdSysColorInverseOnSurfaceLight = '#FFFFFF'
export const MdSysColorInverseSurfaceLight = '#303034'
export const MdSysColorInversePrimaryLight = '#c0c1ff'
export const MdSysColorShadowLight = '#000000'
export const MdSysColorSurfaceTintLight = '#3a3bff'
export const MdSysColorOutlineVariantLight = '#c7c5d0'
export const MdSysColorScrimLight = '#000000'
export const MdSysColorNeutralLight = '#FFFFFF'
export const MdSysColorOnNeutralLight = '#ACABAF'
export const MdSysColorSuccessLight = '#206D0E'
export const MdSysColorOnSuccessLight = '#FFFFFF'
export const MdSysColorSuccessContainerLight = '#A5F789'
export const MdSysColorOnSuccessContainerLight = '#032100'
export const MdSysColorNeutralPrimaryLight = '#ADADFF'
export const MdSysColorSpecialColorOneLight = '#8C4381'
export const MdSysColorOnSpecialColorOneLight = '#FFFFFF'
export const MdSysColorSpecialColorOneContainerLight = '#FFD7F2'
export const MdSysColorOnSpecialColorOneContainerLight = '#390035'
export const MdSysColorSpecialColorTwoLight = '#006397'
export const MdSysColorOnSpecialColorTwoLight = '#FFFFFF'
export const MdSysColorSpecialColorTwoContainerLight = '#CCE5FF'
export const MdSysColorOnSpecialColorTwoContainerLight = '#001D31'
export const MdSysColorPrimaryDark = '#c0c1ff'
export const MdSysColorOnPrimaryDark = '#0b00aa'
export const MdSysColorPrimaryContainerDark = '#363453'
export const MdSysColorOnPrimaryContainerDark = '#e1e0ff'
export const MdSysColorSecondaryDark = '#ffb955'
export const MdSysColorOnSecondaryDark = '#452b00'
export const MdSysColorSecondaryContainerDark = '#633f00'
export const MdSysColorOnSecondaryContainerDark = '#ffddb5'
export const MdSysColorTertiaryDark = '#ffb3b5'
export const MdSysColorOnTertiaryDark = '#680018'
export const MdSysColorTertiaryContainerDark = '#920026'
export const MdSysColorOnTertiaryContainerDark = '#ffdada'
export const MdSysColorErrorDark = '#ffb4ab'
export const MdSysColorErrorContainerDark = '#93000a'
export const MdSysColorOnErrorDark = '#690005'
export const MdSysColorOnErrorContainerDark = '#ffdad6'
export const MdSysColorBackgroundDark = '#161616'
export const MdSysColorOnBackgroundDark = '#E5E1E6'
export const MdSysColorSurfaceDark = '#1C1B1F'
export const MdSysColorSurfaceDark1 = '#24232A'
export const MdSysColorSurfaceDark2 = '#292831'
export const MdSysColorSurfaceDark3 = '#2E2D38'
export const MdSysColorSurfaceDark4 = '#302F3A'
export const MdSysColorSurfaceDark5 = '#33323E'
export const MdSysColorOnSurfaceDark = '#ffd9e4'
export const MdSysColorSurfaceVariantDark = '#24232A'
export const MdSysColorOnSurfaceVariantDark = '#c7c5d0'
export const MdSysColorOutlineDark = '#918f9a'
export const MdSysColorInverseOnSurfaceDark = '#303034'
export const MdSysColorInverseSurfaceDark = '#E4E2E6'
export const MdSysColorInversePrimaryDark = '#3a3bff'
export const MdSysColorShadowDark = '#000000'
export const MdSysColorSurfaceTintDark = '#c0c1ff'
export const MdSysColorOutlineVariantDark = '#46464f'
export const MdSysColorSuccessDark = '#8ADA70'
export const MdSysColorOnSuccessDark = '#073900'
export const MdSysColorSuccessContainerDark = '#0E5300'
export const MdSysColorOnSuccessContainerDark = '#A5F789'
export const MdSysColorNeutralPrimaryDark = '#ADADFF'
export const MdSysColorScrimDark = '#000000'
export const MdSysColorNeutralDark = '#1C1B1F'
export const MdSysColorOnNeutralDark = '#ACABAF'
export const MdSysColorSpecialColorOneDark = '#FFABED'
export const MdSysColorOnSpecialColorOneDark = '#561250'
export const MdSysColorSpecialColorOneContainerDark = '#712B68'
export const MdSysColorOnSpecialColorOneContainerDark = '#FFD7F2'
export const MdSysColorSpecialColorTwoDark = '#92CCFF'
export const MdSysColorOnSpecialColorTwoDark = '#003351'
export const MdSysColorSpecialColorTwoContainerDark = '#004B73'
export const MdSysColorOnSpecialColorTwoContainerDark = '#CCE5FF'
export const MdRefPalettePrimary0 = '#000000'
export const MdRefPalettePrimary10 = '#04006d'
export const MdRefPalettePrimary20 = '#0b00aa'
export const MdRefPalettePrimary30 = '#1400ed'
export const MdRefPalettePrimary40 = '#3a3bff'
export const MdRefPalettePrimary50 = '#5d63ff'
export const MdRefPalettePrimary60 = '#7e84ff'
export const MdRefPalettePrimary70 = '#ADADFF'
export const MdRefPalettePrimary80 = '#c0c1ff'
export const MdRefPalettePrimary90 = '#e1e0ff'
export const MdRefPalettePrimary95 = '#f1efff'
export const MdRefPalettePrimary99 = '#fffbff'
export const MdRefPalettePrimary100 = '#ffffff'
export const MdRefPaletteSecondary0 = '#000000'
export const MdRefPaletteSecondary10 = '#291800'
export const MdRefPaletteSecondary20 = '#452b00'
export const MdRefPaletteSecondary30 = '#633f00'
export const MdRefPaletteSecondary40 = '#835500'
export const MdRefPaletteSecondary50 = '#a46b00'
export const MdRefPaletteSecondary60 = '#c68200'
export const MdRefPaletteSecondary70 = '#ea9a00'
export const MdRefPaletteSecondary80 = '#ffb955'
export const MdRefPaletteSecondary90 = '#ffddb5'
export const MdRefPaletteSecondary95 = '#ffeedd'
export const MdRefPaletteSecondary99 = '#FFFDEC'
export const MdRefPaletteSecondary100 = '#ffffff'
export const MdRefPaletteTertiary0 = '#000000'
export const MdRefPaletteTertiary10 = '#40000b'
export const MdRefPaletteTertiary20 = '#680018'
export const MdRefPaletteTertiary30 = '#920026'
export const MdRefPaletteTertiary40 = '#be0034'
export const MdRefPaletteTertiary50 = '#e3294a'
export const MdRefPaletteTertiary60 = '#ff5166'
export const MdRefPaletteTertiary70 = '#ff888f'
export const MdRefPaletteTertiary80 = '#ffb3b5'
export const MdRefPaletteTertiary90 = '#ffdada'
export const MdRefPaletteTertiary95 = '#ffedec'
export const MdRefPaletteTertiary99 = '#fffbff'
export const MdRefPaletteTertiary100 = '#F1EFF2'
export const MdRefPaletteNeutral0 = '#161616'
export const MdRefPaletteNeutral10 = '#1C1B1F'
export const MdRefPaletteNeutral20 = '#303034'
export const MdRefPaletteNeutral30 = '#46464A'
export const MdRefPaletteNeutral40 = '#5E5E62'
export const MdRefPaletteNeutral50 = '#77777A'
export const MdRefPaletteNeutral60 = '#919094'
export const MdRefPaletteNeutral70 = '#ACABAF'
export const MdRefPaletteNeutral80 = '#C7C6CA'
export const MdRefPaletteNeutral90 = '#E4E2E6'
export const MdRefPaletteNeutral95 = '#F2F0F4'
export const MdRefPaletteNeutral99 = '#FFFBFF'
export const MdRefPaletteNeutral100 = '#E6E7EC'
export const MdRefPaletteNeutralVariant0 = '#000000'
export const MdRefPaletteNeutralVariant10 = '#1b1b23'
export const MdRefPaletteNeutralVariant20 = '#303038'
export const MdRefPaletteNeutralVariant30 = '#46464f'
export const MdRefPaletteNeutralVariant40 = '#5e5d67'
export const MdRefPaletteNeutralVariant50 = '#777680'
export const MdRefPaletteNeutralVariant60 = '#918f9a'
export const MdRefPaletteNeutralVariant70 = '#acaab4'
export const MdRefPaletteNeutralVariant80 = '#c7c5d0'
export const MdRefPaletteNeutralVariant90 = '#e4e1ec'
export const MdRefPaletteNeutralVariant95 = '#f2effa'
export const MdRefPaletteNeutralVariant99 = '#fffbff'
export const MdRefPaletteNeutralVariant100 = '#ffffff'
export const MdRefPaletteError0 = '#000000'
export const MdRefPaletteError10 = '#410002'
export const MdRefPaletteError20 = '#690005'
export const MdRefPaletteError30 = '#93000a'
export const MdRefPaletteError40 = '#ba1a1a'
export const MdRefPaletteError50 = '#de3730'
export const MdRefPaletteError60 = '#ff5449'
export const MdRefPaletteError70 = '#ff897d'
export const MdRefPaletteError80 = '#ffb4ab'
export const MdRefPaletteError90 = '#ffdad6'
export const MdRefPaletteError95 = '#ffedea'
export const MdRefPaletteError99 = '#fffbff'
export const MdRefPaletteError100 = '#ffffff'
export const MdRefPaletteSuccess0 = '#000000'
export const MdRefPaletteSuccess10 = '#002110'
export const MdRefPaletteSuccess20 = '#00391F'
export const MdRefPaletteSuccess30 = '#005230'
export const MdRefPaletteSuccess40 = '#006D41'
export const MdRefPaletteSuccess50 = '#1A8855'
export const MdRefPaletteSuccess60 = '#3DA36D'
export const MdRefPaletteSuccess70 = '#5ABE86'
export const MdRefPaletteSuccess80 = '#77DA9F'
export const MdRefPaletteSuccess90 = '#93F7BA'
export const MdRefPaletteSuccess95 = '#C1FFD5'
export const MdRefPaletteSuccess99 = '#F5FFF4'
export const MdRefPaletteSuccess100 = '#FFFFFF'
export const MdRefPaletteSpecialColorOne0 = '#000000'
export const MdRefPaletteSpecialColorOne10 = '#390035'
export const MdRefPaletteSpecialColorOne20 = '#561250'
export const MdRefPaletteSpecialColorOne30 = '#712B68'
export const MdRefPaletteSpecialColorOne40 = '#8C4381'
export const MdRefPaletteSpecialColorOne50 = '#A95C9B'
export const MdRefPaletteSpecialColorOne60 = '#C675B7'
export const MdRefPaletteSpecialColorOne70 = '#E38FD2'
export const MdRefPaletteSpecialColorOne80 = '#FFABED'
export const MdRefPaletteSpecialColorOne90 = '#FFD7F2'
export const MdRefPaletteSpecialColorOne95 = '#FFEBF6'
export const MdRefPaletteSpecialColorOne99 = '#FFFBFF'
export const MdRefPaletteSpecialColorOne100 = '#FFFFFF'
export const MdRefPaletteSpecialColorTwo0 = '#000000'
export const MdRefPaletteSpecialColorTwo10 = '#001D31'
export const MdRefPaletteSpecialColorTwo20 = '#003351'
export const MdRefPaletteSpecialColorTwo30 = '#004B73'
export const MdRefPaletteSpecialColorTwo40 = '#006397'
export const MdRefPaletteSpecialColorTwo50 = '#187DB9'
export const MdRefPaletteSpecialColorTwo60 = '#4097D5'
export const MdRefPaletteSpecialColorTwo70 = '#60B2F2'
export const MdRefPaletteSpecialColorTwo80 = '#92CCFF'
export const MdRefPaletteSpecialColorTwo90 = '#CCE5FF'
export const MdRefPaletteSpecialColorTwo95 = '#E7F2FF'
export const MdRefPaletteSpecialColorTwo99 = '#FCFCFF'
export const MdRefPaletteSpecialColorTwo100 = '#FFFFFF'
export const MdSysTypescaleDisplayLargeFamily = 'Albert Sans'
export const MdSysTypescaleDisplayLargeWeight = 'Regular'
export const MdSysTypescaleDisplayMediumFamily = 'Albert Sans'
export const MdSysTypescaleDisplayMediumWeight = 'Regular'
export const MdSysTypescaleDisplaySmallFamily = 'Albert Sans'
export const MdSysTypescaleDisplaySmallWeight = 'Regular'
export const MdSysTypescaleHeadlineLargeFamily = 'Albert Sans'
export const MdSysTypescaleHeadlineLargeWeight = 'Regular'
export const MdSysTypescaleHeadlineMediumFamily = 'Albert Sans'
export const MdSysTypescaleHeadlineMediumWeight = 'Regular'
export const MdSysTypescaleHeadlineSmallFamily = 'Albert Sans'
export const MdSysTypescaleHeadlineSmallWeight = 'Regular'
export const MdSysTypescaleBodyLargeFamily = 'Albert Sans'
export const MdSysTypescaleBodyLargeWeight = 'Regular'
export const MdSysTypescaleBodyMediumFamily = 'Albert Sans'
export const MdSysTypescaleBodyMediumWeight = 'Regular'
export const MdSysTypescaleBodySmallFamily = 'Albert Sans'
export const MdSysTypescaleBodySmallWeight = 'Regular'
export const MdSysTypescaleLabelLargeFamily = 'Albert Sans'
export const MdSysTypescaleLabelLargeWeight = 'Medium'
export const MdSysTypescaleLabelMediumFamily = 'Albert Sans'
export const MdSysTypescaleLabelMediumWeight = 'Medium'
export const MdSysTypescaleLabelSmallFamily = 'Albert Sans'
export const MdSysTypescaleLabelSmallWeight = 'Medium'
export const MdSysTypescaleTitleLargeFamily = 'Albert Sans'
export const MdSysTypescaleTitleLargeWeight = 'Regular'
export const MdSysTypescaleTitleMediumFamily = 'Albert Sans'
export const MdSysTypescaleTitleMediumWeight = 'Medium'
export const MdSysTypescaleTitleSmallFamily = 'Albert Sans'
export const MdSysTypescaleTitleSmallWeight = 'Medium'
export const MdSysTypescaleDisplayLargeSize = 56
export const MdSysTypescaleDisplayMediumSize = 48
export const MdSysTypescaleDisplaySmallSize = 36
export const MdSysTypescaleHeadlineLargeSize = 32
export const MdSysTypescaleHeadlineMediumSize = 24
export const MdSysTypescaleHeadlineSmallSize = 20
export const MdSysTypescaleBodyLargeSize = 16
export const MdSysTypescaleBodyMediumSize = 15
export const MdSysTypescaleBodySmallSize = 12
export const MdSysTypescaleLabelLargeSize = 14
export const MdSysTypescaleLabelMediumSize = 12
export const MdSysTypescaleLabelSmallSize = 11
export const MdSysTypescaleTitleLargeSize = 22
export const MdSysTypescaleTitleMediumSize = 16
export const MdSysTypescaleTitleSmallSize = 14
export const MdSysTypescaleDescriptionLargeSize = 14
export const MdSysTypescaleDescriptionMediumSize = 12
export const MdSysTypescaleDescriptionSmallSize = 10
