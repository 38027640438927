import React, { useEffect } from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'react-native-paper'

import { spacing } from '@camped/theme-provider'

import { MobileAddEditDepartment } from '../../components/department/mobileAddUpdateDepartment'
import { HeaderLeft } from '../../components/header'

const MobileView = (props) => {
  const { colors, fonts } = useTheme()
  const {
    fileInputRef,
    handleAdd,
    handleAddImage,
    moduleData,
    navigation,
    onChangeText,
    setShowModal,
    showModal,
    errors,
    user,
    setUser,
    id,
    checked,
    setChecked,
    getLookupData,
  } = props
  useEffect(() => {
    if (id) {
      navigation.setOptions({
        headerLeft: () => localHeaderLeft(user),
      })
    }
  }, [user])

  return (
    <ScrollView
      contentContainerStyle={{
        paddingHorizontal: spacing.spacing5,
        backgroundColor: colors.background,
        flexGrow: 1,
        paddingTop: spacing.spacing6,
        paddingBottom: spacing.spacing11,
      }}
      showsVerticalScrollIndicator={false}
      onScroll={({
        nativeEvent: {
          contentOffset: { y: scrollY },
        },
      }) => {
        navigation.setOptions({
          headerTitle: scrollY > 80 ? 'Department' : '',
          headerTitleStyle: {
            color: colors.onSurface,
            ...fonts.headlineSmall,
          },
        })
      }}
      scrollEventThrottle={460}
    >
      <MobileAddEditDepartment
        fileInputRef={fileInputRef}
        handleAdd={handleAdd}
        handleAddImage={handleAddImage}
        moduleData={moduleData}
        navigation={navigation}
        onChangeText={onChangeText}
        setShowModal={setShowModal}
        setUser={setUser}
        showModal={showModal}
        errors={errors}
        user={user}
        id={id}
        checked={checked}
        setChecked={setChecked}
        getLookupData={getLookupData}
      />
    </ScrollView>
  )
}

export default MobileView

const localHeaderLeft = (props) => {
  const { user } = props
  return <HeaderLeft title={user?.department} hasBack />
}
