import React from 'react'
import { Dimensions, Platform, StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import KeyBoardView from './KeyBoardView'

const MobileContainer = (props) => {
  const { style, children, hasKeyBoard = false, ...rest } = props

  const { spacing } = useTheme()

  return (
    <React.Fragment key={children}>
      {hasKeyBoard ? (
        <KeyBoardView>
          <View
            style={[
              {
                height: '100%',
                paddingBottom: spacing.spacing11,
              },
              style,
              Platform.OS === 'web' ? styles.webContainer : '',
            ]}
            {...rest}
          >
            {children}
          </View>
        </KeyBoardView>
      ) : (
        <View
          style={[
            {
              height: '100%',
              minHeight: '100%',
              paddingBottom: spacing.spacing11,
            },
            style,

            Platform.OS === 'web' ? styles.webContainer : '',
          ]}
          {...rest}
        >
          {children}
        </View>
      )}
    </React.Fragment>
  )
}

const windowInnerHeight = Dimensions.get('window').height

const styles = StyleSheet.create({
  webContainer: {
    minHeight: `var(--100vh, ${windowInnerHeight}px)`,
    maxHeight: `var(--100vh, ${windowInnerHeight}px)`,
  },
})

export default MobileContainer
