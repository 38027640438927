import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Button, Text } from '@camped/components'
import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider/src/tokens'

const QuestionPaperCard = (props) => {
  const { item, onClick } = props
  const { colors } = useTheme()
  const styles = createStyles(colors)

  return (
    <View style={styles.container}>
      <Icon
        name='Admission'
        fill={colors.onSurface}
        style={styles.icon}
        width={20}
        height={20}
      />
      <Text style={styles.title}>{item?.examName}</Text>
      <View style={styles.space}>
        <View style={styles.spacemargin}>
          <Text style={{ paddingVertical: spacing.spacing2 }}>
            Total Marks: {item?.totalMarks}
          </Text>
          <Text style={{ paddingVertical: spacing.spacing2 }}>
            Difficulty: {item?.difficultyLevel}
          </Text>
          <Text style={{ paddingVertical: spacing.spacing2 }}>
            Date Of Examination: {item?.date}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-evenly',
              paddingTop: spacing.spacing4,
            }}
          >
            <Button
              mode='outlined'
              style={{
                height: 28,
                justifyContent: 'center',
              }}
              onPress={() => {
                onClick(item, 'view')
              }}
            >
              Preview
            </Button>
            <Button
              mode='outlined'
              style={{
                height: 28,
                justifyContent: 'center',
              }}
              onPress={() => {
                onClick(item, 'edit')
              }}
            >
              Edit
            </Button>
          </View>
        </View>
      </View>
    </View>
  )
}

const createStyles = (colors) =>
  StyleSheet.create({
    space: {
      paddingVertical: spacing.spacing4,
    },
    spacemargin: {
      paddingVertical: spacing.spacing4,
      paddingHorizontal: spacing.spacing5,
    },

    container: {
      marginVertical: spacing.spacing5,
      marginLeft: spacing.spacing6,
      backgroundColor: colors.surface2,
      borderRadius: spacing.spacing5,
      borderColor: colors.onSurface,
      borderWidth: 0.35,
      minWidth: 306,
      maxWidth: 327,
      paddingBottom: spacing.spacing3,
    },
    title: {
      marginTop: spacing.spacing6,
      color: colors.onPrimaryContainer,
      paddingLeft: spacing.spacing5,
      fontSize: spacing.spacing5,
      fontWeight: '600',
    },
    side: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: spacing.spacing3,
    },
    icon: {
      height: 30,
      width: 30,
      marginTop: spacing.spacing5,
      marginLeft: spacing.spacing5,
    },

    progress: {
      paddingLeft: spacing.spacing5,
      paddingRight: spacing.spacing5,
    },
  })

export default QuestionPaperCard
