import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, useWindowDimensions } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Auth } from '@camped/api'
import { Audit } from '@camped/audit'
import { Button, PasswordTextInput, Text, TextInput } from '@camped/components'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout, testProps } from '@camped/utils'

import {
  EventAction,
  EventEntityType,
  EventScreen,
  EventType,
} from '../constants/audit'
import { Behavior, ScreenName, TypeofControl } from '../constants/testing'
import { ContactUs } from './ContactUs'

export const ResetPassword = () => {
  const { t } = useTranslation()
  const route = useRoute()
  const { colors, fonts } = useTheme()
  const navigation = useNavigation()
  const { width } = useWindowDimensions()

  const userName = route?.params?.userName
  const code = route?.params?.confirmation_code
  const [success, setSuccess] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { confirmPassword: '', password: '' },
  })

  const isDesktop = ScreenLayout.isWeb(width)

  const handleResetSubmit = async (data) => {
    Audit.logEvent({
      action: EventAction.CLICK,
      entityType: EventEntityType.RESET_PASSWORD,
      eventType: EventType.RESET_PASSWORD,
      details: {
        Screen: EventScreen.RESET_PASSWORD,
        Email: userName,
      },
    })
    if (data?.password === data?.confirmPassword) {
      try {
        const res = await Auth.resetPasswordAsync(
          userName,
          code,
          data?.password,
        )
        if (res?.message) {
          toast.show(res?.message, { type: 'danger' })
        } else setSuccess(true)
      } catch (error) {
        if (error) {
          toast.show(error.message, { type: 'danger' })
        }
      }
    }
    if (data?.password !== data?.confirmPassword) {
      toast.show(t('Error_Message.AUTH_PASSWORD'), { type: 'danger' })
    }
  }
  if (success) {
    return (
      <View>
        <Text
          variant={isDesktop ? 'headlineLarge' : 'headlineSmall'}
          style={{
            color: colors.onSurfaceVariant,
            fontWeight: '600',
          }}
        >
          {t('Auth.PROCESS_COMPLETED')}
        </Text>
        <Text
          variant='bodyLarge'
          style={{
            color: colors.onSurfaceVariant,
            marginVertical: isDesktop ? spacing.spacing7 : spacing.spacing6,
          }}
        >
          {t('Auth.PASSWORD_RESET_CONFIRMATION')}
        </Text>
        <Button
          mode='contained'
          style={{
            backgroundColor: colors.primary,
            alignSelf: 'flex-start',
          }}
          labelStyle={{
            color: colors.onPrimary,
            fontSize: spacing.spacing5,
            fontWeight: '400',
          }}
          onPress={() => navigation.navigate('campus')}
          {...testProps(
            `${ScreenName.RESET_PASSWORD}_${TypeofControl.INPUT}_${Behavior.BACK}`,
          )}
        >
          {t('Auth.SIGN_IN_RETURN')}
        </Button>
      </View>
    )
  }
  return (
    <View>
      <View>
        <Text
          variant={isDesktop ? 'headlineLarge' : 'headlineSmall'}
          style={{
            color: colors.onSurfaceVariant,
            fontWeight: '600',
            marginBottom: isDesktop ? spacing.spacing7 : spacing.spacing6,
          }}
        >
          {t('Auth.PASSWORD_RESET')}
        </Text>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <PasswordTextInput
              label={t('Auth.NEW_PASSWORD')}
              placeholder={t('Auth.NEW_PASSWORD')}
              right={<TextInput.Affix />}
              secureTextEntry
              style={{
                backgroundColor: colors.surfaceVariant,
                marginBottom: spacing.spacing6,
              }}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              error={errors?.password}
              method={handleSubmit}
              {...testProps(
                `${ScreenName.RESET_PASSWORD}_${TypeofControl.INPUT}_${Behavior.NEW_PASSWORD}`,
              )}
              testName={`${ScreenName.RESET_PASSWORD}_${TypeofControl.BUTTON}_${Behavior.CLICK}`}
            />
          )}
          name='password'
          rules={{
            required: 'Password is Required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters long',
            },
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
              message: t('Auth.PASSWORD_COMPLEXITY_REQUIREMENTS'),
            },
          }}
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <PasswordTextInput
              label={t('Auth.CONFIRM_PASSWORD')}
              placeholder={t('Auth.CONFIRM_PASSWORD')}
              right={<TextInput.Affix />}
              secureTextEntry
              style={{
                backgroundColor: colors.surfaceVariant,
              }}
              value={value}
              onBlur={onBlur}
              onChangeText={onChange}
              error={errors?.confirmPassword}
              method={handleSubmit}
              {...testProps(
                `${ScreenName.RESET_PASSWORD}_${TypeofControl.INPUT}_${Behavior.CONFIRM_PASSWORD}`,
              )}
              testName={`${ScreenName.RESET_PASSWORD}_${TypeofControl.BUTTON}_${Behavior.CLICK}`}
            />
          )}
          name='confirmPassword'
          rules={{
            required: 'confirm Password is Required',
            minLength: {
              value: 8,
              message: 'confirm Password must be at least 8 characters long',
            },
          }}
        />
      </View>

      <Button
        mode='contained'
        style={{
          justifyContent: 'center',
          backgroundColor: colors.primary,
          marginTop: spacing.spacing8,
          marginBottom: isDesktop ? 0 : spacing.spacing13,
          alignSelf: 'flex-end',
        }}
        labelStyle={{
          color: colors.onPrimary,
          ...(isDesktop ? fonts.titleMedium : fonts.bodyMedium),
        }}
        onPress={handleSubmit(handleResetSubmit)}
        {...testProps(
          `${ScreenName.RESET_PASSWORD}_${TypeofControl.BUTTON}_${Behavior.SUBMIT}`,
        )}
      >
        {t('Auth.RESET')}
      </Button>
      {isDesktop && (
        <View
          style={{
            flexDirection: 'row',
            marginTop: spacing.spacing7,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            variant='bodyLarge'
            style={{
              textAlign: 'center',
              color: colors.onSurfaceVariant,
            }}
          >
            {t('Auth.SUPPORT_ASSISTANCE')}{' '}
          </Text>
          <ContactUs />
        </View>
      )}
    </View>
  )
}
