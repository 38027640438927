import React from 'react'
import {
  Dimensions,
  Modal,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { useTheme } from 'react-native-paper'

import { ScreenLayout } from '@camped/utils'

import { BottomSheet } from './BottomSheet'

const BottomDrawer = (props) => {
  const { colors } = useTheme()
  const {
    children,
    containerStyle,
    drawerColor = colors.onPrimary,
    setShowDrawer = () => {},
    showDrawer = false,
    isEnable = true,
  } = props
  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout?.isWeb(windowWidth)

  return (
    <Modal
      animation
      visible={showDrawer}
      transparent
      onRequestClose={() => setShowDrawer(false)}
    >
      <View style={{ flexDirection: 'row', height: '100%', width: '100%' }}>
        {isDesktop && <View style={{ maxWidth: 475, width: '100%' }} />}
        <View style={[styles.wrapper, { flex: 1 }]}>
          <View
            style={{
              flex: 1,
            }}
          />
          <TouchableOpacity
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
              height: '100%',
              width: '100%',
            }}
            onPress={() => setShowDrawer(false)}
          />

          <BottomSheet dismiss={setShowDrawer} isEnable={isEnable}>
            <View
              style={[
                styles.container,
                {
                  backgroundColor: drawerColor,
                  width: '100%',
                },
                containerStyle,
              ]}
            >
              {children}
            </View>
          </BottomSheet>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    height: Dimensions.get('window').height,
    width: '100%',
  },
  container: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    bottom: 0,
  },
})

export default BottomDrawer
