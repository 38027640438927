import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

export const Loading = () => {
  const { colors } = useTheme()

  return (
    <View style={[styles.loader]}>
      <ActivityIndicator size='large' color={colors.primary} />
    </View>
  )
}
const styles = StyleSheet.create({
  loader: {
    flex: 1,
    justifyContent: 'center',
  },
})
