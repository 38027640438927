import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-paper'

const DesktopContainer = (props) => {
  const { children } = props
  const { colors } = useTheme()

  return (
    <View
      style={{
        backgroundColor: colors.surface1,
        height: '100%',
        borderColor: colors.surfaceVariant,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderTopWidth: 0,
        borderWidth: 1,
      }}
    >
      {children}
    </View>
  )
}
export default DesktopContainer
