import { useNavigation } from '@react-navigation/native'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Platform } from 'react-native'

import { useQuery } from '@tanstack/react-query'

import { API } from '@camped/api'
import { Text } from '@camped/components'
import { ScreenLayout, SecureStore } from '@camped/utils'

import { HeaderIcon } from '../../components/header'
import { useGlobalSearchData } from '../../hooks/useGlobalSearchData'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const Roles = (props) => {
  const { module, isAdd, route } = props
  const navigation = useNavigation()
  const { id } = route?.params || ''

  const [role, setRole] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropDownWidth, setDropDownWidth] = useState(0)
  const [inputValue, setInputValue] = useState('')

  const DropdownButton = useRef()
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputValue.length === 0) {
      setVisible(false)
    }
  }, [inputValue])
  useEffect(() => {
    if (isAdd) return
    navigation.setOptions({
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => (
        <HeaderIcon
          iconName='PlusIcon'
          module={module}
          onPress={() => {
            navigation.navigate(`add-role`)
          }}
        />
      ),
    })
  }, [])

  const { data: singleRole } = useQuery(
    [`role-data`],
    async () => {
      const response = await API.getRoleByIdAPI({
        id,
      })
      return response
    },
    {
      enabled: id !== undefined,
    },
  )

  const { allData, refetch } = useGlobalSearchData({
    queryKey: ['search'],
    searchText: inputValue,
    type: singleRole?.userPerson.toLowerCase() ?? '',
  })
  const getSearchData = useMemo(() => AwesomeDebouncePromise(refetch, 1000), [])

  const { data: rolesData, refetchRole } = useQuery(
    [`rolesData-${module}`],
    async () => {
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)
      if (module === 'Role') {
        const response = await API.getRolesByFilterAPI({
          filter: {
            tenant: userDetail?.[0]?.tenantId ?? '',
          },
        })
        return response
      }
    },
    {
      enabled: id === undefined,
    },
  )

  const toggleDropdown = () => {
    if (visible) {
      setVisible(false)
      return
    }
    if (!visible) {
      DropdownButton?.current?.measure((_fx, _fy, _w, h, _px, py) => {
        setDropdownTop(py)
        setDropDownWidth(_w)
        setDropdownLeft(_px)
      })
      setVisible(true)
    }
  }
  const getLookupData = () => {
    const options = []
    if (rolesData?.length > 0) {
      rolesData?.map((item, i) => {
        options.push({
          displayName: item?.name,
          value: item?.name?.replace(/ /g, ''),
          id: item._id,
          userPerson: item.userPerson,
          displayOrder: i,
        })
        return null
      })
    }
    return options
  }
  const onChangeText = (data, text) => {
    if (typeof text === 'object') {
      setRole({
        ...role,
        [data]: text?.displayName,
        userPerson: text?.userPerson,
        clonedRole: text?.id,
      })
    } else {
      setRole({
        ...role,
        [data]: text,
      })
    }
  }
  const handleCreate = async () => {
    const tenant = await SecureStore.getCookie('user-details')
    const userDetail = JSON.parse(tenant?.userRoleDetails)
    setIsLoading(true)
    const newRole = await API.createRoleAPI({
      clonedRole: role?.clonedRole,
      role: {
        name: role?.name,
        userPerson: role?.userPerson,
        tenant: userDetail?.[0]?.tenantId ?? '',
      },
    })
    if (!newRole?.errors) {
      navigation.navigate('roles')
      setIsLoading(false)
      refetchRole()
    } else {
      toast.show(newRole?.errors?.[0]?.message, { type: 'danger' })
    }
    return newRole
  }
  const handleClick = async (item) => {
    setVisible(false)
    const tenant = await SecureStore.getCookie('user-details')
    const userDetail = JSON.parse(tenant?.userRoleDetails)
    setIsLoading(true)
    const date = new Date()

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const currentDate = `${year}-${month}-${day}`
    const newRole = await API.createTenantUserRoleMappingAPI({
      tenantUserRoleMapping: {
        enabledOn: currentDate,
        userPerson: singleRole?.userPerson,
        tenant: userDetail?.[0]?.tenantId ?? '',
        user: item?.user?._id,
        role: id,
        isActive: true,
      },
    })
    if (!newRole?.errors) {
      setIsLoading(false)
      if (Platform.OS === 'web') {
        window.history.go(-1)
      } else {
        navigation.goBack()
      }
      refetchRole()
    } else {
      toast.show(newRole?.errors?.[0]?.message, { type: 'danger' })
    }
    return newRole
  }
  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const viewProps = {
    rolesData,
    isAdd,
    onChangeText,
    role,
    getLookupData,
    handleCreate,
    isLoading,
    module,
    inputRef,
    visible,
    setVisible,
    toggleDropdown,
    DropdownButton,
    dropdownLeft,
    dropDownWidth,
    dropdownTop,
    singleRole,
    inputValue,
    setInputValue,
    getSearchData,
    allData,
    handleClick,
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default Roles
