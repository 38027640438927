import { useNavigationState } from '@react-navigation/native'
import { useAtom } from 'jotai'
import { useRef } from 'react'
import { Platform } from 'react-native'

import { useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { userRollAtom } from '@camped/jotai-storage'
import { FileHandler, SecureStore } from '@camped/utils'

const useUploadImage = () => {
  const queryClient = useQueryClient()
  const [userPerson] = useAtom(userRollAtom)
  const currentScreen = useNavigationState(
    (state) => state?.routes?.[state.routes.length - 1],
  )?.name
  const uploadImage = async (id, module, attachment) => {
    const tenant = await SecureStore.getCookie('user-details')
    const userDetail = JSON.parse(tenant?.userRoleDetails)
    const moduleCapitalized =
      module === 'staff' ||
      module === 'staffProfile' ||
      module === 'adminProfile'
        ? 'staff'
        : 'student'
    const screenIds = queryClient.getQueryData(['getScreenIds'])
    const screen = screenIds?.find((item) => item?.fieldName === currentScreen)
    let profileId
    if (module === 'staffProfile' || module === 'adminProfile') {
      const staffData = await API.getStaffsByFilterAPI({
        filter: {
          tenant: userDetail?.[0]?.tenantId ?? '',
          user: tenant?.['custom:userId'] ?? '',
        },
        extraHeader: {
          screen: screen?._id,
        },
      })
      profileId = staffData?.[0]?._id
    } else if (module === 'studentProfile') {
      const studentData = await API.getStudentsByFilterAPI({
        filter: {
          tenant: userDetail?.[0]?.tenantId ?? '',
          user: tenant?.['custom:userId'] ?? '',
        },
        extraHeader: {
          screen: screen?._id,
        },
      })
      profileId = studentData?.[0]?._id
    }
    if (
      attachment?.file &&
      !/\.(gif|jpg|jpeg|tiff|png)$/i.test(attachment?.file)
    ) {
      // for custom image upload function in s3 upload | fetching s3 signed url
      const response = await API.getS3ImageUploadUrlAPI({
        s3Data: {
          id: id ?? profileId,
          folderName: moduleCapitalized.toUpperCase(),
          userPerson,
        },
      })
      if (response) {
        await FileHandler.uploadFileToS3({
          url: response.uploadUrl,
          file: attachment?.file,
        })

        let res
        if (
          module === 'staff' ||
          module === 'staffProfile' ||
          module === 'adminProfile'
        ) {
          res = await API.updateStaffAPI({
            id: id ?? profileId,
            staff: {
              profileImage: `${moduleCapitalized.toUpperCase()}/${userPerson}/${
                id ?? profileId
              }`,
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
        } else if (module === 'student' || module === 'studentProfile') {
          res = await API.updateStudentAPI({
            id: id ?? profileId,
            student: {
              profileImage: `${moduleCapitalized.toUpperCase()}/${userPerson}/${
                id ?? profileId
              }`,
            },
            extraHeader: {
              screen: screen?._id,
            },
          })
        }
        if (!res?.errors) {
          toast.show(`Photo added successfully`, {
            type: 'success',
          })
        } else {
          toast.show(res?.errors?.[0]?.message, {
            type: 'danger',
          })
        }
      }
    }
  }
  const handleAddImage = () => {
    if (Platform.OS === 'web') {
      openFilePicker()
    }
  }
  // image upload

  const fileInputRef = useRef(null)
  const openFilePicker = () => fileInputRef?.current?.click()
  const getBase64 = async (f) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(f)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  return { fileInputRef, getBase64, handleAddImage, uploadImage }
}

export default useUploadImage
