import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { Checkbox, Text, useTheme } from 'react-native-paper'

import { Divider } from '@camped/components'
import { DynamicField, FormContainer } from '@camped/form-container'
import { Icon } from '@camped/icons'
import { MobileContainer } from '@camped/shared-container'
import { spacing } from '@camped/theme-provider'

import Accodion from '../../components/accordion'

const MobileView = (props) => {
  const {
    data,
    user,
    toggleSwitch,
    selectedScreen,
    onDropdownChange,
    permissionData,
    module,
    uniqueScreen,
  } = props
  const { colors } = useTheme()
  const navigation = useNavigation()
  const renderItem = (localData) => {
    localData.filter = true
    return (
      <DynamicField
        data={localData}
        value={selectedScreen?.displayName}
        isEdit
        list={uniqueScreen(permissionData?.map((item) => item?.screen))}
        multiSelect={localData.multiSelect}
        dropDownWidth={320}
        onChangeText={onDropdownChange}
      />
    )
  }

  return (
    <MobileContainer style={{ backgroundColor: colors.surface2 }}>
      {module === 'Role' && (
        <View style={{ backgroundColor: colors.background }}>
          <View
            style={{
              height: 70,
              padding: spacing.spacing4,
              alignItems: 'center',
              backgroundColor: colors.surface2,
            }}
          >
            <FormContainer data={data} renderItem={renderItem} width={350} />
          </View>
        </View>
      )}
      <ScrollView style={{ marginTop: spacing.spacing2 }}>
        {user?.rolesData?.map((item, index) => (
          <Accodion
            title={item?.role?.name}
            isAccordian
            style={{ justifyContent: 'center', marginTop: spacing.spacing2 }}
            isEnable
          >
            {module === 'Role' &&
              ['view', 'add', 'edit', 'delete'].map((permission) => (
                <>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingHorizontal: 30,
                      paddingVertical: 15,
                    }}
                  >
                    <Text
                      style={{ flex: 5, alignItems: 'center' }}
                      variant='bodyMedium'
                    >
                      {permission}
                    </Text>

                    <Checkbox
                      status={
                        item?.permissions?.[permission]
                          ? 'checked'
                          : 'unchecked'
                      }
                      onPress={() => {
                        toggleSwitch(
                          index,
                          permission,
                          !item?.permissions?.[permission],
                        )
                      }}
                      disabled={
                        item?.permissions?.[permission] === null ||
                        item?.permissions?.[permission] === 'null' ||
                        item?.permissions?.[permission] === undefined ||
                        (item?.permissions?.view === false &&
                          permission !== 'view')
                      }
                    />
                  </View>

                  <Divider color={colors.surface2} />
                </>
              ))}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingHorizontal: 30,
                paddingVertical: 15,
                alignItems: 'center',
              }}
            >
              <Text variant='bodyMedium' style={{ flex: 0.95 }}>
                {module === 'Role' ? 'Field Permissions' : 'Menu Permissions'}
              </Text>
              <TouchableOpacity
                disabled={!item?.permissions?.view && module === 'Role'}
                onPress={() =>
                  navigation.navigate('field-permissions', {
                    roleData: item,
                    module,
                    role: item?.role?.name,
                  })
                }
              >
                <Icon name='Clipboard' fill={colors.onBackground} />
              </TouchableOpacity>
            </View>
          </Accodion>
        ))}
      </ScrollView>
    </MobileContainer>
  )
}

export default MobileView
