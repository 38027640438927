import { useNavigation } from '@react-navigation/native'
import { useAtom } from 'jotai'
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Icon } from '@camped/icons'
import { userAtom, userRollAtom } from '@camped/jotai-storage'
import { ProfileMenu } from '@camped/shared-component-profile-menu'

const HeaderRight = (props) => {
  const navigation = useNavigation()
  const { hasSearch, hasNotification, hasProfile, children } = props
  const { colors } = useTheme()
  const [globalUser] = useAtom(userAtom)
  const [userRoll] = useAtom(userRollAtom)
  return (
    <View style={styles.container}>
      {hasSearch && userRoll === 'admin' && (
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('global-search')
          }}
        >
          <View style={styles.boxSpace}>
            <Icon
              name='Search'
              color={colors.onSurface}
              width={20}
              height={20}
            />
          </View>
        </TouchableOpacity>
      )}
      {hasProfile && (
        <View style={styles.boxSpace}>
          <ProfileMenu globalUser={globalUser} />
        </View>
      )}
      {children}
    </View>
  )
}

export default HeaderRight
const styles = StyleSheet.create({
  boxSpace: { padding: 5 },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
})
