export function processPhoneValue(value, code, user) {
  if (value) {
    if (value.indexOf('-') !== -1) {
      return value.split('-')[1]
    }
    if (
      value[0] === '+' &&
      value.substring(1, code + 1) === user?.callingCode
    ) {
      return value.slice(code + 1, value.length)
    }
    if (value[0] === '+') {
      return value.slice(1, value.length)
    }
  }
  return value
}
