import { useTheme } from '@react-navigation/native'
import React, { useCallback, useState } from 'react'
import { Modal, TouchableOpacity, View } from 'react-native'

import { Icon } from '@camped/icons'
import { spacing } from '@camped/theme-provider'

import BottomDrawer from '../BottomDrawer'
import Text from '../Text'

const externalOpen = (URL) => {
  console.log({ URL })
  window.open(URL, '_blank', 'noopener')
}

const iconList = {
  facebook: {
    name: 'SocialFacebook',
    e: (l) => externalOpen(`https://www.facebook.com/sharer/sharer.php?u=${l}`),
  },
  twitter: {
    name: 'SocialTwitter',
    e: (l, t) =>
      externalOpen(`https://twitter.com/intent/tweet?text=${t}&url=${l}`),
  },
  whatsapp: {
    name: 'SocialWhatsapp',
    e: (l, t) => externalOpen(`https://api.whatsapp.com/send?text=${t} ${l}`),
  },
  reddit: {
    name: 'SocialReddit',
    e: (l, t) =>
      externalOpen(`https://www.reddit.com/submit?url=${l}&title=${t}`),
  },
  telegram: {
    name: 'SocialTelegram',
    e: (l, t) =>
      externalOpen(`https://telegram.me/share/msg?url=${l}&text=${t}`),
  },
  linkedin: {
    name: 'SocialLinkedin',
    e: (l, t, ti) =>
      externalOpen(
        `https://www.linkedin.com/shareArticle?mini=true&url=${l}&title=${ti}&summary=${t}`,
      ),
  },
  mail: {
    name: 'SocialGmail',
    e: (l, t) => externalOpen(`mailto:?body=${l}&subject=${t}`),
  },
  copy: {
    name: 'SocialCopy',
    e: (l) => navigator?.clipboard?.writeText(decodeURIComponent(l)),
  },
}

export const CampedShare = (props) => {
  const {
    children,
    data = {},
    onClick,
    onPress,
    isExternalLink,
    itemID,
  } = props
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = useCallback(() => setIsOpen(true), [])
  const onClose = useCallback(() => setIsOpen(false), [])
  const { colors } = useTheme()

  const url = data?.url

  const options = {
    title: data?.title || 'Edvanza',
    text: data?.text,
    url: url || '',
  }

  const handleOnIconClicked = (e) => {
    onClick && onClick() // callback
    e(encodeURIComponent(url), data.text, data.title)
    onClose()
  }

  const handleOnClick = useCallback(async () => {
    if (window.navigator.share) {
      try {
        await window.navigator.share(options)
        props.onClick()
      } catch (e) {
        console.warn(e)
      }
    } else {
      onOpen()
    }
  }, [options])

  return (
    <>
      <TouchableOpacity
        onPress={onPress ? () => onPress() : () => handleOnClick()}
      >
        <View>{children}</View>
      </TouchableOpacity>
      {isOpen && (
        <Modal
          visible={isOpen}
          onRequestClose={() => setIsOpen(false)}
          transparent
          animationType='slide'
        >
          <View
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}
          />
          <View
            style={{
              paddingVertical: spacing.spacing5,
              backgroundColor: colors.background,
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 10,
              marginHorizontal: 10,
              alignItems: 'center',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '30%',
              }}
            >
              {Object?.values(iconList)?.map((value) => {
                return (
                  <TouchableOpacity
                    style={{ margin: spacing.spacing4 }}
                    onPress={() => handleOnIconClicked(value?.e)}
                  >
                    <Icon name={value.name} width={60} height={60} />
                  </TouchableOpacity>
                )
              })}
            </View>
            <TouchableOpacity
              style={{
                backgroundColor: colors.backgroundSurface2,
                padding: spacing.spacing4,
                marginTop: spacing.spacing4,
                alignItems: 'center',
              }}
              onPress={onClose}
            >
              <Text style={{ fontSize: 14 }}>Close</Text>
            </TouchableOpacity>
          </View>
        </Modal>
      )}
    </>
  )
}
