import React from 'react'
import { Image, ScrollView, View } from 'react-native'
import { useTheme } from 'react-native-paper'

import { Text } from '@camped/components'

import departmentsEmptyStatus from 'assets/departmentsEmptyStatus.png'
import userListImage from 'assets/userList.png'

const EdgeCase = (props) => {
  const { module } = props
  const { colors, spacing } = useTheme()

  const getImageSrc = () => {
    if (module === 'student') {
      return userListImage
    }
    if (module === 'course') {
      return userListImage
    }
    if (module === 'department') {
      return departmentsEmptyStatus
    }
    if (module === 'graduation') {
      return departmentsEmptyStatus
    }

    return userListImage
  }

  return (
    <ScrollView
      contentContainerStyle={{
        backgroundColor: colors.surface1,
        flexGrow: 1,
      }}
      showsVerticalScrollIndicator={false}
    >
      <View
        style={{
          alignItems: 'center',
          flex: 1,
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Image style={{ height: 213, width: 200 }} source={getImageSrc()} />
        <Text
          style={{
            flex: 0.1,
            fontSize: spacing.spacing6,
            fontWeight: '600',
            justifyContent: 'center',
            paddingTop: spacing.spacing8,
          }}
        >
          {`There are no ${module.toLowerCase()} to show`}
        </Text>
        <Text
          style={{
            flex: 0.1,
            fontSize: spacing.spacing5,
            fontWeight: '400',
            justifyContent: 'center',
            width: 220,
            textAlign: 'center',
            marginTop: spacing.spacing5,
          }}
        >
          {`Get started by adding ${module.toLowerCase()} to your campus`}
        </Text>
      </View>
    </ScrollView>
  )
}

export default EdgeCase
