import { useIsFocused, useNavigation } from '@react-navigation/native'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowDimensions } from 'react-native'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { API } from '@camped/api'
import { Text } from '@camped/components'
import { HeaderIcon } from '@camped/core/src/components/header'
import { ScreenLayout, appUtil } from '@camped/utils'

import { useGetFilterOptions } from '../hooks/use-get-filter-options'
import { useGetListingData } from '../hooks/use-get-listing-data'
import { usePagination } from '../hooks/use-pagination'
import { useSendInvitation } from '../hooks/use-send-invitation'
import { capitalize } from '../utils/capitailize'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

const Listing = (props) => {
  const {
    setShowActionView = () => {},
    isBulkClick,
    setIsBulkClick = () => {},
    module,
  } = props
  const navigation = useNavigation()
  const queryClient = useQueryClient()
  const windowWidth = useWindowDimensions().width
  const isDesktop = ScreenLayout.isWeb(windowWidth)
  const isFocused = useIsFocused()

  const [errors, setErrors] = useState()
  const [expanded, setExpanded] = useState(false)
  const [hasMultipleSelect, setHasMultipleSelect] = useState(false)
  const [selectedIDs, setSelectedIDs] = useState([])
  const [selectedSortValue, setSelectedSortValue] = useState('')
  const [showDrawer, setShowDrawer] = useState(false)
  const [showSortDrawer, setShowSortDrawer] = useState(false)
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)
  const [disableUser, setdisableUser] = useState({})

  const { t } = useTranslation()

  const { data: filterValue } = useQuery(
    [`filter${capitalize(module)}Value`],
    async () => {},
    {
      enabled: true,
      initialData: {},
    },
  )
  const { id } = props.route?.params || ''

  const limit = 50
  const { courseData, degreeData, departmentData, graduationData } =
    useGetFilterOptions(module)
  const {
    totalCount,
    metaData,
    permissions,
    filterList,
    sortList,
    totalCountRefecth,
  } = useGetListingData(module, filterValue)
  const {
    fetchNextPage,
    hasNextPage,
    isFetching: userFetching,
    isFetchingNextPage,
    tableHeader,
    refetch,
    userLists,
    profileImageList,
  } = usePagination({
    sortValue: selectedSortValue,
    filterValue,
    module,
    limit,
    id,
    metaData,
    totalCount,
  })

  useEffect(() => {
    if (!permissions?.add || !isDesktop) return
    navigation.setOptions({
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => (
        <HeaderIcon
          iconName='PlusIcon'
          module={appUtil.capitalize(module)}
          onPress={() => {
            if (module === 'rolelisting') {
              navigation.navigate(`assign-role`, { id })
            } else {
              navigation.navigate(`add-${module}`)
            }
          }}
        />
      ),
    })
  }, [permissions])

  useEffect(() => {
    if (isFocused) {
      refetch()
    }
  }, [isFocused])
  const onSortSelected = (val) => {
    const filterData = sortList?.find((r) => r?.displayName === val)

    if (filterData) {
      setSelectedSortValue(filterData)
    } else {
      setSelectedSortValue({})
    }
  }
  useEffect(() => {
    if (!selectedSortValue) return
    refetch()
  }, [selectedSortValue])

  const goTo = (screen, m) => {
    if (module === 'rolelisting' && !m.isActive) {
      return
    }
    if (module === 'rolelisting' && m.isActive) {
      setdisableUser(m)
      setModalVisible(true)
    }
    if (selectedIDs?.length > 0) {
      return handleCheckBox(m?._id)
    }
    if (!isDesktop && expanded !== m?._id) {
      return setExpanded(m?._id)
    }

    if (screen === 'detail') {
      setExpanded('')
      navigation.navigate(`view-${module}-details`, {
        id: m?._id,
        firstName: m?.firstName || m?.courseName,
      })
    }
  }

  // handleCheckBox -> checks single check Box
  const handleCheckBox = (localId) => {
    if (selectedIDs.includes(localId.toString())) {
      setSelectedIDs(selectedIDs.filter((sID) => sID !== localId))
    } else {
      setSelectedIDs([...selectedIDs, localId])
      if (isDesktop) {
        setShowActionView(true)
      }
    }
    if (userLists && selectedIDs) {
      if (userLists.length === selectedIDs.length + 1) {
        setHasMultipleSelect(true)
      }
    }
  }

  useEffect(() => {
    if (isDesktop && selectedIDs?.length === 0) {
      setIsBulkClick(false)
      setShowActionView(false)
      setHasMultipleSelect(false)
    }
  }, [selectedIDs])

  const handleMultipleCheckBox = () => {
    if (hasMultipleSelect) {
      setSelectedIDs([])
      setHasMultipleSelect(false)
    } else {
      const filtered = userLists?.map((m) => m?._id)
      const uniqueProfile = [...new Set(filtered, selectedIDs)]
      setSelectedIDs(uniqueProfile)
      setHasMultipleSelect(true)
      setShowActionView(true)
    }
  }

  const handleDropdownChange = async (dropdownName, value, fieldName) => {
    const setValueFunction = (prevSelectedValues) => {
      if (fieldName === 'isInvitationSent') value = value?.value === 'true'
      const newSelectedValues = {
        ...prevSelectedValues,
        [dropdownName?.fieldName]: value.displayName
          ? value.displayName
          : value,
      }
      return newSelectedValues
    }
    queryClient.setQueriesData(
      [`filter${capitalize(module)}Value`],
      setValueFunction,
    )
  }

  const disableField = (data) => {
    if (data?.mandatoryField) {
      let value = {}
      if (data?.filter) {
        value = filterValue
      }
      if (
        value[data?.mandatoryField] === undefined ||
        value[data?.mandatoryField] === null
      ) {
        return true
      }
      return false
    }
  }

  const getLookupData = (fieldName) => {
    const mapData = (data, displayNameKey) => {
      if (data)
        return data?.map((item, i) => ({
          displayName: item[displayNameKey],
          value: item[displayNameKey]?.replace(/ /g, ''),
          id: item._id,
          displayOrder: i,
        }))
    }

    let options = []

    if (fieldName === 'courseName' || fieldName === 'course') {
      options = mapData(courseData, 'courseName')
    } else if (fieldName === 'department') {
      options = mapData(departmentData, 'name')
    } else if (fieldName === 'graduation') {
      options = mapData(graduationData, 'name')
    } else if (fieldName === 'degree') {
      options = mapData(degreeData, 'name')
    }

    return options
  }

  const handleDisableUser = async () => {
    const date = new Date()

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const currentDate = `${year}-${month}-${day}`
    const user = await API.updateTenantUserRoleMappingAPI({
      id: disableUser?._id,
      tenantUserRoleMapping: {
        disabledOn: currentDate,
        isActive: false,
      },
    })
    if (!user.error) {
      toast.show('Disabled The User', { type: 'success' })
      setModalVisible(false)
      refetch()
    }
  }

  const { mutate: sendInvitation } = useSendInvitation({
    queryKey: `getAll${capitalize(module)}sSummaryAPI`,
    queryFunc: API.sendUserInvitationAPI,
    message: t('Success_Messages.INVITATION_SUCCESS'),
    onSuccess: () => {
      setIsBulkClick(false)
      setShowActionView(false)
      setHasMultipleSelect(false)
      setSelectedIDs([])
    },
  })
  const handleInviteClick = (item) => {
    if (!item?.isInvitationSent) {
      sendInvitation({
        emails: [item?.emailId],
      })
    } else {
      toast.show('Invite Already Sent', { type: 'danger' })
    }
  }
  const handleBulkInvite = () => {
    const emails = []
    selectedIDs?.forEach((localId) => {
      userLists?.filter((item) => {
        if (localId === item?._id && !item?.isInvitationSent) {
          emails.push(item?.emailId)
        }
        return null
      })
    })
    if (emails?.length !== 0) {
      sendInvitation({
        emails,
      })
    }
  }
  useEffect(() => {
    if (!isBulkClick) return
    handleBulkInvite()
  }, [isBulkClick])

  const viewProps = {
    disableField,
    errors,
    expanded,
    setExpanded,
    fetchNextPage,
    filterList,
    filterValue,
    getLookupData,
    goTo,
    handleCheckBox,
    handleDropdownChange,
    handleInviteClick,
    handleBulkInvite,
    handleMultipleCheckBox,
    hasMultipleSelect,
    hasNextPage,
    isFetchingNextPage,
    isFilterVisible,
    tableHeader,
    module,
    navigation,
    onSortSelected,
    refetch,
    selectedIDs,
    selectedSortValue,
    setErrors,
    setIsFilterVisible,
    setShowDrawer,
    setShowSortDrawer,
    showDrawer,
    showSortDrawer,
    sortList,
    totalCount,
    userFetching,
    userLists,
    isModalVisible,
    setModalVisible,
    handleDisableUser,
    profileImageList,
    totalCountRefecth,
    ...props,
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default Listing
