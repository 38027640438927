import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Image,
  ImageBackground,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native'
import { useTheme } from 'react-native-paper'

import { useQueryClient } from '@tanstack/react-query'

import { Text } from '@camped/components'
import { spacing } from '@camped/theme-provider'
import { ScreenLayout } from '@camped/utils'

import CoverImg from 'assets/Vadim2.png'

import { CampEdBrandingSkeleton } from '../skeleton/CampEdBrandingSkeleton'
import { CampEdLogo } from './branding/CampEdLogo'

export const CampEdBranding = ({ bgImage }) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const isDesktop = ScreenLayout.isWeb(width)
  const { colors } = useTheme()
  const queryClient = useQueryClient()

  const [validCoverImg, setValidCoverImg] = useState(true)

  const isLoading = queryClient.isFetching(['getDomainDetails'])

  if (isLoading) {
    return <CampEdBrandingSkeleton />
  }

  return (
    <>
      <View
        style={{
          minHeight: 61,
          marginBottom: isDesktop ? spacing.spacing7 : 0,
        }}
      >
        <CampEdLogo isDesktop={isDesktop} />
      </View>
      {isDesktop ? (
        <>
          <Image
            onError={() => setValidCoverImg(false)}
            source={validCoverImg ? bgImage : CoverImg}
            style={styles.img}
            resizeMode='cover'
          />
          <Text variant='displayMedium' style={styles.content(colors)}>
            {t('Auth.CAMPED_DRIVE')}
          </Text>
        </>
      ) : (
        <ImageBackground
          onError={() => setValidCoverImg(false)}
          source={validCoverImg ? bgImage : CoverImg}
          resizeMode='cover'
          style={styles.imgBg}
        />
      )}
    </>
  )
}
const styles = StyleSheet.create({
  img: {
    width: '100%',
    aspectRatio: 1.5,
    borderRadius: 4,
  },
  imgBg: {
    width: '100%',
    height: 224,
  },
  content: (colors) => ({
    color: colors.primary,
    marginTop: spacing.spacing8,
  }),
})
