import React, { useState } from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { useTheme } from 'react-native-paper'

export const WebView = (props) => {
  const { uri, iframeTitle } = props
  const { colors } = useTheme()

  const [isLoading, setIsLoading] = useState(true)

  const handleLoad = () => {
    setIsLoading(false)
  }

  return (
    <>
      {isLoading ? (
        <View style={styles.loadingWrapper}>
          <ActivityIndicator color={colors.primary} />
        </View>
      ) : null}

      <iframe
        width='100%'
        height='100%'
        src={uri}
        title={iframeTitle}
        frameBorder='0'
        allow='camera;microphone'
        onLoad={handleLoad}
      />
    </>
  )
}

const styles = StyleSheet.create({
  loadingWrapper: {
    flex: 1,
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
