import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import { useTheme } from 'react-native-paper'
import { WebView } from 'react-native-webview'

export default (props) => {
  const { setCanGoBack = () => {}, webViewRef } = props
  const { params } = props.route
  const { colors } = useTheme()

  return (
    <WebView
      style={{ flex: 1 }}
      source={{ uri: params.uri }}
      onLoadProgress={(event) => setCanGoBack(event.nativeEvent.canGoBack)}
      startInLoadingState
      onContentProcessDidTerminate={() => webViewRef.current.reload()}
      ref={webViewRef}
      allowsFullscreenVideo
      androidLayerType='hardware'
      mixedContentMode='always'
      renderLoading={() => (
        <View
          style={{
            flex: 1,
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator color={colors.primary} />
        </View>
      )}
    />
  )
}
